import React from 'react';

import DevicesListTable from './devicesListTable';
import DevicesListHeader from './devicesListHeader';
import DevicesListFooter from './devicesListFooter';
import ModalStatus from '../../common/modals/ModalStatus';

const DevicesList = () => (
    <div className="content-wrapper">
        <div className="container">
            <DevicesListHeader/>
            <DevicesListTable/>
            <DevicesListFooter/>
            <ModalStatus />
        </div>
    </div>
);

DevicesList.propTypes = {};

export default DevicesList;
