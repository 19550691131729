import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import React from 'react';
import ConfigurationForm from '../components/conf_template/form/form';
import ConfigurationHeader from '../components/conf_template/form/header';
import {CONFIGS_LIST_PAGE} from '../configuration/paths';
import * as actionTypes from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';


// TODO: use only get data, not render
class ConfigDetailsPage extends React.Component {
    componentDidMount() {
        this.props.fetchConfiguration({id: this.props.match.params.configId});
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="container">
                    <div className="content">
                        <ConfigurationHeader title={'Preconfig: ' + this.props.config.name}
                            link={CONFIGS_LIST_PAGE}/>
                        <ConfigurationForm editMode={true} deleteConfig={this.props.deleteConfig}
                            id={Number(this.props.match.params.configId)}
                            link={CONFIGS_LIST_PAGE} config={this.props.config}/>
                    </div>
                </div>
            </div>
        );
    }
}

ConfigDetailsPage.propTypes = {
    config: PropTypes.PropTypes.shape({
        plugins: PropTypes.arrayOf(
            PropTypes.object
        ),
        name: PropTypes.string,
    }),
    pluginChosen: PropTypes.bool,
    link: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            configId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    fetchSections: PropTypes.func.isRequired,
    deleteConfig: PropTypes.func,
    editMode: PropTypes.bool,
    fetchConfiguration: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    config: state.deviceConfiguration,
});

const mapDispatchToProps = {
    currentConfigDetails: makeAction(actionTypes.SELECTED_CONFIG_DETAILS),
    deleteConfig: makeAction(actionTypes.DELETE_DEVICE_CONFIGURATION),
    fetchSections: makeAction(actionTypes.FETCH_SECTIONS_EDIT),
    fetchConfiguration: makeAction(actionTypes.FETCH_CONFIGURATION),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigDetailsPage));
