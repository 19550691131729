import {call, put, takeLatest, take, all} from 'redux-saga/effects';
import request, {GET, PUT} from '../../services/request';
import {API_URL} from '../../configuration/config';
import {handleError} from './errors';
import * as actionTypes from '../actionTypes';
import {ERROR as get_error_notification, SUCCESS as get_success_notification} from '../../configuration/notifications';

function *fetch() {
    try {
        const url = `${API_URL}companies/`;
        const response = yield call(request, GET, url);
        yield put({
            type: actionTypes.FETCH_COMPANIES_SUCCESS,
            payload: response.data,
        });
    } catch (e) {
        console.error(e);
        yield handleError(e);
        yield put({
            type: actionTypes.FETCH_COMPANIES_FAILED,
            error: e,
        });
    }
}

function *fetchCompanies() {
    yield takeLatest(actionTypes.FETCH_COMPANIES, fetch);
}

function *manageCompany() {
    try {
        const {payload: {devices, company}} = yield take(actionTypes.MANAGE_COMPANY);
        yield request(PUT, API_URL + 'companies/multiple/', {company: company.value, devices: devices});
        yield put({
            type: actionTypes.ADD_NOTIFICATION,
            notification: get_success_notification(`Company ${company.label} updated`),
        });
    } catch (e) {
        console.error(e);
        yield handleError(e);
        yield put({
            type: actionTypes.MANAGE_COMPANY,
            notification: get_error_notification('Managing company failed.'),
        });
    }
}


export default function *companySaga() {
    yield all([
        fetchCompanies(),
        manageCompany(),
    ]);
}
