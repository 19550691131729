import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as actionTypes from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';
import PreInstallPrinter from '../components/devices/details/printers/preInstall/preInstallPrinter';

class PreInstallPrinterPage extends React.Component {
    componentDidMount() {
        this.props.preCreatePrinter(this.props.match.params.deviceId);
    }

    render() {
        return (
            <PreInstallPrinter device_id={this.props.match.params.deviceId} installed_printer={this.props.installed_printer}/>
        );
    }
}

PreInstallPrinterPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string,
        }).isRequired,
    }).isRequired,
    installed_printer: PropTypes.object.isRequired,
    preCreatePrinter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    installed_printer: state.device.installed_printer,
});

const mapDispatchToProps = {
    preCreatePrinter: makeAction(actionTypes.PRE_CREATE_PRINTER),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PreInstallPrinterPage));
