import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';
import CollapsingGroup from '../CollapsingGroup';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import tooltip from '../../../../common/tooltip';
import CopyButton from '../../CopyButton';

const OraclePlugin = ({plugin, endpoint_url}) => (
    <div>
        <CollapsingGroup id="mainInfo" name="Main Plugin Information">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('name', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.name || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('type', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('is_active', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('is_active', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    {plugin.is_active ? translate('active', {'scope': 'common'}) : translate('inactive', {'scope': 'common'})}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('endpoint_url', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('endpoint_url', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                    <CopyButton target={`endpoint_url_${plugin.id}`} />
                </Col>
                <Col xs={6} id={`endpoint_url_${plugin.id}`}>{endpoint_url || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="pmsConnection" name="PMS Connection">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('host', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('host', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.host || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('port', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('port', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.port || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="timeouts" name="Timeouts, Intervals & Amounts">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('pms_connection_interval', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('pms_connection_interval', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.check_interval || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_interval', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.shift_close_check_interval || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_retry_interval', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close_retry_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.shift_close_retry_interval || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_max_retry_attempts', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close_max_retry_attempts', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.shift_close_max_retry_attempts || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('max_rounding_fix', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('max_rounding_fix', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.max_rounding_fix || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="features" name="Features">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('split_discount', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('split_discount', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.split_discount.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('negative_postings', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('negative_postings', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.allow_negative_postings.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('block_zero_payments', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('block_zero_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.block_zero_payments.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('auto_grab_outlet_id', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('auto_grab_outlet_id', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.business_id_outlet.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('duplicate_check', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('duplicate_check', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate((!plugin.disable_duplicate_check).toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('serviceperiod', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('serviceperiod', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.enable_serviceperiod.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate((!plugin.disable_shift_close).toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('internal', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('internal', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.forward_internal ? 'Forward' : 'Block'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('nonturnover', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('nonturnover', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.forward_nonturnover ? 'Forward' : 'Block'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.constraints_behavior'})}:
                    <OverlayTrigger overlay={
                        tooltip(translate('constraints_behavior', {scope: 'tooltips.hotel_systems.plugin'}) +
                            '\n' + translate('all', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('non_zero', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_non_zero_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('items', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_sub_items_only', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('main', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_constraint_main_item', {scope: 'tooltips.hotel_systems.items_behaviour'})
                        )}
                    >
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.constraints_behavior ? translate(plugin.constraints_behavior, {scope: 'devices.plugins.constraints_behavior'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.aggregated_items_behavior'})}:
                    <OverlayTrigger overlay={tooltip(translate('aggregated_items_behavior', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle" />
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.aggregated_items_behavior ? translate(plugin.aggregated_items_behavior, {scope: 'devices.plugins.aggregated_items_behavior'}) : '---'}</Col>
            </Row>
        </CollapsingGroup>
    </div>
);

OraclePlugin.propTypes = {
    plugin: PropTypes.object.isRequired,
    endpoint_url: PropTypes.string.isRequired,
};

export default OraclePlugin;
