import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';
import $ from 'jquery';

import tooltip from '../../common/tooltip';


const NewHeaderCell = ({children, className, currentOrder, field, label, onSortChange, tooltipContent, tooltipIcon = false}) => {
    const sort = currentOrder === field ? 1 : currentOrder === '-' + field ? -1 : 0;

    /* TODO Extract tooltip placement to outer component */
    return (
        <React.Fragment>
            {tooltipContent === '' ?
                <th className={className + (sort ? sort === -1 ? ' desc' : ' asc' : '')}>
                    <span className="th-label" onClick={() => {
                        const newSort = sort === 1 ? -1 : sort + 1;
                        onSortChange(newSort === 0 ? '' : newSort === 1 ? field : '-' + field);
                    }}>
                        {label}
                    </span>
                    {children}
                </th>
                :
                tooltipIcon ?
                    <th className={className + (sort ? sort === -1 ? ' desc' : ' asc' : '')}>
                        <span className="th-label" onClick={() => {
                            const newSort = sort === 1 ? -1 : sort + 1;
                            onSortChange(newSort === 0 ? '' : newSort === 1 ? field : '-' + field);
                        }}>
                            {label}
                            <OverlayTrigger placement="top" overlay={tooltip(tooltipContent)}>
                                <i className="inline-block fa fa-info-circle modal-trigger"/>
                            </OverlayTrigger>
                        </span>
                        {children}
                    </th>
                    :
                    field === 'status' ?
                        <th className={className + (sort ? sort === -1 ? ' desc' : ' asc' : '')}>
                            <span className="th-label" onClick={() => {
                                const newSort = sort === 1 ? -1 : sort + 1;
                                onSortChange(newSort === 0 ? '' : newSort === 1 ? field : '-' + field);
                            }}>
                                {label}
                                <OverlayTrigger placement="top" overlay={tooltip(translate('click_to_see', {scope: 'tooltips.status'}))}>
                                    <i className="inline-block fa fa-info-circle modal-trigger" onClick={() => $('#statusModal').modal('toggle')}/>
                                </OverlayTrigger>
                            </span>
                            {children}
                        </th>
                        :
                        field === 'status_invoices' || field === 'payment_names' ?
                            <th className={className + (sort ? sort === -1 ? ' desc' : ' asc' : '')}>
                                <span className="th-label" onClick={() => {
                                    const newSort = sort === 1 ? -1 : sort + 1;
                                    onSortChange(newSort === 0 ? '' : newSort === 1 ? field : '-' + field);
                                }}>
                                    {label}
                                    <OverlayTrigger placement="top" overlay={tooltip(translate('click_to_see', {scope: 'tooltips.status'}))}>
                                        <i className="inline-block fa fa-info-circle modal-trigger" onClick={() => $('#statusInvoicesModal').modal('toggle')}/>
                                    </OverlayTrigger>
                                </span>
                                {children}
                            </th>
                            :
                            <OverlayTrigger placement={'top'} overlay={tooltip(tooltipContent)}>
                                <th className={className + (sort ? sort === -1 ? ' desc' : ' asc' : '')}>
                                    <span className="th-label" onClick={() => {
                                        const newSort = sort === 1 ? -1 : sort + 1;
                                        onSortChange(newSort === 0 ? '' : newSort === 1 ? field : '-' + field);
                                    }}>
                                        {label}
                                    </span>
                                    {children}
                                </th>
                            </OverlayTrigger>
            }
        </React.Fragment>
    );
};

NewHeaderCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    currentOrder: PropTypes.string,
    field: PropTypes.string,
    label: PropTypes.string,
    tooltipContent: PropTypes.string,
    tooltipIcon: PropTypes.bool,
    onSortChange: PropTypes.func,
};

export default NewHeaderCell;
