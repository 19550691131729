import React from 'react';
import translate from 'counterpart';

const printerInProgress = () => (
    <div>
        <h1>{translate('in_progress', {scope: 'modals'})}</h1>
    </div>
);

printerInProgress.propTypes = {};

export default printerInProgress;
