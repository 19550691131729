import {createSelector} from 'reselect';
import {deviceConfiguration, extraEdit, lanEdit} from '../deviceConfiguration/stateSelector';
import {detailsExtra, deviceDetails, deviceDetailsConfiguration, deviceGlobal, deviceSettings} from './stateSelector';
import _ from 'lodash';
import idx from 'idx';
import {editMode} from '../navigation/stateSelector';
import {editableStatus, validationSelector} from './validation';
import translate from 'counterpart';

const ethernet_section = createSelector(
    deviceDetails,
    (device) => _.find(idx(device, (d) => d.device_configuration.ethernets) || [], {eth_number: 0})
);

const extra_ethernet_section = createSelector(
    deviceDetails,
    (device) => _.find(idx(device, (d) => d.device_configuration.ethernets) || [], {eth_number: 1})
);

const select_options = [{label: translate('static', {scope: 'devices.device_network'}), value: 'STATIC'}, {label: translate('dhcp', {scope: 'devices.device_network'}), value: 'DHCP'}];

export const deviceNetwork = createSelector(
    deviceConfiguration,
    editMode,
    deviceGlobal,
    deviceSettings,
    ethernet_section,
    validationSelector,
    editableStatus,
    deviceDetailsConfiguration,
    (config, edit, global, settings, ethernetSection, validation, editable, detailsConfig) => ({
        config,
        editMode: edit,
        global_section: {
            server_thread_pool: String(global.server_thread_pool) || '---',
            server_socket_host: String(global.server_socket_host) || '---',
            server_socket_port: String(global.server_socket_port) || '---',
            ssl_enabled: global.ssl_enabled ? translate('enabled', {scope: 'devices.device_network'}) : translate('disabled', {scope: 'devices.device_network'}),
        },
        ethernet_section: ethernetSection,
        proxy: detailsConfig.proxy || '---',
        validation,
        editable,
    })
);

export const deviceNetworkLAN = createSelector(
    editMode,
    lanEdit,
    ethernet_section,
    validationSelector,
    editableStatus,
    (edit, lan, ethernet, validation, editable) => ({
        editMode: edit,
        lanEdit: lan,
        name: 'LAN',
        header: translate('lan', {scope: 'devices.device_network'}),
        lan: ethernet,
        options: select_options,
        validation,
        editable,
    })
);

export const deviceNetworkExtra = createSelector(
    editMode,
    extraEdit,
    extra_ethernet_section,
    detailsExtra,
    deviceConfiguration,
    validationSelector,
    editableStatus,
    (edit, extra, ethernetExtra, extraDetails, config, validation, editable) => ({
        editMode: edit,
        extraEdit: extra,
        name: 'usb_to_ethernet',
        header: translate('usb_to_ethernet', {scope: 'devices.device_network'}),
        extra: ethernetExtra,
        enabled: config.ethernets && config.ethernets.length > 1 && !extra.disabled,
        extraEnabled: !extraDetails.disabled,
        options: select_options,
        validation,
        editable,
    })
);
