import disableWrapperAction from '../redux/actions/common/navigation/disableWrapper';
import PropTypes from 'prop-types';
import React from 'react';

import {connect} from 'react-redux';
import translate from 'counterpart';

class UnauthorizedPage extends React.Component {
    componentDidMount() {
        this.props.disableWrapper();
    }

    render() {
        return (
            <div>
                <b>403 - {translate('unauthorized', {scope: 'common'})}</b>
                <p>{translate('unauthorized', {scope: 'common'})}</p>
            </div>
        );
    }
}

UnauthorizedPage.propTypes = {
    disableWrapper: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    disableWrapper: disableWrapperAction,
};

export default connect(
    null,
    mapDispatchToProps
)(UnauthorizedPage);
