import {createSelector} from 'reselect';
import _ from 'lodash';

export const deviceBranch = (state) => state.device;

export const invoiceDetails = createSelector(
    deviceBranch,
    (device) => device.invoice
);

export const deviceDetails = createSelector(
    deviceBranch,
    (device) => device.device
);

export const deviceId = createSelector(
    deviceDetails,
    (device) => device.id
);

export const deviceDetailsConfiguration = createSelector(
    deviceDetails,
    (device) => device.device_configuration
);

export const deviceStatus = createSelector(
    deviceDetails,
    (device) => device.status
);

export const devicePlugins = createSelector(
    deviceDetailsConfiguration,
    (config) => config.plugins.sort((first, second) => {
        const order_active = first.is_active - second.is_active;
        if (order_active) {
            return -order_active;
        }
        return first.name > second.name;
    })
);

export const invoices = createSelector(
    deviceBranch,
    (device) => device.invoices
);

export const invoicesColumns = createSelector(
    deviceBranch,
    (device) => device.invoices_columns
);

export const invoicesCount = createSelector(
    deviceBranch,
    (device) => device.invoices_count
);

export const invoicesParams = createSelector(
    deviceBranch,
    (device) => device.invoices_params
);

export const logEntries = createSelector(
    deviceBranch,
    (device) => device.log_entries
);

export const logCount = createSelector(
    deviceBranch,
    (device) => device.log_count
);

export const logParams = createSelector(
    deviceBranch,
    (device) => device.log_params
);

export const syncInProgress = createSelector(
    deviceBranch,
    (device) => device.syncing
);

export const selectedCompany = createSelector(
    deviceBranch,
    (device) => device.selected_company
);

export const filteredEntries = createSelector(
    logEntries,
    (log_entries) => _.filter(log_entries, (value) => value.level >= 40)
);

export const detailsExtra = createSelector(
    deviceDetailsConfiguration,
    (config) => (config ? config.ethernets[1] : null)
);

export const deviceGlobal = createSelector(
    deviceDetailsConfiguration,
    (config) => (config ? config.global_section : null)
);

export const deviceSettings = createSelector(
    deviceDetailsConfiguration,
    (config) => (config ? config.settings_section : null)
);
