import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';

import Loader from '../common/loaderBox';
import EditableCell from '../common/editableCell';
import makeAction from '../../redux/actions/common/makeAction';
import {FETCH_IMAGES, IMAGES_SET_PARAMS, IMAGES_UPDATE_IMAGE} from '../../redux/actionTypes';
import debouncedFunc from '../../services/debounce';
import NewHeaderCell from '../common/table/newHeaderCell';
import translate from 'counterpart';
import {IMAGE_TYPES_OPTIONS} from '../../configuration/constants';

const debounce = debouncedFunc();

const ImgListTable = ({fetchConfigs, images, imagesDates, imagesVersions, inProgress, params, setParams, updateImage, changes}) => {
    const getSorting = (field) => {
        const arg = {...params, ordering: field};
        setParams(arg);
        fetchConfigs(arg);
    };
    return (<div className="table-wrapper content">
        <table className="table main-list table-hover">
            <thead>
                <tr>
                    <NewHeaderCell className="cell-sm nofilter sortable" field="date" label="Date"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('date', {scope: 'tooltips.image_list'})}>
                        <Select clearable={false} searchable={false} value={params.dateIndex || 0} options={imagesDates} onChange={(e) => {
                            const arg = {...params, date: e.value === 0 ? '' : e.label, dateIndex: e.value};
                            setParams(arg);
                            fetchConfigs(arg);
                        }}/>
                    </NewHeaderCell>
                    <NewHeaderCell className="cell-sm nofilter sortable" field="version" label="Version"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('version', {scope: 'tooltips.image_list'})}>
                        <Select clearable={false} searchable={false} value={params.versionIndex || 0} options={imagesVersions} onChange={(e) => {
                            const arg = {...params, version: e.value === 0 ? '' : e.label, versionIndex: e.value};
                            setParams(arg);
                            fetchConfigs(arg);
                        }}/>
                    </NewHeaderCell>
                    <NewHeaderCell className="cell-sm nofilter sortable" field="type" label="Type"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('type', {scope: 'tooltips.image_list'})}>
                        <Select clearable={false} searchable={false} value={params.type || 0} options={IMAGE_TYPES_OPTIONS} onChange={(e) => {
                            const arg = {...params, type: e.value === 0 ? '' : e.value};
                            setParams(arg);
                            fetchConfigs(arg);
                        }}/>
                    </NewHeaderCell>
                    <NewHeaderCell className="sortable" field="name" label="Name"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('name', {scope: 'tooltips.image_list'})}>
                        <div className="input-group">
                            <input type="search" className="form-control" value={params.name} onChange={(e) => {
                                const arg = {...params, name: e.target.value};
                                setParams(arg);
                                debounce(fetchConfigs, arg);
                            }}/>
                            <span className="input-group-addon" onClick={() => {
                                const arg = {...params, name: ''};
                                setParams(arg);
                                fetchConfigs(arg);
                            }}>
                                { params.name ?
                                    <i className="fa fa-remove" /> :
                                    null
                                }
                            </span>
                        </div>
                    </NewHeaderCell>
                    <NewHeaderCell className="nofilter sortable" field="changelog" label="Changelog"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('changelog', {scope: 'tooltips.image_list'})}/>
                    <th className="nofilter">
                        <span className="th-label">{translate('link', {scope: 'system_images'})}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {inProgress ?
                    <tr><td colSpan="5"><Loader/></td></tr>
                    :
                    (images || []).map((image, index) => (
                        <tr key={index}>
                            <td>{image.date}</td>
                            <td>{image.version}</td>
                            <td>{IMAGE_TYPES_OPTIONS[image.type].label}</td>
                            <td>{image.name}</td>
                            <td><EditableCell
                                value={(changes[image.id] || {}).changelog ? changes[image.id].changelog : image.changelog}
                                onEditFinished={(value) => {
                                    updateImage({'id': image.id, data: {'changelog': value}});
                                }}
                            /></td>
                            <td><a href={image.link}>{translate('click_to_download', {scope: 'system_images'})}</a></td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </div>);
};

ImgListTable.propTypes = {
    fetchConfigs: PropTypes.func.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.object
    ),
    imagesDates: PropTypes.arrayOf(
        PropTypes.object,
    ),
    imagesVersions: PropTypes.arrayOf(
        PropTypes.object,
    ),
    inProgress: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changes: PropTypes.object,
    setParams: PropTypes.func.isRequired,
    updateImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    inProgress: state.sysImageReducer.inProgress,
    images: state.sysImageReducer.results,
    imagesDates: state.sysImageReducer.dates,
    imagesVersions: state.sysImageReducer.versions,
    params: state.sysImageReducer.params,
    changes: state.sysImageReducer.changes,
});

const mapDispatchToProps = {
    fetchConfigs: makeAction(FETCH_IMAGES),
    setParams: makeAction(IMAGES_SET_PARAMS),
    updateImage: makeAction(IMAGES_UPDATE_IMAGE),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImgListTable);
