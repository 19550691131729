import {createSelector} from 'reselect';
import {getNavigationBranch} from './stateSelector';

export const getNavigationWrapper = createSelector(
    getNavigationBranch,
    (navigation) => ({
        disabled: navigation.disabled,
        lang: navigation.lang,
    })
);
