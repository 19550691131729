import {createSelector} from 'reselect';
import translate from 'counterpart';
import _ from 'lodash';
import {DEVICE_STATUS} from '../../../configuration/constants';
import {
    devicesChecked, devicesColumns, devicesFilters, devicesInProgress, devicesList, devicesSort,
    softList,
} from './stateSelector';

export const devicesListTable = createSelector(
    [
        devicesInProgress,
        devicesChecked,
        devicesList,
        devicesSort,
        devicesFilters,
        softList,
        devicesColumns,
        (state) => state.companies.list,
    ],
    (inProgress, checked, list = [], ordering = 'id', filters, soft, columns, companyOptions) => ({
        inProgress,
        checked,
        list: list.map((device) => ({
            ...device,
            relevant: device.relevant ? 'Relevant' : 'Irrelevant',
        })),
        ordering,
        filters,
        columns,
        selectColumnOptions: {
            soft_version: [
                {value: '', label: translate('all', {scope: 'devices.table_options'})},
                ...soft,
            ],
            status: [
                {value: '', label: translate('all', {scope: 'devices.table_options'})},
                ..._.toPairs(DEVICE_STATUS).map((pair) => ({
                    value: pair[1],
                    label: translate(pair[1], {scope: 'devices.table_options'}),
                })),
            ],
            relevant: [
                {
                    value: 'True',
                    label: translate('relevant_devices', {scope: 'devices.table_columns.column_values.relevant_values'}),
                },
                {
                    value: 'False',
                    label: translate('irrelevant_devices', {scope: 'devices.table_columns.column_values.relevant_values'}),
                },
                {
                    value: '',
                    label: translate('all_devices', {scope: 'devices.table_columns.column_values.relevant_values'}),
                },
            ],
            company_id: [
                {
                    value: '',
                    label: translate('all', {scope: 'devices.table_options'}),
                },
                {
                    value: 'null',
                    label: translate('undefined', {scope: 'devices.table_options'}),
                },
                ...companyOptions.map((obj) => ({
                    value: obj.id,
                    label: obj.name,
                })),
            ],
            plugins: [
                {
                    value: '',
                    label: translate('all', {scope: 'devices.table_options'}),
                },
                {
                    value: 'OraclePlugin',
                    label: translate('OraclePlugin', {scope: 'devices.plugins'}),
                },
                {
                    value: 'SihotPlugin',
                    label: translate('SihotPlugin', {scope: 'devices.plugins'}),
                },
                {
                    value: 'ProtelPlugin',
                    label: translate('ProtelPlugin', {scope: 'devices.plugins'}),
                },

            ],
        },
    })
);
