import PropTypes from 'prop-types';
import React from 'react';

const Switch = ({setInput, section, sectionName, fieldName, innerClass}) => (
    <div>
        <div className={'mode-edit onoffswitch ' + innerClass}>
            <input type="checkbox"
                onChange={() => setInput(
                    sectionName ?
                        {
                            [sectionName]: {
                                ...section,
                                [fieldName]: !section[fieldName],
                            },
                        }
                        :
                        {
                            ...section,
                            [fieldName]: !section[fieldName],
                        }
                )}
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id={fieldName + 'Id'}
                checked={section[fieldName] || false}/>
            <label className="onoffswitch-label" htmlFor={fieldName + 'Id'}>
                <span className="onoffswitch-inner"/>
                <span className="onoffswitch-switch"/>
            </label>
        </div>
    </div>
);

Switch.propTypes = {
    setInput: PropTypes.func,
    section: PropTypes.object,
    sectionName: PropTypes.string,
    fieldName: PropTypes.string,
    innerClass: PropTypes.string,
};

export default Switch;
