import PropTypes from 'prop-types';
import React from 'react';

const CollapsingGroup = ({id, name, children}) => (
    <div>
        <div role="tab" id="headingOne">
            <h4 className="panel-title">
                <a style={{'textDecoration': 'none'}} role="button" data-toggle="collapse" href={'#' + id}
                    aria-expanded="true" aria-controls="collapseOne">
                    <i className="fa fa-angle-down left"/>
                    {name}
                </a>
            </h4>
        </div>
        <div id={id} className="collapse in" role="tabpanel" aria-labelledby="headingOne">
            {children}
        </div>
    </div>
);

CollapsingGroup.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
};

export default CollapsingGroup;
