import PropTypes from 'prop-types';
import React from 'react';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import tooltip from '../../../../common/tooltip';

const NetworkFields = ({obj}) => (
    <div className="panel-body">
        {obj &&
            <dl className="dl-horizontal dl-casual">
                <dt>
                    {translate('type', {scope: 'devices.device_network'})}:
                    {obj.type === 'STATIC' ?
                        <OverlayTrigger overlay={tooltip(translate('static', {scope: 'tooltips.device_details.network'}))}>
                            <i className="error-icon fa fa-exclamation-circle"/>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger overlay={tooltip(translate('dhcp', {scope: 'tooltips.device_details.network'}))}>
                            <i className="error-icon fa fa-exclamation-circle"/>
                        </OverlayTrigger>
                    }
                </dt>
                <dd>{obj.type}</dd>
                <dt>
                    {translate('ip_address', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('ip_address', {scope: 'tooltips.device_details.network'}))}>
                        <i className="error-icon fa fa-exclamation-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>{obj.address || '---'}</dd>
                <dt>
                    {translate('subnet_mask', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('subnet_mask', {scope: 'tooltips.device_details.network'}))}>
                        <i className="error-icon fa fa-exclamation-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>{obj.netmask || '---'}</dd>
                <dt>
                    {translate('gateway', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('gateway', {scope: 'tooltips.device_details.network'}))}>
                        <i className="error-icon fa fa-exclamation-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>{obj.gateway || '---'}</dd>
                <dt>
                    {translate('dns_server', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('dns_server', {scope: 'tooltips.device_details.network'}))}>
                        <i className="error-icon fa fa-exclamation-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>{obj.dns_nameservers || '---'}</dd>
            </dl>
        }
    </div>
);

NetworkFields.propTypes = {
    obj: PropTypes.object.isRequired,
};

export default NetworkFields;
