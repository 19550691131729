import {createSelector} from 'reselect';
import _ from 'lodash';
import {DEVICES_LIST_ADDABLE_COLUMNS} from '../../../configuration/constants';
import {devicesColumns, devicesCount, devicesLimit, devicesPage} from './stateSelector';
import translate from 'counterpart';

export const devicesListHeader = createSelector(
    devicesLimit,
    devicesCount,
    devicesPage,
    devicesColumns,
    (limit, count, page, columns) => ({
        limit,
        count,
        page,
        columns,
        selectableColumns: DEVICES_LIST_ADDABLE_COLUMNS.map(
            (obj) => ({
                ...obj,
                label: translate(obj.name, {scope: 'devices.table_columns'}),
                selected: _.findIndex(columns, ['name', obj.name]) !== -1,
            })
        ),
    })
);
