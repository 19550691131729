import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import Select from 'react-select';
import * as _ from 'lodash';
import translate from 'counterpart';

import {mappingsSelector} from '../../../../redux/selectors/device/hotelSystems';
import MappingsAdd from './MappingsAdd';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import {MAPPING_TYPES} from '../../../../configuration/constants';
import tooltip from '../../../common/tooltip';

class Mappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {key: '', value: '', vatrate: ''};
    }

    componentDidUpdate() {
        if (!_.isEmpty(this.props.options) && !this.props.id) {
            this.props.selectID(this.props.options[0].value);
        }
    }

    render() {
        return (
            <div className="col-xs-8 col-xs-offset-2">
                <h4>
                    <span>
                        {translate('mappings', {scope: 'devices.plugins'})}
                    </span>
                    {(this.props.edit || this.props.plugin.id === 'new') &&
                    <MappingsAdd/>
                    }
                </h4>
                <div className="row">
                    <div className="col-xs-5">
                        <h5>
                            {translate('business_id', {scope: 'devices.plugins'})}
                            <OverlayTrigger overlay={tooltip(translate('business_id', {scope: 'tooltips.hotel_systems.plugin'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </h5>
                    </div>
                    <div className="col-xs-7 text-right">
                        <Select
                            value={this.props.id}
                            options={this.props.options}
                            onChange={(option) => {
                                this.props.selectID(option.value);
                            }}
                            searchable={false}
                            clearable={false}
                            noResultsText={translate('no_results_found', {scope: 'common'})}
                            placeholder={translate('select_value', {scope: 'common'})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-5">
                        <h5>
                            {translate('mapping_type', {scope: 'devices.plugins'})}
                            <OverlayTrigger overlay={tooltip(translate('mapping_type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </h5>
                    </div>
                    <div className="col-xs-7 text-right">
                        <Select
                            value={this.props.name}
                            options={this.props.names}
                            onChange={(option) => {
                                this.props.selectName(option.value);
                            }}
                            searchable={false}
                            clearable={false}
                            noResultsText={translate('no_results_found', {scope: 'common'})}
                            placeholder={translate('select_value', {scope: 'common'})}
                        />
                    </div>
                </div>
                <div className="table-wrapper content">
                    <table className="table main-list table-hover table-sm">
                        <thead>
                            <tr>
                                <th>{translate('gastrofix_data', {scope: 'devices.plugins'})}</th>
                                {this.props.mapping && this.props.mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING &&
                                    <th>{translate('vat_rate', {scope: 'devices.plugins'})}</th>
                                }
                                <th>{translate('hotel_data', {scope: 'devices.plugins'})}</th>
                                {this.props.edit && this.props.mapping.type !== MAPPING_TYPES.SHIFT_CLOSE &&
                                <th>&nbsp;</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.items.map((item, key) => (
                                <tr key={key}>
                                    <td>{this.props.mapping && this.props.mapping.type === MAPPING_TYPES.SHIFT_CLOSE ? translate(item.key, {scope: 'shift_close'}) : item.key}</td>
                                    {this.props.mapping && this.props.mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING &&
                                        <td>{item.vatrate}</td>
                                    }
                                    {this.props.edit && this.props.mapping && this.props.mapping.type === MAPPING_TYPES.SHIFT_CLOSE &&
                                        <td>
                                            <input value={item.value} placeholder={translate('type_to_add', {scope: 'placeholders'})}
                                                className="form-control"
                                                onChange={(event) => this.props.changePairValue({
                                                    item: {
                                                        ...item,
                                                        value: event.target.value,
                                                    },
                                                    pluginId: this.props.mapping.plugin,
                                                    mappingId: this.props.mapping.id,
                                                })}
                                            />
                                        </td>}
                                    { (!this.props.edit || (this.props.mapping && this.props.mapping.type !== MAPPING_TYPES.SHIFT_CLOSE)) &&
                                    <td>{item.value}</td>
                                    }
                                    {this.props.edit && this.props.mapping.type !== MAPPING_TYPES.SHIFT_CLOSE &&
                                    <td className="td-icon">
                                        <i
                                            className="fa fa-close"
                                            onClick={() => this.props.removeMappingPair(
                                                {
                                                    item: {
                                                        ...item,
                                                    },
                                                    pluginId: this.props.mapping.plugin,
                                                    mappingId: this.props.mapping.id,
                                                }
                                            )}
                                        />
                                    </td>
                                    }
                                </tr>
                            ))}
                            {((this.props.edit || this.props.selected === 'new')
                                && this.props.name && this.props.id && this.props.mapping.type !== MAPPING_TYPES.SHIFT_CLOSE) &&
                            <tr>
                                <td className="input-wrapper">
                                    <input
                                        placeholder={translate('type_to_add', {scope: 'placeholders'})}
                                        className="form-control"
                                        value={this.state.key}
                                        onChange={(event) => this.setState({key: event.target.value})}
                                    />
                                </td>
                                {this.props.mapping && this.props.mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING &&
                                    <td className="input-wrapper">
                                        <input
                                            placeholder={translate('type_to_add', {scope: 'placeholders'})}
                                            className="form-control"
                                            value={this.state.vatrate}
                                            onChange={(event) => this.setState({vatrate: event.target.value})}
                                        />
                                    </td>
                                }
                                <td className="input-wrapper">
                                    <input
                                        placeholder={translate('type_to_add', {scope: 'placeholders'})}
                                        className="form-control"
                                        value={this.state.value}
                                        onChange={(event) => this.setState({value: event.target.value})}
                                    />
                                </td>
                                {this.props.mapping && <td className="td-icon">
                                    <i
                                        className="fa fa-plus"
                                        onClick={() => {
                                            this.props.addMappingPair({
                                                pluginId: this.props.mapping.plugin,
                                                mappingId: this.props.mapping.id,
                                                mappingType: this.props.mapping.type,
                                                key: this.state.key,
                                                vatrate: this.state.vatrate,
                                                value: this.state.value,
                                            });
                                            this.setState({value: '', key: '', vatrate: ''});
                                        }}
                                    />
                                </td>}
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

Mappings.propTypes = {
    mappings: PropTypes.object.isRequired,
    mapping: PropTypes.object,
    edit: PropTypes.bool.isRequired,
    selectID: PropTypes.func.isRequired,
    selectName: PropTypes.func.isRequired,
    addMappingPair: PropTypes.func.isRequired,
    removeMappingPair: PropTypes.func.isRequired,
    changePairValue: PropTypes.func.isRequired,
    id: PropTypes.any,
    selected: PropTypes.any,
    options: PropTypes.array,
    names: PropTypes.array,
    items: PropTypes.array,
    name: PropTypes.any,
    plugin: PropTypes.any,
};

const mapDispatchToProps = {
    selectID: makeAction(actionTypes.SELECT_ID),
    selectName: makeAction(actionTypes.SELECT_NAME),
    addMappingPair: makeAction(actionTypes.ADD_MAPPING_PAIR),
    removeMappingPair: makeAction(actionTypes.REMOVE_MAPPING_PAIR),
    changePairValue: makeAction(actionTypes.CHANGE_PAIR_VALUE),
};

export default connect(mappingsSelector, mapDispatchToProps)(Mappings);
