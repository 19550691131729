import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import Select from 'react-select';
import translate from 'counterpart';

import DriversSelect from './DriversDropdown';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import PrinterFormFooter from './printerFormFooter';
import tooltip from '../../../common/tooltip';

const printerForm = ({changePrinterName, changePrinterDescription, changePrinterLocation, changePrinterPaperSize, device_id, printer, edit}) => (
    <dl className="dl-horizontal dl-casual">
        <dt>
            {translate('name', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <input disabled={edit} className="form-control" id="Name" defaultValue={printer.name} onBlur={(event) => changePrinterName(event.target.value)}/>
        </dd>
        <dt>
            {translate('description', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('description', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <input className="form-control" id="Name" defaultValue={printer.description} onBlur={(event) => changePrinterDescription(event.target.value)}/>
        </dd>
        <dt>
            {translate('location', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('location', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <input className="form-control" id="Name" defaultValue={printer.location} onBlur={(event) => changePrinterLocation(event.target.value)}/>
        </dd>
        <dt>
            {translate('connection', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('connection', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <input className="form-control" disabled id="Name" value={printer.connection}/>
        </dd>
        <dt>
            {translate('url', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('url', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <input className="form-control" disabled id="Name" value={printer.uri}/>
        </dd>
        <dt>
            {translate('model', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('model', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <input className="form-control" disabled id="Name" value={printer.make_and_model}/>
        </dd>
        <dt>
            {translate('drivers', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('drivers', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <DriversSelect printer_drivers={printer.drivers} edit={edit} installed_driver={printer.selected_driver}/>
        </dd>
        <dt>
            {translate('paper_size', {scope: 'devices.printers'})}:
            <OverlayTrigger overlay={tooltip(translate('paper_size', {scope: 'tooltips.printers'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </dt>
        <dd>
            <Select value={printer.paper_size} clearable={false} searchable={false} options={[
                {value: 'A4', label: 'A4'},
                {value: 'A5', label: 'A5'},
            ]} onChange={(newOption) => changePrinterPaperSize(newOption.value)}/>
        </dd>
        <PrinterFormFooter edit={edit} device_id={device_id} printer={printer}/>
    </dl>
);

printerForm.propTypes = {
    printer: PropTypes.object.isRequired,
    device_id: PropTypes.string.isRequired,
    changePrinterName: PropTypes.func.isRequired,
    changePrinterDescription: PropTypes.func.isRequired,
    changePrinterLocation: PropTypes.func.isRequired,
    changePrinterPaperSize: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    changePrinterName: makeAction(actionTypes.CHANGE_PRINTER_NAME),
    changePrinterDescription: makeAction(actionTypes.CHANGE_PRINTER_DESCRIPTION),
    changePrinterLocation: makeAction(actionTypes.CHANGE_PRINTER_LOCATION),
    changePrinterPaperSize: makeAction(actionTypes.CHANGE_PRINTER_PAPER_SIZE),
};

export default connect(null, mapDispatchToProps)(printerForm);
