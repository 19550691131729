import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import idx from 'idx';
import {MAPPING_TYPES} from '../../configuration/constants';
import {getId} from './helpers/utils';

const DEFAULT_STATE = {
    global_section: {},
    settings_section: {},
    ethernets: [],
    plugins: [],
    pluginsToDelete: [],
    network_change_time: 0,
    fetched: false,
};

const deviceConfigurationReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return DEFAULT_STATE;
        }
        case actionTypes.FETCH_CONFIGURATION: {
            return {
                ...state,
                inProgress: true,
                fetched: false,
            };
        }
        case actionTypes.UPDATE_DEVICE: {
            return {
                ...state,
                networkSettingsChanged: false,
            };
        }
        case actionTypes.NETWORK_NOT_CHANGED: {
            return {
                ...state,
                networkSettingsChanged: false,
            };
        }
        case actionTypes.FETCH_DEVICE_CONFIGURATION: {
            return {
                ...state,
                ...action.payload.config,
                pluginsToDelete: [],
                inProgress: false,
                fetched: true,
                relevant: action.payload.relevant,
                client_key: action.payload.client_key,
                system_key: action.payload.system_key,
            };
        }
        case actionTypes.FETCH_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                inProgress: false,
                fetched: true,
            };
        }
        case actionTypes.SET_CONFIGURATION: {
            const proxy_changed = idx(action.payload, (pl) => pl.settings_section.proxy) && action.payload.settings_section.proxy !== state.settings_section.proxy;

            return {
                ...state,
                ...action.payload,
                networkSettingsChanged: proxy_changed ? true : state.networkSettingsChanged,
                network_change_time: proxy_changed ? Date.now() : state.network_change_time,
            };
        }
        case actionTypes.SET_ETHERNET: {
            return {
                ...state,
                networkSettingsChanged: true,
                ethernets: state.ethernets.map((ethernet) => (ethernet.id === action.payload.id ? {...action.payload} : {...ethernet})),
                network_change_time: Date.now(),
            };
        }
        case actionTypes.SET_PLUGIN: {
            return {
                ...state,
                plugins: state.plugins.map(
                    (plugin) => (
                        plugin.id === action.payload.id ? {
                            ...action.payload,
                            samba_server_workgroup: action.payload.samba_server_workgroup_switch ?
                                action.payload.samba_server_workgroup ? action.payload.samba_server_workgroup : '' : null,
                        } : {
                            ...plugin,
                        }
                    )
                ),
            };
        }
        case actionTypes.ADD_PLUGIN: {
            const pluginsByType = _.filter(state.plugins, {type: action.payload.type});
            // find first available order_no
            let order = 1;
            const comp = (e) => e.order_no === order;
            for (; order < pluginsByType.length + 1; order += 1) {
                if (typeof pluginsByType.find(comp) === 'undefined') {
                    break;
                }
            }

            return {
                ...state,
                plugins: [...state.plugins, {
                    ...action.payload,
                    id: 'new',
                    order_no: order,
                    mappings: [],
                }],
            };
        }
        case actionTypes.REMOVE_PLUGIN: {
            return {
                ...state,
                plugins: state.plugins.filter((plugin) => plugin.id !== action.payload.id),
                pluginsToDelete: [
                    ...state.pluginsToDelete,
                    action.payload.id,
                ],
            };
        }
        case actionTypes.CHANGE_PAIR_VALUE: {
            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: plugin.mappings.map((mapping) => (mapping.id === action.payload.mappingId ? {
                        ...mapping,
                        items: mapping.items.map((item) => (item.id === action.payload.item.id ? {
                            ...action.payload.item,
                        } : {...item})),
                    } : {...mapping})),
                } : {...plugin})),
            };
        }

        case actionTypes.REMOVE_MAPPING_PAIR: {
            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: plugin.mappings.map((mapping) => (mapping.id === action.payload.mappingId ? {
                        ...mapping,
                        items: mapping.items.filter((item) => item.id !== action.payload.item.id),
                    } : {...mapping})),
                } : {...plugin})),
            };
        }

        case actionTypes.REMOVE_MAPPING: {
            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: plugin.mappings.filter((mapping) => mapping.mapping_group !== action.payload.mappingGroup),
                } : {...plugin})),
            };
        }

        case actionTypes.REMOVE_MAPPING_ID: {
            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: plugin.mappings.filter((mapping) => mapping.business_id !== action.payload.business_id),
                } : {...plugin})),
            };
        }

        case actionTypes.ADD_MAPPING_PAIR: {
            const insert_data = (existingItems) => {
                const data = {
                    id: getId(existingItems, 'new_mapping_pair_'),
                    key: action.payload.key,
                    value: action.payload.value,
                };
                if (action.payload.mappingType === MAPPING_TYPES.GROUP_VAT_MAPPING) {
                    data.vatrate = action.payload.vatrate;
                }
                return data;
            };

            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: plugin.mappings.map((mapping) => (mapping.id === action.payload.mappingId ? {
                        ...mapping,
                        items: [
                            ...mapping.items,
                            insert_data(mapping.items),
                        ],
                    } : {...mapping})),
                } : {...plugin})),
            };
        }

        case actionTypes.ADD_MAPPING: {
            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: [
                        ...plugin.mappings,
                        ...(action.payload.mappings || []).map((mapping) => ({
                            ...mapping,
                            id: _.uniqueId('new_mapping_'),
                            business_id: action.payload.business_id || '',
                            plugin: plugin.id,
                            mapping_group: action.payload.mapping_group,
                        }))],
                } : {...plugin})),
            };
        }

        case actionTypes.CHANGE_BUSINESS: {
            return {
                ...state,
                plugins: state.plugins.map((plugin) => (plugin.id === action.payload.pluginId ? {
                    ...plugin,
                    mappings: [
                        ...plugin.mappings.filter((mapping) => mapping.mapping_group !== action.payload.mappingGroup),
                        ...plugin.mappings.filter((mapping) => mapping.mapping_group === action.payload.mappingGroup).map((mapping) => ({
                            ...mapping,
                            business_id: action.payload.business_id,
                        }))],
                } : {...plugin})),
            };
        }


        default: {
            return state;
        }
    }
};

export default deviceConfigurationReducer;
