// auth
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_DONE = 'LOG_OUT_DONE';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export const LOG_IN_RUNNING = 'LOG_IN_RUNNING';

// devices
export const MULTIPLE_ACTION_FAILED = 'MULTIPLE_ACTION_FAILED';
export const MULTIPLE_ACTION_SUCCESS = 'MULTIPLE_ACTION_SUCCESS';
export const MULTIPLE_ACTION_IN_PROGRESS = 'MULTIPLE_ACTION_IN_PROGRESS';
export const MULTIPLE_INSTALL = 'MULTIPLE_INSTALL';
export const MULTIPLE_REMOVE = 'MULTIPLE_REMOVE';
export const MANAGE_COMPANY = 'MANAGE_COMPANY';
export const MULTIPLE_REBOOT = 'MULTIPLE_REBOOT';
export const SELECT_MULTI_SOFT = 'SELECT_MULTI_SOFT';
export const SELECT_MULTI_COMPANY = 'SELECT_MULTI_COMPANY';
export const SELECT_MULTI_ACTION = 'SELECT_MULTI_ACTION';
export const CHECK_DEVICES = 'CHECK_DEVICES';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const DEVICES_FETCHED = 'DEVICES_FETCHED';
export const DEVICES_FETCH_FAILED = 'DEVICES_FETCH_FAILED';
export const FETCH_IN_PROGRESS = 'FETCH_IN_PROGRESS';
export const CHANGE_IN_PROGRESS = 'CHANGE_IN_PROGRESS';
export const CHANGE_SUCCESS = 'CHANGE_SUCCESS';
export const CHANGE_FAILED = 'CHANGE_FAILED';
export const RETRY_PROVISIONING = 'RETRY_PROVISIONING';

// device
export const CHECK_DEVICE = 'CHECK_DEVICE';
export const UPDATE_IN_PROGRESS = 'UPDATE_IN_PROGRESS';
export const DEVICE_UPDATE_FAILED = 'DEVICE_UPDATE_FAILED';
export const DEVICE_UPDATE_SUCCESS = 'DEVICE_UPDATE_SUCCESS';
export const FETCH_DEVICE_EDIT = 'FETCH_DEVICE_EDIT';
export const FETCH_PASSWORD = 'FETCH_PASSWORD';
export const FETCH_PASSWORD_SUCCESS = 'FETCH_PASSWORD_SUCCESS';
export const FETCH_PASSWORD_FAILED = 'FETCH_PASSWORD_FAILED';
export const RESET_PASSWORD = 'SEND_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'SEND_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'SEND_PASSWORD_FAILED';
export const FETCH_DEVICE = 'FETCH_DEVICE';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const REBOOT_DEVICE = 'REBOOT_DEVICE';
export const PULL_DEVICE = 'PULL_DEVICE';
export const DEVICE_CLEAR_ALERT = 'DEVICE_CLEAR_ALERT';
export const DEVICE_CLEAR_ALERT_SUCCEES = 'DEVICE_CLEAR_ALERT_SUCCEES';
export const DEVICE_CLEAR_ALERT_FAILURE = 'DEVICE_CLEAR_ALERT_FAILURE';
export const DEVICE_FETCH_SUCCESS = 'DEVICE_FETCH_SUCCESS';
export const DEVICE_FETCH_FAILED = 'DEVICE_FETCH_FAILED';
export const DEVICE_PULL_SUCCESS = 'DEVICE_PULL_SUCCESS';
export const DEVICE_PULL_FAILED = 'DEVICE_PULL_FAILED';
export const DEVICE_REBOOT_SUCCESS = 'DEVICE_REBOOT_SUCCESS';
export const DEVICE_REBOOT_IN_PROGRESS = 'DEVICE_REBOOT_IN_PROGRESS';
export const DEVICE_REBOOT_FAILED = 'DEVICE_REBOOT_FAILED';
export const DEVICE_REMOVE_SUCCESS = 'DEVICE_REMOVE_SUCCESS';
export const DEVICE_REMOVE_IN_PROGRESS = 'DEVICE_REMOVE_IN_PROGRESS';
export const DEVICE_REMOVE_FAILED = 'DEVICE_REMOVE_FAILED';
export const SOFT_UPDATE_SUCCESS = 'SOFT_UPDATE_SUCCESS';
export const SOFT_UPDATE_FAILED = 'SOFT_UPDATE_FAILED';
export const PULL_DEVICE_PRINTERS = 'PULL_DEVICE_PRINTERS';
export const REFRESH_PRINTERS_LIST = 'REFRESH_PRINTERS_LIST';
export const REFRESH_PRINTERS_FAILED = 'REFRESH_PRINTERS_FAILED';
export const REFRESH_PRINTERS_SUCCESS = 'REFRESH_PRINTERS_SUCCESS';
export const DEVICE_PRINTERS_PULL_SUCCESS = 'DEVICE_PRINTERS_PULL_SUCCESS';
export const DEVICE_PRINTERS_PULL_FAILED = 'DEVICE_PRINTERS_PULL_FAILED';
export const INSTALL_PRINTER = 'INSTALL_PRINTER';
export const PRE_INSTALL_PRINTER = 'PRE_INSTALL_PRINTER';
export const UPDATE_PRINTER = 'UPDATE_PRINTER';
export const UPDATE_PRINTER_IN_PROGRESS = 'UPDATE_PRINTER_IN_PROGRESS';
export const UPDATE_PRINTER_SUCCESS = 'UPDATE_PRINTER_SUCCESS';
export const UPDATE_PRINTER_FAILED = 'UPDATE_PRINTER_FAILED';
export const REMOVE_PRINTER = 'REMOVE_PRINTER';
export const PRINTER_INSTALL_IN_PROGRESS = 'PRINTER_INSTALL_IN_PROGRESS';
export const PRINTER_INSTALL_FAILED = 'PRINTER_INSTALL_FAILED';
export const PRINTER_REMOVE_FAILED = 'PRINTER_REMOVE_FAILED';
export const PRINTER_INSTALL_SUCCESS = 'PRINTER_INSTALL_SUCCESS';
export const PRINTER_REMOVE_SUCCESS = 'PRINTER_REMOVE_SUCCESS';
export const PRINTER_REMOVE_IN_PROGRESS = 'PRINTER_REMOVE_IN_PROGRESS';
export const PULL_PRINTER = 'PULL_PRINTER';
export const PRINTER_PULL_SUCCESS = 'PRINTER_PULL_SUCCESS';
export const PRINTER_PULL_FAILED = 'PRINTER_PULL_FAILED';
export const CHANGE_PRINTER_NAME = 'CHANGE_PRINTER_NAME';
export const CHANGE_PRINTER_URI = 'CHANGE_PRINTER_URI';
export const CHANGE_PRINTER_MODEL = 'CHANGE_PRINTER_MODEL';
export const CHANGE_PRINTER_MAKE = 'CHANGE_PRINTER_MAKE';
export const CHANGE_PRINTER_DESCRIPTION = 'CHANGE_PRINTER_DESCRIPTION';
export const CHANGE_PRINTER_LOCATION = 'CHANGE_PRINTER_LOCATION';
export const CHANGE_PRINTER_PAPER_SIZE = 'CHANGE_PRINTER_PAPER_SIZE';
export const PULL_PRINTERS_DRIVERS = 'PULL_PRINTERS_DRIVERS';
export const PULL_PRINTERS_DRIVERS_IN_PROGRESS = 'PULL_PRINTERS_DRIVERS_IN_PROGRESS';
export const PULL_PRINTERS_DRIVERS_FAILED = 'PULL_PRINTERS_DRIVERS_FAILED';
export const PULL_PRINTERS_DRIVERS_SUCCESS = 'PULL_PRINTERS_DRIVERS_SUCCESS';
export const SELECT_PRINTER_DRIVER = 'SELECT_PRINTER_DRIVER';
export const PRE_CREATE_PRINTER = 'PRE_CREATE_PRINTER';
export const CHANGE_PRINTER_STAGE = 'CHANGE_PRINTER_STAGE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const DOWNLOAD_INVOICES = 'DOWNLOAD_INVOICES';
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export const FETCH_INVOICE_FAILURE = 'FETCH_INVOICE_FAILURE';
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const RE_SYNC = 'RE_SYNC';
export const RE_SYNC_SUCCESS = 'RE_SYNC_SUCCESS';
export const RE_SYNC_FAILURE = 'RE_SYNC_FAILURE';
export const INVOICES_CLEAR_PARAMS = 'INVOICES_CLEAR_PARAMS';
export const INVOICES_SET_PARAMS = 'INVOICES_SET_PARAMS';
export const FETCH_LOGENTRIES = 'FETCH_LOGENTRIES';
export const FETCH_LOGENTRIES_SUCCESS = 'FETCH_LOGENTRIES_SUCCESS';
export const FETCH_LOGENTRIES_FAILURE = 'FETCH_LOGENTRIES_FAILURE';
export const LOGENTRIES_CLEAR_PARAMS = 'LOGENTRIES_CLEAR_PARAMS';
export const LOGENTRIES_SET_PARAMS = 'LOGENTRIES_SET_PARAMS';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const SELECT_PLUGIN_CONFIG = 'SELECT_PLUGIN_CONFIG';

// pre_configuration
export const SET_RELEVANT = 'SET_RELEVANT';
export const CLONE_CONFIG_SUCCESS = 'CLONE_CONFIG_SUCCESS';
export const CLONE_CONFIG_FAILED = 'CLONE_CONFIG_FAILED';
export const CLONE_CONFIG = 'CLONE_CONFIG';
export const DO_TEMPLATE = 'DO_TEMPLATE';
export const SEND_CONFIG_ID = 'SEND_CONFIG_ID';
export const REMOVE_PLUGIN = 'REMOVE_PLUGIN';
export const ADD_PLUGIN = 'ADD_PLUGIN';
export const SELECT_ID = 'SELECT_ID';
export const SELECT_NAME = 'SELECT_NAME';
export const SELECTED_CONFIG_DETAILS = 'SELECTED_CONFIG_DETAILS';
export const REMOVE_SIMPLE_MAPPING_PAIR = 'REMOVE_SIMPLE_MAPPING_PAIR';
export const REMOVE_MAPPING_PAIR = 'REMOVE_MAPPING_PAIR';
export const REMOVE_MAPPING = 'REMOVE_MAPPING';
export const REMOVE_MAPPING_ID = 'REMOVE_MAPPING_ID';
export const FETCH_SECTIONS_EDIT = 'FETCH_SECTIONS_EDIT';
export const FETCH_SECTIONS = 'FETCH_SECTIONS';
export const FETCH_PLUGINS = 'FETCH_PLUGINS';
export const FETCH_CONFIGS = 'FETCH_CONFIGS';
export const FETCH_CONFIG = 'FETCH_CONFIG';
export const ENABLE_SECTION = 'ENABLE_SECTION';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const CHANGE_SIMPLE_PAIR_VALUE = 'CHANGE_SIMPLE_PAIR_VALUE';
export const CHANGE_PLUGIN_VALUE = 'CHANGE_PLUGIN_VALUE';
export const CHANGE_MODE = 'CHANGE_MODE';
export const CHANGE_PAIR_VALUE = 'CHANGE_PAIR_VALUE';
export const CHANGE_FORM_ROW_VALUE = 'CHANGE_FORM_ROW_VALUE';
export const CHANGE_PROXY_VALUE = 'CHANGE_PROXY_VALUE';
export const CHANGE_BUSINESS = 'CHANGE_BUSINESS';
export const ADD_SIMPLE_MAPPING_PAIR = 'ADD_SIMPLE_MAPPING_PAIR';
export const ADD_MAPPING_PAIR = 'ADD_MAPPING_PAIR';
export const CHANGE_SHIFT_CLOSE_DAY = 'CHANGE_SHIFT_CLOSE_DAY';
export const OPEN_SHIFT_CLOSE_MAPPING = 'OPEN_SHIFT_CLOSE_MAPPING';
export const ADD_MAPPING = 'ADD_MAPPING';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_FAILED = 'FETCH_CONFIG_FAILED';
export const FETCH_CONFIGS_FAILED = 'FETCH_CONFIGS_FAILED';
export const SEND_CONFIG_SUCCESS = 'SEND_CONFIG_SUCCESS';
export const SEND_CONFIG_FAILED = 'SEND_CONFIG_FAILED';
export const FETCH_CONFIGS_SUCCESS = 'FETCH_CONFIGS_SUCCESS';
export const FETCH_PLUGINS_SUCCESS = 'FETCH_PLUGINS_SUCCESS';
export const FETCH_PLUGINS_FAILED = 'FETCH_PLUGINS_FAILED';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_FAILED = 'FETCH_SECTIONS_FAILED';
export const SELECTED_CONFIG_DETAILS_SUCCESS = 'SELECTED_CONFIG_DETAILS_SUCCESS';
export const SELECTED_CONFIG_DETAILS_FAILED = 'SELECTED_CONFIG_DETAILS_FAILED';
export const SELECT_PLUGIN = 'SELECT_PLUGIN';

export const FETCH_CONFIGURATIONS = 'FETCH_CONFIGURATIONS';
export const FETCH_CONFIGURATIONS_SUCCESS = 'FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_CONFIGURATIONS_FAILED = 'FETCH_CONFIGURATIONS_FAILED';

export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_FAILED = 'FETCH_CONFIGURATION_FAILED';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_ETHERNET = 'SET_ETHERNET';
export const SET_PLUGIN = 'SET_PLUGIN';
export const NETWORK_NOT_CHANGED = 'NETWORK_NOT_CHANGED';

export const FETCH_DEFAULT_CONFIGURATION = 'FETCH_DEFAULT_CONFIGURATION';
export const FETCH_DEFAULT_CONFIGURATION_SUCCESS = 'FETCH_DEFAULT_CONFIGURATION_SUCCESS';
export const FETCH_DEFAULT_CONFIGURATION_FAILED = 'FETCH_DEFAULT_CONFIGURATION_FAILED';

export const CONFIG_LIST_CLEAR_PARAMS = 'CONFIG_LIST_CLEAR_PARAMS';
export const CONFIG_LIST_SET_PARAMS = 'CONFIG_LIST_SET_PARAMS';

export const FETCH_DEVICE_CONFIGURATION = 'FETCH_DEVICE_CONFIGURATION';

// system_images
export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_FAILED = 'FETCH_IMAGES_FAILED';
export const IMAGES_CLEAR_PARAMS = 'IMAGES_CLEAR_PARAMS';
export const IMAGES_SET_PARAMS = 'IMAGES_SET_PARAMS';
export const IMAGES_UPDATE_IMAGE = 'IMAGES_UPDATE_IMAGE';
export const IMAGES_UPDATE_IMAGE_SUCCESS = 'IMAGES_UPDATE_IMAGE_SUCCESS';


// notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// soft version
export const SELECT_SOFT_VERSION = 'SELECT_SOFT_VERSION';
export const INSTALL_SOFT_VERSION = 'INSTALL_SOFT_VERSION';
export const INSTALL_IN_PROGRESS = 'INSTALL_IN_PROGRESS';
export const SOFT_INSTALL_IN_PROGRESS = 'SOFT_INSTALL_IN_PROGRESS';
export const FETCH_SOFT = 'FETCH_SOFT';
export const FETCH_SOFT_SUCCESS = 'FETCH_SOFT_SUCCESS';
export const FETCH_SOFT_FAILED = 'FETCH_SOFT_FAILED';

// common
export const CHANGE_MODAL = 'CHANGE_MODAL';

export const DEVICES_LIST_PAGINATION = 'DEVICES_LIST_PAGINATION';
export const DEVICES_LIST_CHANGE_SORT = 'DEVICES_LIST_CHANGE_SORT';
export const DEVICES_LIST_CHANGE_OPERATOR = 'DEVICES_LIST_CHANGE_OPERATOR';
export const DEVICES_LIST_CHANGE_BUSINESS = 'DEVICES_LIST_CHANGE_BUSINESS';
export const DEVICES_LIST_CHANGE_NAME = 'DEVICES_LIST_CHANGE_NAME';
export const DEVICES_LIST_CHANGE_STATUS = 'DEVICES_LIST_CHANGE_STATUS';
export const DEVICES_LIST_CHANGE_LIMIT = 'DEVICES_LIST_CHANGE_LIMIT';
export const DEVICES_LIST_CLEAR_FILTERS = 'DEVICES_LIST_CLEAR_FILTERS';
export const DEVICES_LIST_CHANGE_FILTERS = 'DEVICES_LIST_CHANGE_FILTERS';
export const DEVICES_LIST_ADD_COLUMN = 'DEVICES_LIST_ADD_COLUMN';
export const DEVICES_LIST_REMOVE_COLUMN = 'DEVICES_LIST_REMOVE_COLUMN';
export const INVOICES_LIST_ADD_COLUMN = 'INVOICES_LIST_ADD_COLUMN';
export const INVOICES_LIST_REMOVE_COLUMN = 'INVOICES_LIST_REMOVE_COLUMN';
export const CHANGE_EDIT_MODE = 'CHANGE_EDIT_MODE';
export const CHANGE_LANG = 'CHANGE_LANG';
export const DISABLE_NAVIGATION = 'DISABLE_NAVIGATION';

export const FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE = 'FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE';
export const FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE_SUCCESS = 'FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE_SUCCESS';
export const DELETE_DEVICE_CONFIGURATION = 'DELETE_DEVICE_CONFIGURATION';
export const DELETE_DEVICE_CONFIGURATION_SUCCESS = 'DELETE_DEVICE_CONFIGURATION_SUCCESS';
export const SAVE_DEVICE_CONFIGURATION = 'SAVE_DEVICE_CONFIGURATION';
export const CLONE_DEVICE_CONFIGURATION = 'CLONE_DEVICE_CONFIGURATION';
export const CLONE_DEVICE_CONFIGURATION_SUCCESS = 'CLONE_DEVICE_CONFIGURATION_SUCCESS';
export const CLONE_DEVICE_CONFIGURATION_FAILED = 'CLONE_DEVICE_CONFIGURATION_FAILED';
export const CHANGE_DEVICE_STATUS = 'CHANGE_DEVICE_STATUS';
export const PULL_DEVICE_FILES = 'PULL_DEVICE_FILES';
export const PULL_DEVICE_FILES_SUCCESS = 'PULL_DEVICE_FILES_SUCCESS';
export const PULL_DEVICE_FILES_FAILED = 'PULL_DEVICE_FILES_FAILED';
export const SAVE_DEVICE_FILE = 'SAVE_DEVICE_FILE';
export const SET_DEVICE_FILE = 'SET_DEVICE_FILE';
export const PULL_DEVICE_FILE = 'PULL_DEVICE_FILE';
export const DELETE_DEVICE_FILE = 'DELETE_DEVICE_FILE';
export const PING_DEVICE = 'PING_DEVICE';
export const PING_DEVICE_SUCCESS = 'PING_DEVICE_SUCCESS';
export const PING_DEVICE_FAILED = 'PING_DEVICE_FAILED';
export const PROBE_DEVICE = 'PROBE_DEVICE';
export const PROBE_DEVICE_SUCCESS = 'PROBE_DEVICE_SUCCESS';
export const PROBE_DEVICE_FAILED = 'PROBE_DEVICE_FAILED';
export const DELETE_DEVICE_DATA = 'DELETE_DEVICE_DATA';
export const DELETE_DEVICE_DATA_SUCCESS = 'DELETE_DEVICE_DATA_SUCCESS';
export const DELETE_DEVICE_DATA_FAILED = 'DELETE_DEVICE_DATA_FAILED';
export const SELECT_SEND_PLUGIN = 'SELECT_SEND_PLUGIN';
export const CHANGE_XML_VALUE = 'CHANGE_XML_VALUE';
export const SEND_XML_TO_PMS = 'SEND_XML_TO_PMS';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SELECT_PLUGIN_CONFIG_TO_SAVE = 'SELECT_PLUGIN_CONFIG_TO_SAVE';
export const SAVE_PLUGINS_CONFIG = 'SAVE_PLUGINS_CONFIG';
// companies
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED';

// errors

export const APP_ERROR = 'APP_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const BAD_REQUEST = 'BAD_REQUEST';
export const SERVER_ERROR = 'SERVER_ERROR';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
