import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import _ from 'lodash';
import Stage2Footer from './stage2Footer';
import translate from 'counterpart';

const PrinterStage2 = ({device_id, selectDriverAction, selected_driver, installed_printer, changePrinterNameAction, changePrinterDescriptionAction, changePrinterLocationAction}) => {
    const select_options = (installed_printer.drivers || []).map((driver) => ({label: driver['make-and-model'], value: driver}));
    return (
        <form className="form-horizontal">
            <div className="form-group">
                <label className="control-label col-xs-2" htmlFor="Name">{translate('name', {scope: 'devices.printers'})}:</label>
                <div className="col-xs-10">
                    <input className="form-control" id="Name" defaultValue={installed_printer.name}
                        onBlur={(event) => changePrinterNameAction(event.target.value)}/>
                </div>
            </div>
            <div className="form-group">
                <label className="control-label col-xs-2" htmlFor="Name">{translate('description', {scope: 'devices.printers'})}:</label>
                <div className="col-xs-10">
                    <input className="form-control" id="Name" defaultValue={installed_printer.description}
                        onBlur={(event) => changePrinterDescriptionAction(event.target.value)}/>
                </div>
            </div>
            <div className="form-group">
                <label className="control-label col-xs-2" htmlFor="Name">{translate('location', {scope: 'devices.printers'})}:</label>
                <div className="col-xs-10">
                    <input className="form-control" id="Name" defaultValue={installed_printer.location}
                        onBlur={(event) => changePrinterLocationAction(event.target.value)}/>
                </div>
            </div>
            <div className="form-group">
                <label className="control-label col-xs-2" htmlFor="Name">{translate('driver', {scope: 'devices.printers'})}:</label>
                <Select
                    value={_.isEmpty(selected_driver) ? '' : selected_driver}
                    options={select_options}
                    onChange={(driver) => selectDriverAction(driver)}
                    searchable={false}
                    clearable={false}
                />
            </div>
            <Stage2Footer device_id={device_id}/>
        </form>
    );
};

PrinterStage2.propTypes = {
    installed_printer: PropTypes.object.isRequired,
    changePrinterNameAction: PropTypes.func.isRequired,
    changePrinterDescriptionAction: PropTypes.func.isRequired,
    changePrinterLocationAction: PropTypes.func.isRequired,
    selected_driver: PropTypes.object.isRequired,
    selectDriverAction: PropTypes.func.isRequired,
    device_id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    selected_driver: state.device.selected_driver,
    installed_printer: state.device.installed_printer,
});

const mapDispatchToProps = {
    changePrinterNameAction: makeAction(actionTypes.CHANGE_PRINTER_NAME),
    changePrinterDescriptionAction: makeAction(actionTypes.CHANGE_PRINTER_DESCRIPTION),
    changePrinterLocationAction: makeAction(actionTypes.CHANGE_PRINTER_LOCATION),
    selectDriverAction: makeAction(actionTypes.SELECT_PRINTER_DRIVER),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrinterStage2);
