import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../../redux/actionTypes';
import WarningModal from '../../../../common/modals/WarningModal';
import PermissionCheck from '../../../../authorization/PermissionCheck';
import * as permissions from '../../../../../configuration/permissions';

const PluginHeader = ({panelHeadingId, collapseId, plugin, removePluginAction, label}) => (
    <div className="panel-heading">
        <div className="row">
            <h3 className="panel-title col-xs-10" id={panelHeadingId} role="button" data-toggle="collapse" data-parent={`#${plugin.id}`} href={`#${collapseId}`}
                aria-expanded="true" aria-controls={collapseId}>
                {label}
            </h3>
            <PermissionCheck permission={permissions.DELETE_PLUGIN}>
                <div className="col-xs-2" role="button" data-toggle="modal" data-target={`#removeModal${plugin.name}${plugin.id}`} data-backdrop="static" data-keyboard="false">
                    <div className="pull-right">
                        <i className="fa fa-close"/> Remove Plugin
                    </div>
                    <WarningModal id={`removeModal${plugin.name}${plugin.id}`} onSubmit={() => removePluginAction({id: plugin.id})}
                        text={`Are you sure you want to Remove plugin: ${plugin.name} #${plugin.order_no}`}/>
                </div>
            </PermissionCheck>
        </div>
    </div>
);

PluginHeader.propTypes = {
    panelHeadingId: PropTypes.string,
    collapseId: PropTypes.string,
    plugin: PropTypes.object,
    removePluginAction: PropTypes.func.isRequired,
    label: PropTypes.string,
};

const mapDispatchToProps = {
    removePluginAction: makeAction(actionTypes.REMOVE_PLUGIN),
};

export default connect(null, mapDispatchToProps)(PluginHeader);
