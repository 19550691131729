import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {getNavigationFooter} from '../../../redux/selectors/navigation/navigationFooter';
import {DASHBOARD_PAGE} from '../../../configuration/paths';
import PropTypes from 'prop-types';
import translate from 'counterpart';

const Footer = ({version_backend}) => (
    <div className="footer">
        <div className="container">
            <p>{translate('copyright', {scope: 'footer'})} <Link to={DASHBOARD_PAGE}>GASTROFIX GmbH.</Link>
                {translate('all_rights', {scope: 'footer'})}
            </p>
            <p>{translate('version', {scope: 'footer'})} {version_backend}</p>
        </div>
    </div>
);

Footer.propTypes = {
    version_backend: PropTypes.string,
};

export default connect(getNavigationFooter)(Footer);
