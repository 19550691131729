import PropTypes from 'prop-types';
import React from 'react';
import OraclePlugin from './edit/OraclePlugin';
import ProtelPlugin from './edit/ProtelPlugin';
import SihotPlugin from './edit/SihotPlugin';

const PluginEdit = ({plugin, endpoint_url, validation}) => {
    switch (plugin.type) {
        case 'OraclePlugin':
            return <OraclePlugin plugin={plugin} endpoint_url={endpoint_url}/>;

        case 'ProtelPlugin':
            return <ProtelPlugin plugin={plugin} endpoint_url={endpoint_url}/>;

        case 'SihotPlugin':
            return <SihotPlugin plugin={plugin} endpoint_url={endpoint_url} validation={validation}/>;

        default:
            return <div/>;
    }
};

PluginEdit.propTypes = {
    validation: PropTypes.bool.isRequired,
    plugin: PropTypes.object.isRequired,
    endpoint_url: PropTypes.string,
};

export default PluginEdit;
