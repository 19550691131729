import {all, call, put, take} from 'redux-saga/effects';
import FileSaver from 'file-saver';

import {API_URL} from '../../../../configuration/config';
import {DEVICE_STATUS} from '../../../../configuration/constants';
import {ERROR as get_error_notification, INFO as get_info_notification, SUCCESS as get_success_notification, WARNING as get_warning_notification} from '../../../../configuration/notifications';
import {CONFIGS_LIST_PAGE, DEVICE_DETAILS_PAGE, DEVICE_PRINTERS_PAGE} from '../../../../configuration/paths';

import request, {DELETE, GET, POST, PUT} from '../../../../services/request';
import {save} from '../../../../services/persist';
import * as actionTypes from '../../../actionTypes';
import {saveDeviceConfiguration} from '../../deviceConfigurationsSaga';
import {push} from 'react-router-redux';
import {handleError} from '../../errors';


function *sendPassword() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.RESET_PASSWORD);
            const response = yield request(PUT, API_URL + `devices/${payload}/password/`);
            yield put({
                type: actionTypes.RESET_PASSWORD_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Password changed'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.RESET_PASSWORD_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Password change failed'),
            });
        }
    }
}

function *fetchPassword() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.FETCH_PASSWORD);
            const response = yield request(GET, API_URL + `devices/${payload}/password/`);
            yield put({
                type: actionTypes.FETCH_PASSWORD_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Password fetched'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_PASSWORD_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Password fetch failed'),
            });
        }
    }
}

function *pingDevice() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PING_DEVICE);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification('Ping request in progress...'),
            });
            const response = yield request(POST, API_URL + `devices/${payload.id}/ping/`, payload.data);
            yield put({
                type: actionTypes.PING_DEVICE_SUCCESS,
                payload: response.data,
            });
            if (response.data.result) {
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_success_notification(`You where able to ping ${payload.data.address}`),
                });
            } else {
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_error_notification(`Unable to reach ${payload.data.address}`),
                });
            }
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PING_DEVICE_FAILED, error: e});
            if (!e.status) {
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_warning_notification('You are not connected to any network'),
                });
            }
        }
    }
}

function *probeDevice() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PROBE_DEVICE);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification('Probing request in progress...'),
            });
            const response = yield request(POST, API_URL + `devices/${payload.id}/probe/`, payload.data);
            yield put({
                type: actionTypes.PROBE_DEVICE_SUCCESS,
                payload: response.data,
            });
            if (response.data.result) {
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_success_notification(`Probed ${payload.data.address}:${payload.data.port}`),
                });
            } else {
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_error_notification(`Unable to reach ${payload.data.address}`),
                });
            }
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PROBE_DEVICE_FAILED, error: e});
            if (!e.status) {
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_warning_notification('You are not connected to any network'),
                });
            }
        }
    }
}

function *fetchConfigDevice() {
    while (true) {
        try {
            const {type, payload} = yield take([actionTypes.PULL_DEVICE, actionTypes.SEND_CONFIG_SUCCESS, actionTypes.DEVICE_UPDATE_SUCCESS]);
            const id = type === actionTypes.SEND_CONFIG_SUCCESS ? payload.device : payload.id;
            if (id === null) {
                break;
            }
            const response = yield request(GET, API_URL + `devices/${id}/`);
            yield put({
                type: actionTypes.DEVICE_PULL_SUCCESS,
                payload: response.data,
            });
            save('status', response.data.status);
        } catch (e) {
            console.error(e);
            yield handleError(e, true);
            yield put({type: actionTypes.DEVICE_PULL_FAILED, error: e});
        }
    }
}

function *fetchPrinter() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PULL_PRINTER);
            const response = yield request(GET, API_URL + `devices/${payload.device_id}/printers/${payload.printer_hash}/`);
            yield put({
                type: actionTypes.PRINTER_PULL_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PRINTER_PULL_FAILED, error: e});
        }
    }
}

function *refreshPrintersList() {
    while (true) {
        try {
            const {payload: id} = yield take(actionTypes.REFRESH_PRINTERS_LIST);
            const response = yield request(GET, API_URL + `devices/${id}/printers/refresh/`);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Printers for device: ${id} are being refreshed.`),
            });
            yield put({
                type: actionTypes.REFRESH_PRINTERS_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.REFRESH_PRINTERS_FAILED, error: e});
        }
    }
}

function *fetchPrintersList() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PULL_DEVICE_PRINTERS);
            const response = yield request(GET, API_URL + `devices/${payload}/printers/`);
            yield put({
                type: actionTypes.DEVICE_PRINTERS_PULL_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DEVICE_PRINTERS_PULL_FAILED, error: e});
        }
    }
}

function *fetchDevice() {
    while (true) {
        try {
            const {payload: {deviceId}} = yield take(actionTypes.FETCH_DEVICE);
            save('device_id', deviceId);

            const response = yield request(GET, API_URL + `devices/${deviceId}/provisioning_log/`);
            yield put({type: actionTypes.DEVICE_FETCH_SUCCESS, payload: response.data});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DEVICE_FETCH_FAILED, error: e});
        }
    }
}

function *deviceClearAlert() {
    while (true) {
        const {payload} = yield take(actionTypes.DEVICE_CLEAR_ALERT);
        try {
            yield request(POST, API_URL + `devices/${payload.id}/clear_alert/`);
            yield put({type: actionTypes.DEVICE_CLEAR_ALERT_SUCCEES, payload: payload.id});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DEVICE_CLEAR_ALERT_FAILURE, error: e});
        }
    }
}

function *rebootDevice() {
    while (true) {
        const {payload} = yield take(actionTypes.REBOOT_DEVICE);
        try {
            yield put({
                type: actionTypes.DEVICE_REBOOT_IN_PROGRESS,
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Device ${payload.id} reboot in progress`),
            });
            const response = yield request(POST, API_URL + `devices/${payload.id}/reboot/`);
            yield put({
                type: actionTypes.DEVICE_REBOOT_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Device ${payload.id} rebooted`),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DEVICE_REBOOT_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification(`Device ${payload.id} reboot failed`),
            });
        }
    }
}

function *getPrinterDrivers() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PULL_PRINTERS_DRIVERS);
            yield put({
                type: actionTypes.PULL_PRINTERS_DRIVERS_IN_PROGRESS,
            });
            const response = yield request(POST, API_URL + `devices/${payload.id}/printers/drivers/`, payload.printer);
            yield put({type: actionTypes.PULL_PRINTERS_DRIVERS_SUCCESS, payload: response.data});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PULL_PRINTERS_DRIVERS_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Drivers pull for printer failed'),
            });
        }
    }
}

function *installPrinter() {
    while (true) {
        const {payload} = yield take(actionTypes.INSTALL_PRINTER);
        try {
            yield put({
                type: actionTypes.PRINTER_INSTALL_IN_PROGRESS,
                payload: {hash: payload.printer.hash},
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Printer: ${payload.printer.make_and_model} is installing on device ${payload.id}`),
            });
            yield request(POST, API_URL + `devices/${payload.id}/printers/${payload.printer.id}/install/`, payload);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Printer: ${payload.printer.make_and_model} installed successfully from device ${payload.id}`),
            });
            yield put({type: actionTypes.PRINTER_INSTALL_SUCCESS, payload: payload.printer.hash});
            yield call(payload.callback);
            yield put({type: actionTypes.PULL_DEVICE_PRINTERS, payload: payload.id});
            yield put(push(DEVICE_PRINTERS_PAGE(payload.id)));
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PRINTER_INSTALL_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification(`Printer: ${payload.printer.make_and_model} install on device ${payload.id} failed`),
            });
        }
    }
}

function *pre_installPrinter() {
    while (true) {
        const {payload} = yield take(actionTypes.PRE_INSTALL_PRINTER);
        try {
            yield put({
                type: actionTypes.PRINTER_INSTALL_IN_PROGRESS,
                payload: {hash: payload.printer.hash},
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Printer: ${payload.printer.make} ${payload.printer.model} is installing on device ${payload.id}`),
            });
            yield request(POST, API_URL + `devices/${payload.id}/printers/preinstall/`, payload);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Printer: ${payload.printer.make} ${payload.printer.model} installed successfully from device ${payload.id}`),
            });
            yield put({type: actionTypes.PRINTER_INSTALL_SUCCESS, payload: payload.printer.hash});
            yield call(payload.callback);
            yield put(push(DEVICE_PRINTERS_PAGE(payload.id)));
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PRINTER_INSTALL_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification(`Printer: ${payload.printer.make} ${payload.printer.model} install on device ${payload.id} failed`),
            });
        }
    }
}

function *updatePrinter() {
    while (true) {
        const {payload} = yield take(actionTypes.UPDATE_PRINTER);
        try {
            yield put({
                type: actionTypes.UPDATE_PRINTER_IN_PROGRESS,
                payload: {hash: payload.printer.hash},
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Printer: ${payload.printer.make_and_model} updated on device ${payload.id}`),
            });
            yield request(PUT, API_URL + `devices/${payload.id}/printers/${payload.printer.id}/`, payload);
            yield put({type: actionTypes.UPDATE_PRINTER_SUCCESS, payload: payload.printer.hash});
            yield call(payload.callback);
            yield put(push(DEVICE_PRINTERS_PAGE(payload.id)));
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.UPDATE_PRINTER_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification(`Printer: ${payload.printer.make_and_model} install on device ${payload.id} failed`),
            });
        }
    }
}

function *removePrinter() {
    while (true) {
        const {payload} = yield take(actionTypes.REMOVE_PRINTER);
        try {
            yield put({
                type: actionTypes.PRINTER_REMOVE_IN_PROGRESS,
                payload: {hash: payload.printer.hash},
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Printer: ${payload.printer.name} is being removed on device ${payload.id}`),
            });
            yield request(DELETE, API_URL + `devices/${payload.id}/printers/${payload.printer.id}/`);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Printer: ${payload.printer.name} removed successfully from device ${payload.id}`),
            });
            yield put({type: actionTypes.PRINTER_REMOVE_SUCCESS, payload: payload.printer.name});
            yield put({type: actionTypes.PULL_DEVICE_PRINTERS, payload: payload.id});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PRINTER_REMOVE_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification(`Printer: ${payload.printer.make_and_model} remove on device ${payload.id} failed`),
            });
        }
    }
}

function *removeDevice() {
    while (true) {
        const {payload} = yield take(actionTypes.REMOVE_DEVICE);
        try {
            yield put({
                type: actionTypes.DEVICE_REMOVE_IN_PROGRESS,
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Device ${payload.id} remove in progress`),
            });
            const response = yield request(DELETE, API_URL + `devices/${payload.id}/`);
            yield put({
                type: actionTypes.DEVICE_REMOVE_SUCCESS,
                payload: response.data,
            });
            yield put({
                type: actionTypes.FETCH_DEVICES,
                payload: {},
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Device ${payload.id} removed`),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DEVICE_REMOVE_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification(`Device ${payload.id} remove failed`),
            });
        }
    }
}

function *updateDevice() {
    while (true) {
        try {
            const {payload: {data, callback, device}} = yield take(actionTypes.UPDATE_DEVICE);
            const path = DEVICE_DETAILS_PAGE(device.id);
            yield put({
                type: actionTypes.UPDATE_IN_PROGRESS,
                id: device.id,
            });
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification('Device update in progress. It may take few minutes to finish.'),
            });

            yield saveDeviceConfiguration(data);

            if (typeof data.relevant !== 'undefined') {
                yield request(PUT, API_URL + `devices/${device.id}/relevant_update/`, {relevant: data.relevant ? 'True' : 'False'});
            }
            yield request(PUT, API_URL + `devices/${device.id}/keys_update/`, {client_key: (data.client_key || ''), system_key: (data.system_key || '')});

            if (device.status === DEVICE_STATUS.CONNECTED) {
                yield request(POST, `${API_URL}devices/${device.id}/push_config/`,
                    {'network_change_time': device.network_change_time});
            }

            yield put({type: actionTypes.DEVICE_UPDATE_SUCCESS, payload: {id: device.id}});
            if (callback) {
                yield call(callback, path);
            }

            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Device ${device.id} updated`),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DEVICE_UPDATE_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Device update failed'),
            });
        }
    }
}

function *fetchInvoice() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.FETCH_INVOICE);
            const response = yield request(GET, API_URL + `devices/${payload.deviceId}/invoices/${payload.invoiceId}/`);
            yield put({
                type: actionTypes.FETCH_INVOICE_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_INVOICE_FAILURE, error: e});
        }
    }
}

function *fetchInvoices() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.FETCH_INVOICES);
            const response = yield request(GET, API_URL + `devices/${payload.id}/invoices/`, {
                params: {
                    ...payload.params,
                    // take care of decimal separator
                    tip: payload.params.tip.replace(',', '.'),
                    total: payload.params.total.replace(',', '.'),
                    discount: payload.params.discount.replace(',', '.'),
                    // convert datetime from moment.js
                    start_date: payload.params.start_date && payload.params.start_date.format('YYYY-MM-DD'),
                    end_date: payload.params.end_date && payload.params.end_date.format('YYYY-MM-DD'),
                },
            });
            yield put({
                type: actionTypes.FETCH_INVOICES_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_INVOICES_FAILURE, error: e});
        }
    }
}

function *downloadInvoices() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.DOWNLOAD_INVOICES);
            const response = yield request(GET, API_URL + `devices/${payload.id}/invoices/xls/`, {
                params: {
                    ...payload.params,
                    // convert datetime from moment.js
                    start_date: payload.params.start_date && payload.params.start_date.format('YYYY-MM-DD'),
                    end_date: payload.params.end_date && payload.params.end_date.format('YYYY-MM-DD'),
                },
                responseType: 'blob',
            });
            FileSaver.saveAs(response.data, 'invoices.xls');
        } catch (e) {
            console.error(e);
            yield handleError(e);
        }
    }
}

function *fetchLogEntries() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.FETCH_LOGENTRIES);
            const response = yield request(GET, API_URL + `devices/${payload.id}/logs/`, {
                params: {
                    ...payload.params,
                    // convert datetime from moment.js
                    start_date: payload.params.start_date && payload.params.start_date.format('YYYY-MM-DD'),
                    end_date: payload.params.end_date && payload.params.end_date.format('YYYY-MM-DD'),
                },
            });
            yield put({
                type: actionTypes.FETCH_LOGENTRIES_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_LOGENTRIES_FAILURE, error: e});
        }
    }
}

function *fetchDeviceFile() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PULL_DEVICE_FILE);
            const response = yield request(GET, API_URL + `devices/${payload}/files/${payload.id}/`);
            yield put({
                type: actionTypes.SET_DEVICE_FILE,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PULL_DEVICE_FILES_FAILED, error: e});
        }
    }
}

function *fetchDeviceFiles() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.PULL_DEVICE_FILES);
            const response = yield request(GET, API_URL + `devices/${payload}/files/`);
            yield put({
                type: actionTypes.PULL_DEVICE_FILES_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PULL_DEVICE_FILES_FAILED, error: e});
        }
    }
}

export function *createOrUpdateDeviceFile(data, device) {
    const dataToSent = {
        ...data,
        device: device.id,
    };
    let response;
    if (dataToSent.id) {
        response = yield request(PUT, `${API_URL}devices/${device.id}/files/${dataToSent.id}/`, dataToSent);
    } else {
        response = yield request(POST, `${API_URL}devices/${device.id}/files/`, dataToSent);
    }
    return response;
}


function *saveDeviceFile() {
    while (true) {
        try {
            const path = CONFIGS_LIST_PAGE;
            const {payload: {data, device, callback}} = yield take(actionTypes.SAVE_DEVICE_FILE);
            const response = yield createOrUpdateDeviceFile(data, device);
            yield request(POST, `${API_URL}devices/${device.id}/copy_files/`, {});
            yield put({type: actionTypes.SEND_CONFIG_SUCCESS, payload: response.data});
            yield call(callback, path);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Config Updated'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.SEND_CONFIG_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Config update failed'),
            });
        }
    }
}

function *deleteDeviceFile() {
    while (true) {
        try {
            const {payload: {device, id, callback}} = yield take(actionTypes.DELETE_DEVICE_FILE);
            yield request(DELETE, API_URL + `devices/${device}/files/${id}/`);
            yield call(callback);
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.PULL_DEVICE_FILES_FAILED, error: e});
        }
    }
}

function *deleteData() {
    while (true) {
        try {
            const {payload: device_id} = yield take(actionTypes.DELETE_DEVICE_DATA);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification('Deleting data from device ' + device_id + ' in progress.'),
            });
            yield request(DELETE, API_URL + `devices/${device_id}/invoice_delete/`);
            yield put({type: actionTypes.DELETE_DEVICE_DATA_SUCCESS});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Deleting data from device ' + device_id + ' successful.'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.DELETE_DEVICE_DATA_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Deleting data from device failed.'),
            });
        }
    }
}

function *sendXmlToPms() {
    while (true) {
        try {
            const {payload: {device: device_id, plugin, xml}} = yield take(actionTypes.SEND_XML_TO_PMS);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification('Sending XML to ' + plugin + ' plugin.'),
            });
            yield request(POST, API_URL + `devices/${device_id}/send_xml/`, {'plugin': plugin, 'xml': xml});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('XML sent.'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Sending XML failed.'),
            });
        }
    }
}

export default function *deviceSaga() {
    yield all([
        fetchDevice(),
        rebootDevice(),
        removeDevice(),
        fetchConfigDevice(),
        updateDevice(),
        pingDevice(),
        probeDevice(),
        fetchPassword(),
        fetchPrintersList(),
        installPrinter(),
        pre_installPrinter(),
        removePrinter(),
        fetchPrinter(),
        updatePrinter(),
        getPrinterDrivers(),
        refreshPrintersList(),
        fetchInvoice(),
        fetchInvoices(),
        downloadInvoices(),
        fetchLogEntries(),
        fetchDeviceFiles(),
        saveDeviceFile(),
        fetchDeviceFile(),
        deleteDeviceFile(),
        deviceClearAlert(),
        sendPassword(),
        deleteData(),
        sendXmlToPms(),
    ]);
}
