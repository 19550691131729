import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import ImgListTable from '../components/image_list/ImgListTable';
import ImgListHeader from '../components/image_list/ImgListHeader';
import ImgListFooter from '../components/image_list/ImgListFooter';
import {FETCH_IMAGES} from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';
import {get_default_state} from '../redux/reducers/sysImageReducer';

class ImageList extends React.Component {
    componentDidMount() {
        this.props.fetchImages({...get_default_state().params});
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="container">
                    <ImgListHeader/>
                    <ImgListTable/>
                    <ImgListFooter/>
                </div>
            </div>
        );
    }
}

ImageList.propTypes = {
    fetchImages: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    fetchImages: makeAction(FETCH_IMAGES),
};

export default connect(null, mapDispatchToProps)(ImageList);
