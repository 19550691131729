import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({changePage, count, limit, page, params, fetchConfigs}) => {
    const maxPage = Math.ceil(count / limit);
    return (
        <ReactPaginate
            disabledClassName={'disabled'}
            previousLabel={<i className="fa fa-angle-left"/>}
            nextLabel={<i className="fa fa-angle-right"/>}
            breakLabel={<span>...</span>}
            breakClassName={'break-me'}
            pageCount={maxPage === 0 ? 1 : maxPage}
            forcePage={page - 1}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(data) => {
                if (params) {
                    const arg = {...params, page: data.selected + 1};
                    changePage(arg);
                    fetchConfigs(arg);
                } else {
                    changePage({page: data.selected + 1});
                }
            }}
            containerClassName={'pagi list-inline list-unstyled'}
            activeClassName={'active'}
        />
    );
};

Pagination.propTypes = {
    changePage: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    fetchConfigs: PropTypes.func,
    params: PropTypes.object,
};

export default Pagination;
