import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../../redux/actions/common/makeAction';
import PluginCollapse from '../../rows/pluginsCollapse';
import * as actionTypes from '../../../../../redux/actionTypes';
import SectionInput from '../../rows/valueTypes/SectionInput';
import PluginHeader from './PluginHeader';
import PluginMapping from './PluginMapping';
import SectionSwitch from '../../rows/valueTypes/SectionSwitch';
import SectionSelect from '../../rows/valueTypes/SectionSelect';
import {
    AGGREGATED_ITEMS_OPTIONS,
    CONSTRAINT_OPTIONS,
    CUMULATED_INVOICE_TO_ROOM_OPTIONS,
    CUMULATED_SHIFT_CLOSE,
    CUMULATED_SHIFT_CLOSE_OPTIONS,
} from '../../../../../configuration/constants';
import translate from 'counterpart';
import CollapsingGroup from '../../../../devices/details/plugins/CollapsingGroup';

const SIHOTPlugin = ({plugin, setPlugin}) => {
    const panelHeadingId = `pluginRowHeading${plugin.id}`;
    const collapseId = `pluginCollapse${plugin.id}`;
    return (
        <li className="panel-group" id={plugin.id} role="tablist" aria-multiselectable="true">
            <div className="panel panel-default">
                <PluginHeader label={`Sihot/Gubse ${plugin.order_no}`} panelHeadingId={panelHeadingId} collapseId={collapseId} plugin={plugin} />
                <div id={collapseId} className="panel-collapse collapse" role="tabpanel" aria-labelledby={panelHeadingId}>
                    <div className="panel-body">
                        <div className="m-b-15">
                            <CollapsingGroup id="mainInfo" name="Main Plugin Information">
                                <SectionInput label="Name" setInput={setPlugin} section={plugin} fieldName="name"/>
                                <SectionSwitch label="Active" setInput={setPlugin} section={plugin} fieldName="is_active"/>
                            </CollapsingGroup>
                            <CollapsingGroup id="pmsConnection" name="PMS Connection">

                                <SectionInput label="Url" setInput={setPlugin} section={plugin} fieldName="url"/>
                                <SectionInput label="API key" setInput={setPlugin} section={plugin} fieldName="api_key"/>
                                <SectionInput label="Hotel" setInput={setPlugin} section={plugin} fieldName="hotel"/>
                            </CollapsingGroup>
                            <CollapsingGroup id="timeouts" name="Timeouts, Intervals & Amounts">
                                <SectionInput label="Shift close interval" setInput={setPlugin} section={plugin}
                                    fieldName="shift_close_interval"/>
                                <SectionInput label="Shift close retry interval" setInput={setPlugin} section={plugin} fieldName="shift_close_retry_interval"
                                    number={true}/>
                                <SectionInput label="Shift close max num. of retry attempts" setInput={setPlugin} section={plugin}
                                    fieldName="shift_close_max_retry_attempts" number={true}/>
                            </CollapsingGroup>
                            <CollapsingGroup id="features" name="Features">
                                <SectionSwitch label="Allow negative postings" setInput={setPlugin} section={plugin}
                                    fieldName="allow_negative_postings"/>
                                <SectionSwitch label="Block amountless transactions" setInput={setPlugin} section={plugin}
                                    fieldName="block_zero_payments"/>
                                <SectionSwitch label="Disable duplicate check" setInput={setPlugin} section={plugin}
                                    fieldName="disable_duplicate_check" negate/>
                                <SectionSwitch label="Enable service periods" setInput={setPlugin} section={plugin}
                                    fieldName="enable_serviceperiod"/>
                                <SectionSwitch label="Disable shift close" setInput={setPlugin} section={plugin}
                                    fieldName="disable_shift_close" negate/>
                                <SectionSwitch label="Forward internal" setInput={setPlugin} section={plugin} fieldName="forward_internal"
                                    forwBlock/>
                                <SectionSwitch label="Forward non-turnover items" setInput={setPlugin} section={plugin} fieldName="forward_nonturnover"
                                    forwBlock/>
                                <SectionSwitch label="Midnight separation" setInput={setPlugin} section={plugin}
                                    fieldName="midnight_separation" negate inverse/>
                                <SectionSwitch label="Enable Tip payment substraction" setInput={setPlugin} section={plugin} fieldName="substract_tip_payments"
                                    negate inverse/>
                                <SectionSelect label="Constraints behavior" setInput={setPlugin} section={plugin}
                                    fieldName="constraints_behavior" options={CONSTRAINT_OPTIONS}/>
                                <SectionSelect label="Aggregated items behavior" setInput={setPlugin} section={plugin}
                                    fieldName="aggregated_items_behavior"
                                    options={AGGREGATED_ITEMS_OPTIONS}/>
                                <SectionSelect label="Cumulated Shift Close Behavior" setInput={setPlugin} section={plugin}
                                    fieldName="cumulated_shift_close"
                                    options={CUMULATED_SHIFT_CLOSE_OPTIONS}/>
                                <SectionSelect label="Cumulated Invoice ToRoom behavior" setInput={setPlugin}
                                    section={plugin} fieldName="cumulated_invoice_to_room"
                                    options={CUMULATED_INVOICE_TO_ROOM_OPTIONS}/>
                            </CollapsingGroup>
                            <CollapsingGroup id="others" name="Others">
                                <SectionInput label="Gratuity id" setInput={setPlugin} section={plugin}
                                    fieldName="gratuity_id"/>
                                <SectionInput label="Gratuity group id" setInput={setPlugin} section={plugin}
                                    fieldName="gratuity_group_id"/>
                                <SectionInput label="Gratuity supergroup id" setInput={setPlugin} section={plugin}
                                    fieldName="gratuity_supergroup_id"/>
                                <SectionInput label="Gratuity label" setInput={setPlugin} section={plugin}
                                    fieldName="gratuity_label"/>
                                <SectionInput label="Gratuity payment method" setInput={setPlugin} section={plugin}
                                    fieldName="gratuity_payment_method"/>
                                <SectionInput label="Decimal precision" setInput={setPlugin} section={plugin}
                                    fieldName="decimal_precision" number={true}/>
                                <SectionInput label="Decimal separator" setInput={setPlugin} section={plugin}
                                    fieldName="decimal_separator"/>
                            </CollapsingGroup>
                            {plugin.cumulated_shift_close === CUMULATED_SHIFT_CLOSE.OFF ?
                                (plugin.disable_substract_tip_payments ? translate(plugin.disable_substract_tip_payments.toString(), {scope: 'common'}) : '---') :
                                <SectionSwitch label="Substract tip payments" setInput={setPlugin} section={plugin} fieldName="disable_substract_tip_payments"/>}
                        </div>
                        <PluginMapping plugin={plugin}/>
                        <PluginCollapse plugin={plugin}/>
                    </div>
                </div>
            </div>
        </li>
    );
};

SIHOTPlugin.propTypes = {
    plugin: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    setPlugin: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    setPlugin: makeAction(actionTypes.SET_PLUGIN),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SIHOTPlugin);
