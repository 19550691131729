import * as actionTypes from '../actionTypes';

const DEFAULT_STATE = {
    name: '',
    content: '',
};

const deviceFileReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return DEFAULT_STATE;
        }
        case actionTypes.SET_DEVICE_FILE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default deviceFileReducer;
