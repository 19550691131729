import PropTypes from 'prop-types';
import React from 'react';

const SectionInput = ({label, setInput, section, sectionName, fieldName, number = false, readOnly = false, error = false}) => {
    const input = (
        readOnly ?
            <input readOnly type="text" className="form-control" value={section[fieldName] || ''}/>
            :
            <input
                onChange={(event) => setInput(
                    sectionName ?
                        {
                            [sectionName]: {
                                ...section,
                                [fieldName]: number && event.target.value ? parseInt(event.target.value, 10) : event.target.value,
                            },
                        }
                        :
                        {
                            ...section,
                            [fieldName]: number && event.target.value ? parseInt(event.target.value, 10) : event.target.value,
                        }
                )}
                type={number ? 'number' : 'text'}
                className={`form-control ${error ? 'has-error iconed' : ''}`}
                value={section[fieldName] || ''}/>
    );
    return (
        <React.Fragment>
            {label ?
                <div className="form-group">
                    <label className="control-label col-xs-2">{label}:</label>
                    <div className="col-xs-10">
                        {input}
                    </div>
                </div>
                :
                input
            }
        </React.Fragment>
    );
};

SectionInput.propTypes = {
    label: PropTypes.string,
    setInput: PropTypes.func,
    section: PropTypes.object,
    sectionName: PropTypes.string,
    fieldName: PropTypes.string,
    number: PropTypes.bool,
    readOnly: PropTypes.bool,
    error: PropTypes.bool,
};

export default SectionInput;
