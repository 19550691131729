import React from 'react';
import translate from 'counterpart';

const printerFetchFailed = () => (
    <div>
        <h1>404 {translate('not_found', {scope: 'modals'})}</h1>
    </div>
);

printerFetchFailed.propTypes = {};

export default printerFetchFailed;
