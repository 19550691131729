import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import React from 'react';
import SectionInput from '../../../../conf_template/form/rows/valueTypes/SectionInput';
import SectionSwitch from '../../../../conf_template/form/rows/valueTypes/SectionSwitch';
import SectionSelect from '../../../../conf_template/form/rows/valueTypes/SectionSelect';
import {AGGREGATED_ITEMS_OPTIONS, CONSTRAINT_OPTIONS, CUMULATED_INVOICE_TO_ROOM_OPTIONS, CUMULATED_SERVICEPERIOD_MAP, CUMULATED_SHIFT_CLOSE, CUMULATED_SHIFT_CLOSE_OPTIONS, CUMULATED_SHIFT_CLOSE_SERVICEPERIOD_OPTIONS} from '../../../../../configuration/constants';
import makeAction from '../../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../../redux/actionTypes';

import tooltip from '../../../../common/tooltip';
import WarningModal from '../../../../common/modals/WarningModal';
import $ from 'jquery';
import CopyButton from '../../CopyButton';
import CollapsingGroup from '../CollapsingGroup';

// TODO: REFACTOR SERVICEPERIOD MODAL
const SihotPlugin = ({plugin, endpoint_url, setPlugin, validation}) => {
    const showServiceperiodModal = (change) => {
        if (plugin.cumulated_shift_close.includes('serviceperiod')) {
            $(`#disableServicePeriod${plugin.id}`).modal('show');
        } else {
            setPlugin(change);
        }
    };
    return [
        <div key="sihot-main-block">
            <CollapsingGroup id="mainInfo" name="Main Plugin Information">
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('name', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="name"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('type', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>{plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'}</Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('is_active', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('is_active', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="is_active"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('endpoint_url', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('endpoint_url', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                        <CopyButton target={`endpoint_url_${plugin.id}`} />
                    </Col>
                    <Col xs={6} id={`endpoint_url_${plugin.id}`}>{endpoint_url || '---'}</Col>
                </Row>
            </CollapsingGroup>
            <CollapsingGroup id="pmsConnection" name="PMS Connection">
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('url', {scope: 'devices.plugins.sihot'})}:
                        {validation ?
                            <OverlayTrigger overlay={tooltip(translate('url', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger overlay={tooltip('This field is required')}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="url" error={!validation}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('api_key', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('api_key', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="api_key"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('hotel', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('hotel', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="hotel"/>
                    </Col>
                </Row>
            </CollapsingGroup>
            <CollapsingGroup id="timeouts" name="Timeouts, Intervals & Amounts">
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('shift_close_interval', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('shift_close_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="shift_close_check_interval"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('shift_close_retry_interval', {scope: 'devices.plugins'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('shift_close_retry_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="shift_close_retry_interval"
                            number={true}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('shift_close_max_retry_attempts', {scope: 'devices.plugins'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('shift_close_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="shift_close_max_retry_attempts"
                            number={true}/>
                    </Col>
                </Row>
            </CollapsingGroup>
            <CollapsingGroup id="features" name="Features">
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('negative_postings', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('negative_postings', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="allow_negative_postings"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('block_zero_payments', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('block_zero_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="block_zero_payments"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('duplicate_check', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('duplicate_check', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="disable_duplicate_check" negate/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('serviceperiod', {scope: 'devices.plugins'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('serviceperiod', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={plugin.enable_serviceperiod ? showServiceperiodModal : setPlugin} section={plugin} fieldName="enable_serviceperiod"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('shift_close', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('shift_close', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="disable_shift_close" negate/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('internal', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('internal', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="forward_internal" forwBlock/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('nonturnover', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('nonturnover', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="forward_nonturnover" forwBlock/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('midnight_separation', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('midnight_separation', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSwitch setInput={setPlugin} section={plugin} fieldName="midnight_separation" negate inverse/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('substract_tip_payments', {scope: 'devices.plugins'})}:
                        <OverlayTrigger overlay={tooltip(translate('substract_tip_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        {plugin.cumulated_shift_close === CUMULATED_SHIFT_CLOSE.OFF ? translate(plugin.substract_tip_payments.toString(), {scope: 'common'}) :
                            <SectionSwitch setInput={setPlugin} section={plugin} fieldName="substract_tip_payments" negate inverse/>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('label', {scope: 'devices.plugins.constraints_behavior'})}:
                        <OverlayTrigger overlay={
                            tooltip(translate('constraints_behavior', {scope: 'tooltips.hotel_systems.plugin'}) +
                                '\n' + translate('all', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                                '\n' + translate('non_zero', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_non_zero_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                                '\n' + translate('items', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_sub_items_only', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                                '\n' + translate('main', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_constraint_main_item', {scope: 'tooltips.hotel_systems.items_behaviour'})
                            )}
                        >
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSelect setInput={setPlugin} section={plugin} fieldName="constraints_behavior" options={CONSTRAINT_OPTIONS}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('label', {scope: 'devices.plugins.aggregated_items_behavior'})}:
                        <OverlayTrigger overlay={tooltip(translate('aggregated_items_behavior', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSelect setInput={setPlugin} section={plugin} fieldName="aggregated_items_behavior"
                            options={AGGREGATED_ITEMS_OPTIONS}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('label', {scope: 'devices.plugins.cumulated_shift_close'})}:
                        <OverlayTrigger overlay={tooltip(translate('cumulated_shift_close', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSelect setInput={setPlugin} section={plugin} fieldName="cumulated_shift_close" options={plugin.enable_serviceperiod ? [].concat(CUMULATED_SHIFT_CLOSE_OPTIONS, CUMULATED_SHIFT_CLOSE_SERVICEPERIOD_OPTIONS) : CUMULATED_SHIFT_CLOSE_OPTIONS}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('label', {scope: 'devices.plugins.cumulated_invoice_to_room'})}:
                        <OverlayTrigger overlay={tooltip(translate('cumulated_invoice_to_room', {scope: 'tooltips.hotel_systems.plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionSelect setInput={setPlugin} section={plugin} fieldName="cumulated_invoice_to_room" options={CUMULATED_INVOICE_TO_ROOM_OPTIONS}/>
                    </Col>
                </Row>
            </CollapsingGroup>
            <CollapsingGroup id="others" name="Others">
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('gratuity_id', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('gratuity_id', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="gratuity_id"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('gratuity_group_id', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('gratuity_group_id', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="gratuity_group_id"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('gratuity_super_group_id', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('gratuity_super_group_id', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="gratuity_supergroup_id"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('gratuity_label', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('gratuity_label', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="gratuity_label"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('gratuity_payment_method', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('gratuity_payment_method', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="gratuity_payment_method"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('decimal_precision', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('decimal_precision', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="decimal_precision" number={true}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className="options-label">
                        {translate('decimal_separator', {scope: 'devices.plugins.sihot'})}:
                        <OverlayTrigger overlay={tooltip(translate('decimal_separator', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                        <SectionInput setInput={setPlugin} section={plugin} fieldName="decimal_separator"/>
                    </Col>
                </Row>
            </CollapsingGroup>
        </div>, <WarningModal id={`disableServicePeriod${plugin.id}`} header={translate('disable_serviceperiod_header', {scope: 'modals'})}
            onSubmit={() => {
                setPlugin({...plugin, enable_serviceperiod: false, cumulated_shift_close: CUMULATED_SERVICEPERIOD_MAP[plugin.cumulated_shift_close]});
            }} text={translate('disable_serviceperiod_body', {scope: 'modals'})} key={`disableServicePeriod${plugin.id}`}/>];
};

SihotPlugin.propTypes = {
    plugin: PropTypes.object.isRequired,
    validation: PropTypes.bool.isRequired,
    endpoint_url: PropTypes.string,
    setPlugin: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setPlugin: makeAction(actionTypes.SET_PLUGIN),
};

export default connect(null, mapDispatchToProps)(SihotPlugin);
