import {createSelector} from 'reselect';
import translate from 'counterpart';

import {formatAmount, formatDate} from '../../../services/formatters';
import {invoiceDetails} from './stateSelector';

export const invoiceDetailsSelector = createSelector(
    invoiceDetails,
    (invoice) => ({
        invoice: invoice ? {
            ...invoice,
            ts: formatDate(invoice.datetime),
            startts: formatDate(invoice.startdatetime),
            status_name: translate(invoice.status || 'all', {scope: 'devices.invoices'}),
            tip: formatAmount(invoice.tip),
            total: formatAmount(invoice.total),
            discount: formatAmount(invoice.discount),
            categories: invoice.categories.map((category) => ({
                ...category,
                amount: formatAmount(category.amount),
                nettoamount: formatAmount(category.nettoamount),
                vatrate: formatAmount(category.vatrate),
            })),
            items: invoice.items.map((item) => ({
                ...item,
                amount: formatAmount(item.amount),
                price: formatAmount(item.price),
                vatrate: formatAmount(item.vatrate),
            })),
            payments: invoice.payments.map((payment) => ({
                ...payment,
                amountbasecurrency: formatAmount(payment.amountbasecurrency),
                currencyexchangerate: formatAmount(payment.currencyexchangerate),
                totalamount: formatAmount(payment.totalamount),
                vat: formatAmount(payment.vat),
            })),
        } : {},
    })
);
