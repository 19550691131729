import PropTypes from 'prop-types';
import React from 'react';
import NotifySystem from 'react-notification-system';
import {connect} from 'react-redux';
import makeAction from '../../redux/actions/common/makeAction';
import {REMOVE_NOTIFICATION} from '../../redux/actionTypes';


class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    system() {
        return this.myRef.current;
    }

    updateNotifications(notifications) {
        const notificationIds = notifications.map((notification) => notification.uid);

        (this.system().state.notifications || []).forEach((notification) => {
            if (notificationIds.indexOf(notification.uid) < 0) {
                this.system().removeNotification({uid: notification.uid});
            }
        });

        notifications.forEach((notification) => {
            this.system().addNotification({
                ...notification,
                onRemove: (notification_) => {
                    this.props.removeNotification({uid: notification_.uid});
                },
            });
        });
    }

    componentDidMount() {
        const {notifications} = this.props;
        this.updateNotifications(notifications);
    }

    componentDidUpdate() {
        this.updateNotifications(this.props.notifications);
    }

    shouldComponentUpdate(nextProps) {
        return this.props !== nextProps;
    }

    render() {
        return (
            <NotifySystem ref={this.myRef} allowHTML={true} />
        );
    }
}

Notifications.propTypes = {
    notifications: PropTypes.array.isRequired,
    removeNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    notifications: state.notifications,
});

const mapDispatchToProps = {
    removeNotification: makeAction(REMOVE_NOTIFICATION),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);
