import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import makeAction from '../../../redux/actions/common/makeAction';
import {DEVICE_CLEAR_ALERT} from '../../../redux/actionTypes';
import tooltip from '../../common/tooltip';

const header = ({device, clearAlert}) => (

    <div className="details-header">
        <div className="row">
            <div className="col-xs-8">
                <h2>{device.id}<span className="extra-info name"> - {device.name}</span></h2>
            </div>
            {device.alert_level >= 30 &&
                <div className="col-xs-4 error-reporting text-right">
                    <div className="media">
                        <div className="width media-body">
                            <p>{translate(String(device.alert_code), {scope: ['logging', 'code', String(device.alert_group)]})}</p>
                            <OverlayTrigger overlay={tooltip(translate('resolve_issue', {scope: 'tooltips.device_details'}))}>
                                <a id="resolveWarning" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    clearAlert({id: device.id});
                                }}>{translate('resolve_issue', {scope: 'devices.device_details'})}</a>
                            </OverlayTrigger>
                        </div>
                        <span className="media-right media-middle collapsed">
                            <span className="fa-stack fa-lg">
                                <i className={'fa fa-circle fa-stack-2x ' + (device.alert_level >= 40 ? 'error-icon' : 'warning-icon')}/>
                                <i className="fa fa-exclamation fa-stack-1x fa-inverse"/>
                            </span>
                        </span>
                    </div>
                </div>
            }
        </div>
    </div>
);

header.propTypes = {
    device: PropTypes.object,
    clearAlert: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    clearAlert: makeAction(DEVICE_CLEAR_ALERT),
};

export default connect(null, mapDispatchToProps)(header);
