import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

const Loader = ({label, icon}) => (
    <div className="alert-box-wrapper">
        <div className="spinner-small">
            <i className={`${icon} m-b-5`}/>
            <div>{label}</div>
        </div>
    </div>
);

Loader.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    label: state.loader.label,
    icon: state.loader.icon,
});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);
