import * as actionTypes from '../../actionTypes';

const reducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            return [
                ...state,
                {
                    ...action.notification,
                    uid: Date.now(),
                },
            ];
        case actionTypes.REMOVE_NOTIFICATION:
            return state.filter((notification) => notification.uid !== action.payload.uid);
        default:
            return state;
    }
};

export default reducer;
