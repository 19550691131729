import PropTypes from 'prop-types';
import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';
import {connect} from 'react-redux';

import makeAction from '../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../redux/actionTypes';

import {getStatus} from '../../../services/statusResolver';
import translate from 'counterpart';

const SelectConfigModal = ({device, enterConfigs, sendConfigID, configID, onSubmit, page, changeModal}) => (
    <Modal show={page.modal.selectConfigModal} onHide={() => changeModal({selectConfigModal: false})}>
        <Modal.Header>
            <button type="button" className="close" onClick={() => changeModal({selectConfigModal: false})}>×</button>
            <h3 className="modal-title">Choose Config</h3>
        </Modal.Header>
        <Modal.Body>
            <DropdownButton id="preconf-dropdown" title={configID.name || 'Pre Configuration'}>
                {enterConfigs.map((obj, index) => (
                    <MenuItem id={'preconf-menu-item' + obj.id}
                        key={index}
                        onClick={() => sendConfigID(obj)}
                        eventKey={obj.id}>{obj.name}
                    </MenuItem>
                ))}
            </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
            <button
                className={'btn btn-default pull-left' + getStatus(device.status)}
                onClick={
                    () => {
                        if (device.status === 'new') {
                            const payload = {
                                status: 'accepted',
                                id: device.id,
                                preconfig_id: null,
                            };

                            if (configID.hasOwnProperty('id')) {
                                payload.preconfig_id = configID.id;
                            }

                            onSubmit(payload);
                            changeModal({selectConfigModal: false});
                        }
                    }
                }
            >
                <span>{translate('yes', {scope: 'common'})}</span><i className="fa fa-check"/>
            </button>
            <button type="button" className="btn btn-default pull-right" onClick={() => changeModal({selectConfigModal: false})}>
                {translate('close', {scope: 'modals'})}
            </button>

        </Modal.Footer>
    </Modal>
);

SelectConfigModal.propTypes = {
    device: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.string,
        mac: PropTypes.string,
        external_ip: PropTypes.string,
        serial: PropTypes.string,
        internal_ip: PropTypes.string,
    }).isRequired,
    enterConfigs: PropTypes.arrayOf(
        PropTypes.object
    ),
    configID: PropTypes.object,
    sendConfigID: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    changeModal: PropTypes.func.isRequired,
    page: PropTypes.shape({
        modal: PropTypes.shape({
            selectConfigModal: PropTypes.bool,
        }),
    }),
};

const mapDispatchToProps = {
    sendConfigID: makeAction(actionTypes.SEND_CONFIG_ID),
    changeModal: makeAction(actionTypes.CHANGE_MODAL),
    onSubmit: makeAction(actionTypes.CHANGE_DEVICE_STATUS),
};

const mapStateToProps = (state) => ({
    enterConfigs: state.deviceConfigurationsToProvisione.list,
    configID: state.devices.selected_pre_config,
    page: state.page,
    device: state.device.device,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectConfigModal);
