import {createSelector} from 'reselect';
import _ from 'lodash';
import {DEVICES_LIST_ADDABLE_COLUMNS} from '../../../configuration/constants';
import {devicesColumns} from './stateSelector';
import translate from 'counterpart';

export const manageColumnsButton = createSelector(
    devicesColumns,
    (columns) => ({
        selectableColumns: DEVICES_LIST_ADDABLE_COLUMNS.map(
            (obj) => ({
                ...obj,
                label: translate(obj.name, {scope: 'devices.table_columns'}),
                selected: _.findIndex(columns, ['name', obj.name]) !== -1,
            })
        ),
    })
);
