import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import ConfigurationForm from './form/form';
import ConfigurationHeader from './form/header';
import {CONFIGS_LIST_PAGE} from '../../configuration/paths';
import makeAction from '../../redux/actions/common/makeAction';
import * as actionTypes from '../../redux/actionTypes';

const ConfigurationPage = ({config, pluginChosen, handleSave}) => (
    <div className="content-wrapper">
        <div className="container">
            <div className="content">
                <ConfigurationHeader title="Create template"/>
                <ConfigurationForm handleSave={handleSave} link={CONFIGS_LIST_PAGE} config={config} pluginChosen={pluginChosen}/>
            </div>
        </div>
    </div>
);

ConfigurationPage.propTypes = {
    config: PropTypes.object.isRequired,
    pluginChosen: PropTypes.bool,
    handleSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    config: state.deviceConfiguration,
    defaultConfiguration: state.defaultDeviceConfiguration,
});

const mapDispatchToProps = {
    handleSave: makeAction(actionTypes.SAVE_DEVICE_CONFIGURATION),
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationPage);
