import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../../redux/actions/common/makeAction';
import PluginCollapse from '../../rows/pluginsCollapse';
import * as actionTypes from '../../../../../redux/actionTypes';
import SectionInput from '../../rows/valueTypes/SectionInput';
import SectionSwitch from '../../rows/valueTypes/SectionSwitch';
import PluginHeader from './PluginHeader';
import PluginMapping from './PluginMapping';
import {AGGREGATED_ITEMS_OPTIONS, CONSTRAINT_OPTIONS} from '../../../../../configuration/constants';
import SectionSelect from '../../rows/valueTypes/SectionSelect';
import CollapsingGroup from '../../../../devices/details/plugins/CollapsingGroup';

const OraclePlugin = ({plugin, setPlugin}) => {
    const panelHeadingId = `pluginRowHeading${plugin.id}`;
    const collapseId = `pluginCollapse${plugin.id}`;
    return (<li className="panel-group" id={plugin.id} role="tablist" aria-multiselectable="true">
        <div className="panel panel-default">
            <PluginHeader label={`Oracle ${plugin.order_no}`} panelHeadingId={panelHeadingId} collapseId={collapseId}
                plugin={plugin}/>
            <div id={collapseId} className="panel-collapse collapse" role="tabpanel" aria-labelledby={panelHeadingId}>
                <div className="panel-body">
                    <div className="m-b-15">
                        <CollapsingGroup id="mainInfo" name="Main Plugin Information">
                            <SectionInput label="Name" setInput={setPlugin} section={plugin} fieldName="name"/>
                            <SectionSwitch label="Active" setInput={setPlugin} section={plugin} fieldName="is_active"/>
                        </CollapsingGroup>
                        <CollapsingGroup id="pmsConnection" name="PMS Connection">
                            <SectionInput label="Host" setInput={setPlugin} section={plugin} fieldName="host"/>
                            <SectionInput label="Port" setInput={setPlugin} section={plugin} fieldName="port" number={true}/>
                        </CollapsingGroup>
                        <CollapsingGroup id="timeouts" name="Timeouts, Intervals & Amounts">
                            <SectionInput label="Connection interval" setInput={setPlugin} section={plugin}
                                fieldName="check_interval" number={true}/>
                            <SectionInput label="Shift close check interval" setInput={setPlugin} section={plugin}
                                fieldName="shift_close_check_interval"/>
                            <SectionInput label="Shift close retry interval" setInput={setPlugin} section={plugin}
                                fieldName="shift_close_retry_interval" number={true}/>
                            <SectionInput label="Shift close max retry attempts" setInput={setPlugin} section={plugin}
                                fieldName="shift_close_max_retry_attempts" number={true}/>
                            <SectionInput label="Max rounding fix" setInput={setPlugin} section={plugin}
                                fieldName="max_rounding_fix" number={true}/>
                        </CollapsingGroup>
                        <CollapsingGroup id="features" name="Features">
                            <SectionSwitch label="Split discount" setInput={setPlugin} section={plugin}
                                fieldName="split_discount"/>
                            <SectionSwitch label="Allow negative postings" setInput={setPlugin} section={plugin}
                                fieldName="allow_negative_postings"/>
                            <SectionSwitch label="Block amountless transactions" setInput={setPlugin} section={plugin}
                                fieldName="block_zero_payments"/>
                            <SectionSwitch label="Auto-grab outlet ID" setInput={setPlugin} section={plugin}
                                fieldName="business_id_outlet"/>
                            <SectionSwitch label="Enable duplicate check" setInput={setPlugin} section={plugin}
                                fieldName="disable_duplicate_check" negate/>
                            <SectionSwitch label="Enable service periods" setInput={setPlugin} section={plugin}
                                fieldName="enable_serviceperiod"/>
                            <SectionSwitch label="Enable shift close" setInput={setPlugin} section={plugin}
                                fieldName="disable_shift_close" negate/>
                            <SectionSwitch label="Forward internal payments" setInput={setPlugin} section={plugin}
                                fieldName="forward_internal" forwBlock/>
                            <SectionSwitch label="Forward nonturnover" setInput={setPlugin} section={plugin}
                                fieldName="forward_nonturnover" forwBlock/>
                            <SectionSelect label="Constraints behavior" setInput={setPlugin} section={plugin}
                                fieldName="constraints_behavior"
                                options={CONSTRAINT_OPTIONS}/>
                            <SectionSelect label="Aggregated items behavior" setInput={setPlugin} section={plugin}
                                fieldName="aggregated_items_behavior" options={AGGREGATED_ITEMS_OPTIONS}/>
                        </CollapsingGroup>
                    </div>
                    <PluginMapping plugin={plugin}/>
                    <PluginCollapse plugin={plugin}/>
                </div>
            </div>
        </div>
    </li>);
};

OraclePlugin.propTypes = {
    plugin: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    setPlugin: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    setPlugin: makeAction(actionTypes.SET_PLUGIN),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OraclePlugin);
