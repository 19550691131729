import tooltip from '../../common/tooltip';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import React from 'react';
import PropTypes from 'prop-types';

const CopyButton = ({target}) => (
    <OverlayTrigger overlay={tooltip(translate('copy', {scope: 'common'}))}>
        <a className="clipboard inline-block" data-clipboard-target={`#${target}`} href="" onClick={(e) => e.preventDefault()}><i className="fa fa-clipboard"/></a>
    </OverlayTrigger>
);

CopyButton.propTypes = {
    target: PropTypes.string.isRequired,
};

export default CopyButton;
