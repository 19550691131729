import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import translate from 'counterpart';
import makeAction from '../../../redux/actions/common/makeAction';
import {CHECK_DEVICE} from '../../../redux/actionTypes';
import DevicesValues from './devicesValues';

const DeviceListRow = ({checkDevice, device, columns}) => (
    <tr id={device.id} className={device.alert_level >= 40 ? 'device-error' : ''}>
        <td id={`check${device.id}`} >
            <div className="checkbox checkbox-wrapper">
                <input type="checkbox" id={`checkbox${device.id}`}
                    onChange={() => checkDevice({
                        id: device.id,
                        state: !device.checked,
                    })}
                    checked={device.checked || false}
                />
                <label htmlFor={`checkbox${device.id}`}><span className="fake-checkbox" /></label>
            </div>
        </td>
        <DevicesValues device={device} columns={columns}/>
        <td id={`soft_version${device.id}`} >
            <span className="text-right">{device.soft_version || '---'}</span>
        </td>
        <td className="status" id={`status${device.id}`} >
            <span id="deviceStatus">{translate(device.status, {scope: 'devices.table_options'})}</span>
        </td>
    </tr>
);

DeviceListRow.propTypes = {
    device: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.oneOf(['new', 'accepted', 'rejected', 'installing_bridge_software', 'installing_bridge_software', 'provisioned',
            'provisioning_failed', 'upgrading', 'rebooting', 'connected', 'not_connected', 'failed']),
        mac: PropTypes.string,
        external_ip: PropTypes.string,
        serial: PropTypes.string,
        internal_ip: PropTypes.string,
        company: PropTypes.string,
    }).isRequired,
    checkDevice: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
    checkDevice: makeAction(CHECK_DEVICE),
};

export default connect(null, mapDispatchToProps)(DeviceListRow);
