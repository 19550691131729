import * as actionTypes from '../actionTypes';
import {TYPE as RESET_STATE} from '../actions/authorization/resetState';

const DEFAULT_STATE = {
    inProgress: false,
    user: {
        groups: [],
    },
    version: '',
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.LOG_IN_RUNNING: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.LOG_IN_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                inProgress: false,
                version: (action.payload.headers['x-backend-version'] || ''),
            };
        case actionTypes.LOG_IN_FAILED:
        case RESET_STATE: {
            return {
                ...state,
                inProgress: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
