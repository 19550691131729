import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../redux/actions/common/makeAction';
import {withRouter} from 'react-router';
import {Link, NavLink} from 'react-router-dom';
import {CHANGE_LANG, LOG_OUT} from '../../../redux/actionTypes';
import * as paths from '../../../configuration/paths';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';
import {nameSelector} from '../../../redux/selectors/auth/stateSelector';
import translate from 'counterpart';
import $ from 'jquery';

const Header = ({onLogOut, history, fullname, changeLang, lang}) => (
    <div className="main-navbar">
        <nav className="navbar navbar-static-top">
            <div className="container">
                <div className="navbar-header">
                    <Link id="dashboard" className="logo-brand hide-text" to={paths.DASHBOARD_PAGE}>Gastrofix command center</Link>
                </div>
                <ul className="nav navbar-nav">
                    <PermissionCheck permission={permissions.LIST_DEVICE}>
                        <li id="key" role="presentation"
                            className={window.location.pathname === paths.DASHBOARD_PAGE ? 'active' : ''}>
                            <NavLink id="devices" to={paths.DASHBOARD_PAGE} activeClassName="active">
                                {translate('table_header', {scope: 'devices'})}
                            </NavLink>
                        </li>
                    </PermissionCheck>
                    <PermissionCheck permission={permissions.LIST_CONFIGS}>
                        <li id="key" role="presentation"
                            className={window.location.pathname === paths.CONFIGS_LIST_PAGE ? 'active' : ''}>
                            <NavLink id="configs" to={paths.CONFIGS_LIST_PAGE} activeClassName="active">
                                {translate('configs_table_header', {scope: 'devices'})}
                            </NavLink>
                        </li>
                    </PermissionCheck>
                    <PermissionCheck permission={permissions.LIST_SYSTEM_IMAGES}>
                        <li id="key" role="presentation"
                            className={window.location.pathname === paths.IMAGES_LIST_PAGE ? 'active' : ''}>
                            <NavLink id="images" to={paths.IMAGES_LIST_PAGE} activeClassName="active">
                                {translate('table_header', {scope: 'system_images'})}
                            </NavLink>
                        </li>
                    </PermissionCheck>
                </ul>
                <div className="nav navbar-nav navbar-right">
                    <div className="language-wrapper">
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            {lang}
                        </button>
                        <ul className="collapse list-unstyled language-list" id="collapseExample"
                            onClick={() => $('.language-list').collapse('hide')}>
                            <li>
                                <a type="button" onClick={() => changeLang('en')}>
                                    en
                                </a>
                            </li>
                            <li>
                                <a type="button" onClick={() => changeLang('de')}>
                                    de
                                </a>
                            </li>
                        </ul>
                    </div>
                    <label>{fullname}</label>
                    <button id="logout" type="button" className="btn btn-primary" onClick={() => onLogOut({
                        callback: () => history.push(paths.LOGIN_PAGE),
                    })}>
                        <i className="fa fa-sign-out"/>
                    </button>
                </div>
            </div>
        </nav>
    </div>
);

Header.propTypes = {
    fullname: PropTypes.string,
    onLogOut: PropTypes.func.isRequired,
    changeLang: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    fullname: nameSelector(state),
    lang: state.navigation.lang,
});

const mapDispatchToProps = {
    onLogOut: makeAction(LOG_OUT),
    changeLang: makeAction(CHANGE_LANG),
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header));
