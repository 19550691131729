import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import translate from 'counterpart';

import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import WarningModal from '../../../common/modals/WarningModal';
import tooltip from '../../../common/tooltip';

const PrinterRemoveButtons = ({printer, device_id, removePrinter}) => (
    <div className="dropdown">
        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
            <span className="fa fa-cog"/>
        </button>
        <ul className="dropdown-menu">
            <li data-toggle="modal" data-target={`#removeModal${printer.hash}`} data-backdrop="static"
                data-keyboard="false">
                <OverlayTrigger overlay={tooltip(translate('remove_printer', {scope: 'devices.printers'}))}>
                    <span>
                        <i className={'fa fa-remove actions_dropdown_i'}/> {translate('remove', {scope: 'devices.printers'})}
                    </span>
                </OverlayTrigger>
            </li>
        </ul>
        <WarningModal id={`removeModal${printer.hash}`} onSubmit={() => {
            removePrinter({id: device_id, printer: printer});
        }} text={translate('remove_printer', {scope: 'modals'}) + printer.name}/>
    </div>
);

PrinterRemoveButtons.propTypes = {
    printer: PropTypes.object.isRequired,
    device_id: PropTypes.number.isRequired,
    removePrinter: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    removePrinter: makeAction(actionTypes.REMOVE_PRINTER),
};

export default connect(null, mapDispatchToProps)(PrinterRemoveButtons);
