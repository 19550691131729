import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import WarningModal from '../../common/modals/WarningModal';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';
import translate from 'counterpart';

const footer = ({config, deleteConfig, editMode, link, id, history, clickSave, deviceEdit}) => (
    <div className="details-content">
        <div className="row">
            <div className="col-xs-12 text-right buttons-wrapper">
                <Link to={link}>
                    <button className="btn btn-default">
                        <span>{translate('cancel', {scope: 'devices.device_details'})}</span><i className="fa fa-remove"/>
                    </button>
                </Link>
                {editMode &&
                    <PermissionCheck permission={permissions.DELETE_CONFIGS}>
                        <button type="button" className="btn btn-default" data-toggle="modal" data-target="#deleteModal" data-backdrop="static" data-keyboard="false">
                            <span>{translate('delete', {scope: 'devices.printers'})}</span><i className="fa fa-remove"/>
                        </button>
                    </PermissionCheck>
                }
                {deviceEdit && config.networkSettingsChanged ?
                    <button type="button" className="btn btn-info" data-toggle="modal" data-target="#networkWarning" data-backdrop="static" data-keyboard="false">
                        {translate('save_changes', {scope: 'devices.device_details'})}
                    </button>
                    :
                    <button className="btn btn-info" type="submit">
                        {translate('save_changes', {scope: 'devices.device_details'})}
                    </button>
                }
            </div>
            <WarningModal id="deleteModal" onSubmit={() => {
                deleteConfig({id: id, callback: (location) => history.push(location)});
            }} text={'Are you sure you want to DELETE Configuration: ' + config.name}/>
            <WarningModal id="networkWarning" onSubmit={() => clickSave()}
                text={'Changing network settings can make device unusable. Restart of device also will be required. Do you want to continue?'}
            />
        </div>
    </div>
);

footer.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    config: PropTypes.object.isRequired,
    deleteConfig: PropTypes.func,
    editMode: PropTypes.bool,
    link: PropTypes.string.isRequired,
    id: PropTypes.number,
    clickSave: PropTypes.func.isRequired,
    deviceEdit: PropTypes.bool,
};

export default withRouter(footer);
