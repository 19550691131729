import {all, put, takeLatest} from 'redux-saga/effects';
import {get} from '../../services/persist';
import * as actionTypes from '../actionTypes';
import request, {GET, PATCH} from '../../services/request';
import {API_URL} from '../../configuration/config';
import {handleError} from './errors';
import {ERROR as get_error_notification} from '../../configuration/notifications';

function *fetch({payload}) {
    const page = payload.page || get('images_page') || 0;
    if (!payload.limit) {
        payload.limit = get('images_limit');
    }
    try {
        const response = yield request(GET, API_URL + 'images/', {params: payload});
        yield put({type: actionTypes.FETCH_IMAGES_SUCCESS, payload: response.data});
    } catch (e) {
        console.error(e);
        yield handleError(e, true);
        yield put({type: actionTypes.FETCH_IMAGES_FAILED, error: e});
        if (page > 1) {
            payload.page = 1;
            yield put({type: actionTypes.IMAGES_SET_PARAMS, payload: payload});
            yield put({type: actionTypes.FETCH_IMAGES, payload: payload});
        }
    }
}

function *fetchImages() {
    yield takeLatest([actionTypes.FETCH_IMAGES, actionTypes.IMAGES_CLEAR_PARAMS], fetch);
}

function *update({payload}) {
    try {
        yield request(PATCH, API_URL + 'images/' + payload.id + '/', payload.data);
        yield put({type: actionTypes.IMAGES_UPDATE_IMAGE_SUCCESS, payload: payload});
    } catch (e) {
        console.error(e);
        yield put({
            type: actionTypes.ADD_NOTIFICATION,
            notification: get_error_notification('Image update failed'),
        });
    }
}

function *updateImage() {
    yield takeLatest([actionTypes.IMAGES_UPDATE_IMAGE], update);
}

export default function *rpiImages() {
    yield all([
        fetchImages(),
        updateImage(),
    ]);
}
