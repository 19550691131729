import PropTypes from 'prop-types';
import React from 'react';
import PrinterActionButtons from './printerInstallButtons';
import translate from 'counterpart';

const tableColumnOne = ({printers_list, device_id}) => (
    <div className="content">
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>{translate('model', {scope: 'devices.printers'})}</th>
                    <th>{translate('connection', {scope: 'devices.printers'})}</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {printers_list.map((printer, index) => !printer.installed &&
                    <tr className="break-line" key={index}>
                        <td>{printer.make_and_model}</td>
                        <td>{printer.connection}</td>
                        <td>
                            <PrinterActionButtons printer={printer} device_id={device_id}/>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
);

tableColumnOne.propTypes = {
    printers_list: PropTypes.arrayOf(
        PropTypes.object
    ),
    device_id: PropTypes.number.isRequired,
};

export default tableColumnOne;
