import React from 'react';
import {listPlugins} from '../../../../redux/selectors/device/hotelSystems';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import PermissionCheck from '../../../authorization/PermissionCheck';
import * as permissions from '../../../../configuration/permissions';
import translate from 'counterpart';
import WarningModal from '../../../common/modals/WarningModal';
import SelectPluginConfigModal from '../../../common/modals/SelectPluginConfigModal';

class ListPlugins extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            to_delete: null,
        };
    }

    render() {
        const {plugins, select, selectedPlugin, removePlugin, edit, setEdit, fetchConfiguration, configDetails, fetchConfigurationToProvisione, selectConfig, selectedConfig, savePlugins, device} = this.props;
        return (
            <ul className="col-xs-3 list-group plugins-names">
                {plugins.map((plugin, key) => (
                    <li className={(plugin.id === selectedPlugin ? 'active ' : '') + 'list-group-item' + (plugin.is_active ? '' : ' inactive')} key={key}>
                        <span className="plugin-title"
                            onClick={() => {
                                select(plugin.id);
                                fetchConfiguration({configDetails});
                            }}
                        >
                            {plugin.name} <span className="state">({plugin.is_active ? translate('active', {'scope': 'common'}) : translate('inactive', {'scope': 'common'})})</span>
                        </span>
                        {edit &&
                        <PermissionCheck permission={permissions.DELETE_PLUGIN}>
                            <span
                                className="plugin-action"
                                role="button"
                                data-toggle="modal"
                                data-target="#deletePluginModal"
                                data-keyboard="false"
                                id="apply"
                                onClick={() => {
                                    this.setState({
                                        to_delete: plugin.id,
                                    });
                                }}
                            >
                                <i className="fa fa-close"/>
                            </span>
                        </PermissionCheck>
                        }
                    </li>
                ))}
                <WarningModal id="deletePluginModal"
                    header={translate('delete_plugin', {scope: 'devices.plugins'})}
                    text={translate('delete_plugin_modal', {scope: 'devices.plugins'})}
                    onSubmit={() => {
                        removePlugin({id: this.state.to_delete});
                        setEdit(false);
                    }}
                />
                <PermissionCheck permission={permissions.ADD_PLUGIN}>
                    <li className={(selectedPlugin === 'add' || selectedPlugin === 'new' ? 'active ' : '') + 'list-group-item'}
                        onClick={() => {
                            select('new');
                            fetchConfiguration({configDetails});
                        }}>
                        <span className="plugin-title">{translate('add_plugin', {scope: 'devices.plugins'})}
                            <span className="plugin-action"><i className="fa fa-plus"/></span>
                        </span>
                    </li>

                    {device.status === 'connected' ?
                        <li className="list-group-item" data-toggle="modal" data-target="#pluginConfigModal"
                            data-backdrop="static" data-keyboard="false"
                            onClick={() => {
                                fetchConfigurationToProvisione();
                            }}
                        >
                            <span className="plugin-title">Load configuration</span>
                            <span className="plugin-action"><i className="fa fa-upload"/></span>
                        </li> : ''}
                </PermissionCheck>
                <SelectPluginConfigModal id="#pluginConfigModal"/>
                <WarningModal
                    id="pluginConfigWarningModal"
                    onSubmit={() => {
                        selectConfig(selectedConfig);
                        savePlugins({data: selectedConfig, config: configDetails});
                    }}
                    text="Caution: In case you already had a configuration in place,
                        this new configuration will overwrite the current configuration and may make the device unusable."
                />
            </ul>
        );
    }
}

ListPlugins.propTypes = {
    plugins: PropTypes.array.isRequired,
    select: PropTypes.func.isRequired,
    setEdit: PropTypes.func.isRequired,
    fetchConfiguration: PropTypes.func.isRequired,
    removePlugin: PropTypes.func.isRequired,
    selectedPlugin: PropTypes.any.isRequired,
    edit: PropTypes.bool.isRequired,
    configDetails: PropTypes.object.isRequired,
    fetchConfigurationToProvisione: PropTypes.func.isRequired,
    selectConfig: PropTypes.func.isRequired,
    selectedConfig: PropTypes.object,
    savePlugins: PropTypes.func.isRequired,
    device: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    select: makeAction(actionTypes.SELECT_PLUGIN),
    removePlugin: makeAction(actionTypes.REMOVE_PLUGIN),
    setEdit: makeAction(actionTypes.CHANGE_EDIT_MODE),
    fetchConfiguration: makeAction(actionTypes.FETCH_DEVICE_CONFIGURATION),
    fetchConfigurationToProvisione: makeAction(actionTypes.FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE),
    selectConfig: makeAction(actionTypes.SELECT_PLUGIN_CONFIG_TO_SAVE),
    savePlugins: makeAction(actionTypes.SAVE_PLUGINS_CONFIG),
};

export default connect(listPlugins, mapDispatchToProps)(ListPlugins);
