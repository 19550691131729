import {createSelector} from 'reselect';

export const deviceConfiguration = (state) => state.deviceConfiguration;

export const defaultDeviceConfiguration = (state) => state.defaultDeviceConfiguration;

export const defaultPlugins = createSelector(
    defaultDeviceConfiguration,
    (config) => config.plugins
);

export const lanEdit = createSelector(
    deviceConfiguration,
    (config) => config.ethernets[0]
);

export const extraEdit = createSelector(
    deviceConfiguration,
    (config) => config.ethernets[1]
);

export const globalEdit = createSelector(
    deviceConfiguration,
    (config) => config.global_section
);

export const pluginsEdit = createSelector(
    deviceConfiguration,
    (config) => config.plugins
);

export const pluginsToDelete = createSelector(
    deviceConfiguration,
    (config) => config.pluginsToDelete
);
