import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ClipboardJS from 'clipboard';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Highlight from 'react-highlight/lib/optimized';
import translate from 'counterpart';

import TabView from '../../common/TabView';
import tooltip from '../../common/tooltip';

class LogDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {visible: 0};
    }

    componentDidMount() {
        this.clipboard = new ClipboardJS('.clipboard');
    }

    componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    }

    render() {
        return (
            <div className="modal fade" id="logDetailsModal" tabIndex={-1} role="dialog" aria-labelledby="logDetailsModal"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h3 className="modal-title">{translate('log_details', {scope: 'logging.label'})}</h3>
                        </div>
                        <TabView visible={this.state.visible} names={[
                            translate('info', {scope: 'common'}),
                            this.props.data.xml_pos && translate('xml_pos_rpi', {scope: 'logging.label'}),
                            this.props.data.xml_bridge && translate('xml_rpi_pms', {scope: 'logging.label'}),
                            this.props.data.xml_pms && translate('xml_pms', {scope: 'logging.label'}),
                            this.props.data.changelog && this.props.data.changelog !== 'null' && translate('changelog', {scope: 'logging.label'}),
                        ]} onChange={(i) => this.setState({visible: i})}/>
                        <div className="modal-body">
                            <div className={this.state.visible === 0 ? '' : 'hidden'}>
                                <h4 className="inline-block">
                                    {translate('info', {scope: 'common'})}
                                </h4>
                                <dl className="dl-horizontal dl-casual">
                                    <dt>
                                        {translate('timestamp', {scope: 'logging.label'})}
                                        <OverlayTrigger overlay={tooltip(translate('timestamp', {scope: 'tooltips.tools'}))}>
                                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                        </OverlayTrigger>
                                    </dt>
                                    <dd>{this.props.data.ts}</dd>
                                    <dt>{translate('level', {scope: 'logging.label'})}</dt>
                                    <dd>{this.props.data.level}</dd>
                                    <dt>
                                        {translate('error_group', {scope: 'logging.label'})}
                                    </dt>
                                    <dd className="overflowing">{this.props.data.group}</dd>
                                    <dt>
                                        {translate('error_code', {scope: 'logging.label'})}
                                    </dt>
                                    <dd className="overflowing">{this.props.data.code}</dd>
                                    <dt>
                                        {translate('message', {scope: 'logging.label'})}
                                        <OverlayTrigger overlay={tooltip(translate('message', {scope: 'tooltips.log'}))}>
                                            <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                        </OverlayTrigger>
                                    </dt>
                                    <dd className="overflowing multiline">{(this.props.data.message || []).map((item, key) => <span key={key}>{item}<br/></span>)}</dd>
                                    {(this.props.data.extra || []).map((obj, key) => [
                                        <dt key={`${obj[0]}${key}`}>{obj[0]}</dt>,
                                        <dd className="overflowing multiline" key={`${obj[1]}${key}`}>{obj[1]}</dd>,
                                    ]
                                    )}
                                </dl>
                            </div>
                            <div className={this.state.visible === 1 ? '' : 'hidden'}>
                                <h4 className="inline-block">{translate('xml_pos_rpi', {scope: 'logging.label'})}</h4>
                                <OverlayTrigger overlay={tooltip(translate('copied', {scope: 'common'}))} trigger="focus">
                                    <button className="clipboard btn btn-default copy-button pull-right"
                                        data-clipboard-target=".xml_pos">{translate('copy', {scope: 'common'})}</button>
                                </OverlayTrigger>
                                <Highlight languages={['xml']} className="xml xml_pos">{this.props.data.xml_pos}</Highlight>
                            </div>
                            <div className={this.state.visible === 2 ? '' : 'hidden'}>
                                <h4 className="inline-block">{translate('xml_rpi_pms', {scope: 'logging.label'})}</h4>
                                <OverlayTrigger overlay={tooltip(translate('copied', {scope: 'common'}))} trigger="focus">
                                    <button className="clipboard btn btn-default copy-button pull-right"
                                        data-clipboard-target=".xml_bridge">{translate('copy', {scope: 'common'})}</button>
                                </OverlayTrigger>
                                <Highlight languages={['xml']} className="xml_bridge">{this.props.data.xml_bridge}</Highlight>
                            </div>
                            <div className={this.state.visible === 3 ? '' : 'hidden'}>
                                <h4 className="inline-block">{translate('xml_pms', {scope: 'logging.label'})}</h4>
                                <OverlayTrigger overlay={tooltip(translate('copied', {scope: 'common'}))} trigger="focus">
                                    <button className="clipboard btn btn-default copy-button pull-right"
                                        data-clipboard-target=".xml_pms">{translate('copy', {scope: 'common'})}</button>
                                </OverlayTrigger>
                                <Highlight languages={['xml']} className="xml_pms">{this.props.data.xml_pms}</Highlight>
                            </div>
                            <div className={this.state.visible === 4 ? '' : 'hidden'}>
                                <h4 className="inline-block">{translate('changelog', {scope: 'logging.label'})}</h4>
                                <OverlayTrigger overlay={tooltip(translate('copied', {scope: 'common'}))} trigger="focus">
                                    <button className="clipboard btn btn-default copy-button pull-right"
                                        data-clipboard-target=".changelog">{translate('copy', {scope: 'common'})}</button>
                                </OverlayTrigger>
                                <Highlight languages={['xml']} className="changelog">{this.props.data.changelog}</Highlight>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">{translate('close', {scope: 'modals'})}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LogDetailsModal.propTypes = {
    data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.navigation.modalData,
});

export default connect(mapStateToProps)(LogDetailsModal);
