import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import translate from 'counterpart';
import DatePicker from 'react-datepicker';
import {DATE_FORMAT} from '../../../services/formatters';
import makeAction from '../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../redux/actionTypes';
import {connect} from 'react-redux';
import moment from 'moment';

class TimedUpgradeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {date: null, time: null, min_time: '', invalid: true};
    }

    minTimeChange() {
        this.setState({min_time: moment().format('HH:mm')});
    }

    render() {
        const closeModal = (_id) => $(`#${_id}`).modal('hide');
        return (<div id={this.props.id} className="modal fade" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => closeModal(this.props.id)}>×</button>
                        <h3 className="modal-title">{translate('timed_upgrade', {scope: 'devices'})}</h3>
                    </div>
                    <div className="modal-body timed-upgrade">
                        {translate('timed_upgrade', {scope: 'tooltips.action'})}
                        <DatePicker className="form-control"
                            placeholderText={translate('select_date', {scope: 'modals'})}
                            dateFormat={DATE_FORMAT}
                            defaultValue={null}
                            minDate={moment()}
                            selected={this.state.date}
                            showDisabledMonthNavigation
                            onChange={(date) => {
                                if (date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                                    this.minTimeChange();
                                }
                                this.setState({date: date});
                            }}
                        />
                        <input
                            className="form-control time-field"
                            type="time"
                            min={this.state.min_time}
                            placeholder="HH:MM"
                            onChange={(event) => {
                                if ((/^([01]\d|2[0-3]):?([0-5]\d)$/u).test(event.target.value)) {
                                    this.setState({
                                        invalid: false,
                                        time: event.target.value,
                                    });
                                } else {
                                    this.setState({invalid: true});
                                }
                            }}
                        />
                    </div>
                    <div className=" modal-footer">
                        <div className=" row">
                            <div className=" col-xs-6">
                                <button className=" btn btn-primary"
                                    disabled={this.state.invalid}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.multipleInstall({
                                            devices: this.props.devices,
                                            soft_version: this.props.soft_version,
                                            action: 'install',
                                            eta: this.state.date.format('YYYY-MM-DD') + 'T' + this.state.time,
                                        });
                                        closeModal(this.props.id);
                                    }}>
                                    <span>{translate('ok', {scope: 'modals'})}</span>
                                </button>
                            </div>
                            <div className=" col-xs-6 text-right">
                                <button type=" button" className=" btn btn-default"
                                    onClick={() => closeModal(this.props.id)}>{translate('cancel', {scope: 'modals'})}
                                </button>
                            </div>
                        </div>
                    </div>
                    `
                </div>
            </div>
        </div>);
    }
}

TimedUpgradeModal.propTypes = {
    id: PropTypes.string.isRequired,
    devices: PropTypes.array,
    soft_version: PropTypes.string,
    multipleInstall: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    multipleInstall: makeAction(actionTypes.MULTIPLE_INSTALL),
};

export default connect(null, mapDispatchToProps)(TimedUpgradeModal);
