import PropTypes from 'prop-types';
import React from 'react';
import PrinterModelMake from './printerModelMake';
import PrinterStage2 from './printerStage2';
import Loader from '../../../../common/loaderBox';
import translate from 'counterpart';

const PreInstallPrinter = ({device_id, installed_printer}) => {
    let currentSheet = <div/>;
    switch (installed_printer.stage) {
        case 'one': {
            currentSheet = <PrinterModelMake device_id={device_id}/>;
            break;
        }
        case 'two': {
            currentSheet = <PrinterStage2 device_id={device_id}/>;
            break;
        }
        case 'in_progress': {
            currentSheet = <Loader/>;
            break;
        }
        default: {
            break;
        }
    }
    return (
        <div className="details-content">
            <h3>{translate('printer_preinstallation', {scope: 'devices.printers'})}</h3>
            {currentSheet}
        </div>
    );
};

PreInstallPrinter.propTypes = {
    device_id: PropTypes.string.isRequired,
    installed_printer: PropTypes.object.isRequired,
};

export default PreInstallPrinter;
