import React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import translate from 'counterpart';
import PropTypes from 'prop-types';

import {PAGE_SIZE_OPTIONS} from '../../configuration/constants';
import Pagination from '../common/react-pagination';
import makeAction from '../../redux/actions/common/makeAction';
import {FETCH_IMAGES, IMAGES_SET_PARAMS} from '../../redux/actionTypes';

const ImgListFooter = ({count, fetchConfigs, params, setParams}) => (
    <div className="table-footer row">
        <div className="col-xs-4"/>
        <div className="col-xs-4 text-center">
            <Pagination changePage={setParams} limit={params.limit} page={params.page} count={count}/>
        </div>
        <div className="col-xs-4 text-right">
            <label id="pageSelect" className="no-input select-wrapper pagination-select">
                {translate('per_page', {scope: 'devices'})}
                <Select value={params.limit} options={PAGE_SIZE_OPTIONS} clearable={false} searchable={false}
                    onChange={(e) => {
                        const arg = {...params, limit: e.value, page: 1};
                        setParams(arg);
                        fetchConfigs(arg);
                    }}/>
            </label>
        </div>
    </div>
);

ImgListFooter.propTypes = {
    count: PropTypes.number.isRequired,
    fetchConfigs: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    count: state.sysImageReducer.count,
    params: state.sysImageReducer.params,
});

const mapDispatchToProps = {
    fetchConfigs: makeAction(FETCH_IMAGES),
    setParams: makeAction(IMAGES_SET_PARAMS),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImgListFooter);
