export const LIST_DEVICE = 'list_device';
export const CHANGE_DEVICE = 'change_device';
export const DELETE_DEVICE = 'delete_device';
export const UPGRADE_DEVICE = 'upgrade_device';
export const REBOOT_DEVICE = 'reboot_device';
export const DEVICE_PASSWORD = 'device_password';
export const LIST_PLUGIN = 'list_abstractplugin';
export const ADD_PLUGIN = 'add_abstractplugin';
export const EDIT_PLUGIN = 'change_abstractplugin';
export const DELETE_PLUGIN = 'delete_abstractplugin';
export const LIST_CONFIGS = 'list_deviceconfiguration';
export const ADD_CONFIGS = 'add_deviceconfiguration';
export const CHANGE_CONFIGS = 'change_deviceconfiguration';
export const DELETE_CONFIGS = 'delete_deviceconfiguration';
export const LIST_INVOICE = 'list_invoice';
export const LIST_PRINTERS = 'list_printer';
export const LIST_SYSTEM_IMAGES = 'list_systemimage';
export const SHOW_TOOLS = 'show_tools';
export const CHANGE_RELEVANT = 'change_relevant';
export const MANAGE_COMPANY = 'manage_company';
export const SEND_XML = 'send_xml';
