import {createSelector} from 'reselect';
import {devicesCount, devicesLimit, devicesPage} from './stateSelector';
import {optionsSelector} from '../device/actions';

export const devicesListFooter = createSelector(
    devicesLimit,
    devicesCount,
    devicesPage,
    optionsSelector,
    (limit, count, page, options) => ({
        limit,
        count,
        page,
        notEmpty: options.length > 0,
    })
);
