/*
 * import 'react-select/dist/react-select.css';
 *
 * Avoid duplicates: use custom Bootstrap from 'designs/scss'
 * import 'bootstrap/dist/css/bootstrap.min.css';
 */
import 'bootstrap/dist/js/bootstrap.min';
import 'font-awesome/css/font-awesome.min.css';
import 'highlight.js/styles/github-gist.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../design/css/style.css';
import '../design/scss/input.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './redux/reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './redux/saga';
import createHistory from 'history/createBrowserHistory';
import routes from './configuration/routes';
import {composeWithDevTools} from 'redux-devtools-extension';
import translate from 'counterpart';
import en from './configuration/lang/en.json';
import de from './configuration/lang/de.json';
import {get} from './services/persist';

const sagaMiddleware = createSagaMiddleware();
const history = createHistory();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middlewares)
    )
);

translate.registerTranslations('de', de);
translate.registerTranslations('en', en);
translate.setFallbackLocale('en');
translate.setLocale(get('lang') || window.navigator.language.substr(0, 2));

sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            {routes}
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
