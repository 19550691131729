export function getStatus(value) {
    switch (value) {
        case 'accepted':
        case 'rejected':
        case 'step1':
        case 'step1_finished':
        case 'step2':
        case 'provisioned':
            return 'disabled';
        case 'new':
        default: {
            return '';
        }
    }
}
