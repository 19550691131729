import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import SectionInput from '../../../conf_template/form/rows/valueTypes/SectionInput';
import {DEVICE_PRINTERS_PAGE} from '../../../../configuration/paths';
import TextArea from '../../../conf_template/form/rows/valueTypes/TextArea';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import translate from 'counterpart';

const EditTemplate = ({setConfiguration, device, deviceFile, saveDeviceFile, history, pullDeviceFiles}) => (
    <div className="details-content">
        <h3>{translate('edit_print_template', {scope: 'devices.printers'})}</h3>
        <SectionInput label="Name" setInput={setConfiguration} section={deviceFile} fieldName="name"/>
        <div className="clearfix" />
        <TextArea label="Content" setInput={setConfiguration} section={deviceFile} fieldName="contents"/>
        <div className="row">
            <div className="col-xs-12 text-right buttons-wrapper">
                <Link to={DEVICE_PRINTERS_PAGE(device.id)}>
                    <button className="btn btn-default">
                        {translate('cancel', {scope: 'modals'})}
                    </button>
                </Link>
                <button onClick={() => saveDeviceFile({
                    device, data: deviceFile, callback: () => {
                        pullDeviceFiles(device.id);
                        history.push(DEVICE_PRINTERS_PAGE(device.id));
                    },
                })} type="button" className="btn btn-info" data-backdrop="static" data-keyboard="false">
                    {translate('save_changes', {scope: 'devices.device_details'})}
                </button>
            </div>
        </div>
    </div>
);

EditTemplate.propTypes = {
    deviceEdit: PropTypes.bool,
    setConfiguration: PropTypes.func.isRequired,
    device: PropTypes.object.isRequired,
    deviceFile: PropTypes.object.isRequired,
    saveDeviceFile: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    pullDeviceFiles: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    setConfiguration: makeAction(actionTypes.SET_DEVICE_FILE),
    saveDeviceFile: makeAction(actionTypes.SAVE_DEVICE_FILE),
    pullDeviceFiles: makeAction(actionTypes.PULL_DEVICE_FILES),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTemplate));
