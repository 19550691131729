import PropTypes from 'prop-types';
import React from 'react';
import SectionInput from '../../../../conf_template/form/rows/valueTypes/SectionInput';
import {connect} from 'react-redux';

import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import Select from 'react-select';
import NetworkHeader from './NetworkHeader';
import NetworkFields from './NetworkFields';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {deviceNetworkLAN} from '../../../../../redux/selectors/device/network';
import translate from 'counterpart';


const DeviceNetworkConfig = ({lanEdit, editable, name, header, editMode, lan, options, setEthernet, tooltip, validation}) => (
    <li>
        <NetworkHeader name={name} header={header} status={lan ? lan.status : null}/>
        <div id={name} className="collapse" role="tabpanel" aria-labelledby="headingOne">
            {editMode && editable ?
                <div className="panel-body">
                    {lanEdit &&
                    <dl className="dl-casual dl-horizontal">
                        <dt>
                            {translate('type', {scope: 'devices.device_network'})}:
                            {lanEdit.type === 'STATIC' ?
                                <OverlayTrigger overlay={tooltip(translate('static', {scope: 'tooltips.device_details.network'}))}>
                                    <i className="error-icon fa fa-exclamation-circle"/>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger overlay={tooltip(translate('dhcp', {scope: 'tooltips.device_details.network'}))}>
                                    <i className="error-icon fa fa-exclamation-circle"/>
                                </OverlayTrigger>
                            }
                        </dt>
                        <dd className="overflowing">
                            {lanEdit.type === 'STATIC' ?
                                <OverlayTrigger overlay={tooltip(translate('static', {scope: 'tooltips.device_details'}))}>
                                    <Select
                                        value={lanEdit.type}
                                        options={options}
                                        onChange={(option) => setEthernet({...lanEdit, type: option.value})}
                                        searchable={false}
                                        clearable={false}
                                    />
                                </OverlayTrigger>
                                :
                                <OverlayTrigger overlay={tooltip(translate('dhcp', {scope: 'tooltips.device_details'}))}>
                                    <Select
                                        value={lanEdit.type}
                                        options={options}
                                        onChange={(option) => setEthernet({...lanEdit, type: option.value})}
                                        searchable={false}
                                        clearable={false}
                                    />
                                </OverlayTrigger>
                            }

                        </dd>
                        {lanEdit.type === 'STATIC' &&
                        <div>
                            <dt>
                                {translate('ip_address', {scope: 'devices.device_network'})}:
                                <OverlayTrigger overlay={tooltip(translate('ip_address', {scope: 'tooltips.device_details.network'}))}>
                                    <i className="error-icon fa fa-exclamation-circle"/>
                                </OverlayTrigger>
                            </dt>
                            <dd>
                                <span className="mode-edit">
                                    <SectionInput
                                        setInput={setEthernet}
                                        section={lanEdit}
                                        fieldName="address"
                                        error={!validation.lan_ip_valid}
                                    />
                                    {!validation.lan_ip_valid &&
                                        <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                            <i className="error-icon fa fa-exclamation-circle"/>
                                        </OverlayTrigger>
                                    }
                                </span>
                            </dd>
                            <dt>
                                {translate('subnet_mask', {scope: 'devices.device_network'})}:
                                <OverlayTrigger overlay={tooltip(translate('subnet_mask', {scope: 'tooltips.device_details.network'}))}>
                                    <i className="error-icon fa fa-exclamation-circle"/>
                                </OverlayTrigger>
                            </dt>
                            <dd>
                                <span className="mode-edit">
                                    <SectionInput
                                        setInput={setEthernet}
                                        section={lanEdit}
                                        fieldName="netmask"
                                        error={!validation.lan_mask_valid}
                                    />
                                    {!validation.lan_mask_valid &&
                                        <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                            <i className="error-icon fa fa-exclamation-circle"/>
                                        </OverlayTrigger>
                                    }
                                </span>
                            </dd>
                            <dt>
                                {translate('gateway', {scope: 'devices.device_network'})}:
                                <OverlayTrigger overlay={tooltip(translate('gateway', {scope: 'tooltips.device_details.network'}))}>
                                    <i className="error-icon fa fa-exclamation-circle"/>
                                </OverlayTrigger>
                            </dt>
                            <dd>
                                <span className="mode-edit">
                                    <SectionInput
                                        setInput={setEthernet}
                                        section={lanEdit}
                                        fieldName="gateway"
                                        error={!validation.lan_gateway_valid}
                                    />
                                    {!validation.lan_gateway_valid &&
                                        <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                            <i className="error-icon fa fa-exclamation-circle"/>
                                        </OverlayTrigger>
                                    }
                                </span>
                            </dd>
                            <dt>
                                {translate('dns_server', {scope: 'devices.device_network'})}:
                                <OverlayTrigger overlay={tooltip(translate('dns_server', {scope: 'tooltips.device_details.network'}))}>
                                    <i className="error-icon fa fa-exclamation-circle"/>
                                </OverlayTrigger>
                            </dt>
                            <dd>
                                <span className="mode-edit">
                                    <SectionInput
                                        setInput={setEthernet}
                                        section={lanEdit}
                                        fieldName="dns_nameservers"
                                        error={!validation.lan_dns_valid}
                                    />
                                    {!validation.lan_dns_valid &&
                                        <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                            <i className="error-icon fa fa-exclamation-circle"/>
                                        </OverlayTrigger>
                                    }
                                </span>
                            </dd>
                        </div>
                        }
                    </dl>
                    }
                </div>
                :
                <NetworkFields obj={lan} lan={true}/>
            }
        </div>
    </li>
);

DeviceNetworkConfig.propTypes = {
    editMode: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    lanEdit: PropTypes.object,
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    lan: PropTypes.object,
    setEthernet: PropTypes.func.isRequired,
    tooltip: PropTypes.func.isRequired,
    validation: PropTypes.object,
    options: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
    setEthernet: makeAction(actionTypes.SET_ETHERNET),
};

export default connect(
    deviceNetworkLAN,
    mapDispatchToProps
)(DeviceNetworkConfig);
