import translate from 'counterpart';

export const mapErrorGroups = () => [0, 1, 2, 3, 100, 101, 102, 103, 200, 201].map((i) => (
    {value: i, label: translate(String(i), {scope: 'logging.group'})})
);

export const mapErrorCodes = (group = 0) => {
    let codes;
    switch (group) {
        case 100:
            codes = [1, 2, 3, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 99];
            break;
        case 102:
            codes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 99];
            break;
        case 103:
            codes = [1, 2, 3, 4, 5, 12, 99];
            break;
        case 200:
            codes = [1, 2, 3, 4, 5, 99];
            break;
        case 2:
            codes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 99];
            break;
        case 3:
            codes = [1, 2, 3, 4, 99];
            break;
        case 1:
        case 101:
        case 201:
            codes = [99];
            break;
        default:
            codes = [];
    }
    return [
        {value: 0, label: translate('0', {scope: 'logging.group'})},
        ...codes.map((i) => (
            {value: i, label: translate(String(i), {scope: ['logging', 'code', String(group)]})})
        ),
    ];
};
