import {all, call, put, take, takeLatest} from 'redux-saga/effects';

import * as actionTypes from '../actionTypes';
import request, {DELETE, GET, POST, PUT} from '../../services/request';
import {get} from '../../services/persist';
import {API_URL} from '../../configuration/config';
import _ from 'lodash';
import {CONFIGS_LIST_PAGE} from '../../configuration/paths';
import {ERROR as get_error_notification, SUCCESS as get_success_notification} from '../../configuration/notifications';
import {handleError} from './errors';

function *fetch({payload}) {
    const page = payload.page || get('configs_page') || 0;
    if (!payload.limit) {
        payload.limit = get('configs_limit');
    }
    payload.template_level__gt = 0;
    payload.device__isnull = true;
    try {
        const response = yield request(GET, API_URL + 'device_configurations/', {params: payload});
        yield put({type: actionTypes.FETCH_CONFIGURATIONS_SUCCESS, payload: response.data});
    } catch (e) {
        console.error(e);
        yield handleError(e, true);
        yield put({type: actionTypes.FETCH_CONFIGURATIONS_FAILED, error: e});
        if (page > 1) {
            payload.page = 1;
            yield put({type: actionTypes.CONFIG_LIST_SET_PARAMS, payload: payload});
            yield put({type: actionTypes.FETCH_CONFIGURATIONS, payload: payload});
        }
    }
}

function *fetchConfigs() {
    yield takeLatest([actionTypes.FETCH_CONFIGURATIONS, actionTypes.CONFIG_LIST_CLEAR_PARAMS, actionTypes.CONFIG_LIST_SET_PARAMS], fetch);
}

function *fetchConfig() {
    while (true) {
        try {
            const {payload: {id}} = yield take(actionTypes.FETCH_CONFIGURATION);
            const response = yield request(GET, API_URL + `device_configurations/${id}/`);
            yield put({type: actionTypes.FETCH_CONFIGURATION_SUCCESS, payload: response.data});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_CONFIGURATION_FAILED, error: e});
        }
    }
}

function *fetchDefaultConfiguration() {
    try {
        const response = yield request(GET, API_URL + 'device_configurations/default/?template_level=0');
        yield put({type: actionTypes.FETCH_DEFAULT_CONFIGURATION_SUCCESS, payload: response.data});
    } catch (e) {
        console.error(e);
        yield put({type: actionTypes.FETCH_DEFAULT_CONFIGURATION_FAILED, error: e});
    }
}

export function *savePlugin(deviceConfigurationId, plugin) {
    if (!plugin.id) {
        return yield request(POST, API_URL + `device_configurations/${deviceConfigurationId}/plugins/`,
            {
                ...plugin,
                device_configuration: deviceConfigurationId,
            });
    }
    return yield request(PUT, `${API_URL}device_configurations/${deviceConfigurationId}/plugins/${plugin.id}/`,
        {
            ...plugin,
            device_configuration: deviceConfigurationId,
        });
}

export function *saveDeviceConfiguration(data) {
    const dataToSent = {
        ...data,
        ethernets: data.ethernets.map((ethernet) => (
            _.startsWith(ethernet.id, 'new') ? {..._.omit(ethernet, ['id', 'device_configuration'])} : ethernet
        )),
        plugins: data.plugins.map((plugin) => ({
            ...plugin,
            mappings: plugin.mappings.map((mapping) => (
                _.startsWith(mapping.id, 'new') ? {..._.omit(mapping, ['id', 'plugin'])} : mapping
            )),
        })).map((plugin) => (_.startsWith(plugin.id, 'new') ? {..._.omit(plugin, 'id')} : plugin)),
    };
    let response;
    if (dataToSent.id) {
        response = yield request(PUT, API_URL + 'device_configurations/' + dataToSent.id + '/', dataToSent);
    } else {
        response = yield request(POST, API_URL + 'device_configurations/', dataToSent);
    }

    for (const plugin of dataToSent.plugins) {
        yield savePlugin(response.data.id, plugin);
    }
    for (const pluginId of data.pluginsToDelete) {
        if (!_.startsWith(pluginId, 'new_')) {
            yield request(DELETE, `${API_URL}device_configurations/${response.data.id}/plugins/${pluginId}/`);
        }
    }

    return response;
}

function *updateDeviceConfiguration() {
    while (true) {
        try {
            const {payload: {data, callback}} = yield take(actionTypes.SAVE_DEVICE_CONFIGURATION);
            const path = CONFIGS_LIST_PAGE;
            const response = yield saveDeviceConfiguration(data);
            yield put({type: actionTypes.SEND_CONFIG_SUCCESS, payload: response.data});
            yield call(callback, path);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Config Updated'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.SEND_CONFIG_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Config update failed'),
            });
        }
    }
}

function *fetchConfigurationsToProvisione() {
    while (true) {
        try {
            yield take(actionTypes.FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE);
            const response = yield request(GET, API_URL + 'device_configurations/', {params: {template_level: 2, device__isnull: true, limit: 1000}});
            yield put({type: actionTypes.FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE_SUCCESS, payload: response.data});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_DEFAULT_CONFIGURATION_FAILED, error: e});
        }
    }
}

function *deleteConfig() {
    while (true) {
        try {
            const path = CONFIGS_LIST_PAGE;
            const {payload: {id, callback}} = yield take(actionTypes.DELETE_DEVICE_CONFIGURATION);
            const response = yield request(DELETE, API_URL + 'device_configurations/' + id + '/');
            yield put({type: actionTypes.DELETE_DEVICE_CONFIGURATION_SUCCESS, payload: response.data});
            yield call(callback, path);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Config ID: ${id} Deleted`),
            });
            yield put({
                type: actionTypes.FETCH_CONFIGURATIONS,
                payload: {},
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.SEND_CONFIG_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Config delete failed'),
            });
        }
    }
}

function *cloneConfig() {
    while (true) {
        try {
            const {payload: {id}} = yield take(actionTypes.CLONE_DEVICE_CONFIGURATION);
            const response = yield request(POST, API_URL + 'device_configurations/' + id + '/template/');
            yield put({type: actionTypes.CLONE_DEVICE_CONFIGURATION_SUCCESS, payload: response.data});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification(`Config ID: ${id} cloned.`),
            });
            yield put({
                type: actionTypes.FETCH_CONFIGURATIONS,
                payload: {},
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.CLONE_DEVICE_CONFIGURATION_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Config delete failed'),
            });
        }
    }
}

function *savePluginConfig() {
    while (true) {
        try {
            const {payload: {data, config}} = yield take(actionTypes.SAVE_PLUGINS_CONFIG);
            const plugins = data.map((plugin) => ({
                ...plugin,
                mappings: plugin.mappings.map((mapping) => ({
                    ..._.omit(mapping, ['id', 'plugin']),
                }
                )),
            })).map((plugin) => ({
                ..._.omit(plugin, 'id'),
            }));

            for (const plugin of config.plugins) {
                if (!_.startsWith(plugin.id, 'new_')) {
                    yield request(DELETE, `${API_URL}device_configurations/${config.id}/plugins/${plugin.id}/`);
                }
            }

            for (const plugin of plugins) {
                yield savePlugin(config.id, plugin);
            }
            yield request(POST, `${API_URL}devices/${config.device}/push_config/`);

            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Configuration was loaded successfully.'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Loading configuration failed.'),
            });
        }
    }
}


export default function *deviceConfigurationsSaga() {
    yield all([
        fetchConfigs(),
        fetchConfig(),
        fetchDefaultConfiguration(),
        updateDeviceConfiguration(),
        fetchConfigurationsToProvisione(),
        deleteConfig(),
        cloneConfig(),
        // setNetworkChange(),
        savePluginConfig(),
    ]);
}
