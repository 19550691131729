import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../../redux/actions/common/makeAction';
import PluginCollapse from '../../rows/pluginsCollapse';
import * as actionTypes from '../../../../../redux/actionTypes';
import SectionInput from '../../rows/valueTypes/SectionInput';
import SectionSwitch from '../../rows/valueTypes/SectionSwitch';
import SectionSelect from '../../rows/valueTypes/SectionSelect';
import PluginHeader from './PluginHeader';
import PluginMapping from './PluginMapping';
import {AGGREGATED_ITEMS_OPTIONS, CONSTRAINT_OPTIONS} from '../../../../../configuration/constants';
import CollapsingGroup from '../../../../devices/details/plugins/CollapsingGroup';

const ProtelPlugin = ({plugin, setPlugin}) => {
    const panelHeadingId = `pluginRowHeading${plugin.id}`;
    const collapseId = `pluginCollapse${plugin.id}`;
    return (
        <li className="panel-group" id={plugin.id} role="tablist" aria-multiselectable="true">
            <div className="panel panel-default">
                <PluginHeader label={`Protel ${plugin.order_no}`} panelHeadingId={panelHeadingId} collapseId={collapseId} plugin={plugin} />
                <div id={collapseId} className="panel-collapse collapse" role="tabpanel" aria-labelledby={panelHeadingId}>
                    <div className="panel-body">
                        <div className="m-b-15">
                            <CollapsingGroup id="mainInfo" name="Main plugin information">
                                <SectionInput label="Name" setInput={setPlugin} section={plugin} fieldName="name"/>
                                <SectionSwitch label="Active" setInput={setPlugin} section={plugin}
                                    fieldName="is_active"/>
                            </CollapsingGroup>
                            <CollapsingGroup id="pms_connection" name="PMS Connection">
                                <SectionInput label="Samba Server IP" setInput={setPlugin} section={plugin}
                                    fieldName="samba_server_address"/>
                                <SectionInput label="Samba server share name" setInput={setPlugin} section={plugin}
                                    fieldName="samba_server_share_name"/>
                                <SectionInput label="Path inhouse dat" setInput={setPlugin} section={plugin}
                                    fieldName="path_inhouse_dat"/>
                                <SectionInput label="Path roombook dat" setInput={setPlugin} section={plugin}
                                    fieldName="path_roombook_dat"/>
                                <SectionInput label="Path xreduct dat" setInput={setPlugin} section={plugin}
                                    fieldName="path_xreduct_dat"/>
                                <SectionSwitch label="Samba server is guest" setInput={setPlugin} section={plugin}
                                    fieldName="samba_server_is_guest"/>
                                <SectionInput label="Samba server username" setInput={setPlugin} section={plugin}
                                    fieldName="samba_server_username"/>
                                <SectionInput label="Samba server password" setInput={setPlugin} section={plugin}
                                    fieldName="samba_server_password"/>
                            </CollapsingGroup>
                            <CollapsingGroup id="features" name="Features">
                                <SectionSwitch label="Block amountless transactions" setInput={setPlugin} section={plugin} field="block_zero_payments"/>
                                <SectionSwitch label="Disable strip salutation" setInput={setPlugin} section={plugin}
                                    fieldName="strip_salutation" negate/>
                                <SectionSwitch label="Auto-grab outlet ID" setInput={setPlugin} section={plugin} fieldName="business_id_outlet"/>
                                <SectionSelect label="Constraints behavior" setInput={setPlugin} section={plugin} fieldName="constraints_behavior" options={CONSTRAINT_OPTIONS}/>
                                <SectionSelect label="Aggregated items behavior" setInput={setPlugin} section={plugin} fieldName="aggregated_items_behavior"
                                    options={AGGREGATED_ITEMS_OPTIONS}/>
                            </CollapsingGroup>
                            <CollapsingGroup id="others" name="Others">
                                <SectionInput label="Base currency" setInput={setPlugin} section={plugin}
                                    fieldName="base_currency"/>
                                <SectionInput label="Invoice offset" setInput={setPlugin} section={plugin}
                                    fieldName="invoice_offset" number={true}/>
                            </CollapsingGroup>
                        </div>
                        <PluginMapping plugin={plugin}/>
                        <PluginCollapse plugin={plugin}/>
                    </div>
                </div>
            </div>
        </li>
    );
};

ProtelPlugin.propTypes = {
    plugin: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    setPlugin: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    setPlugin: makeAction(actionTypes.SET_PLUGIN),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtelPlugin);
