import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import * as actionTypes from '../../../redux/actionTypes';
import makeAction from '../../../redux/actions/common/makeAction';
import {tools} from '../../../redux/selectors/device/tools';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';

import tooltip from '../../common/tooltip';
import WarningModal from '../../common/modals/WarningModal';
import PluginExtender from './PluginExtender';
import AceEditor from 'react-ace';

import 'brace/mode/xml';
import 'brace/theme/github';
import Select from 'react-select';

class DeviceTools extends React.Component {
    constructor(props) {
        super(props);
        this.state = {ip: '', address: '', port: '', hidden: true};
    }

    setIP(ip) {
        this.setState({ip: ip});
    }

    setAddress(address) {
        this.setState({address: address});
    }

    setPort(port) {
        this.setState({port: port});
    }

    render() {
        const doPing = (event) => {
            event.preventDefault();
            this.props.ping({
                id: this.props.match.params.deviceId,
                data: {address: this.state.ip},
            });
        };
        const doProbing = (event) => {
            event.preventDefault();
            this.props.probe({
                id: this.props.match.params.deviceId,
                data: {address: this.state.address, port: this.state.port},
            });
        };

        return (
            <div className="details-content">
                <div className="row">
                    <div className="col-xs-4">
                        <h3>
                            {translate('actions', {scope: 'devices'})}
                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('actions', {scope: 'tooltips.tools'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </h3>
                        <ul className="list-unstyled list-form">
                            <li className="input-group">
                                <input className="form-control" id="ipAddress" placeholder={translate('hostname_or_address', {scope: 'placeholders'})}
                                    onChange={(event) => this.setState({ip: event.target.value})}
                                    onKeyPress={(event) => {
                                        if (!this.props.inProgress && event.key === 'Enter') {
                                            doPing(event);
                                        }
                                    }}
                                    value={this.state.ip}
                                />
                                <span className="input-group-btn">
                                    {this.props.disabled ?
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('action_disabled', {scope: 'common'}))}>
                                            <span className="btn btn-primary const-width-sm" id="ping" disabled={true}>{translate('ping', {scope: 'devices.tools'})}</span>
                                        </OverlayTrigger>
                                        :
                                        <React.Fragment>
                                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('ping_ip', {scope: 'tooltips.tools'}))}>
                                                <button className="btn btn-primary const-width-sm" type="button" id="ping"
                                                    onClick={(event) => doPing(event)}
                                                    disabled={this.state.ip === '' || this.props.inProgress}>{
                                                        this.props.inProgress ?
                                                            <i className="fa fa-spinner fa-pulse"/>
                                                            :
                                                            <span>{translate('ping', {scope: 'devices.tools'})}</span>
                                                    }
                                                </button>
                                            </OverlayTrigger>
                                            <PluginExtender setIP={this.setIP.bind(this)} urls={this.props.urls}/>
                                        </React.Fragment>
                                    }
                                </span>
                            </li>

                            <li className="input-group">
                                <input className="form-control w-75" id="probeHost" placeholder={translate('hostname_or_address', {scope: 'placeholders'})}
                                    onChange={(event) => this.setState({address: event.target.value})}
                                    onKeyPress={(event) => {
                                        if (!this.props.disabled && !this.props.inProgress && event.key === 'Enter') {
                                            doProbing(event);
                                        }
                                    }}
                                    value={this.state.address}
                                />
                                <input className="form-control w-25" id="probePort" placeholder={translate('port', {scope: 'placeholders'})}
                                    onChange={(event) => this.setState({port: event.target.value})}
                                    onKeyPress={(event) => {
                                        if (!this.props.disabled && !this.props.inProgress && event.key === 'Enter') {
                                            doProbing(event);
                                        }
                                    }}
                                    value={this.state.port}
                                />
                                <span className="input-group-btn">
                                    {this.props.disabled ?
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('action_disabled', {scope: 'common'}))}>
                                            <span className="btn btn-primary const-width-sm" id="ping" disabled={true}>{translate('probe', {scope: 'devices.tools'})}</span>
                                        </OverlayTrigger>
                                        :
                                        <React.Fragment>
                                            <OverlayTrigger placement="top" overlay={tooltip(translate('ping_port', {scope: 'tooltips.tools'}))}>
                                                <button className="btn btn-primary const-width-sm" type="button" id="ping"
                                                    onClick={(event) => doProbing(event)}
                                                    disabled={this.state.address === '' || this.state.port === '' || this.props.inProgress}
                                                >{this.props.inProgress ?
                                                        <i className="fa fa-spinner fa-pulse"/>
                                                        :
                                                        <span>{translate('probe', {scope: 'devices.tools'})}</span>
                                                    }
                                                </button>
                                            </OverlayTrigger>
                                            <PluginExtender setAddress={this.setAddress.bind(this)} setPort={this.setPort.bind(this)} urls={this.props.urls}/>
                                        </React.Fragment>
                                    }
                                </span>
                            </li>
                            <PermissionCheck permission={permissions.DEVICE_PASSWORD}>
                                <li className="input-group">
                                    <span className="input-group-btn wrapper-125">
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('show_password', {scope: 'tooltips.tools'}))}>
                                            <span className="btn btn-default full-width" type="button"
                                                onClick={() => {
                                                    this.setState({hidden: false});
                                                    this.props.getPassword(this.props.match.params.deviceId);
                                                }}
                                                disabled={!this.state.hidden}
                                            >{translate('show_password', {scope: 'devices.tools'})}</span>
                                        </OverlayTrigger>
                                    </span>
                                    <input className="form-control" type="text" value={this.props.password} disabled/>
                                    <span className="input-group-btn">
                                        {this.props.disabled ?
                                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('action_disabled', {scope: 'common'}))}>
                                                <span className="btn btn-primary" disabled={true}>{translate('reset', {scope: 'devices.tools'})}</span>
                                            </OverlayTrigger>
                                            :
                                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('reset_password', {scope: 'tooltips.tools'}))}>
                                                <button className="btn btn-primary" type="button"
                                                    onClick={() => this.props.resetPassword(this.props.match.params.deviceId)}>{translate('reset', {scope: 'devices.tools'})}</button>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </li>
                            </PermissionCheck>
                            <PermissionCheck permission={permissions.DELETE_DEVICE}>
                                <li className="input-group">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        id="delete-data"
                                        disabled={this.props.deleteInProgress}
                                        data-toggle="modal"
                                        data-target="#deleteDataModal"
                                        data-keyboard="false"
                                    >
                                        {translate('delete-data', {scope: 'devices.tools'})}
                                    </button>
                                    <WarningModal id="deleteDataModal"
                                        header="Delete data"
                                        text="Do you want to delete device data?"
                                        onSubmit={() => {
                                            this.props.deleteData(this.props.match.params.deviceId);
                                        }}
                                    />
                                </li>
                            </PermissionCheck>
                            <PermissionCheck permission={permissions.SEND_XML}>
                                <li>
                                    <h3>Send XML to PMS: <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                    </h3>
                                    <form>
                                        <label>Choose plugin <i
                                            className="tooltip-trigger inline-block fa fa-info-circle"/></label>
                                        <Select
                                            placeholder="Plugin"
                                            name="form-field-name"
                                            value={this.props.selectedPlugin}
                                            options={this.props.plugins}
                                            searchable={false}
                                            clearable={false}
                                            onChange={(obj) => this.props.selectPlugin(obj)}
                                        />
                                        <AceEditor
                                            className="ace-editor"
                                            mode="xml"
                                            theme="github"
                                            name="xml-editor"
                                            value={this.props.xmlValue}
                                            onChange={this.props.changeXmlValue}
                                            editorProps={{$blockScrolling: true}}
                                        />
                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            id="send-to-pms"
                                            disabled={!this.props.selectedPlugin || !this.props.xmlValue || this.props.disabled}
                                            onClick={() => {
                                                this.props.sendXmlToPms({
                                                    device: this.props.match.params.deviceId,
                                                    plugin: this.props.selectedPlugin,
                                                    xml: this.props.xmlValue,
                                                });
                                            }}
                                        >
                                            Send to PMS
                                        </button>
                                    </form>
                                </li>
                            </PermissionCheck>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-lg-offset-2 col-xs-8">
                        <h3>
                            {translate('last_ping_responses', {scope: 'devices.tools'})}
                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('last_responses', {scope: 'tooltips.tools'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </h3>
                        <div className="table-wrapper content error-table">
                            <table className="table main-list table-hover">
                                <thead>
                                    <tr>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('timestamp', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('timestamp', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('address', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('address', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('response', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('response', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.pingTests.map((value, key) => (
                                        <tr key={key}>
                                            <td>{value.ts}</td>
                                            <td>{value.address}</td>
                                            <td>{value.result}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <h3 className="m-t-30">
                            {translate('last_probe_responses', {scope: 'devices.tools'})}
                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('last_responses', {scope: 'tooltips.tools'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </h3>
                        <div className="table-wrapper content error-table">
                            <table className="table main-list table-hover">
                                <thead>
                                    <tr>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('timestamp', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('timestamp', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('address', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('address', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('port', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('port', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={tooltip(translate('response', {scope: 'tooltips.tools'}))}>
                                            <th><span className="th-label">{translate('response', {scope: 'devices.tools'})}</span></th>
                                        </OverlayTrigger>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.probeTests.map((value, key) => (
                                        <tr key={key}>
                                            <td>{value.ts}</td>
                                            <td>{value.address}</td>
                                            <td>{value.port}</td>
                                            <td>{value.result}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DeviceTools.propTypes = {
    ping: PropTypes.func.isRequired,
    probe: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    fetched: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    deleteInProgress: PropTypes.bool,
    getPassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    pingTests: PropTypes.array,
    probeTests: PropTypes.array,
    inProgress: PropTypes.bool,
    deleteData: PropTypes.func.isRequired,
    urls: PropTypes.array.isRequired,
    plugins: PropTypes.array.isRequired,
    selectedPlugin: PropTypes.string,
    selectPlugin: PropTypes.func.isRequired,
    xmlValue: PropTypes.string.isRequired,
    changeXmlValue: PropTypes.func.isRequired,
    sendXmlToPms: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    ping: makeAction(actionTypes.PING_DEVICE),
    probe: makeAction(actionTypes.PROBE_DEVICE),
    getPassword: makeAction(actionTypes.FETCH_PASSWORD),
    resetPassword: makeAction(actionTypes.RESET_PASSWORD),
    deleteData: makeAction(actionTypes.DELETE_DEVICE_DATA),
    selectPlugin: makeAction(actionTypes.SELECT_SEND_PLUGIN),
    changeXmlValue: makeAction(actionTypes.CHANGE_XML_VALUE),
    sendXmlToPms: makeAction(actionTypes.SEND_XML_TO_PMS),
};

export default connect(
    tools,
    mapDispatchToProps
)(withRouter(DeviceTools));
