import {get} from '../../services/persist';
import {Redirect} from 'react-router';
import React from 'react';
import {LOGIN_PAGE} from '../paths';

function inLoggedIn(component) {
    if (get('token')) {
        return component;
    }
    return <Redirect to={LOGIN_PAGE}/>;
}

export default inLoggedIn;
