import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import PrinterForm from './printerForm';
import Loader from './loader';
import NotFound from './notFound';
import translate from 'counterpart';

const InstallPrinter = ({printer_in_progress, printer_fetched, printer, device_id, edit}) => (
    <div className="details-content">
        <h3>{edit ? translate('edit_printer', {scope: 'devices.printers'}) : translate('install_printer', {scope: 'devices.printers'})}</h3>
        {printer_in_progress ?
            <Loader/>
            :
            printer_fetched ?
                <PrinterForm edit={edit} printer={printer} device_id={device_id}/>
                :
                <NotFound/>
        }
    </div>
);

InstallPrinter.propTypes = {
    printer: PropTypes.object.isRequired,
    device_id: PropTypes.string.isRequired,
    printer_in_progress: PropTypes.bool.isRequired,
    printer_fetched: PropTypes.bool.isRequired,
    edit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    printer_in_progress: state.device.printer_in_progress,
    printer_fetched: state.device.printer_fetched,
    printer: state.device.installed_printer,
});

export default connect(mapStateToProps)(InstallPrinter);
