import {createSelector} from 'reselect';
import translate from 'counterpart';
import _ from 'lodash';

import {deviceStatus, invoices, invoicesColumns, invoicesCount, invoicesParams, syncInProgress} from './stateSelector';
import {formatAmount, formatDate} from '../../../services/formatters';
import {INVOICES_LIST_ADDABLE_COLUMNS} from '../../../configuration/constants';

export const deviceInvoices = createSelector(
    deviceStatus,
    invoices,
    invoicesCount,
    invoicesParams,
    syncInProgress,
    invoicesColumns,
    (status, entries, count, params, syncing, columns) => ({
        status,
        count,
        columns,
        entries: entries.map((entry) => ({
            ...entry,
            ts: formatDate(entry.datetime),
            startts: formatDate(entry.startdatetime),
            status_name: translate(entry.status || 'all', {scope: 'devices.invoices'}),
            tip: formatAmount(entry.tip),
            total: formatAmount(entry.total),
            discount: formatAmount(entry.discount),
        })),
        selectableColumns: INVOICES_LIST_ADDABLE_COLUMNS.map(
            (obj) => ({
                ...obj,
                label: translate(obj.name, {scope: 'devices.table_columns'}),
                selected: Boolean(_.find(columns, {name: obj.name})),
            }),
        ),
        params,
        syncing,
    })
);
