import PropTypes from 'prop-types';
import React from 'react';
import Header from './header';
import Footer from './footer';
import NotificationSystem from '../common/notifications';
import Modals from './modals/ModalsContainer';

const Wrapper = ({children}) => (
    <div>
        <Header />
        {children}
        <Modals />
        <NotificationSystem />
        <Footer/>
    </div>
);

Wrapper.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Wrapper;
