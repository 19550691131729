import PropTypes from 'prop-types';
import React from 'react';
import TableColumnTwo from './printers/tableColumnRemove';
import TableColumnOne from './printers/tableColumnInstall';
import PrintersNotFound from './printers/PrintersNotFound';
import {Link} from 'react-router-dom';
import {EDIT_PRINT_TEMPLATE_PAGE, PRE_INSTALL_PRINTER_PAGE} from '../../../configuration/paths';
import PrinterTemplates from './printer_templates/PrinterTemplates';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import translate from 'counterpart';


const DevicePrinters = ({printers_list, template_list, device_id}) => (
    <div className="details-content">
        {printers_list.length === 0 ?
            <PrintersNotFound/>
            :
            <div className="row">
                <div className="component-panel col-xs-6">
                    <h3>{translate('detected', {scope: 'devices.printers'})}</h3>
                    <TableColumnOne printers_list={printers_list} device_id={device_id}/>
                </div>
                <div className="component-panel col-xs-6">
                    <h3>{translate('installed', {scope: 'devices.printers'})}</h3>
                    <TableColumnTwo device_id={device_id} printers_list={printers_list}/>
                    <Link type="button" className="btn btn-md btn-info under-table" to={PRE_INSTALL_PRINTER_PAGE(device_id)}>
                        <span><i className="fa fa-plus"/><span>{translate('preinstall', {scope: 'devices.printers'})}</span></span>
                    </Link>
                </div>
            </div>
        }
        <h3>{translate('templates', {scope: 'devices.printers'})}</h3>
        <PrinterTemplates template_list={template_list} device_id={device_id}/>
        <Link type="button" className="btn btn-info under-table" to={EDIT_PRINT_TEMPLATE_PAGE(device_id, 'new')}>
            <span><i className="fa fa-plus"/><span>{translate('add_template', {scope: 'devices.printers'})}</span></span>
        </Link>
    </div>
);

DevicePrinters.propTypes = {
    printers_list: PropTypes.arrayOf(
        PropTypes.object
    ),
    device_id: PropTypes.number.isRequired,
    template_list: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
        })
    ),
};

const mapStateToProps = (state) => ({
    printers_list: state.device.printers_list,
    template_list: state.device.files,
    device_id: state.device.device.id,
});

export default connect(mapStateToProps)(withRouter(DevicePrinters));
