import PropTypes from 'prop-types';
import React from 'react';
import translate from 'counterpart';
import _ from 'lodash';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import tooltip from '../../../common/tooltip';

const InvoiceDetails = ({invoice}) => (
    <div className="modal-body">
        <h4 className="inline-block">{translate('info', {scope: 'common'})}</h4>
        <dl className="dl-horizontal dl-casual">
            <dt>
                {translate('status', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate(`status.${invoice.status}`, {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.status}</dd>
            <dt>
                {translate('status_message', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('status_message', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd className="overflowing">{invoice.status_message || '---'}</dd>
            <dt>
                {translate('table', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('table', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.table}</dd>
            <dt>
                {translate('waiter_id', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('waiter_id', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.waiterid}</dd>
            <dt>
                {translate('start_date_time', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('start_date_time', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.startts}</dd>
            <dt>
                {translate('date_time', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('date_time', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.ts}</dd>
            <dt>
                {translate('pos_name', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('pos_name', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.posname}</dd>
            <dt>
                {translate('business_id', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('business_id', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.outlet}</dd>
            <dt>
                {translate('room_id', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('room_id', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.roomid || '---'}</dd>
            <dt>
                {translate('reservation_id', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('reservation_id', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.resid || '---'}</dd>
            <dt>
                {translate('currency', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('currency', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.currency}</dd>
            <dt>
                {translate('tip', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('tip', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.tip}</dd>
            <dt>
                {translate('voided', {scope: 'devices.invoices'})}:
                <OverlayTrigger placement="right" overlay={tooltip(translate('voided', {scope: 'tooltips.invoices'}))}>
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </dt>
            <dd>{invoice.voided ? translate('yes', {scope: 'common'}) : translate('no', {scope: 'common'})}</dd>
        </dl>

        <h4 className="inline-block">{translate('invoice', {scope: 'devices.invoices'})}</h4>
        <div className="table-wrapper content">
            <table className="table">
                <thead>
                    <tr>
                        <th className="cell-xs">{translate('qty', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s text-right">{translate('id', {scope: 'devices.invoices'})}</th>
                        <th>{translate('article', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s">{translate('type', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s">{translate('plu', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s text-right">{translate('article_group_id', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s">{translate('article_group', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s text-right">{translate('article_super_group_id', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s">{translate('article_super_group', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s text-right">{translate('price', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s text-right">{translate('tax', {scope: 'devices.invoices'})}</th>
                        <th className="cell-s text-right">{translate('amount', {scope: 'devices.invoices'})}</th>
                    </tr>
                </thead>
                <tbody>
                    {_.isEmpty(invoice.items) ?
                        <tr>
                            <td colSpan="12" className="text-center">{translate('no_items', {scope: 'devices.invoices'})}</td>
                        </tr>
                        :
                        invoice.items.map((item, i) => (
                            <tr key={i}>
                                <td>{item.count}</td>
                                <td className="text-right">{item.ident}</td>
                                <td>{item.name}</td>
                                <td>{item.type}</td>
                                <td>{item.ident}</td>
                                <td className="text-right">{item.groupid}</td>
                                <td>{item.groupname}</td>
                                <td className="text-right">{item.supergroupid}</td>
                                <td>{item.supergroupname}</td>
                                <td className="text-right">{item.price}</td>
                                <td className="text-right">{item.vatrate}%</td>
                                <td className="text-right">{invoice.voided ? '-' + item.amount : item.amount}</td>
                            </tr>
                        ))
                    }
                    <tr className="row-summary">
                        <td colSpan="11" className="text-right">{translate('summary', {scope: 'devices.invoices'})}</td>
                        <td className="text-right">{invoice.voided ? '-' + invoice.total : invoice.total}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        {!_.isEmpty(invoice.payments) && (
            <h4 key="payment-head">{translate('payment', {scope: 'devices.invoices'})}</h4>,
            <div key="payment-table" className="table-wrapper content">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="cell-s text-right">{translate('id', {scope: 'devices.invoices'})}</th>
                            <th>{translate('name', {scope: 'devices.invoices'})}</th>
                            <th className="cell-s text-right">{translate('amount', {scope: 'devices.invoices'})}</th>
                            <th className="cell-s">{translate('currency', {scope: 'devices.invoices'})}</th>
                            <th className="cell-s text-right">{translate('ex_rate', {scope: 'devices.invoices'})}</th>
                            <th className="cell-s text-right">{translate('total', {scope: 'devices.invoices'})}</th>
                            <th className="cell-s text-right">{translate('tax', {scope: 'devices.invoices'})}</th>
                            <th className="cell-s text-right">{translate('tax_id', {scope: 'devices.invoices'})}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.payments.map((payment, i) => (
                            <tr key={i}>
                                <td className="text-right">{payment.ident}</td>
                                <td>{payment.name}</td>
                                <td className="text-right">{payment.amountbasecurrency}</td>
                                <td>{payment.currencycode}</td>
                                <td className="text-right">{payment.currencyexchangerate}</td>
                                <td className="text-right">{payment.totalamount}</td>
                                <td className="text-right">{payment.vat}%</td>
                                <td className="text-right">{payment.vatid}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )}
    </div>
);

InvoiceDetails.propTypes = {
    invoice: PropTypes.object.isRequired,
};

export default InvoiceDetails;
