import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import _ from 'lodash';
import WarningModal from '../../../common/modals/WarningModal';
import EditSubmitButton from './editSubmitButton';
import {DEVICE_DETAILS_PAGE} from '../../../../configuration/paths';
import translate from 'counterpart';

const PrinterFormFooter = ({edit, device_id, printer, removePrinter, installPrinterAction, selected_driver, history}) => {
    const callback = () => history.push(DEVICE_DETAILS_PAGE(device_id));
    return (
        <div className="row">
            <div className="col-xs-12 text-right buttons-wrapper">
                {edit ?
                    <EditSubmitButton device_id={device_id} printer={printer}/>
                    :
                    _.isEmpty(selected_driver) ?
                        <button type="button" disabled={true} className="pull-right btn btn-md btn-primary">
                            <span><i className="fa fa-plus"/><span>{translate('install', {scope: 'devices.printers'})}</span></span>
                        </button>
                        :
                        <button type="button" className="pull-right btn btn-md btn-primary"
                            onClick={() => installPrinterAction(
                                {
                                    id: device_id,
                                    printer: printer,
                                    driver: selected_driver.value,
                                    callback: callback,
                                }
                            )}>
                            <span><i className="fa fa-plus"/><span>{translate('install', {scope: 'devices.printers'})}</span></span>
                        </button>
                }
                {edit &&
                <span>
                    <button type="button" className="btn btn-md btn-default" data-toggle="modal" data-target={`#removeModal${printer.hash}`} data-backdrop="static" data-keyboard="false">
                        <span>{translate('delete', {scope: 'devices.printers'})}</span><i className="fa fa-remove"/>
                    </button>
                    <WarningModal id={`removeModal${printer.hash}`} onSubmit={() => {
                        removePrinter({id: device_id, printer: printer});
                    }} text={'Are you sure you want to REMOVE printer: ' + printer.name}/>
                </span>
                }
            </div>
        </div>
    );
};

PrinterFormFooter.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    edit: PropTypes.bool.isRequired,
    device_id: PropTypes.string.isRequired,
    printer: PropTypes.object.isRequired,
    removePrinter: PropTypes.func.isRequired,
    installPrinterAction: PropTypes.func.isRequired,
    selected_driver: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    selected_driver: state.device.selected_driver,
});

const mapDispatchToProps = {
    installPrinterAction: makeAction(actionTypes.INSTALL_PRINTER),
    removePrinter: makeAction(actionTypes.REMOVE_PRINTER),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PrinterFormFooter));
