import PropTypes from 'prop-types';
import React from 'react';

import disableWrapperAction from '../redux/actions/common/navigation/disableWrapper';
import {connect} from 'react-redux';
import translate from 'counterpart';

class NotFoundPage extends React.Component {
    componentDidMount() {
        this.props.disableWrapper();
    }

    render() {
        return (
            <div>
                <b>404 - {translate('not_found', {scope: 'modals'})}</b>
                <p>{translate('not_found_content', {scope: 'common'})}</p>
            </div>
        );
    }
}

NotFoundPage.propTypes = {
    disableWrapper: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    disableWrapper: disableWrapperAction,
};

export default connect(
    null,
    mapDispatchToProps
)(NotFoundPage);

