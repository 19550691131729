import * as actionTypes from '../actionTypes';

const DEFAULT_STATE = {
    list: [],
};

const softReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SOFT_SUCCESS: {
            return {
                ...state,
                list: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export default softReducer;
