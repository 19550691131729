import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import LogIn from '../components/authorization/LogIn';
import resetStateAction from '../redux/actions/authorization/resetState';
import disableWrapperAction from '../redux/actions/common/navigation/disableWrapper';

class LogInPage extends React.Component {
    componentDidMount() {
        this.props.disableWrapper();
        this.props.resetState();
    }

    render() {
        return (
            <LogIn/>
        );
    }
}

LogInPage.propTypes = {
    resetState: PropTypes.func.isRequired,
    disableWrapper: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    resetState: resetStateAction,
    disableWrapper: disableWrapperAction,
};

export default connect(null, mapDispatchToProps)(LogInPage);
