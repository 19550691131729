import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import translate from 'counterpart';

const WarningModal = ({id, onSubmit, header = 'Are you sure?', text}) => {
    const closeModal = (_id) => $(`#${_id}`).modal('hide');
    return (
        <div id={id} className="modal fade" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => closeModal(id)}>×</button>
                        <h3 className="modal-title">{header}</h3>
                    </div>
                    <div className="modal-body">
                        <p>{text}</p>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-xs-6">
                                <button className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onSubmit();
                                        closeModal(id);
                                    }}>
                                    <span>{translate('ok', {scope: 'modals'})}</span>
                                </button>
                            </div>
                            <div className="col-xs-6 text-right">
                                <button type="button" className="btn btn-default"
                                    onClick={() => closeModal(id)}>{translate('cancel', {scope: 'modals'})}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

WarningModal.propTypes = {
    id: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    text: PropTypes.string.isRequired,
    header: PropTypes.string,
};

export default WarningModal;
