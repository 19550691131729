import {put} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import {UNAUTHORIZED_PAGE} from '../../configuration/paths';
import {ERROR as get_error_notification} from '../../configuration/notifications';

export function *handleError(error, redirect) {
    if (error.response) {
        const {response} = error;
        switch (true) {
            case response.status === 403: {
                yield put({type: actionTypes.AUTH_ERROR, error});
                if (redirect) {
                    yield put(push(UNAUTHORIZED_PAGE));
                }
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_error_notification('Authorization error'),
                });
                break;
            }
            case _.inRange(response.status, 405, 500) || _.includes([400, 402, 404], response.status): {
                yield put({type: actionTypes.BAD_REQUEST, error});
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_error_notification('Bad request'),
                });
                break;
            }
            case response.status >= 500: {
                yield put({type: actionTypes.SERVER_ERROR, error});
                yield put({
                    type: actionTypes.ADD_NOTIFICATION,
                    notification: get_error_notification('Server Error'),
                });
                break;
            }
            default: {
                yield put({type: actionTypes.UNKNOWN_ERROR, error});
                break;
            }
        }
    } else {
        console.error(error);
        yield put({type: actionTypes.APP_ERROR, error});
        yield put({
            type: actionTypes.ADD_NOTIFICATION,
            notification: get_error_notification('Application error'),
        });
    }
}
