import {take, put, call, all} from 'redux-saga/effects';

import {API_URL} from '../../configuration/config';

import {get, remove, save} from '../../services/persist';
import request, {POST, GET} from '../../services/request';
import * as actionTypes from '../actionTypes';
import {handleError} from './errors';

function *checkAuthorization() {
    if (get('token')) {
        try {
            const response = yield request(GET, API_URL + 'check-auth/');
            yield put({type: actionTypes.LOG_IN_SUCCESS, payload: response});
        } catch (e) {
            remove('token');
            console.error(e);
            yield handleError(e);
        }
    }
}

function *logIn() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.LOG_IN);
            yield put({type: actionTypes.LOG_IN_RUNNING});
            const response = yield request(POST, API_URL + 'token-auth/', payload.data);
            save('token', response.data.token);
            const response2 = yield request(GET, API_URL + 'check-auth/', {});
            yield put({type: actionTypes.LOG_IN_SUCCESS, payload: response2});
            yield call(payload.callback);
        } catch (e) {
            console.error(e);
            yield put({type: actionTypes.LOG_IN_FAILED, error: e});
        }
    }
}

function *logOut() {
    while (true) {
        const {payload: {callback}} = yield take(actionTypes.LOG_OUT);
        remove('token');
        yield put({type: actionTypes.LOG_OUT_DONE});
        yield call(callback);
    }
}

export default function *auth() {
    yield all([
        checkAuthorization(),
        logIn(),
        logOut(),
    ]);
}
