import PropTypes from 'prop-types';
import React from 'react';

const TextArea = ({label, setInput, section, sectionName, fieldName, number = false}) => (
    <div className="form-group">
        <label className="control-label col-xs-2">{label}:</label>
        <div className="col-xs-10">
            <textarea
                rows="30"
                onChange={(event) => setInput(
                    sectionName ?
                        {
                            [sectionName]: {
                                ...section,
                                [fieldName]: number && event.target.value ? parseInt(event.target.value, 10) : event.target.value,
                            },
                        }
                        :
                        {
                            ...section,
                            [fieldName]: number && event.target.value ? parseInt(event.target.value, 10) : event.target.value,
                        }

                )}
                className="form-control" value={section[fieldName]}/>
        </div>
    </div>
);

TextArea.propTypes = {
    label: PropTypes.string,
    setInput: PropTypes.func,
    section: PropTypes.object,
    sectionName: PropTypes.string,
    fieldName: PropTypes.string,
    number: PropTypes.bool,
};

export default TextArea;

