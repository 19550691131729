import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Navigation from '../components/common';
import PropTypes from 'prop-types';
import React from 'react';
import {getNavigationWrapper} from '../redux/selectors/navigation/navigationWrapper';
import translate from 'counterpart';

class NavigationWrapper extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.lang !== prevProps.lang) {
            translate.setLocale(this.props.lang);
            this.props.history.push(window.location.pathname);
        }
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children,
            (child, key) => React.cloneElement(child, {key: key})
        );
        if (this.props.disabled) {
            return (
                <div>
                    {childrenWithProps}
                </div>
            );
        }
        return (
            <Navigation>
                {childrenWithProps}
            </Navigation>
        );
    }
}

NavigationWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    disabled: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(connect(
    getNavigationWrapper,
    null
)(NavigationWrapper));
