import PropTypes from 'prop-types';
import React from 'react';
import PluginMappings from '../../rows/pluginMappings';
import _ from 'lodash';

const PluginMapping = ({plugin}) => {
    const mappingsGrouped = _.toPairs(_.groupBy(plugin.mappings, 'mapping_group'));
    return mappingsGrouped.map((mappingPair, idx) => <PluginMappings key={`pluginMapping_${plugin.id}_${idx}`} plugin={plugin} pluginMappings={mappingPair[1]} mappingGroup={parseInt(mappingPair[0], 10)}/>);
};

PluginMapping.propTypes = {
    plugin: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
};

export default PluginMapping;
