import axios from 'axios';
import {get} from './persist';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';

const request = (method, url, data = {}) => {
    axios.defaults.headers.common = {
        'Accept': 'application/json',
        'Accept-Language': window.navigator.language,
        'Authorization': get('token') ? `Token ${get('token')}` : '',
    };
    axios.defaults.withCredentials = true;

    switch (method) {
        case GET:
        case DELETE:
            return axios({
                ...data,
                method: method,
                url: url,
            });
        case POST:
        case PUT:
        case PATCH:
            return axios({
                data: data,
                method: method,
                url: url,
            });
        default:
            throw new TypeError('Invalid request type provided');
    }
};

export default request;
