import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

const PluginsHeader = ({plugins, addPluginAction}) => {
    const options = plugins.map((plugin) => ({value: plugin.id, label: plugin.name}));
    const pluginsDict = plugins.reduce((sum, plugin) => ({...sum, [plugin.id]: plugin}), {});
    return (
        <div className="row m-b-15">
            <div className="col-xs-2">
                <h4>Plugins</h4>
            </div>
            <div className="col-xs-10">
                <DropdownButton id="add-plugins-dropdown" title={<span><i className="fa fa-plus"/>ADD</span>}>
                    {options.map((plugin, index) => (
                        <MenuItem id={index} key={index} onClick={() => addPluginAction(pluginsDict[plugin.value])}>{plugin.label}</MenuItem>
                    ))}
                </DropdownButton>
            </div>
        </div>
    );
};

PluginsHeader.propTypes = {
    plugins: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    addPluginAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    plugins: state.defaultDeviceConfiguration.plugins,
});

const mapDispatchToProps = {
    addPluginAction: makeAction(actionTypes.ADD_PLUGIN),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PluginsHeader);
