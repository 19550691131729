import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';
import CollapsingGroup from '../CollapsingGroup';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import _ from 'lodash';

import tooltip from '../../../../common/tooltip';
import CopyButton from '../../CopyButton';

const ProtelPlugin = ({plugin, endpoint_url}) => (
    <div>
        <CollapsingGroup id="mainInfo" name="Main Plugin Information">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('name', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.name || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('type', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('is_active', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('is_active', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    {plugin.is_active ? translate('active', {'scope': 'common'}) : translate('inactive', {'scope': 'common'})}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('endpoint_url', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('endpoint_url', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                    <CopyButton target={`endpoint_url_${plugin.id}`} />
                </Col>
                <Col xs={6} id={`endpoint_url_${plugin.id}`}>{endpoint_url || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="pmsConnection" name="PMS Connection">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_address', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_address', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.samba_server_address || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_share_name', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_share_name', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.samba_server_share_name || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('path_inhouse', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('path_inhouse', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.path_inhouse_dat || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('path_roombook', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('path_roombook', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.path_roombook_dat || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('path_xreduct', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('path_xreduct', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.path_xreduct_dat || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_is_guest', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_is_guest', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{ plugin.samba_server_is_guest ? translate(plugin.samba_server_is_guest.toString(), {scope: 'common'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_username', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_username', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.samba_server_username || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_password', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_password', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.samba_server_password || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_workgroup', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_workgroup', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{_.isString(plugin.samba_server_workgroup) ? plugin.samba_server_workgroup : '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="features" name="Features">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('block_zero_payments', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('block_zero_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.block_zero_payments ? translate(plugin.block_zero_payments.toString(), {scope: 'common'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('strip_salutation', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('strip_salutation', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.strip_salution ? translate((!plugin.strip_salutation).toString(), {scope: 'common'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('auto_grab_outlet_id', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('auto_grab_outlet_id', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.business_id_outlet ? translate(plugin.business_id_outlet.toString(), {scope: 'common'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.constraints_behavior'})}:
                    <OverlayTrigger overlay={
                        tooltip(translate('constraints_behavior', {scope: 'tooltips.hotel_systems.plugin'}) +
                            '\n' + translate('all', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('non_zero', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_non_zero_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('items', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_sub_items_only', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('main', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_constraint_main_item', {scope: 'tooltips.hotel_systems.items_behaviour'})
                        )}
                    >
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.constraints_behavior ? translate(plugin.constraints_behavior, {scope: 'devices.plugins.constraints_behavior'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.aggregated_items_behavior'})}:
                    <OverlayTrigger overlay={tooltip(translate('aggregated_items_behavior', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.aggregated_items_behavior ? translate(plugin.aggregated_items_behavior, {scope: 'devices.plugins.aggregated_items_behavior'}) : '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="others" name="Others">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('base_currency', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('base_currency', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.base_currency || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('invoice_offset', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('invoice_offset', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.invoice_offset || '---'}</Col>
            </Row>
        </CollapsingGroup>
    </div>
);

ProtelPlugin.propTypes = {
    plugin: PropTypes.object.isRequired,
    endpoint_url: PropTypes.string.isRequired,
};

export default ProtelPlugin;
