import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import * as paths from '../../../configuration/paths';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import tooltip from '../../common/tooltip';

const DetailsLinks = ({device}) => (
    <ul className="details-nav list-inline list-unstyled row">
        <OverlayTrigger placement="bottom" overlay={tooltip(translate('explanation', {scope: 'tooltips.device_details'}))}>
            <li key="details" id="details"
                className={window.location.pathname === paths.DEVICE_DETAILS_PAGE(device.id) ? 'active' : ''}
                role="presentation">
                <Link to={paths.DEVICE_DETAILS_PAGE(device.id)}>
                    {translate('device_info', {scope: 'devices.device_menu'})}
                </Link>
            </li>
        </OverlayTrigger>
        <PermissionCheck permission={permissions.LIST_PLUGIN}>
            <OverlayTrigger placement="bottom" overlay={tooltip(translate('explanation', {scope: 'tooltips.hotel_systems'}))}>
                <li className={window.location.pathname === paths.DEVICE_CONFIG_PAGE(device.id) ? 'active' : ''}
                    key="configuration" id="configuration" role="presentation">
                    <Link to={paths.DEVICE_CONFIG_PAGE(device.id)}>
                        {translate('hotel_systems', {scope: 'devices.device_menu'})}
                    </Link>
                </li>
            </OverlayTrigger>
        </PermissionCheck>
        <PermissionCheck permission={permissions.LIST_PRINTERS}>
            <li className={window.location.pathname === paths.DEVICE_PRINTERS_PAGE(device.id) ? 'active' : ''}
                key="printers" id="printers" role="presentation">
                <Link to={paths.DEVICE_PRINTERS_PAGE(device.id)}>
                    {translate('printers', {scope: 'devices.device_menu'})}
                </Link>
            </li>
        </PermissionCheck>
        <PermissionCheck permission={permissions.LIST_INVOICE}>
            <OverlayTrigger placement="bottom" overlay={tooltip(translate('title', {scope: 'tooltips.invoices'}))}>
                <li className={window.location.pathname === paths.DEVICE_INVOICES_PAGE(device.id) ? 'active' : ''}
                    key="invoices" id="invoices" role="presentation">
                    <Link to={paths.DEVICE_INVOICES_PAGE(device.id)}>
                        {translate('invoices', {scope: 'devices.device_menu'})}
                    </Link>
                </li>
            </OverlayTrigger>
        </PermissionCheck>
        <OverlayTrigger placement="bottom" overlay={tooltip(translate('explanation', {scope: 'tooltips.log'}))}>
            <li className={window.location.pathname === paths.DEVICE_LOG_PAGE(device.id) ? 'active' : ''}
                key="log" id="log" role="presentation">
                <Link to={paths.DEVICE_LOG_PAGE(device.id)}>
                    {translate('log', {scope: 'devices.device_menu'})}
                </Link>
            </li>
        </OverlayTrigger>
        <PermissionCheck permission={permissions.SHOW_TOOLS}>
            <OverlayTrigger placement="bottom" overlay={tooltip(translate('explanation', {scope: 'tooltips.tools'}))}>
                <li className={window.location.pathname === paths.DEVICE_TOOLS_PAGE(device.id) ? 'active' : ''}
                    key="tools" id="tools" role="presentation">
                    <Link to={paths.DEVICE_TOOLS_PAGE(device.id)}>
                        {translate('tools', {scope: 'devices.device_menu'})}
                    </Link>
                </li>
            </OverlayTrigger>
        </PermissionCheck>
    </ul>
);

DetailsLinks.propTypes = {
    device: PropTypes.object,
};

export default DetailsLinks;
