import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../redux/actions/common/makeAction';
import {RETRY_PROVISIONING} from '../../../../redux/actionTypes';
import {DEVICE_STATUS} from '../../../../configuration/constants';
import translate from 'counterpart';

const RetryButton = ({retryProvisioningAction, device}) => (
    device.status === DEVICE_STATUS.PROVISIONING_FAILED &&
        <button type="button" className="soft_install_button btn btn-default" onClick={() => retryProvisioningAction({status: DEVICE_STATUS.STEP2, id: device.id})}>
            <span>{translate('retry_provisioning', {scope: 'common'})}</span><i className="fa fa-refresh"/>
        </button>
);

RetryButton.propTypes = {
    retryProvisioningAction: PropTypes.func.isRequired,
    device: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    retryProvisioningAction: makeAction(RETRY_PROVISIONING),
};

export default connect(null, mapDispatchToProps)(RetryButton);
