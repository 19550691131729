import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import {withRouter} from 'react-router';

import ActionsBar from './actionsBar';
import DeviceGeneral from './DeviceGeneral';
import DeviceNetwork from './network/DeviceNetwork';
import LastErrors from './LastErrors';

class DeviceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.refreshInterval = null;
    }

    setRefreshDeviceInterval(id) {
        this.refreshInterval = setInterval(() => {
            this.props.pullDevice({id: id});
        }, 10000);
    }

    componentDidMount() {
        this.props.pullDevice({id: this.props.match.params.deviceId});
        this.setRefreshDeviceInterval(this.props.match.params.deviceId);
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    render() {
        return (
            <div className={`details-content ${this.props.editMode ? 'edit-mode' : 'read-mode'}`}>
                <ActionsBar/>
                <div className="row read-edit-panel">
                    <DeviceGeneral/>
                    <DeviceNetwork/>
                    <LastErrors id={this.props.match.params.deviceId}/>
                </div>
            </div>
        );
    }
}

DeviceDetails.propTypes = {
    editMode: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    pullDevice: PropTypes.func,
};

const mapStateToProps = (state) => ({
    editMode: state.navigation.editMode,
});

const mapDispatchToProps = {
    pullDevice: makeAction(actionTypes.PULL_DEVICE),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceDetails));
