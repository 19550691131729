import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import translate from 'counterpart';

import {IMAGES_CLEAR_PARAMS, IMAGES_SET_PARAMS} from '../../redux/actionTypes';
import Pagination from '../common/react-pagination';
import makeAction from '../../redux/actions/common/makeAction';

const ImgListHeader = ({clearParams, count, params, setParams}) => {
    const from = (params.page - 1) * params.limit + 1;
    const to = Math.min(params.page * params.limit, count);
    return (<div className="table-header row">
        <div className="col-xs-4">
            <h2>
                {translate('table_header', {scope: 'system_images'})}
                <span className="extra-info">{translate('table_count', {scope: 'devices', from, to, count: count})}</span>
            </h2>
        </div>
        <div className="col-xs-4">
            <Pagination changePage={setParams} limit={params.limit} page={params.page} count={count}/>
        </div>
        <div className="col-xs-4 text-right options">
            <div className="table-panel">
                <button className="btn btn-info" onClick={() => clearParams({})}>Clear filters</button>
            </div>
        </div>
    </div>);
};

ImgListHeader.propTypes = {
    clearParams: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    count: state.sysImageReducer.count,
    params: state.sysImageReducer.params,
});

const mapDispatchToProps = {
    clearParams: makeAction(IMAGES_CLEAR_PARAMS),
    setParams: makeAction(IMAGES_SET_PARAMS),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImgListHeader);
