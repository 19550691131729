import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import translate from 'counterpart';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import SectionInput from '../../../../conf_template/form/rows/valueTypes/SectionInput';
import SectionSwitch from '../../../../conf_template/form/rows/valueTypes/SectionSwitch';
import {AGGREGATED_ITEMS_OPTIONS, CONSTRAINT_OPTIONS} from '../../../../../configuration/constants';
import SectionSelect from '../../../../conf_template/form/rows/valueTypes/SectionSelect';
import makeAction from '../../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../../redux/actionTypes';
import tooltip from '../../../../common/tooltip';
import CopyButton from '../../CopyButton';
import CollapsingGroup from '../CollapsingGroup';

const ProtelPlugin = ({plugin, endpoint_url, setPlugin}) => (
    <div>
        <CollapsingGroup id="mainInfo" name="Main Plugin Information">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('name', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="name"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('type', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('is_active', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('is_active', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="is_active"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('endpoint_url', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('endpoint_url', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                    <CopyButton target={`endpoint_url_${plugin.id}`} />
                </Col>
                <Col xs={6} id={`endpoint_url_${plugin.id}`}>{endpoint_url || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="pmsConnection" name="PMS Connection">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_address', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_address', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="samba_server_address"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_share_name', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_share_name', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="samba_server_share_name"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('path_inhouse', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('path_inhouse', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="path_inhouse_dat"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('path_roombook', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('path_roombook', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="path_roombook_dat"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('path_xreduct', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('path_xreduct', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="path_xreduct_dat"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_is_guest', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_is_guest', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="samba_server_is_guest"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_username', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_username', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="samba_server_username"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_password', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('samba_server_password', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="samba_server_password"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('samba_server_workgroup', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('samba_server_workgroup', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={2}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="samba_server_workgroup_switch"
                        innerClass="workgroup-switch"/>
                </Col>
                <Col xs={4}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="samba_server_workgroup" readOnly={
                        !plugin.samba_server_workgroup_switch
                    }/>
                </Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="features" name="Features">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('block_zero_payments', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('block_zero_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="block_zero_payments" negate/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('strip_salutation', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('strip_salutation', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="strip_salutation" negate/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('auto_grab_outlet_id', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('auto_grab_outlet_id', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="business_id_outlet"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.constraints_behavior'})}:
                    <OverlayTrigger overlay={
                        tooltip(translate('constraints_behavior', {scope: 'tooltips.hotel_systems.plugin'}) +
                            '\n' + translate('all', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('non_zero', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_non_zero_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('items', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_sub_items_only', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('main', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_constraint_main_item', {scope: 'tooltips.hotel_systems.items_behaviour'})
                        )}
                    >
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSelect setInput={setPlugin} section={plugin} fieldName="constraints_behavior" options={CONSTRAINT_OPTIONS}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.aggregated_items_behavior'})}:
                    <OverlayTrigger overlay={tooltip(translate('aggregated_items_behavior', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSelect setInput={setPlugin} section={plugin} fieldName="aggregated_items_behavior"
                        options={AGGREGATED_ITEMS_OPTIONS}/>
                </Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="others" name="Others">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('base_currency', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('base_currency', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="base_currency"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('invoice_offset', {scope: 'devices.plugins.protel'})}:
                    <OverlayTrigger overlay={tooltip(translate('invoice_offset', {scope: 'tooltips.hotel_systems.protel_hypersoft'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="invoice_offset" number={true}/>
                </Col>
            </Row>
        </CollapsingGroup>
    </div>
);

ProtelPlugin.propTypes = {
    plugin: PropTypes.object.isRequired,
    endpoint_url: PropTypes.string,
    setPlugin: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setPlugin: makeAction(actionTypes.SET_PLUGIN),
};

export default connect(null, mapDispatchToProps)(ProtelPlugin);
