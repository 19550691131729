import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {Link} from 'react-router-dom';
import translate from 'counterpart';

import WarningModal from '../../../common/modals/WarningModal';
import {EDIT_PRINT_TEMPLATE_PAGE} from '../../../../configuration/paths';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import tooltip from '../../../common/tooltip';

const TemplateButtons = ({template, device_id, deleteTemplate, pullTemplates}) => (
    <div className="dropdown">
        <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
            <span className="fa fa-cog"/>
        </button>
        <ul className="dropdown-menu">
            <li>
                <OverlayTrigger overlay={tooltip(translate('edit_print_template', {scope: 'devices.device_details'}))}>
                    <Link to={EDIT_PRINT_TEMPLATE_PAGE(device_id, template.id)}>
                        <span>
                            <i className={'fa fa-plus actions_dropdown_i'}/>{translate('edit', {scope: 'devices.device_details'})}
                        </span>
                    </Link>
                </OverlayTrigger>
            </li>

            <li data-toggle="modal" data-target={`#printTemplateModal${template.id}`} data-backdrop="static" >
                <OverlayTrigger overlay={tooltip(translate('delete_print_template', {scope: 'devices.printers'}))}>
                    <a>
                        <span>
                            <i className={'fa fa-minus actions_dropdown_i'}/>  {translate('delete', {scope: 'devices.printers'})}
                        </span>
                    </a>
                </OverlayTrigger>
            </li>
        </ul>
        <WarningModal id={`printTemplateModal${template.id}`} text={translate('remove_template', {scope: 'modals'})}
            onSubmit={() => deleteTemplate({device: device_id, id: template.id, callback: () => pullTemplates()})}/>
    </div>
);

TemplateButtons.propTypes = {
    device_id: PropTypes.number.isRequired,
    template: PropTypes.object.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    pullTemplates: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    deleteTemplate: makeAction(actionTypes.DELETE_DEVICE_FILE),
    pullTemplates: makeAction(actionTypes.PULL_DEVICE_FILES),
};

export default connect(
    null,
    mapDispatchToProps
)(TemplateButtons);
