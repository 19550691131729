import * as actionTypes from '../../actionTypes';

const DEFAULT_STATE = {
    label: 'Loading...',
    icon: 'fa fa-spinner fa-pulse fa-3x fa-fw',
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return DEFAULT_STATE;
        }
        case actionTypes.PULL_PRINTERS_DRIVERS_IN_PROGRESS: {
            return {
                ...state,
                icon: 'fa fa-spinner fa-pulse fa-3x fa-fw',
                label: 'Fetching drivers...',
            };
        }
        case actionTypes.PULL_PRINTERS_DRIVERS_FAILED: {
            return {
                ...state,
                icon: 'fa fa-exclamation-triangle fa-4x',
                label: 'Fetching drivers failed',
            };
        }
        case actionTypes.DEVICE_FETCH_FAILED: {
            return {
                ...state,
                icon: 'fa fa-exclamation-triangle fa-4x',
                label: 'Fetching output failed',
            };
        }
        case actionTypes.DEVICES_FETCH_FAILED: {
            return {
                ...state,
                icon: 'fa fa-exclamation-triangle fa-4x',
                label: 'Fetching devices failed',
            };
        }
        case actionTypes.FETCH_CONFIGS_FAILED: {
            return {
                ...state,
                icon: 'fa fa-exclamation-triangle fa-4x',
                label: 'Fetching configurations failed',
            };
        }
        case actionTypes.FETCH_IMAGES_FAILED: {
            return {
                ...state,
                icon: 'fa fa-exclamation-triangle fa-4x',
                label: 'Fetching images failed',
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
