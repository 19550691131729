import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Select from 'react-select';
import {connect} from 'react-redux';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import {DASHBOARD_PAGE} from '../../../../configuration/paths';
import {withRouter} from 'react-router';
import translate from 'counterpart';

import AcceptButton from '../header_buttons/acceptButton';
import RetryButton from '../header_buttons/retryButton';
import WarningModal from '../../../common/modals/WarningModal';
import LoadConfigModal from '../../../common/modals/LoadConfigModal';
import SelectConfigModal from '../../../common/modals/SelectConfigModal';
import PermissionCheck from '../../../authorization/PermissionCheck';
import {actionsBar} from '../../../../redux/selectors/device/actions';
import * as permissions from '../../../../configuration/permissions';

import tooltip from '../../../common/tooltip';
import TimedUpgradeModal from '../../../common/modals/TimedUpgradeModal';

const DeviceActionBar = ({
    device, listSoft, apply, options, editMode, editable, error, config, selectedAction, softValue, configFromDevice,
    selectMultiSoft, fetchConfiguration, selectSoft, installSoft, removeDevice, rebootDevice, changeEditMode, handleSave, setNetwork,
    history, companies, company, selectCompany, manageCompany,
}) => (
    <div className="row">
        <div className="col-xs-7">
            {options.length > 0 &&
            <form className="form-horizontal table-panel">
                <label className="buttons-title" htmlFor="email">
                    {translate('actions', {scope: 'devices'})}
                    <OverlayTrigger overlay={
                        tooltip(translate('reboot', {scope: 'devices'}) + ' - ' + translate('reboot', {scope: 'tooltips.action'}) +
                        '\n' + translate('remove', {scope: 'devices'}) + ' - ' + translate('remove', {scope: 'tooltips.action'}) +
                        '\n' + translate('upgrade', {scope: 'devices'}) + ' - ' + translate('upgrade', {scope: 'tooltips.action'}) +
                        '\n' + translate('timed_upgrade', {scope: 'devices'}) + ' - ' + translate('timed_upgrade', {scope: 'tooltips.action'}) +
                        '\n' + translate('select_version', {scope: 'placeholders'}) + ' - ' + translate('select_version', {scope: 'tooltips.action'})
                        )}
                    >
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </label>
                <Select
                    placeholder={translate('select_action', {scope: 'placeholders'})}
                    className="soft_select"
                    name="form-field-name"
                    value={selectedAction}
                    options={options}
                    searchable={false}
                    clearable={false}
                    onChange={(obj) => selectMultiSoft(obj)}
                />
                {selectedAction === 'manage_company' ?
                    <Select
                        className="soft_select"
                        name="form-field-name"
                        value={company}
                        options={companies}
                        searchable={false}
                        onChange={(obj) => selectCompany(obj)}
                    /> :
                    <PermissionCheck permission={permissions.UPGRADE_DEVICE}>
                        <Select
                            className="soft_select"
                            name="form-field-name"
                            value={softValue}
                            placeholder={translate('select_version', {scope: 'placeholders'})}
                            options={listSoft}
                            searchable={false} onChange={(obj) => selectSoft({...obj, id: device.id})}
                            disabled={!editable || selectedAction !== 'upgrade' && selectedAction !== 'timed_upgrade'}
                        />
                    </PermissionCheck>
                }
                <OverlayTrigger placement="top"
                    overlay={tooltip((apply.disabled ? translate('apply_disabled', {scope: 'tooltips.action'}) : translate('apply', {scope: 'tooltips.action'})))}>
                    <span
                        className="btn btn-default"
                        type={apply.type}
                        id="apply"
                        data-toggle={apply.dataToggle}
                        data-target={apply.dataTarget}
                        data-backdrop={apply.dataBackdrop}
                        data-keyboard={apply.dataKeyboard}
                        disabled={apply.disabled}>
                        <span>{translate('apply', {scope: 'devices'})}</span>
                        <i className="fa fa-angle-right"/>
                    </span>
                </OverlayTrigger>
            </form>
            }
            <WarningModal
                id="rebootModal"
                text={translate('reboot_device', {scope: 'modals'}) + device.id + '?'}
                onSubmit={() => rebootDevice({id: device.id})}/>
            <WarningModal
                id="removeModal"
                text={translate('remove_device', {scope: 'modals'}) + device.id + '?'}
                onSubmit={() => {
                    removeDevice({id: device.id});
                    history.push(DASHBOARD_PAGE);
                }}/>
            <WarningModal
                id="installModal"
                text={translate('upgrade_device', {scope: 'modals', device_id: device.id})}
                onSubmit={() => installSoft({
                    id: device.id,
                    soft_version: device.soft_update,
                })}/>
            <WarningModal id="companyModal"
                header={translate('manage_company_header', {scope: 'modals'})}
                text={
                    (company.value ? translate(
                        'manage_company_detail_body_assign', {scope: 'modals'}) + company.label + '?' : translate(
                        'manage_company_detail_body_unassign', {scope: 'modals'}))
                }
                onSubmit={() => manageCompany({
                    devices: [device.id],
                    company: company,
                })}/>
            <TimedUpgradeModal
                id="timedUpgradeModal"
                devices={[device.id]}
                soft_version={device.soft_update}
            />
            <LoadConfigModal/>
            <WarningModal
                id="networkWarning"
                onSubmit={() => {
                    handleSave({
                        data: config,
                        callback: false,
                        device: {
                            ...device,
                            network_change_time: config.network_change_time,
                        },
                    });
                    changeEditMode(false);
                }}
                text={translate('network_changed', {scope: 'modals'})}
            />
        </div>
        <div className="col-xs-5 text-right">
            {
                editMode ?
                    <div className="table-panel">
                        <OverlayTrigger placement="bottom"
                            overlay={tooltip(translate('cancel', {scope: 'tooltips.device_details'}))}>
                            <button
                                id="cancel"
                                className="btn btn-default shuffle-modes"
                                onClick={(event) => {
                                    event.preventDefault();
                                    changeEditMode(false);
                                    setNetwork();
                                }}
                            >{translate('cancel', {scope: 'devices.device_details'})}
                            </button>
                        </OverlayTrigger>
                        {config.networkSettingsChanged ?
                            <OverlayTrigger placement="bottom"
                                overlay={tooltip(translate('save', {scope: 'tooltips.device_details'}))}>
                                <button
                                    id="saveChanges"
                                    type="button"
                                    className="btn btn-info shuffle-modes"
                                    data-toggle="modal"
                                    data-target="#networkWarning"
                                    data-backdrop="static"
                                    data-keyboard="false">
                                    {translate('save_changes', {scope: 'devices.device_details'})}
                                </button>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger placement="bottom"
                                overlay={tooltip(translate('save', {scope: 'tooltips.device_details'}))}>
                                <button
                                    id="saveChanges"
                                    className="btn btn-info shuffle-modes"
                                    onClick={(event) => {
                                        if (!error) {
                                            event.preventDefault();
                                            handleSave({
                                                data: config,
                                                callback: false,
                                                device: {
                                                    ...device,
                                                    network_change_time: config.network_change_time,
                                                },
                                            });
                                            changeEditMode(false);
                                        }
                                    }}
                                >{translate('save_changes', {scope: 'devices.device_details'})}
                                </button>
                            </OverlayTrigger>
                        }
                    </div>
                    :
                    <div className="table-panel">
                        <PermissionCheck permission={permissions.CHANGE_DEVICE}>
                            <OverlayTrigger placement="bottom"
                                overlay={tooltip(translate('load_config', {scope: 'tooltips.device_details'}))}>
                                <span disabled className="btn btn-info disabled"
                                    // onClick={() => $('#loadConfigModal').modal('toggle')}
                                >{translate('load_config', {scope: 'devices.device_details'})}</span>
                            </OverlayTrigger>
                            <AcceptButton device={device}/>
                            <RetryButton device={device}/>
                            {editable ?
                                <OverlayTrigger placement="bottom"
                                    overlay={tooltip(translate('edit_tab', {scope: 'tooltips.device_details'}))}>
                                    <button
                                        id="edit"
                                        className="btn btn-info shuffle-modes"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            changeEditMode(true);
                                            fetchConfiguration({
                                                config: configFromDevice,
                                                relevant: device.relevant,
                                                client_key: device.client_key,
                                                system_key: device.system_key,
                                            });
                                        }}
                                    >
                                        {translate('edit_tab', {scope: 'devices.device_details'})}
                                    </button>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger placement="bottom"
                                    overlay={tooltip(translate('disabled_explanation', {scope: 'devices.device_details'}))}>
                                    <button
                                        id="edit"
                                        className="btn btn-info shuffle-modes"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            changeEditMode(true);
                                            fetchConfiguration({
                                                config: configFromDevice,
                                                relevant: device.relevant,
                                                client_key: device.client_key,
                                                system_key: device.system_key,
                                            });
                                        }}
                                    >
                                        {translate('edit_tab', {scope: 'devices.device_details'})}
                                    </button>
                                </OverlayTrigger>
                            }
                        </PermissionCheck>
                    </div>
            }
            <SelectConfigModal/>
        </div>
    </div>
);

DeviceActionBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,

    device: PropTypes.object.isRequired,
    listSoft: PropTypes.array.isRequired,
    apply: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    editMode: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    config: PropTypes.object,
    configFromDevice: PropTypes.object,
    selectedAction: PropTypes.string,
    softValue: PropTypes.string,
    companies: PropTypes.array,
    company: PropTypes.object,
    selectCompany: PropTypes.func,
    manageCompany: PropTypes.func.isRequired,

    selectMultiSoft: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    changeEditMode: PropTypes.func.isRequired,
    fetchConfiguration: PropTypes.func.isRequired,
    selectSoft: PropTypes.func.isRequired,
    installSoft: PropTypes.func.isRequired,
    rebootDevice: PropTypes.func.isRequired,
    removeDevice: PropTypes.func.isRequired,
    setNetwork: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    selectMultiSoft: makeAction(actionTypes.SELECT_MULTI_SOFT),
    changeEditMode: makeAction(actionTypes.CHANGE_EDIT_MODE),
    fetchConfiguration: makeAction(actionTypes.FETCH_DEVICE_CONFIGURATION),
    handleSave: makeAction(actionTypes.UPDATE_DEVICE),
    selectSoft: makeAction(actionTypes.SELECT_SOFT_VERSION),
    installSoft: makeAction(actionTypes.INSTALL_SOFT_VERSION),
    rebootDevice: makeAction(actionTypes.REBOOT_DEVICE),
    removeDevice: makeAction(actionTypes.REMOVE_DEVICE),
    setNetwork: makeAction(actionTypes.NETWORK_NOT_CHANGED),
    selectCompany: makeAction(actionTypes.SELECT_COMPANY),
    manageCompany: makeAction(actionTypes.MANAGE_COMPANY),
};

export default connect(actionsBar, mapDispatchToProps)(withRouter(DeviceActionBar));
