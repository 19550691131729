import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import Select from 'react-select';
import translate from 'counterpart';

import WarningModal from '../../common/modals/WarningModal';
import makeAction from '../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../redux/actionTypes';
import {multiActionsBar} from '../../../redux/selectors/devices/multiActionsBar';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';
import tooltip from '../../common/tooltip';
import TimedUpgradeModal from '../../common/modals/TimedUpgradeModal';

// TODO: multiple actions refactor; Move modals to one place (navigation/header)
const MultipleActionsBar = ({
    checked, options, soft, isDeviceSelected, multi, action, softValue, softDisabled, applyDisabled, dataTarget, companies,
    multipleInstall, multipleRemove, selectMultiSoft, selectMultiAction, multipleReboot, selectCompany, company, manageCompany}) => (
    <div>
        <form className="form-horizontal table-panel">
            <label htmlFor="email">
                {translate('actions', {scope: 'devices'})}
                <OverlayTrigger overlay={
                    tooltip(translate('reboot', {scope: 'devices'}) + ' - ' + translate('reboot', {scope: 'tooltips.action'}) +
                        '\n' + translate('remove', {scope: 'devices'}) + ' - ' + translate('remove', {scope: 'tooltips.action'}) +
                        '\n' + translate('upgrade', {scope: 'devices'}) + ' - ' + translate('upgrade', {scope: 'tooltips.action'}) +
                        '\n' + translate('timed_upgrade', {scope: 'devices'}) + ' - ' + translate('timed_upgrade', {scope: 'tooltips.action'}) +
                        '\n' + translate('select_version', {scope: 'placeholders'}) + ' - ' + translate('select_version', {scope: 'tooltips.action'}) + '\n' + translate('manage_company', {scope: 'devices'}) + ' - ' + translate('manage_company', {scope: 'tooltips.action'})
                    )}
                >
                    <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                </OverlayTrigger>
            </label>
            {action.value ?
                <OverlayTrigger placement="top"
                    overlay={tooltip(translate(action.value, {scope: 'tooltips.action'}))}>
                    <Select
                        placeholder={translate('select_action', {scope: 'placeholders'})}
                        className="soft_select"
                        name="form-field-name"
                        value={action.value}
                        options={options}
                        searchable={false}
                        clearable={false}
                        onChange={
                            (obj) => selectMultiAction(obj)
                        }
                        disabled={!isDeviceSelected}
                    />
                </OverlayTrigger> :
                <Select
                    placeholder={translate('select_action', {scope: 'placeholders'})}
                    className="soft_select"
                    name="form-field-name"
                    value={action.value}
                    options={options}
                    searchable={false}
                    clearable={false}
                    onChange={
                        (obj) => selectMultiAction(obj)
                    }
                    disabled={!isDeviceSelected}
                />
            }
            {action.value === 'manage_company' ?
                <OverlayTrigger placement="top"
                    overlay={tooltip(translate('select_version', {scope: 'tooltips.action'}))}>
                    <Select
                        placeholder={translate('select_companies', {scope: 'placeholders'})}
                        className="soft_select"
                        name="form-field-name"
                        value={company}
                        options={companies}
                        searchable={false}
                        clearable={false}
                        onChange={(obj) => selectCompany(obj)}
                    />
                </OverlayTrigger> :
                <PermissionCheck permission={permissions.UPGRADE_DEVICE}>
                    <OverlayTrigger placement="top"
                        overlay={tooltip(translate('select_version', {scope: 'tooltips.action'}))}>
                        <Select
                            placeholder={translate('select_version', {scope: 'placeholders'})}
                            className="soft_select"
                            name="form-field-name"
                            value={softValue}
                            options={soft}
                            searchable={false}
                            clearable={false}
                            onChange={(obj) => selectMultiSoft(obj)}
                            disabled={softDisabled}
                        />
                    </OverlayTrigger>
                </PermissionCheck>
            }
            {applyDisabled ?
                <OverlayTrigger placement="top"
                    overlay={tooltip(translate('apply_disabled', {scope: 'tooltips.action'}))}>
                    <span className="soft_install_button btn btn-primary" disabled={true}>
                        {translate('apply', {scope: 'devices'})}
                        <i className="fa fa-angle-right"/>
                    </span>
                </OverlayTrigger>
                :
                <OverlayTrigger placement="top" overlay={tooltip(translate('apply', {scope: 'tooltips.action'}))}>
                    <button className="soft_install_button btn btn-primary" type="button"
                        data-toggle="modal"
                        data-target={dataTarget}
                        data-backdrop="static"
                        id="apply"
                        data-keyboard="false">
                        {translate('apply', {scope: 'devices'})}
                        <i className="fa fa-angle-right"/>
                    </button>
                </OverlayTrigger>
            }
        </form>

        <WarningModal id="rebootModal"
            header={translate('reboot_devices_header', {scope: 'modals'})}
            text={translate('reboot_devices_body', {scope: 'modals'})}
            onSubmit={() => multipleReboot({
                devices: checked.map((device) => device.id),
                action: 'reboot',
            })}/>
        <WarningModal id="removeModal"
            header={translate('remove_devices_header', {scope: 'modals'})}
            text={translate('remove_devices_body', {scope: 'modals'})}
            onSubmit={() => multipleRemove({
                devices: checked.map((device) => device.id),
                action: 'remove',
            })}/>
        <WarningModal id="companyModal"
            header={translate('manage_company_header', {scope: 'modals'})}
            text={
                (company.value ? translate(
                    'manage_company_body_assign', {scope: 'modals'}) + company.label : translate(
                    'manage_company_body_unassign', {scope: 'modals'})) + ': ' + checked.map((device) => ' ' + device.name)
            }
            onSubmit={() => manageCompany({
                devices: checked.map((device) => device.id),
                company: company,
            })}/>
        <WarningModal id="installModal"
            header={translate('upgrade_devices_header', {scope: 'modals'})}
            text={translate('upgrade_devices_body', {scope: 'modals'})}
            onSubmit={() => multipleInstall({
                devices: checked.map((device) => device.id),
                action: 'install',
                soft_version: multi.value,
            })}/>
        <TimedUpgradeModal
            id="timedUpgradeModal"
            devices={checked.map((device) => device.id)}
            soft_version={multi.value}
        />
    </div>
);

MultipleActionsBar.propTypes = {
    checked: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    soft: PropTypes.array.isRequired,
    isDeviceSelected: PropTypes.bool.isRequired,
    isSoftSelected: PropTypes.bool.isRequired,
    notConnected: PropTypes.bool.isRequired,
    action: PropTypes.object,
    company: PropTypes.object,
    softValue: PropTypes.string,
    softDisabled: PropTypes.bool.isRequired,
    applyDisabled: PropTypes.bool.isRequired,
    dataTarget: PropTypes.string.isRequired,
    multi: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,

    selectMultiSoft: PropTypes.func.isRequired,
    multipleReboot: PropTypes.func.isRequired,
    multipleRemove: PropTypes.func.isRequired,
    multipleInstall: PropTypes.func.isRequired,
    manageCompany: PropTypes.func.isRequired,
    selectMultiAction: PropTypes.func.isRequired,
    selectCompany: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    selectMultiSoft: makeAction(actionTypes.SELECT_MULTI_SOFT),
    selectMultiAction: makeAction(actionTypes.SELECT_MULTI_ACTION),
    multipleReboot: makeAction(actionTypes.MULTIPLE_REBOOT),
    multipleRemove: makeAction(actionTypes.MULTIPLE_REMOVE),
    multipleInstall: makeAction(actionTypes.MULTIPLE_INSTALL),
    manageCompany: makeAction(actionTypes.MANAGE_COMPANY),
    selectCompany: makeAction(actionTypes.SELECT_MULTI_COMPANY),
};

export default connect(
    multiActionsBar,
    mapDispatchToProps
)(MultipleActionsBar);
