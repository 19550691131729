import React from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import PropTypes from 'prop-types';
import {mappingsAdd} from '../../../../redux/selectors/device/hotelSystems';
import translate from 'counterpart';

class MappingsAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {business_id: ''};
    }

    render() {
        return (
            <div className="inline-block add-plugin-wrapper" id="accordion">
                <div className="panel panel-default panel-transparent">
                    <div id="collapse1" className="width collapse">
                        <div className="input-wrapper">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={translate('business_id', {scope: 'devices.plugins'})}
                                    value={this.state.business_id}
                                    onChange={(event) => this.setState({business_id: event.target.value})}
                                />
                                <span className="input-group-btn">
                                    <span
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        className="btn btn-default collapsed"
                                        type="button"
                                        onClick={() => {
                                            this.props.addMapping({
                                                mappings: this.props.defaults,
                                                pluginId: this.props.plugin.id,
                                                mapping_group: this.props.group + 1,
                                                business_id: this.state.business_id,
                                            });
                                            this.setState({business_id: ''});
                                        }}
                                    >{translate('add_id', {scope: 'devices.plugins'})}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-default panel-transparent">
                    <div id="collapse2" className="width collapse in">
                        <span data-toggle="modal"
                            className="btn btn-default reveal inline-block btn-sm"
                            onClick={() => this.props.removeMapping({pluginId: this.props.plugin.id, business_id: this.props.id})}
                        >{translate('remove_id', {scope: 'devices.plugins'})}</span>
                        <span className="btn btn-default reveal collapsed btn-sm"
                            data-toggle="collapse" data-parent="#accordion"
                            href="#collapse1">{translate('add_id', {scope: 'devices.plugins'})}</span>
                    </div>
                </div>
            </div>
        );
    }
}

MappingsAdd.propTypes = {
    plugin: PropTypes.any.isRequired,
    defaults: PropTypes.array,
    group: PropTypes.any,
    addMapping: PropTypes.func.isRequired,
    removeMapping: PropTypes.func.isRequired,
    id: PropTypes.any,
};

const mapDispatchToProps = {
    addMapping: makeAction(actionTypes.ADD_MAPPING),
    removeMapping: makeAction(actionTypes.REMOVE_MAPPING_ID),
};

export default connect(mappingsAdd, mapDispatchToProps)(MappingsAdd);
