import * as actionTypes from '../actionTypes';
import {save} from '../../services/persist';
import _ from 'lodash';

const DEFAULT_STATE = {
    data: {},
    device: {
        device_configurations: [],
    },
    printers_list: [],
    installed_printer: {},
    selected_driver: {},
    printer_in_progress: false,
    delete_in_progress: false,
    ping_list_in_progress: false,
    probe_list_in_progress: false,
    printer_fetched: false,
    password_fetched: false,
    inProgress: false,
    files: [],
    invoices: [],
    invoices_count: 0,
    invoices_params: {
        limit: 100, page: 1, invoicenumber: '', status: '', table: '', roomid: '', tip: '', total: '',
        start_date: null, end_date: null, outlet: '', payment_names: '', payment_type: '', discount: '',
    },
    log_entries: [],
    log_count: 0,
    log_params: {limit: 100, page: 1, level: 0, start_date: null, end_date: null},
    password: '',
    syncing: false,
    invoices_columns: [],
    selected_company: {label: 'Unassign', value: null},
    selectedPlugin: {label: '', value: null},
    xmlValue: '',
    selected_plugin_config: {},
};

/* eslint max-statements: [2, {"max":128}] */
const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return {
                ...state,
                log_params: DEFAULT_STATE.log_params,
                password: '',
                password_fetched: false,
            };
        }
        case actionTypes.PRE_CREATE_PRINTER: {
            return {
                ...state,
                installed_printer: {
                    name: '',
                    uri: '',
                    description: '',
                    location: '',
                    connection: '',
                    info: '',
                    make: '',
                    model: '',
                    drivers: [],
                    device: action.payload,
                    stage: 'one',
                    installed: false,
                    paper_size: 'A4',
                },
            };
        }
        case actionTypes.PULL_PRINTERS_DRIVERS_SUCCESS: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    drivers: action.payload,
                    stage: 'two',
                },
            };
        }
        case actionTypes.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                password: action.payload.password,
            };
        }
        case actionTypes.FETCH_PASSWORD_SUCCESS: {
            return {
                ...state,
                password: action.payload.password,
                password_fetched: true,
            };
        }
        case actionTypes.PULL_PRINTERS_DRIVERS_FAILED: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    stage: 'in_progress',
                },
            };
        }
        case actionTypes.PULL_PRINTERS_DRIVERS_IN_PROGRESS: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    drivers: action.payload,
                    stage: 'in_progress',
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_STAGE: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    stage: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_NAME: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    name: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_URI: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    uri: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_MODEL: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    model: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_MAKE: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    make: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_DESCRIPTION: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    description: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_LOCATION: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    location: action.payload,
                },
            };
        }
        case actionTypes.CHANGE_PRINTER_PAPER_SIZE: {
            return {
                ...state,
                installed_printer: {
                    ...state.installed_printer,
                    paper_size: action.payload,
                },
            };
        }
        case actionTypes.SELECT_PRINTER_DRIVER: {
            return {
                ...state,
                selected_driver: action.payload,
            };
        }
        case actionTypes.DEVICE_FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                inProgress: false,
            };
        }
        case actionTypes.PULL_PRINTER: {
            return {
                ...state,
                printer_in_progress: true,
                printer_fetched: false,
            };
        }
        case actionTypes.PRINTER_PULL_SUCCESS: {
            return {
                ...state,
                installed_printer: action.payload,
                printer_in_progress: false,
                printer_fetched: true,
            };
        }
        case actionTypes.PRINTER_PULL_FAILED: {
            return {
                ...state,
                printer_in_progress: false,
                printer_fetched: false,
            };
        }
        case actionTypes.DEVICE_PRINTERS_PULL_SUCCESS: {
            return {
                ...state,
                printers_list: action.payload,
            };
        }
        case actionTypes.PRINTER_INSTALL_IN_PROGRESS: {
            return {
                ...state,
                printers_list: state.printers_list.map((printer) => (printer.hash === action.payload.hash ?
                    ({
                        ...printer,
                        installed: 'in_progress',
                    })
                    :
                    printer)
                ),
            };
        }
        case actionTypes.PRINTER_INSTALL_SUCCESS: {
            return {
                ...state,
                printers_list: state.printers_list.map((printer) => (printer.hash === action.payload ?
                    ({
                        ...printer,
                        installed: true,
                    })
                    :
                    printer)
                ),
            };
        }
        case actionTypes.PRINTER_REMOVE_IN_PROGRESS: {
            return {
                ...state,
                printers_list: state.printers_list.map((printer) => (printer.hash === action.payload.hash ?
                    ({
                        ...printer,
                        installed: 'remove_in_progress',
                    })
                    :
                    printer)
                ),
            };
        }
        case actionTypes.PRINTER_REMOVE_SUCCESS: {
            return {
                ...state,
                printers_list: state.printers_list.map((printer) => (printer.hash === action.payload ?
                    ({
                        ...printer,
                        installed: false,
                    })
                    :
                    printer)
                ),
            };
        }
        case actionTypes.FETCH_INVOICE_SUCCESS: {
            return {
                ...state,
                invoice: action.payload,
            };
        }
        case actionTypes.FETCH_INVOICES_SUCCESS: {
            return {
                ...state,
                invoices: action.payload.results,
                invoices_count: action.payload.count,
            };
        }
        case actionTypes.INVOICES_CLEAR_PARAMS: {
            return {
                ...state,
                invoices_params: DEFAULT_STATE.invoices_params,
            };
        }
        case actionTypes.INVOICES_SET_PARAMS: {
            return {
                ...state,
                invoices_params: {...state.invoices_params, ...action.payload},
            };
        }
        case actionTypes.INVOICES_LIST_ADD_COLUMN: {
            const columnsList = [
                ...state.invoices_columns,
                {
                    name: action.payload.name,
                    label: action.payload.label,
                },
            ];
            save('invoices_column_names', JSON.stringify(columnsList.map((el) => el.name)));

            return {
                ...state,
                invoices_columns: columnsList,
            };
        }
        case actionTypes.INVOICES_LIST_REMOVE_COLUMN: {
            const columnsList = _.filter(state.invoices_columns, (obj) => obj.name !== action.payload.name);
            save('invoices_column_names', JSON.stringify(columnsList.map((el) => el.name)));

            return {
                ...state,
                invoices_columns: columnsList,
            };
        }
        case actionTypes.FETCH_LOGENTRIES_SUCCESS: {
            return {
                ...state,
                log_entries: action.payload.results,
                log_count: action.payload.count,
            };
        }
        case actionTypes.LOGENTRIES_CLEAR_PARAMS: {
            return {
                ...state,
                log_params: DEFAULT_STATE.log_params,
            };
        }
        case actionTypes.LOGENTRIES_SET_PARAMS: {
            return {
                ...state,
                log_params: {...state.log_params, ...action.payload},
            };
        }
        case actionTypes.RE_SYNC: {
            return {
                ...state,
                syncing: true,
            };
        }
        case actionTypes.RE_SYNC_SUCCESS:
        case actionTypes.RE_SYNC_FAILURE: {
            return {
                ...state,
                syncing: false,
            };
        }
        case actionTypes.CHANGE_SUCCESS:
        case actionTypes.DEVICE_PULL_SUCCESS: {
            return {
                ...state,
                device: action.payload,
            };
        }
        case actionTypes.FETCH_DEVICE: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.SELECT_SOFT_VERSION: {
            const data = action.payload;

            return {
                ...state,
                device: {
                    ...state.device,
                    soft_update: data.value,
                },
            };
        }
        case actionTypes.SELECT_COMPANY: {
            return {
                ...state,
                selected_company: action.payload,
            };
        }
        case actionTypes.PING_DEVICE: {
            return {
                ...state,
                device: {
                    ...state.device,
                    ping_list_in_progress: true,
                },
            };
        }
        case actionTypes.SET_RELEVANT: {
            return {
                ...state,
                device: {
                    ...action.payload,
                },
            };
        }
        case actionTypes.DELETE_DEVICE_DATA: {
            return {
                ...state,
                delete_in_progress: true,
            };
        }
        case actionTypes.DELETE_DEVICE_DATA_SUCCESS: {
            return {
                ...state,
                delete_in_progress: false,
            };
        }
        case actionTypes.DELETE_DEVICE_DATA_FAILED: {
            return {
                ...state,
                delete_in_progress: false,
            };
        }
        case actionTypes.PING_DEVICE_SUCCESS: {
            return {
                ...state,
                device: {
                    ...state.device,
                    ping_tests: action.payload.ping_tests,
                    ping_list_in_progress: false,
                },
            };
        }
        case actionTypes.PING_DEVICE_FAILED: {
            return {
                ...state,
                device: {
                    ...state.device,
                    ping_list_in_progress: false,
                },
            };
        }
        case actionTypes.PROBE_DEVICE: {
            return {
                ...state,
                device: {
                    ...state.device,
                    probe_list_in_progress: true,
                },
            };
        }
        case actionTypes.PROBE_DEVICE_SUCCESS: {
            return {
                ...state,
                device: {
                    ...state.device,
                    probe_tests: action.payload.probe_tests,
                    probe_list_in_progress: false,
                },
            };
        }
        case actionTypes.PROBE_DEVICE_FAILED: {
            return {
                ...state,
                device: {
                    ...state.device,
                    probe_list_in_progress: false,
                },
            };
        }
        case actionTypes.PULL_DEVICE_FILES_SUCCESS: {
            return {
                ...state,
                files: action.payload,
            };
        }
        case actionTypes.DEVICE_CLEAR_ALERT_SUCCEES: {
            return {
                ...state,
                device: {
                    ...state.device,
                    alert_level: 0,
                },
            };
        }
        case actionTypes.SOFT_UPDATE_SUCCESS: {
            return {
                ...state,
                device: {
                    ...state.device,
                    ...action.payload,
                },
            };
        }
        case actionTypes.DEVICE_REBOOT_SUCCESS: {
            return {
                ...state,
                device: {
                    ...state.device,
                    ...action.payload,
                },
            };
        }
        case actionTypes.SELECT_SEND_PLUGIN: {
            return {
                ...state,
                selectedPlugin: action.payload,
            };
        }
        case actionTypes.CHANGE_XML_VALUE: {
            return {
                ...state,
                xmlValue: action.payload,
            };
        }
        case actionTypes.SELECT_PLUGIN_CONFIG: {
            return {
                ...state,
                selected_plugin_config: action.payload,
            };
        }
        case actionTypes.SELECT_PLUGIN_CONFIG_TO_SAVE: {
            return {
                ...state,
                device: {
                    ...state.device,
                    device_configuration: {
                        ...state.device.device_configuration,
                        plugins: action.payload,
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
