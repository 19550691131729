import {take, put, all} from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import request, {GET} from '../../services/request';
import {API_URL} from '../../configuration/config';
import {handleError} from './errors';

function *fetchSoft() {
    while (true) {
        try {
            yield take(actionTypes.FETCH_SOFT);
            const response = yield request(GET, API_URL + 'packages/');
            yield put({type: actionTypes.FETCH_SOFT_SUCCESS, payload: response.data});
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.FETCH_SOFT_FAILED, error: e});
        }
    }
}

export default function *soft() {
    yield all([
        fetchSoft(),
    ]);
}
