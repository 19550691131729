import * as actionTypes from '../actionTypes';
import _ from 'lodash';

const DEFAULT_STATE = {
    global_section: {},
    plugins: [],
};

const defaultDeviceConfigurationReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DEFAULT_CONFIGURATION_SUCCESS: {
            const defaultConfiguration = action.payload;
            return {
                ...state,
                ...defaultConfiguration,
                id: null,
                template_level: 2,
                name: null,
                ethernets: defaultConfiguration.ethernets.map((ethernet) => ({
                    ...ethernet,
                    id: _.uniqueId('new_'),
                })),
            };
        }
        default: {
            return state;
        }
    }
};

export default defaultDeviceConfigurationReducer;
