import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import idx from 'idx';
import _ from 'lodash';

const pluginsCollapse = ({plugin, addMapping, defaultDeviceConfiguration}) => {
    const mappingDefaults = idx(defaultDeviceConfiguration.plugins.filter((defaultPlugin) => defaultPlugin.type === plugin.type), (p) => p[0].mappings);
    const maxMappingGroup = plugin.mappings && plugin.mappings.length > 0 ? _.maxBy(plugin.mappings, 'mapping_group').mapping_group : 0;
    return (
        <div className="m-b-15">
            <span onClick={() => (
                addMapping({
                    mappings: mappingDefaults,
                    pluginId: plugin.id,
                    mapping_group: maxMappingGroup + 1,
                }))
            } className="btn btn-small col-xs-offset-2" id="basic-addon2">
                <i className="fa fa-plus"/>Add mapping to plugin
            </span>
        </div>
    );
};

pluginsCollapse.propTypes = {
    plugin: PropTypes.object.isRequired,
    addMapping: PropTypes.func.isRequired,
    defaultDeviceConfiguration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    defaultDeviceConfiguration: state.defaultDeviceConfiguration,
});

const mapDispatchToProps = {
    addMapping: makeAction(actionTypes.ADD_MAPPING),
};

export default connect(mapStateToProps, mapDispatchToProps)(pluginsCollapse);
