import {CHANGE_MODAL} from '../../actionTypes';

const DEFAULT_STATE = {
    modal: {},
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case CHANGE_MODAL: {
            return {
                ...state,
                modal: {
                    ...state.modal,
                    ...action.payload,
                },
            };
        }
        case '@@router/LOCATION_CHANGE': {
            return DEFAULT_STATE;
        }
        default: {
            return state;
        }
    }
};

export default reducer;
