import {createSelector} from 'reselect';
import translate from 'counterpart';
import _ from 'lodash';

import {deviceId, logCount, logEntries, logParams, syncInProgress} from './stateSelector';
import {formatDate} from '../../../services/formatters';

export const deviceLogs = createSelector(
    logEntries,
    logParams,
    logCount,
    deviceId,
    syncInProgress,
    (entries, params, count, devid, syncing) => ({
        count,
        entries: entries.map((entry) => ({
            ...entry,
            ts: formatDate(entry.timestamp),
            code: translate(String(entry.error_code), {scope: ['logging', 'code', String(entry.error_group)]}),
            group: translate(String(entry.error_group), {scope: 'logging.group'}),
            level: translate(String(entry.level), {scope: 'logging.level'}),
            message: translate(String(entry.error_code), {scope: ['logging', 'message', String(entry.error_group)]}).split('\n'),
            changelog: String(entry.changelog),
            extra: _.toPairs(entry.extra).sort((first, second) => first[0] > second[0]).map((obj) => [translate(obj[0], {scope: 'logging.label'}), obj[1]]),
        })),
        params,
        levelOptions: [0, 10, 20, 30, 40, 50].map((i) => (
            {value: i, label: translate(String(i), {scope: 'logging.level'})})
        ),
        devid,
        syncing,
    })
);
