import React from 'react';
import translate from 'counterpart';

import {invoiceStates} from '../../../services/invoice';

const ModalStatus = () => (
    <div id="statusInvoicesModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">×</button>
                    <h3 className="modal-title">{translate('statuses_explanation', {scope: 'devices.table_options'})}</h3>
                </div>
                <div className="modal-body">
                    <dl className="dl-horizontal status">
                        {invoiceStates.map((i) => (
                            <React.Fragment key={i}>
                                <dt>{translate(i, {scope: 'devices.invoices'})}</dt>
                                <dd className="overflowing">{translate(i, {scope: 'tooltips.invoices'})}</dd>
                            </React.Fragment>
                        )
                        )}
                    </dl>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">{translate('close', {scope: 'modals'})}</button>
                </div>
            </div>
        </div>
    </div>
);

export default ModalStatus;
