import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import resetStateAction from '../redux/actions/authorization/resetState';
import Details from '../components/devices/details';
import * as actionTypes from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';


class DetailsPage extends React.Component {
    componentDidMount() {
        this.props.resetState();
        this.props.pullDevice({id: this.props.match.params.deviceId});
        this.props.fetchSoft();
        this.props.pullDevicePrinters(this.props.match.params.deviceId);
        this.props.pullDeviceFiles(this.props.match.params.deviceId);
        this.props.fetchLogEntries({id: this.props.match.params.deviceId, params: this.props.log_params});
        this.props.fetchCompanies();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.deviceId !== this.props.match.params.deviceId) {
            this.props.resetState();
            this.props.fetchSoft();
            this.props.pullDevicePrinters(this.props.match.params.deviceId);
            this.props.pullDeviceFiles(this.props.match.params.deviceId);
        }
        this.props.fetchLogEntries({id: this.props.match.params.deviceId, params: this.props.log_params});
        this.props.pullDevice({id: this.props.match.params.deviceId});
        this.props.fetchCompanies();
    }

    render() {
        return <Details {...this.props}/>;
    }
}

DetailsPage.propTypes = {
    resetState: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    pullDevice: PropTypes.func.isRequired,
    fetchSoft: PropTypes.func.isRequired,
    pullDevicePrinters: PropTypes.func.isRequired,
    pullDeviceFiles: PropTypes.func.isRequired,
    fetchInvoices: PropTypes.func.isRequired,
    fetchLogEntries: PropTypes.func.isRequired,
    invoices_params: PropTypes.object.isRequired,
    log_params: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    fetchCompanies: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    invoices_params: state.device.invoices_params,
    log_params: state.device.log_params,
    disabled: state.navigation.navigationDisabled,
});

const mapDispatchToProps = {
    resetState: resetStateAction,
    pullDevice: makeAction(actionTypes.PULL_DEVICE),
    fetchSoft: makeAction(actionTypes.FETCH_SOFT),
    pullDevicePrinters: makeAction(actionTypes.PULL_DEVICE_PRINTERS),
    pullDeviceFiles: makeAction(actionTypes.PULL_DEVICE_FILES),
    fetchInvoices: makeAction(actionTypes.FETCH_INVOICES),
    fetchLogEntries: makeAction(actionTypes.FETCH_LOGENTRIES),
    fetchCompanies: makeAction(actionTypes.FETCH_COMPANIES),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsPage));
