import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {Link} from 'react-router-dom';
import translate from 'counterpart';

import {DEVICE_DETAILS_PAGE} from '../../../configuration/paths';
import tooltip from '../../common/tooltip';
import Select from 'react-select';
import {getListOptions} from '../../../redux/reducers/helpers/utils';

const DevicesValues = ({device, columns}) => (
    <React.Fragment>
        {
            columns.map((column) => {
                if (column.link) {
                    return (
                        <td key={`${column.name}${device.id}`}>
                            <Link to={DEVICE_DETAILS_PAGE(device.id)}>{device[column.name]}</Link>
                        </td>
                    );
                }
                if (device[column.name] && device[column.name].length > 40) {
                    return (
                        <td id={column.name} key={`${column.name}${device.id}`}>
                            <OverlayTrigger
                                overlay={tooltip(device[column.name])}><span>{`${device[column.name].substring(0, 40)}...`}</span></OverlayTrigger>
                        </td>
                    );
                }
                if (column.name === 'plugins') {
                    return (
                        <td key={`${column.name}${device.id}`} className={'overflowing'}>
                            <Select
                                name={`device${device.id}`}
                                value={{
                                    value: 'plugin',
                                    label: translate('plugin_list', {scope: 'devices.table_options'}),
                                }}
                                options={getListOptions(device.plugins)}
                                searchable={false}
                                clearable={false}
                            />
                        </td>
                    );
                }
                return <td id="deviceName" key={`${column.name}${device.id}`}>{device[column.name] || '---'}</td>;
            })
        }
    </React.Fragment>
);

DevicesValues.propTypes = {
    device: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.oneOf(['new', 'accepted', 'rejected', 'installing_bridge_software', 'installing_bridge_software', 'provisioned',
            'provisioning_failed', 'upgrading', 'rebooting', 'connected', 'not_connected', 'failed']),
        mac: PropTypes.string,
        external_ip: PropTypes.string,
        serial: PropTypes.string,
        internal_ip: PropTypes.string,
        company: PropTypes.string,
    }).isRequired,
    columns: PropTypes.array.isRequired,
};

export default DevicesValues;
