import {get, remove, save} from '../../services/persist';
import * as actionTypes from '../actionTypes';

export const get_default_state = () => ({
    count: 0,
    params: {
        date: '',
        dateIndex: 0,
        version: '',
        versionIndex: 0,
        type: '',
        limit: parseInt(get('images_limit'), 10) || save('images_limit', 15),
        name: '',
        ordering: get('images_sort') || '',
        page: parseInt(get('images_page'), 10) || save('images_page', 1),
    },
    changes: {},
    inProgress: false,
    results: [],
});

const sysImageReducer = (state = get_default_state(), action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return get_default_state();
        }
        case actionTypes.FETCH_IMAGES: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.FETCH_IMAGES_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                inProgress: false,
            };
        }
        case actionTypes.IMAGES_CLEAR_PARAMS: {
            remove('images_page');
            remove('images_sort');
            return {
                ...state,
                params: get_default_state().params,
                inProgress: true,
            };
        }
        case actionTypes.IMAGES_SET_PARAMS: {
            save('images_limit', action.payload.limit);
            save('images_page', action.payload.page);
            save('images_sort', action.payload.ordering);
            return {
                ...state,
                params: action.payload,
                inProgress: true,
            };
        }
        case actionTypes.IMAGES_UPDATE_IMAGE_SUCCESS: {
            return {
                ...state,
                changes: {...state.changes,
                    [action.payload.id]: action.payload.data},
                inProgress: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default sysImageReducer;

