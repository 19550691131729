import PropTypes from 'prop-types';
import React from 'react';
import OraclePlugin from './OraclePlugin';
import ProtelPlugin from './ProtelPlugin';
import SihotPlugin from './SihotPlugin';

const PluginsList = ({plugins}) => (
    <ul className="list-unstyled plugins-list row">
        {plugins.map((plugin) => {
            switch (plugin.type) {
                case 'OraclePlugin': {
                    return <OraclePlugin key={plugin.id} plugin={plugin}/>;
                }
                case 'ProtelPlugin': {
                    return <ProtelPlugin key={plugin.id} plugin={plugin}/>;
                }
                case 'SihotPlugin': {
                    return <SihotPlugin key={plugin.id} plugin={plugin}/>;
                }
                default: {
                    return <div/>;
                }
            }
        })}
    </ul>
);

PluginsList.propTypes = {
    plugins: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
};

export default PluginsList;
