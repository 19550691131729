import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {MAPPING_TYPES} from '../../../../configuration/constants';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import translate from 'counterpart';

const mappingItemRow = ({mapping = {}, pair, removeMappingPair, changePairValue}) => (
    <li className="row">
        <div className={mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING ? 'col-xs-2' : 'col-xs-4'}>
            {(mapping.type === MAPPING_TYPES.NORMAL || mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING) &&
                <input value={pair.key} className="form-control"
                    onChange={(event) => changePairValue({
                        item: {
                            ...pair,
                            key: event.target.value,
                        },
                        pluginId: mapping.plugin,
                        mappingId: mapping.id,
                    })}
                />
            }
            {mapping.type === MAPPING_TYPES.SHIFT_CLOSE &&
                <span>{translate(pair.key, {scope: 'shiftClose'})}</span>
            }
            {mapping.type === MAPPING_TYPES.NUMERIC_KEY &&
                <input value={pair.key} type="number" step="0.01" min="0" max="100" className="form-control"
                    onChange={(event) => changePairValue({
                        item: {
                            ...pair,
                            key: event.target.value,
                        },
                        pluginId: mapping.plugin,
                        mappingId: mapping.id,
                    })}
                />
            }
        </div>
        {mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING &&
            <div className="col-xs-2">
                <input value={pair.vatrate} className="form-control"
                    onChange={(event) => changePairValue({
                        item: {
                            ...pair,
                            vatrate: event.target.value,
                        },
                        pluginId: mapping.plugin,
                        mappingId: mapping.id,
                    })}
                />
            </div>
        }
        <div className="col-xs-4">
            <input value={pair.value} className="form-control"
                onChange={(event) => changePairValue({
                    item: {
                        ...pair,
                        value: event.target.value,
                    },
                    pluginId: mapping.plugin,
                    mappingId: mapping.id,
                })}
            />
        </div>
        {mapping.type !== MAPPING_TYPES.SHIFT_CLOSE &&
        <div className="btn btn-md col-xs-2"
            onClick={() => removeMappingPair(
                {
                    item: {
                        ...pair,
                    },
                    pluginId: mapping.plugin,
                    mappingId: mapping.id,
                }
            )}
        >
            <i className="fa fa-minus"/>{translate('remove', {scope: 'devices.printers'})}
        </div>}
    </li>
);

mappingItemRow.propTypes = {
    mapping: PropTypes.object.isRequired,
    pair: PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string,
        vatrate: PropTypes.string,
    }),
    removeMappingPair: PropTypes.func.isRequired,
    changePairValue: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    addMappingPair: makeAction(actionTypes.ADD_MAPPING_PAIR),
    removeMappingPair: makeAction(actionTypes.REMOVE_MAPPING_PAIR),
    changePairValue: makeAction(actionTypes.CHANGE_PAIR_VALUE),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(mappingItemRow);
