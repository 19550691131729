import {createSelector} from 'reselect';
import {deviceBranch, deviceDetails, devicePlugins} from './stateSelector';
import {editableStatus} from './validation';
import {formatDate, formatUrl} from '../../../services/formatters';

export const urlsSelector = createSelector(
    devicePlugins,
    (plugins) => plugins.map((plugin) => ({
        name: plugin.name,
        url: plugin.type === 'ProtelPlugin' ? plugin.samba_server_address :
            plugin.type === 'OraclePlugin' ? plugin.host :
                formatUrl(plugin.url)[0],
        port: plugin.type === 'OraclePlugin' ? plugin.port :
            plugin.type === 'SihotPlugin' ? formatUrl(plugin.url)[1] : '',
    }))
);

export const pluginsSelector = createSelector(
    deviceDetails,
    devicePlugins,
    (device, plugins) => plugins.map((plugin) => ({
        value: plugin.type.replace('Plugin', '').toLowerCase() +
        (plugin.order_no === 1 ? '' : '/' + plugin.order_no),
        label: plugin.name,
    }))
);

export const tools = createSelector(
    deviceDetails,
    deviceBranch,
    editableStatus,
    urlsSelector,
    pluginsSelector,
    (details, device, status, urls, plugins) => ({
        pingTests: details.ping_tests.map(
            (obj) => ({
                ...obj,
                ts: formatDate(obj.timestamp),
                result: obj.result ? 'OK' : 'ERROR',
            })
        ),
        probeTests: details.probe_tests.map(
            (obj) => ({
                ...obj,
                ts: formatDate(obj.timestamp),
                result: obj.result ? 'OK' : 'ERROR',
            })
        ),
        inProgress: device.ping_list_in_progress,
        deleteInProgress: device.delete_in_progress,
        password: device.password || '',
        fetched: device.password_fetched,
        disabled: !status,
        urls,
        plugins,
        selectedPlugin: device.selectedPlugin.value,
        xmlValue: device.xmlValue,

    })
);
