import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import translate from 'counterpart';

import {PAGE_SIZE_OPTIONS} from '../../../configuration/constants';
import Pagination from '../../common/react-pagination';
import * as actionTypes from '../../../redux/actionTypes';
import makeAction from '../../../redux/actions/common/makeAction';
import MultiActionsBar from './multiActionsBar';
import {devicesListFooter} from '../../../redux/selectors/devices/devicesListFooter';

const DevicesListFooter = ({changePage, limit, page, count, changeLimit, notEmpty}) => (
    <div className="table-footer">
        <div className="row">
            <div className="col-xs-9">
                {notEmpty &&
                    <MultiActionsBar/>
                }
            </div>
            <div className="text-right col-xs-3 table-panel">
                <label id="pageSelect" className="no-input select-wrapper pagination-select">
                    <span className="label-title">{translate('per_page', {scope: 'devices'})}</span>
                    <Select value={limit} options={PAGE_SIZE_OPTIONS} clearable={false} searchable={false}
                        onChange={(e) => {
                            if (e.value !== limit) {
                                const new_page = Math.floor((page - 1) * limit / e.value) + 1;
                                changeLimit({limit: e.value, page: new_page});
                            }
                        }}/>
                </label>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 text-center">
                <Pagination {...{changePage, limit, page, count}}/>
            </div>
        </div>
    </div>
);

DevicesListFooter.propTypes = {
    changePage: PropTypes.func.isRequired,
    changeLimit: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    notEmpty: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    changePage: makeAction(actionTypes.DEVICES_LIST_PAGINATION),
    changeLimit: makeAction(actionTypes.DEVICES_LIST_CHANGE_LIMIT),
};

export default connect(devicesListFooter, mapDispatchToProps)(DevicesListFooter);
