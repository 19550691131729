import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import SelectableHeaderCell from '../../common/table/selectableHeaderCell';
import * as actionTypes from '../../../redux/actionTypes';
import makeAction from '../../../redux/actions/common/makeAction';
import DeviceListRow from './listRow';
import Loader from '../../common/loaderBox';
import NewHeaderCell from '../../common/table/newHeaderCell';
import debouncedFunc from '../../../services/debounce';
import {devicesListTable} from '../../../redux/selectors/devices/devicesListTable';
import SelectFilter from './SelectFilter';
import translate from 'counterpart';

const debounce = debouncedFunc();

const DevicesListTable = ({
    checked, checkDevices, list, changeSort, ordering, inProgress, columns, changeFilters, filters, fetchDevices, selectColumnOptions,
}) => {
    const getSorting = (field) => changeSort({ordering: field});

    return (<div className="table-wrapper content">
        <table className="table main-list table-hover" id="devicessList">
            <thead>
                <tr>
                    <SelectableHeaderCell
                        checked={checked}
                        onChange={() => checkDevices({state: !checked})}
                    />
                    {
                        columns.map((obj, index) => (
                            <NewHeaderCell
                                key={index}
                                field={obj.name}
                                label={translate(obj.name, {scope: 'devices.table_columns'})}
                                currentOrder={ordering}
                                onSortChange={getSorting}
                                className={obj.thClass}
                                tooltipContent={translate(obj.name, {scope: 'tooltips.device_list'})}
                            >
                                {obj.selectable ?
                                    <SelectFilter
                                        name={obj.filterName || obj.name}
                                        value={filters[obj.filterName || obj.name]}
                                        options={selectColumnOptions[obj.filterName || obj.name]}
                                    />
                                    :
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            id={obj.name}
                                            value={filters[obj.name]}
                                            onChange={(e) => {
                                                changeFilters({[obj.name]: e.target.value});
                                                debounce(fetchDevices);
                                            }}
                                            autoComplete="off"
                                        />
                                        { filters[obj.name] ?
                                            <span className="input-group-addon" onClick={() => {
                                                changeFilters({[obj.name]: ''});
                                                fetchDevices();
                                                return null;
                                            }}>
                                                <i className="fa fa-remove" />
                                            </span>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            </NewHeaderCell>
                        ))
                    }
                    <NewHeaderCell
                        key="soft_version"
                        field="soft_version"
                        label={translate('bridge_version', {scope: 'devices.table_columns'})}
                        className="sortable"
                        currentOrder={ordering}
                        onSortChange={getSorting}
                        tooltipContent={translate('soft_version', {scope: 'tooltips.device_list'})}>
                        <SelectFilter
                            name="soft_version"
                            value={filters.soft_version}
                            options={selectColumnOptions.soft_version}
                        />
                    </NewHeaderCell>
                    <NewHeaderCell
                        key="status"
                        field="status"
                        tooltipContent="a"
                        label={translate('status', {scope: 'devices.table_columns'})}
                        className="sortable"
                        currentOrder={ordering}
                        onSortChange={getSorting}
                    >
                        <SelectFilter
                            name="status"
                            value={filters.status}
                            options={selectColumnOptions.status}
                        />
                    </NewHeaderCell>
                </tr>
            </thead>
            <tbody>
                {!inProgress && list.length === 0 &&
                <tr>
                    <td colSpan={columns.length + 3} className="text-center">
                        {translate('no_devices', {scope: 'devices.device_details'})}
                    </td>
                </tr>
                }
                {inProgress ?
                    <tr>
                        <td colSpan={columns.length + 3}><Loader/></td>
                    </tr>
                    :
                    list.map((obj) => (
                        <DeviceListRow device={obj} key={obj.id} columns={columns}/>
                    ))
                }
            </tbody>
        </table>
    </div>);
};

DevicesListTable.propTypes = {
    inProgress: PropTypes.bool.isRequired,
    list: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    columns: PropTypes.array.isRequired,
    checkDevices: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    changeSort: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    ordering: PropTypes.string.isRequired,
    changeFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    fetchDevices: PropTypes.func.isRequired,
    selectColumnOptions: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    checkDevices: makeAction(actionTypes.CHECK_DEVICES),
    changeSort: makeAction(actionTypes.DEVICES_LIST_CHANGE_SORT),
    changePage: makeAction(actionTypes.DEVICES_LIST_PAGINATION),
    changeFilters: makeAction(actionTypes.DEVICES_LIST_CHANGE_FILTERS),
    clearFilters: makeAction(actionTypes.DEVICES_LIST_CLEAR_FILTERS),
    fetchDevices: makeAction(actionTypes.FETCH_DEVICES),
};

export default connect(devicesListTable, mapDispatchToProps)(DevicesListTable);
