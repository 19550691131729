import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import translate from 'counterpart';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import {PAGE_SIZE_OPTIONS} from '../../../configuration/constants';
import NewHeaderCell from '../../common/table/newHeaderCell';
import Pagination from '../../common/react-pagination';
import makeAction from '../../../redux/actions/common/makeAction';
import {DOWNLOAD_INVOICES, FETCH_INVOICES, INVOICES_CLEAR_PARAMS, INVOICES_LIST_ADD_COLUMN, INVOICES_LIST_REMOVE_COLUMN, INVOICES_SET_PARAMS, RE_SYNC} from '../../../redux/actionTypes';
import debouncedFunc from '../../../services/debounce';
import {mapInvoiceStates} from '../../../services/invoice';
import {DATE_FORMAT} from '../../../services/formatters';
import {deviceInvoices} from '../../../redux/selectors/device/invoices';
import tooltip from '../../common/tooltip';


const debounce = debouncedFunc();

class DeviceInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.delayFetch = false;
        this.state = {currentInvoice: {}};
        this.refreshInterval = null;
    }

    componentDidMount() {
        this.props.fetchInvoices({id: this.props.match.params.deviceId, params: this.props.params});
        this.setRefreshInvoicesInterval();
    }

    componentDidUpdate(prevProps) {
        if (this.props.params !== prevProps.params) {
            if (this.delayFetch) {
                debounce(this.props.fetchInvoices, {id: this.props.match.params.deviceId, params: this.props.params});
            } else {
                this.props.fetchInvoices({id: this.props.match.params.deviceId, params: this.props.params});
            }
            this.delayFetch = false;
        }
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    setRefreshInvoicesInterval() {
        this.refreshInterval = setInterval(() => {
            this.props.fetchInvoices({id: this.props.match.params.deviceId, params: this.props.params});
        }, 10000);
    }

    render() {
        const getSorting = (field) => this.props.setParams({ordering: field});
        return (
            <div className="details-content">
                <div className="row table-header">
                    <div className="col-md-3 col-xs-5">
                        <h3>
                            {translate('title', {scope: 'devices.invoices'})}
                            <OverlayTrigger placement="right" overlay={tooltip(translate('title', {scope: 'tooltips.invoices'}))}>
                                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </h3>
                    </div>
                    <div className="col-md-9 col-xs-7 text-right">
                        <div className="table-panel">
                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('xls', {scope: 'tooltips.invoices'}))}>
                                <button className="btn btn-info inline-block"
                                    onClick={() => this.props.downloadInvoices({id: this.props.match.params.deviceId, params: this.props.params})}
                                >XLS <span className="glyphicon glyphicon-download-alt" aria-hidden="true"/></button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={tooltip(translate('clear_filters', {scope: 'tooltips.invoices'}))}>
                                <button className="btn btn-info" onClick={() => this.props.clearParams({})}>
                                    {translate('clear_filters', {scope: 'devices.invoices'})}
                                </button>
                            </OverlayTrigger>
                            <div className="dropdown">
                                <OverlayTrigger placement="bottom" overlay={tooltip(translate('manage_columns', {scope: 'tooltips.device_list'}))}>
                                    <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                                        {translate('manage_columns', {scope: 'devices'})}
                                        <span className="caret"/></button>
                                </OverlayTrigger>
                                <ul className="dropdown-menu">
                                    <li>
                                        {this.props.selectableColumns.map((obj) => (
                                            obj.selected ?
                                                <span className="link" key={`remove${obj.name}`}
                                                    onClick={() => {
                                                        this.props.removeColumn(obj);
                                                        this.props.clearParams({});
                                                    }}>
                                                    {translate('hide_column', {scope: 'devices.device_details', column: obj.label})}
                                                </span>
                                                :
                                                <span className="link" key={`add_${obj.name}`}
                                                    onClick={() => this.props.addColumn(obj)}>
                                                    {translate('show_column', {scope: 'devices.device_details', column: obj.label})}
                                                </span>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                            <div className="datepicker-wrapper">
                                <DatePicker className="form-control"
                                    placeholderText={translate('from', {scope: 'placeholders'})}
                                    dateFormat={DATE_FORMAT}
                                    maxDate={this.props.params.end_date || moment()}
                                    isClearable={true}
                                    showMonthDropdown
                                    selected={this.props.params.start_date}
                                    selectsStart
                                    startDate={this.props.params.start_date}
                                    endDate={this.props.params.end_date}
                                    onChange={(d) => {
                                        this.delayFetch = true;
                                        this.props.setParams({start_date: d});
                                    }}/>
                            </div>
                            <label>-</label>
                            <div className="datepicker-wrapper right-datepicker">
                                <DatePicker className="form-control"
                                    data-placement="right"
                                    placeholderText={translate('to', {scope: 'placeholders'})}
                                    dateFormat={DATE_FORMAT}
                                    minDate={this.props.params.start_date}
                                    maxDate={moment()}
                                    isClearable={true}
                                    showMonthDropdown
                                    selected={this.props.params.end_date}
                                    selectsEnd
                                    startDate={this.props.params.start_date}
                                    endDate={this.props.params.end_date}
                                    onChange={(d) => {
                                        this.delayFetch = true;
                                        this.props.setParams({end_date: d});
                                    }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <Pagination changePage={this.props.setParams} limit={this.props.params.limit} page={this.props.params.page} count={this.props.count}/>
                    </div>
                </div>
                <div className="table-wrapper content">
                    <table className="table main-list table-hover">
                        <thead>
                            <tr>
                                <NewHeaderCell className="cell-md nofilter sortable" field="datetime" label={translate('timestamp', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('datetime', {scope: 'tooltips.invoices'})}/>
                                <NewHeaderCell className="cell-sm sortable" field="invoicenumber" label="No."
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('invoice_number', {scope: 'tooltips.invoices'})}>
                                    <div className="input-group">
                                        <input type="search" className="form-control" value={this.props.params.invoicenumber}
                                            onChange={(e) => {
                                                this.delayFetch = true;
                                                this.props.setParams({invoicenumber: e.target.value});
                                            }}/>
                                        <span className="input-group-addon" onClick={() => this.props.setParams({invoicenumber: ''}) }>
                                            { this.props.params.invoicenumber ?
                                                <i className="fa fa-remove" /> :
                                                null
                                            }
                                        </span>
                                    </div>
                                </NewHeaderCell>
                                <NewHeaderCell className="cell-md nofilter sortable" field="status_invoices" label={translate('status', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('click_to_see', {scope: 'tooltips.status'})}>
                                    <Select name="state" value={this.props.params.status || 'all'} clearable={false} searchable={false}
                                        options={mapInvoiceStates()} onChange={(e) => this.props.setParams({status: e.value === 'all' ? '' : e.value})}
                                    />
                                </NewHeaderCell>
                                <NewHeaderCell className="sortable" field="roomid" label={translate('room_id', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('room_id', {scope: 'tooltips.invoices'})}>
                                    <div className="input-group">
                                        <input type="search" className="form-control" value={this.props.params.roomid}
                                            onChange={(e) => {
                                                this.delayFetch = true;
                                                this.props.setParams({roomid: e.target.value});
                                            }}/>
                                        <span className="input-group-addon" onClick={() => this.props.setParams({roomid: ''}) }>
                                            { this.props.params.roomid ?
                                                <i className="fa fa-remove" /> :
                                                null
                                            }
                                        </span>
                                    </div>
                                </NewHeaderCell>
                                <NewHeaderCell className="sortable" field="outlet" label={translate('business_id', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('business_id', {scope: 'tooltips.invoices'})}>
                                    <div className="input-group">
                                        <input type="search" className="form-control" value={this.props.params.outlet}
                                            onChange={(e) => {
                                                this.delayFetch = true;
                                                this.props.setParams({outlet: e.target.value});
                                            }}/>
                                        <span className="input-group-addon" onClick={() => this.props.setParams({outlet: ''}) }>
                                            { this.props.params.outlet ?
                                                <i className="fa fa-remove" /> :
                                                null
                                            }
                                        </span>
                                    </div>
                                </NewHeaderCell>
                                <NewHeaderCell className="cell-md nofilter sortable" field="payment_names" label={translate('payment_names', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('payment_names', {scope: 'tooltips.invoices'})} tooltipIcon={true}>
                                    <Select name="payment-type" value={this.props.params.payment_type} clearable={false} searchable={false}
                                        options={[
                                            {label: translate('all', {scope: 'devices.invoices'}), value: ''},
                                            {label: translate('standard_payment', {scope: 'devices.invoices'}), value: 'False'},
                                            {label: translate('room_payment', {scope: 'devices.invoices'}), value: 'True'},
                                        ]} onChange={(e) => this.props.setParams({payment_type: e.value})}
                                    />
                                </NewHeaderCell>
                                <NewHeaderCell className="sortable" field="tip" label={translate('tip', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('tip', {scope: 'tooltips.invoices'})}>
                                    <div className="input-group">
                                        <input type="search" className="form-control" value={this.props.params.tip}
                                            onChange={(e) => {
                                                this.delayFetch = true;
                                                this.props.setParams({tip: e.target.value});
                                            }}/>
                                        <span className="input-group-addon" onClick={() => this.props.setParams({tip: ''}) }>
                                            { this.props.params.tip ?
                                                <i className="fa fa-remove" /> :
                                                null
                                            }
                                        </span>
                                    </div>
                                </NewHeaderCell>

                                {this.props.columns.map((obj, key) => (

                                    <NewHeaderCell key={key} className="sortable" field={obj.name} label={translate(obj.name, {scope: 'devices.table_columns'})}
                                        currentOrder={this.props.params[obj.name]} onSortChange={getSorting}
                                        tooltipContent={translate(obj.name, {scope: 'tooltips.invoices'})}>
                                        <div className="input-group">
                                            <input type="search" className="form-control" value={this.props.params[obj.name]}
                                                onChange={(e) => {
                                                    this.delayFetch = true;
                                                    this.props.setParams({[obj.name]: e.target.value});
                                                }}/>
                                            <span className="input-group-addon" onClick={() => this.props.setParams({[obj.name]: ''}) }>
                                                { this.props.params[obj.name] ?
                                                    <i className="fa fa-remove" /> :
                                                    null
                                                }
                                            </span>
                                        </div>
                                    </NewHeaderCell>
                                ))}

                                <NewHeaderCell className="sortable" field="total" label={translate('total', {scope: 'devices.table_columns'})}
                                    currentOrder={this.props.params.ordering} onSortChange={getSorting}
                                    tooltipContent={translate('total', {scope: 'tooltips.invoices'})}>
                                    <div className="input-group">
                                        <input type="search" className="form-control" value={this.props.params.total}
                                            onChange={(e) => {
                                                this.delayFetch = true;
                                                this.props.setParams({total: e.target.value});
                                            }}/>
                                        <span className="input-group-addon" onClick={() => this.props.setParams({total: ''}) }>
                                            { this.props.params.total ?
                                                <i className="fa fa-remove" /> :
                                                null
                                            }
                                        </span>
                                    </div>
                                </NewHeaderCell>
                            </tr>
                        </thead>
                        <tbody>
                            {_.isEmpty(this.props.entries) ?
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        {translate('no_match', {scope: 'devices.device_details'})}
                                    </td>
                                </tr>
                                :
                                this.props.entries.map((entry, i) => (
                                    <tr key={i}>
                                        <td>{entry.ts}</td>
                                        <td>{entry.invoicenumber}
                                            <Link
                                                className="icon-wrapper inline-block"
                                                to={`invoices/${entry.id}`}><i className="fa fa-eye"/></Link>
                                        </td>
                                        <td>{entry.status_name}</td>
                                        <td>{entry.roomid}</td>
                                        <td>{entry.outlet}</td>
                                        <td>{entry.payment_names || <em>{translate('room_payment', {scope: 'devices.invoices'})}</em>}</td>
                                        <td className="text-right">{entry.tip} {entry.currency}</td>

                                        {this.props.columns.map((obj, key) => (
                                            <td className="text-right" key={key}>{entry[obj.name]} {entry.currency}</td>
                                        ))}

                                        <td className="text-right">{entry.voided ? '-' + entry.total : entry.total} {entry.currency}</td>
                                    </tr>
                                )
                                )}
                        </tbody>
                    </table>
                </div>
                <div className="row table-footer">
                    <div className="col-xs-4"/>
                    <div className="col-xs-4 text-center">
                        <Pagination changePage={this.props.setParams} limit={this.props.params.limit} page={this.props.params.page} count={this.props.count}/>
                    </div>
                    <div className="col-xs-4 text-right table-panel">
                        <label className="no-input select-wrapper pagination-select">
                            <span className="label-title">{translate('per_page', {scope: 'devices'})}</span>
                            <Select value={this.props.params.limit} options={PAGE_SIZE_OPTIONS} clearable={false} searchable={false}
                                onChange={(e) => {
                                    if (this.props.params.limit !== e.value) {
                                        const new_page = Math.floor((this.props.params.page - 1) * this.props.params.limit / e.value) + 1;
                                        this.props.setParams({limit: e.value, page: new_page});
                                    }
                                }}
                            />
                        </label>
                    </div>
                </div>

            </div>
        );
    }
}

DeviceInvoices.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    clearParams: PropTypes.func.isRequired,
    setParams: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    entries: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    syncing: PropTypes.bool.isRequired,
    fetchInvoices: PropTypes.func.isRequired,
    downloadInvoices: PropTypes.func.isRequired,
    syncInvoices: PropTypes.func.isRequired,
    selectableColumns: PropTypes.array.isRequired,
    addColumn: PropTypes.func.isRequired,
    removeColumn: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
    downloadInvoices: makeAction(DOWNLOAD_INVOICES),
    fetchInvoices: makeAction(FETCH_INVOICES),
    clearParams: makeAction(INVOICES_CLEAR_PARAMS),
    setParams: makeAction(INVOICES_SET_PARAMS),
    syncInvoices: makeAction(RE_SYNC),
    addColumn: makeAction(INVOICES_LIST_ADD_COLUMN),
    removeColumn: makeAction(INVOICES_LIST_REMOVE_COLUMN),
};

export default connect(deviceInvoices, mapDispatchToProps)(withRouter(DeviceInvoices));
