import PropTypes from 'prop-types';
import React from 'react';

const SectionSwitch = ({label, setInput, section, sectionName, fieldName, negate = false, forwBlock = false, inverse = false, innerClass = ''}) => (
    <React.Fragment>
        {label ?
            <div className="form-group">
                <label className="control-label col-xs-2">{label}:</label>
                <div className="col-xs-10">
                    <div
                        className={'onoffswitch' + (forwBlock ? ' forw-block' : '') + (inverse ? ' inverse' : '') + ' ' + innerClass}>
                        <input type="checkbox"
                            onChange={() => setInput(
                                sectionName ?
                                    {
                                        [sectionName]: {
                                            ...section,
                                            [fieldName]: !section[fieldName],
                                        },
                                    }
                                    :
                                    {
                                        ...section,
                                        [fieldName]: !section[fieldName],
                                    }
                            )}
                            id={'switch_' + fieldName + '_' + section.id}
                            checked={negate ? !section[fieldName] : section[fieldName]}
                            className="onoffswitch-checkbox"
                        />
                        <label className="onoffswitch-label" htmlFor={'switch_' + fieldName + '_' + section.id}>
                            <span className="onoffswitch-inner"/>
                            <span className="onoffswitch-switch"/>
                        </label>
                    </div>
                </div>
            </div>
            :
            <div
                className={'onoffswitch' + (forwBlock ? ' forw-block' : '') + (inverse ? ' inverse' : '') + ' ' + innerClass}>
                <input type="checkbox"
                    onChange={() => setInput(
                        sectionName ?
                            {
                                [sectionName]: {
                                    ...section,
                                    [fieldName]: !section[fieldName],
                                },
                            }
                            :
                            {
                                ...section,
                                [fieldName]: !section[fieldName],
                            }
                    )}
                    id={'switch_' + fieldName + '_' + section.id}
                    checked={negate ? !section[fieldName] : section[fieldName]}
                    className="onoffswitch-checkbox"
                />
                <label className="onoffswitch-label" htmlFor={'switch_' + fieldName + '_' + section.id}>
                    <span className="onoffswitch-inner"/>
                    <span className="onoffswitch-switch"/>
                </label>
            </div>
        }
    </React.Fragment>
);

SectionSwitch.propTypes = {
    label: PropTypes.string,
    setInput: PropTypes.func,
    section: PropTypes.object,
    sectionName: PropTypes.string,
    fieldName: PropTypes.string,
    negate: PropTypes.bool,
    forwBlock: PropTypes.bool,
    inverse: PropTypes.bool,
    innerClass: PropTypes.string,
};

export default SectionSwitch;
