import * as actionTypes from '../actionTypes';

const DEFAULT_STATE = {
    list: [],
};

const deviceConfigurationsReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return DEFAULT_STATE;
        }
        case actionTypes.FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE_SUCCESS: {
            return {
                ...state,
                list: action.payload.results,
                inProgress: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default deviceConfigurationsReducer;
