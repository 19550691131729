import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format'; // eslint-disable-line no-unused-vars

export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm:ss';

export const formatDate = (date, format = `${DATE_FORMAT} ${TIME_FORMAT}`) => moment(date).format(format);

export const formatDuration = (date) => moment.duration(parseInt(date, 10), 'seconds').format('d[d] hh[h ]mm[m ]ss', {trim: false});

export const formatAmount = (amount) => Intl.NumberFormat('de-DE', {style: 'decimal', minimumFractionDigits: 2}).format(Number(amount));

// Returns array [host, port]
export const formatUrl = (url) => {
    if (url === '') {
        return url;
    }
    const result = url.match(/^([a-z]+:\/\/)?([^:/]+)(:\d+)?/); // eslint-disable-line require-unicode-regexp
    if (!(typeof result[3] === 'undefined')) {
        // Cutting second element from ':port' to 'port'
        return [result[2], result[3].substr(1)];
    }
    return [result[2], 80];
};
