import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import * as actionTypes from '../../../redux/actionTypes';
import makeAction from '../../../redux/actions/common/makeAction';

import PluginsHeader from './sections/plugins/pluginsHeader';
import FormFooter from './footer';
import SectionInput from './rows/valueTypes/SectionInput';
import PluginsList from './sections/plugins/pluginsList';

// TODO: rename, make function-based; refactor onSubmit
class Form extends React.Component {
    render() {
        if (!Object.keys(this.props.config).length) {
            return <div />;
        }
        const clickSave = () => {
            const save = this.props.handleSave;
            save({
                data: this.props.deviceConfiguration,
                callback: (location) => this.props.history.push(location),
            });
        };

        return (
            <form className="form-horizontal" onSubmit={(event) => {
                event.preventDefault();
                clickSave();
            }}>
                <div className="details-content">
                    <SectionInput label="Name" setInput={this.props.changeInput} section={this.props.config}
                        fieldName="name"/>
                </div>
                <div className="details-content">
                    <PluginsHeader/>
                    <PluginsList plugins={this.props.config.plugins}/>
                </div>
                <FormFooter id={this.props.id}
                    config={this.props.config}
                    deleteConfig={this.props.deleteConfig}
                    editMode={this.props.editMode}
                    link={this.props.link}
                    deviceEdit={this.props.deviceEdit}
                    clickSave={clickSave}
                />
            </form>
        );
    }
}

Form.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    config: PropTypes.object.isRequired,
    link: PropTypes.string,
    handleSave: PropTypes.func.isRequired,
    deleteConfig: PropTypes.func,
    changeInput: PropTypes.func,
    id: PropTypes.number,
    editMode: PropTypes.bool,
    deviceEdit: PropTypes.bool,
    deviceConfiguration: PropTypes.object,
};

const mapStateToProps = (state) => ({
    deviceConfiguration: state.deviceConfiguration,
});

const mapDispatchToProps = {
    changeInput: makeAction(actionTypes.SET_CONFIGURATION),
    handleSave: makeAction(actionTypes.SAVE_DEVICE_CONFIGURATION),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Form));
