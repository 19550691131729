import PropTypes from 'prop-types';
import React from 'react';
import ClipboardJS from 'clipboard';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import translate from 'counterpart';
import $ from 'jquery';

import SectionInput from '../../../conf_template/form/rows/valueTypes/SectionInput';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import {deviceGeneral} from '../../../../redux/selectors/device/general';
import ModalStatus from '../../../common/modals/ModalStatus.jsx';
import tooltip from '../../../common/tooltip';
import Switch from '../../../conf_template/form/rows/valueTypes/Switch';
import * as permissions from '../../../../configuration/permissions';
import PermissionCheck from '../../../authorization/PermissionCheck';
import CopyButton from '../CopyButton';

class DeviceGeneral extends React.Component {
    componentDidMount() {
        this.clipboard = new ClipboardJS('.clipboard');
    }

    componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    }

    render() {
        return (
            <div className="col-lg-5 col-md-4 col-xs-8">
                <h3>
                    {translate('device', {scope: 'devices.device_details'})}
                    <OverlayTrigger overlay={tooltip(translate('explanation', {scope: 'tooltips.device_details'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </h3>
                <dl className="dl-horizontal dl-casual">
                    <dt>
                        {translate('name', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd>
                        <span id="name" className="mode-read">
                            {this.props.name}
                        </span>
                        <span id="nameEdit" className="mode-edit">
                            <SectionInput
                                setInput={this.props.setConfiguration}
                                section={this.props.config}
                                fieldName="name"
                                error={!this.props.validation.name_not_empty || !this.props.validation.name_valid}
                            />
                            {!this.props.validation.name_not_empty &&
                            <OverlayTrigger overlay={tooltip(translate('field_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                            }
                            {!this.props.validation.name_valid &&
                            <OverlayTrigger overlay={tooltip(translate('max_characters', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                            }
                        </span>
                    </dd>
                    <dt>
                        {translate('device_id', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('device_id', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.id}
                    </dd>
                    <PermissionCheck permission={permissions.MANAGE_COMPANY}>
                        <dt>
                            {translate('company', {scope: 'devices.device_details'})}:
                            <OverlayTrigger overlay={tooltip(translate('company', {scope: 'tooltips.device_details'}))}>
                                <i className="inline-block fa fa-info-circle"/>
                            </OverlayTrigger>
                        </dt>
                        <dd className="uneditable">
                            {this.props.company}
                        </dd>
                    </PermissionCheck>
                    <dt>
                        {translate('device_status', {scope: 'devices.device_details'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('device_status', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.active}
                    </dd>
                    <dt>
                        {translate('usage_status', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('prompt', {scope: 'tooltips.status'}))}>
                            <i className="inline-block fa fa-info-circle"
                                onClick={() => $('#statusModal').modal('toggle')}/>
                        </OverlayTrigger>
                    </dt>
                    <dd id="status" className="uneditable">
                        {this.props.status}
                    </dd>
                    <dt>
                        {translate('device_ip', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('device_ip', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.pos_network_ip}
                    </dd>
                    <dt>
                        {translate('internet_ip', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('internet_ip', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.external_ip}
                    </dd>
                    <dt>
                        {translate('business_unit_id', {scope: 'devices.device_details'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('business_unit_id', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd>
                        <span id="businessID" className="mode-read">
                            {this.props.business_id}
                        </span>
                        <span className="mode-edit" id="editBusinessID">
                            <SectionInput
                                setInput={this.props.setConfiguration}
                                section={this.props.config}
                                fieldName="business_id"
                                error={!this.props.validation.business_id_valid}/>
                            {!this.props.validation.business_id_valid &&
                            <OverlayTrigger overlay={tooltip(translate('number_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                            }
                        </span>
                    </dd>
                    <dt>
                        {translate('operator_id', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('operator_id', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd>
                        <span id="operatorID" className="mode-read">
                            {this.props.operator_id}
                        </span>
                        <span id="editOperatorID" className="mode-edit">
                            <SectionInput
                                setInput={this.props.setConfiguration}
                                section={this.props.config}
                                fieldName="operator_id"
                                error={!this.props.validation.operator_id_valid}/>
                            {!this.props.validation.operator_id_valid &&
                            <OverlayTrigger overlay={tooltip(translate('number_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                            }
                        </span>
                    </dd>
                    <dt>
                        {translate('software_version', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('soft_version', {scope: 'tooltips.status'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.soft_version}
                    </dd>
                    <dt>
                        {translate('mac_address', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('mac_address', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.mac}
                    </dd>
                    <dt>
                        {translate('serial_number', {scope: 'devices.device_details'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('serial_number', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">
                        {this.props.serial}
                    </dd>
                    <dt>
                        {translate('external_domain', {scope: 'devices.device_details'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('external_domain', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                        <CopyButton target={`externalDomain${this.props.id}`}/>
                    </dt>
                    <OverlayTrigger overlay={tooltip(this.props.external_domain)}>
                        <dd id={`externalDomain${this.props.id}`}
                            className="uneditable">{this.props.external_domain}</dd>
                    </OverlayTrigger>
                    <dt>
                        {translate('internal_domain', {scope: 'devices.device_details'})}:
                        <OverlayTrigger
                            overlay={tooltip(translate('internal_domain', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                        <CopyButton target={`internalDomain${this.props.id}`}/>
                    </dt>
                    <OverlayTrigger overlay={tooltip(this.props.domain)}>
                        <dd id={`internalDomain${this.props.id}`} className="uneditable">{this.props.domain}</dd>
                    </OverlayTrigger>
                    <dt>
                        {translate('client_key', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('client_key', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                        <CopyButton target={'clientKey'}/>
                    </dt>
                    <dd>
                        <span id="clientKey" className="mode-read">
                            {this.props.client_key}
                        </span>
                        <span className="mode-edit" id="editClientKey">
                            <SectionInput
                                setInput={this.props.setConfiguration}
                                section={this.props.config}
                                fieldName="client_key"/>
                        </span>
                    </dd>
                    <dt>
                        {translate('system_key', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('system_key', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                        <CopyButton target={'systemKey'}/>
                    </dt>
                    <dd>
                        <span id="systemKey" className="mode-read">
                            {this.props.system_key}
                        </span>
                        <span className="mode-edit" id="editSystemKey">
                            <SectionInput
                                setInput={this.props.setConfiguration}
                                section={this.props.config}
                                fieldName="system_key"/>
                        </span>
                    </dd>
                    <dt>
                        {translate('machine', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('machine', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <OverlayTrigger overlay={tooltip(this.props.machine_info)}>
                        <dd className="uneditable">{this.props.machine_info}</dd>
                    </OverlayTrigger>
                    <dt>
                        {translate('system', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('system', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">{this.props.system_info}</dd>
                    <dt>
                        {translate('cpu', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('cpu', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <OverlayTrigger overlay={tooltip(this.props.cpu_info)}>
                        <dd className="uneditable">{this.props.cpu_info}</dd>
                    </OverlayTrigger>
                    <dt>
                        {translate('uptime', {scope: 'devices.device_details'})}:
                        <OverlayTrigger overlay={tooltip(translate('uptime', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd className="uneditable">{this.props.uptime}</dd>

                    <dt>
                        {translate('relevancy', {scope: 'devices.device_details'})}
                        <OverlayTrigger overlay={tooltip(translate('relevancy', {scope: 'tooltips.device_details'}))}>
                            <i className="inline-block fa fa-info-circle"/>
                        </OverlayTrigger>
                    </dt>
                    <dd>
                        <span id="relevant" className="mode-read">
                            {this.props.relevant}
                        </span>
                        <span id="editRelevant" className="mode-edit">
                            <PermissionCheck
                                permission={permissions.CHANGE_RELEVANT}
                                fallbackComponent={
                                    <span className="relevant-display">
                                        {this.props.relevant}
                                    </span>
                                }
                            >
                                <Switch
                                    setInput={this.props.setConfiguration}
                                    section={this.props.config}
                                    fieldName="relevant"
                                    innerClass="relevant"
                                />
                            </PermissionCheck>
                        </span>
                    </dd>
                </dl>
                <ModalStatus/>
            </div>
        );
    }
}

DeviceGeneral.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    company: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    pos_network_ip: PropTypes.string.isRequired,
    external_ip: PropTypes.string.isRequired,
    business_id: PropTypes.string.isRequired,
    operator_id: PropTypes.string.isRequired,
    soft_version: PropTypes.string.isRequired,
    mac: PropTypes.string.isRequired,
    serial: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    external_domain: PropTypes.string.isRequired,
    client_key: PropTypes.string.isRequired,
    system_key: PropTypes.string.isRequired,
    machine_info: PropTypes.string.isRequired,
    system_info: PropTypes.string.isRequired,
    cpu_info: PropTypes.string.isRequired,
    uptime: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    setConfiguration: PropTypes.func.isRequired,
    validation: PropTypes.object.isRequired,
    relevant: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
    setConfiguration: makeAction(actionTypes.SET_CONFIGURATION),
};

export default withRouter(connect(deviceGeneral, mapDispatchToProps)(DeviceGeneral));
