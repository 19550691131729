import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import * as actionTypes from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';
import EditTemplate from '../components/devices/details/printer_templates/EditTemplate';
import translate from 'counterpart';

// TODO: use only get data, not render; check device.in_progress
class PrintTemplateEditPage extends React.Component {
    componentDidMount() {
        if (this.props.match.params.templateId !== 'new') {
            this.props.pullDeviceFile({id: this.props.match.params.templateId});
        }
    }

    render() {
        return (
            this.props.device.in_progress === true ?
                <div>{translate('device_change', {scope: 'devices.printers'})}</div>
                :
                <EditTemplate
                    device={this.props.device}
                    deviceFile={this.props.deviceFile}
                />
        );
    }
}

PrintTemplateEditPage.propTypes = {
    device: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string.isRequired,
            templateId: PropTypes.any,
        }).isRequired,
    }).isRequired,
    pullDeviceFile: PropTypes.func.isRequired,
    deviceFile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    device: state.device.device,
    deviceFile: state.deviceFile,
});

const mapDispatchToProps = {
    pullDeviceFile: makeAction(actionTypes.PULL_DEVICE_FILE),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrintTemplateEditPage));
