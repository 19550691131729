import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import translate from 'counterpart';

import makeAction from '../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../redux/actionTypes';
import Pagination from '../../common/react-pagination';
import {devicesListHeader} from '../../../redux/selectors/devices/devicesListHeader';
import tooltip from '../../common/tooltip';
import ManageColumnsButton from './ManageColumnsButton';

const DevicesListHeader = ({page, limit, count, changePage, clearFilters}) => {
    const from = (page - 1) * limit + 1;
    const to = Math.min(page * limit, count);

    return (
        <div className="row table-header">
            <div className="col-xs-4">
                <h2>{translate('table_header', {scope: 'devices'})}
                    <span className="extra-info">{translate('table_count', {
                        scope: 'devices',
                        from,
                        to,
                        count,
                    })}</span>
                    <OverlayTrigger overlay={tooltip(translate('explanation', {scope: 'tooltips.device_list'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </h2>
            </div>
            <div className="col-xs-4">
                <Pagination {...{changePage, limit, page, count}}/>
            </div>
            <div className="col-xs-4 text-right">
                <div className="table-panel">
                    <OverlayTrigger placement="top" overlay={tooltip(translate('clear_filters', {scope: 'tooltips.device_list'}))}>
                        <button className="btn btn-info" onClick={() => clearFilters()}>
                            {translate('clear_filters', {scope: 'devices'})}
                        </button>
                    </OverlayTrigger>
                    <ManageColumnsButton />
                </div>
            </div>
        </div>
    );
};

DevicesListHeader.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    changeFilters: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    changePage: makeAction(actionTypes.DEVICES_LIST_PAGINATION),
    clearFilters: makeAction(actionTypes.DEVICES_LIST_CLEAR_FILTERS),
    changeFilters: makeAction(actionTypes.DEVICES_LIST_CHANGE_FILTERS),
};

export default connect(devicesListHeader, mapDispatchToProps)(DevicesListHeader);
