import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import {DEVICE_DETAILS_PAGE} from '../../../../configuration/paths';
import {withRouter} from 'react-router';
import translate from 'counterpart';

const EditSubmitButton = ({device_id, printer, updatePrinterAction, history}) => {
    const callback = () => history.push(DEVICE_DETAILS_PAGE(device_id));
    return (
        <button type="button" className="pull-right btn btn-info"
            onClick={() => updatePrinterAction(
                {
                    id: device_id,
                    printer: printer,
                    callback: callback,
                }
            )}>
            {translate('save', {scope: 'devices.printers'})}
        </button>
    );
};

EditSubmitButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    device_id: PropTypes.string.isRequired,
    printer: PropTypes.object.isRequired,
    updatePrinterAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    updatePrinterAction: makeAction(actionTypes.UPDATE_PRINTER),
};

export default connect(null, mapDispatchToProps)(withRouter(EditSubmitButton));
