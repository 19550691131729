import React from 'react';
import PropTypes from 'prop-types';

class PluginExtender extends React.Component {
    render() {
        return (
            <React.Fragment>
                <button data-toggle="dropdown" className="btn btn-primary dropdown-toggle" type="button" id="ping">
                    <span className="glyphicon glyphicon-chevron-down" aria-hidden="true"/>
                </button>
                <ul className="dropdown-menu">
                    {this.props.urls.map((obj, key) => (
                        <li key={key} className="dropdown-item"
                            onClick={
                                () => {
                                    if (this.props.setIP) {
                                        this.props.setIP(obj.url);
                                    } else {
                                        this.props.setAddress(obj.url);
                                        this.props.setPort(obj.port);
                                    }
                                }
                            }
                        >
                            {obj.name}
                        </li>
                    ))}

                </ul>
            </React.Fragment>
        );
    }
}

PluginExtender.propTypes = {
    urls: PropTypes.array.isRequired,
    setIP: PropTypes.func.isRequired,
    setAddress: PropTypes.func.isRequired,
    setPort: PropTypes.func.isRequired,
};

export default PluginExtender;
