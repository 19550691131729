import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';

import * as paths from '../../../../configuration/paths';
import {lastErrors} from '../../../../redux/selectors/device/lastErrors';
import tooltip from '../../../common/tooltip';

const LastErrors = ({log_entries, id, filtered, empty}) => (
    <div className="col-lg-3 col-md-4 col-xs-12">
        <h3>
            {translate('last_errors', {scope: 'devices.device_details'})}
            <OverlayTrigger overlay={tooltip(translate('last_errors', {scope: 'tooltips.device_details'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </h3>
        <div className="table-wrapper content error-table">
            <table className="table main-list table-hover">
                <thead>
                    <tr>
                        <OverlayTrigger placement="top" overlay={tooltip(translate('timestamp', {scope: 'tooltips.tools'}))}>
                            <th>
                                <span className="th-label">
                                    {translate('timestamp', {scope: 'devices.device_details'})}
                                </span>
                            </th>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={tooltip(translate('message', {scope: 'tooltips.log'}))}>
                            <th>
                                <span className="th-label">
                                    {translate('error', {scope: 'devices.device_details'})}
                                </span>
                            </th>
                        </OverlayTrigger>
                    </tr>
                </thead>
                <tbody>
                    {log_entries.length === 0 ?
                        <tr>
                            <td colSpan="2" className="text-center"> {translate('no_errors', {scope: 'devices.device_details'})}</td>
                        </tr>
                        :
                        empty ?
                            <tr>
                                <td colSpan="2" className="text-center"> {translate('no_last_errors', {scope: 'devices.device_details'})}</td>
                            </tr>
                            :
                            filtered.map((entry, key) => (
                                <tr key={key}>
                                    <td>{entry.ts}</td>
                                    <td>{entry.message}</td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>
        </div>
        <OverlayTrigger overlay={tooltip(translate('last_errors_link', {scope: 'tooltips.device_details'}))}>
            <Link className="under-table" to={paths.DEVICE_LOG_PAGE(id)}>
                {translate('last_errors_link', {scope: 'devices.device_details'})}
                <i className="fa fa-angle-right"/>
            </Link>
        </OverlayTrigger>
    </div>
);

LastErrors.propTypes = {
    log_entries: PropTypes.array,
    filtered: PropTypes.array,
    empty: PropTypes.bool.isRequired,
    id: PropTypes.string,
};

export default connect(lastErrors)(LastErrors);
