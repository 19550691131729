import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

class EditableCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editable: false};
        this.textInput = null;
    }

    onClick() {
        this.setState({
            editable: true,
        });
    }

    onEditFinished(value) {
        this.props.onEditFinished(value);
        this.setState({
            editable: false,
        });
    }

    render() {
        return (
            this.state.editable ? (
                <input
                    ref={(input) => {
                        if (input) {
                            this.textInput = input;
                            this.textInput.focus();
                        }
                    }}
                    onBlur={() => this.onEditFinished(this.textInput.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            this.onEditFinished(this.textInput.value);
                        }
                    }}
                    type="text"
                    defaultValue={this.props.value} />
            ) : (
                <div onClick={() => this.onClick()}>
                    {this.props.value} &nbsp;
                </div>
            )
        );
    }
}

EditableCell.propTypes = {
    value: PropTypes.string.isRequired,
    onEditFinished: PropTypes.func.isRequired,
};

export default connect()(EditableCell);
