import React from 'react';
import ModalStatus from './ModalStatus';
import ModalStatusInvoices from './ModalStatusInvoices';


// TODO: move all modals to this component

const NewHeaderCell = () => (
    <React.Fragment>
        <ModalStatus />
        <ModalStatusInvoices />
    </React.Fragment>
);

NewHeaderCell.propTypes = {
};

export default NewHeaderCell;
