import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import $ from 'jquery';
import translate from 'counterpart';
import makeAction from '../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../redux/actionTypes';


const SelectPluginConfigModal = ({enterConfigs, configID, selectConfigID, fetchConfiguration}) => (
    <div id="pluginConfigModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">×</button>
                    <h3 className="modal-title">Choose configuration</h3>
                </div>
                <div className="modal-body">
                    <DropdownButton id="preconf-dropdown" title={configID.name || 'Pre Configuration'}>
                        {enterConfigs.map((obj, index) => (
                            <MenuItem id={'preconf-menu-item' + obj.id}
                                key={index}
                                onClick={() => {
                                    selectConfigID(obj);
                                    fetchConfiguration({id: obj.id});
                                }}
                                eventKey={obj.id}>{obj.name}
                            </MenuItem>
                        ))}
                    </DropdownButton>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default"
                        data-toggle="modal" data-target="#pluginConfigWarningModal"
                        data-backdrop="static" data-keyboard="false"
                        onClick={() => {
                            $('#pluginConfigModal').modal('hide');
                        }}
                    >
                        OK
                    </button>
                    <button type="button" className="btn btn-default" data-dismiss="modal">
                        {translate('close', {scope: 'modals'})}
                    </button>
                </div>
            </div>
        </div>
    </div>
);

SelectPluginConfigModal.propTypes = {
    enterConfigs: PropTypes.arrayOf(
        PropTypes.object
    ),
    configID: PropTypes.object,
    selectConfigID: PropTypes.func.isRequired,
    fetchConfiguration: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    selectConfigID: makeAction(actionTypes.SELECT_PLUGIN_CONFIG),
    fetchConfiguration: makeAction(actionTypes.FETCH_CONFIGURATION),
};

const mapStateToProps = (state) => ({
    enterConfigs: state.deviceConfigurationsToProvisione.list,
    configID: state.device.selected_plugin_config,
    device: state.device.device,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectPluginConfigModal);
