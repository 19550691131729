import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import translate from 'counterpart';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import SectionInput from '../../../../conf_template/form/rows/valueTypes/SectionInput';
import SectionSwitch from '../../../../conf_template/form/rows/valueTypes/SectionSwitch';
import {AGGREGATED_ITEMS_OPTIONS, CONSTRAINT_OPTIONS} from '../../../../../configuration/constants';
import SectionSelect from '../../../../conf_template/form/rows/valueTypes/SectionSelect';
import tooltip from '../../../../common/tooltip';
import CopyButton from '../../CopyButton';
import CollapsingGroup from '../CollapsingGroup';

const OraclePlugin = ({plugin, endpoint_url, setPlugin}) => (
    <div>
        <CollapsingGroup id="mainInfo" name="Main Plugin Information">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('name', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="name"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('type', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6} >
                    {plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('is_active', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('is_active', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="is_active"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('endpoint_url', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('endpoint_url', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                    <CopyButton target={`endpoint_url_${plugin.id}`} />
                </Col>
                <Col xs={6} id={`endpoint_url_${plugin.id}`}>{endpoint_url || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="pmsConnection" name="PMS Connection">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('host', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('host', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="host"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('port', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger overlay={tooltip(translate('port', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="port" number={true}/>
                </Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="timeouts" name="Timeouts, Intervals & Amounts">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('pms_connection_interval', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('pms_connection_interval', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="check_interval" number={true}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_interval', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('shift_close_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="shift_close_check_interval"
                        number={true}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_retry_interval', {scope: 'devices.plugins'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('shift_close_retry_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="shift_close_retry_interval"
                        number={true}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_max_retry_attempts', {scope: 'devices.plugins'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('shift_close_max_retry_attempts', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="shift_close_max_retry_attempts"
                        number={true}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('max_rounding_fix', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('max_rounding_fix', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionInput setInput={setPlugin} section={plugin} fieldName="max_rounding_fix" number={true}/>
                </Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="features" name="Features">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('split_discount', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('split_discount', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="split_discount"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('negative_postings', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('negative_postings', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="allow_negative_postings"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('block_zero_payments', {scope: 'devices.plugins'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('block_zero_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="block_zero_payments"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('auto_grab_outlet_id', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('auto_grab_outlet_id', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="business_id_outlet"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('duplicate_check', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('duplicate_check', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="disable_duplicate_check" negate/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('serviceperiod', {scope: 'devices.plugins'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('serviceperiod', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="enable_serviceperiod"/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close', {scope: 'devices.plugins.oracle'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('shift_close', {scope: 'tooltips.hotel_systems.oracle_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="disable_shift_close" negate/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('internal', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('internal', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="forward_internal" forwBlock/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('nonturnover', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('nonturnover', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSwitch setInput={setPlugin} section={plugin} fieldName="forward_nonturnover" forwBlock/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.constraints_behavior'})}:
                    <OverlayTrigger overlay={
                        tooltip(translate('constraints_behavior', {scope: 'tooltips.hotel_systems.plugin'}) +
                            '\n' + translate('all', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('non_zero', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_non_zero_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('items', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_sub_items_only', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('main', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_constraint_main_item', {scope: 'tooltips.hotel_systems.items_behaviour'})
                        )}
                    >
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSelect setInput={setPlugin} section={plugin} fieldName="constraints_behavior"
                        options={CONSTRAINT_OPTIONS}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.aggregated_items_behavior'})}:
                    <OverlayTrigger
                        overlay={tooltip(translate('aggregated_items_behavior', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    <SectionSelect setInput={setPlugin} section={plugin} fieldName="aggregated_items_behavior"
                        options={AGGREGATED_ITEMS_OPTIONS}/>
                </Col>
            </Row>
        </CollapsingGroup>
    </div>
);

OraclePlugin.propTypes = {
    plugin: PropTypes.object.isRequired,
    endpoint_url: PropTypes.string,
    setPlugin: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setPlugin: makeAction(actionTypes.SET_PLUGIN),
};

export default connect(null, mapDispatchToProps)(OraclePlugin);
