import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';

const EnabledExtra = ({setEthernet, objEdit, ethernets}) => (
    <div className="onoffswitch mode-edit">
        <input type="checkbox"
            onChange={() => {
                setEthernet({
                    ...objEdit,
                    disabled: ethernets && ethernets.length > 1 && !objEdit.disabled,
                });
            }}
            name="onoffswitch"
            className="onoffswitch-checkbox"
            id="switchExtra"
            checked={ethernets && ethernets.length > 1 && !objEdit.disabled}/>
        <label className="onoffswitch-label" htmlFor="switchExtra">
            <span className="onoffswitch-inner"/>
            <span className="onoffswitch-switch"/>
        </label>
        {objEdit.disabled}
    </div>
);

EnabledExtra.propTypes = {
    setEthernet: PropTypes.func.isRequired,
    objEdit: PropTypes.object,
    ethernets: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
    setEthernet: makeAction(actionTypes.SET_ETHERNET),
};


const mapStateToProps = (state) => ({
    objEdit: state.deviceConfiguration.ethernets[1],
    ethernets: state.deviceConfiguration.ethernets,
});

export default connect(mapStateToProps, mapDispatchToProps)(EnabledExtra);
