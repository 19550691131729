import {createSelector} from 'reselect';
import {
    companiesOptions,
    devicesList,
    multiSelected,
    selectedAction,
    selectedCompany,
    softList,
} from './stateSelector';
import _ from 'lodash';
import {optionsSelector} from '../device/actions';
import {checkStatus} from '../../../configuration/validation';

export const checkedDevices = createSelector(
    devicesList,
    (list) => _.filter(list, (device) => device.checked === true)
);

export const notConnectedSelected = createSelector(
    devicesList,
    (list) => (_.filter(list, (device) => device.checked === true && !checkStatus(device.status))).length > 0
);


export const multiActionsBar = createSelector(
    checkedDevices,
    softList,
    multiSelected,
    notConnectedSelected,
    selectedAction,
    optionsSelector,
    companiesOptions,
    selectedCompany,
    (checked, soft, multi, notConnected, action, options, companies, company) => ({
        checked,
        options,
        soft,
        isDeviceSelected: checked.length > 0,
        isSoftSelected: multi.hasOwnProperty('value'),
        multi,
        notConnected,
        action,
        companies: [{label: 'Unassign', value: null}, ...companies],
        company,
        softValue: action.value === 'upgrade' || action.value === 'timed_upgrade' ? multi.value : null,
        softDisabled: !(checked.length > 0) || notConnected || (action.value !== 'upgrade' && action.value !== 'timed_upgrade'),
        applyDisabled: !(checked.length > 0) || (!action.value || (action.value === 'upgrade' || action.value === 'timed_upgrade') && !multi.hasOwnProperty('value'))
            || (action.value === 'upgrade' || action.value === 'timed_upgrade') && notConnected || action.value === 'reboot' && notConnected,
        dataTarget:
            action.value === 'upgrade' ? '#installModal' :
                action.value === 'reboot' ? '#rebootModal' :
                    action.value === 'timed_upgrade' ? '#timedUpgradeModal' :
                        action.value === 'manage_company' ? '#companyModal' : '#removeModal',
    })
);
