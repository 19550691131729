import {createSelector} from 'reselect';

export const getDeviceBranch = (state) => state.device.device;

export const getDeviceConfiguration = createSelector(
    getDeviceBranch,
    (device) => device.device_configuration
);

export const softBranch = (state) => state.soft;
export const softList = createSelector(
    softBranch,
    (branch) => branch.list
);

export const devicesBranch = (state) => state.devices;
export const companiesBranch = (state) => state.companies;

export const devicesInProgress = createSelector(
    devicesBranch,
    (branch) => branch.inProgress
);
export const devicesChecked = createSelector(
    devicesBranch,
    (branch) => branch.checked
);
export const devicesList = createSelector(
    devicesBranch,
    (branch) => branch.list
);
export const devicesSort = createSelector(
    devicesBranch,
    (branch) => branch.ordering
);
export const devicesLimit = createSelector(
    devicesBranch,
    (branch) => branch.limit
);
export const devicesPage = createSelector(
    devicesBranch,
    (branch) => branch.page
);
export const devicesCount = createSelector(
    devicesBranch,
    (branch) => branch.count
);

export const devicesFilters = createSelector(
    devicesBranch,
    (branch) => branch.filters
);

export const devicesColumns = createSelector(
    devicesBranch,
    (branch) => branch.columns
);

export const multiSelected = createSelector(
    devicesBranch,
    (branch) => branch.multi_soft_version
);

export const companiesSelector = createSelector(
    companiesBranch,
    (branch) => branch.list
);

export const selectedAction = createSelector(
    devicesBranch,
    (branch) => branch.selected_action
);

export const selectedCompany = createSelector(
    devicesBranch,
    (branch) => branch.selected_company
);

export const companiesOptions = createSelector(
    companiesSelector,
    (list) => list.map((company) => ({label: company.name, value: company.id}))
);
