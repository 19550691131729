import {createSelector} from 'reselect';
import {deviceConfiguration, extraEdit, globalEdit, lanEdit} from '../deviceConfiguration/stateSelector';
import {
    checkStatus,
    isNotEmpty, isValidID, isValidIP, isValidIPs, isValidName, isValidNumber,
    isValidProxy,
} from '../../../configuration/validation';
import _ from 'lodash';
import {deviceDetails} from './stateSelector';
import {editMode} from '../navigation/stateSelector';

export const editableStatus = createSelector(
    deviceDetails,
    (device) => checkStatus(device.status)
);

export const validationSelector = createSelector(
    deviceConfiguration,
    lanEdit,
    extraEdit,
    globalEdit,
    editableStatus,
    editMode,
    (config, lan, extra, global, status, edit) => ({
        name_not_empty: !edit || isNotEmpty(config.name),
        name_valid: !edit || isValidName(config.name),
        business_id_valid: !edit || isValidID(config.business_id),
        operator_id_valid: !edit || isValidID(config.operator_id),

        server_thread_pool_valid: !edit || !status || isValidNumber(global.server_thread_pool),
        server_socket_host_valid: !edit || !status || isValidIP(global.server_socket_host),
        server_socket_port_valid: !edit || !status || isValidNumber(global.server_socket_port),
        proxy_valid: !edit || !status || isValidProxy(config.proxy),
        server_thread_pool_not_empty: !edit || !status || isNotEmpty(global.server_thread_pool),
        server_socket_host_not_empty: !edit || !status || isNotEmpty(global.server_socket_host),
        server_socket_port_not_empty: !edit || !status || isNotEmpty(global.server_socket_port),

        lan_ip_valid: !edit || !status || (lan ? lan.type === 'DHCP' || isValidIP(lan.address) : true),
        lan_mask_valid: !edit || !status || (lan ? lan.type === 'DHCP' || isValidIP(lan.netmask) : true),
        lan_gateway_valid: !edit || !status || (lan ? lan.type === 'DHCP' || isValidIP(lan.gateway) : true),
        lan_dns_valid: !edit || !status || (lan ? lan.type === 'DHCP' || isValidIPs(lan.dns_nameservers) : true),

        extra_ip_valid: !edit || !status || (extra ? extra.disabled || extra.type === 'DHCP' || isValidIP(extra.address) : true),
        extra_mask_valid: !edit || !status || (extra ? extra.disabled || extra.type === 'DHCP' || isValidIP(extra.netmask) : true),
        extra_gateway_valid: !edit || !status || (extra ? extra.disabled || extra.type === 'DHCP' || isValidIP(extra.gateway) : true),
        extra_dns_valid: !edit || !status || (extra ? extra.disabled || extra.type === 'DHCP' || isValidIPs(extra.dns_nameservers) : true),
    })
);

export const notValid = createSelector(
    validationSelector,
    (validation) => _.includes(validation, false)
);
