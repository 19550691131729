export function save(key, object) {
    localStorage.setItem(key, object);
    return object;
}
export function get(key) {
    return localStorage.getItem(key);
}
export function remove(key) {
    localStorage.removeItem(key);
}

export function saveObject(key, object) {
    localStorage.setItem(key, JSON.stringify(Array.from(Object.entries(object))));
    return object;
}
export function getObject(key) {
    const arrayObj = new Map(JSON.parse(localStorage.getItem(key) || '[]'));
    if (arrayObj.size === 0) {
        return null;
    }
    const obj = {};
    for (const [k, v] of arrayObj) {
        obj[k] = v;
    }
    return obj;
}
