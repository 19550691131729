import PropTypes from 'prop-types';
import React from 'react';
import {EDIT_PRINTER_PAGE} from '../../../../configuration/paths';
import {Link} from 'react-router-dom';
import PrinterRemoveButtons from './printerRemoveButtons';
import translate from 'counterpart';
const tableColumnTwo = ({printers_list, device_id}) => (
    <div className="content">
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>{translate('name', {scope: 'devices.printers'})}</th>
                    <th>{translate('model', {scope: 'devices.printers'})}</th>
                    <th>{translate('connection', {scope: 'devices.printers'})}</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {printers_list.map((printer, index) => (
                    printer.installed === true || printer.installed === 'remove_in_progress'
                ) &&
                    <tr className="break-line" key={index}>
                        <td><Link to={EDIT_PRINTER_PAGE(device_id, printer.hash)}>{printer.name}</Link></td>
                        <td>{printer.make_and_model}</td>
                        <td>{printer.connection}</td>
                        <td>
                            <PrinterRemoveButtons printer={printer} device_id={device_id}/>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
);

tableColumnTwo.propTypes = {
    printers_list: PropTypes.arrayOf(
        PropTypes.object
    ),
    device_id: PropTypes.number.isRequired,
};

export default tableColumnTwo;
