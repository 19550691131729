import translate from 'counterpart';
import en from './lang/en.json';
// import de from './lang/de.json';

translate.registerTranslations('en', en);
translate.setLocale('en');

export const SUCCESS = (message, title = translate('success_title', {scope: 'notifications'})) => (
    {
        title: title,
        message: message,
        level: 'success',
        position: 'tr',
    }
);

export const ERROR = (message, title = translate('error_title', {scope: 'notifications'})) => (
    {
        title: title,
        message: message,
        level: 'error',
        position: 'tr',
    }
);

export const INFO = (message, title = translate('info_title', {scope: 'notifications'})) => (
    {
        title: title,
        message: message,
        level: 'info',
        position: 'tr',
    }
);

export const WARNING = (message, title = translate('warning_title', {scope: 'notifications'})) => (
    {
        title: title,
        message: message,
        level: 'warning',
        position: 'tr',
    }
);
