import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import _ from 'lodash';

const DriversSelect = ({select_driver, selected_driver, installed_driver, printer_drivers, edit}) => {
    const select_options = (printer_drivers || []).map((driver) => ({label: driver['make-and-model'], value: driver}));
    return (
        <Select
            value={edit ? {value: '', label: installed_driver['make-and-model']} : _.isEmpty(selected_driver) ? '' : selected_driver}
            options={select_options}
            onChange={(driver) => select_driver(driver)}
            searchable={false}
            clearable={false}
            disabled={edit}
        />
    );
};

DriversSelect.propTypes = {
    printer_drivers: PropTypes.array.isRequired,
    selected_driver: PropTypes.object.isRequired,
    installed_driver: PropTypes.object.isRequired,
    select_driver: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    selected_driver: state.device.selected_driver,
});

const mapDispatchToProps = {
    select_driver: makeAction(actionTypes.SELECT_PRINTER_DRIVER),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DriversSelect);
