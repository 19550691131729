import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';

import {PAGE_SIZE_OPTIONS} from '../../../configuration/constants';
import NewHeaderCell from '../../common/table/newHeaderCell';
import Pagination from '../../common/react-pagination';
import makeAction from '../../../redux/actions/common/makeAction';
import {LOGENTRIES_CLEAR_PARAMS, LOGENTRIES_SET_PARAMS, SET_MODAL_DATA} from '../../../redux/actionTypes';
import {mapErrorGroups, mapErrorCodes} from '../../../services/logger';
import LogDetailsModal from '../../common/modals/LogDetailsModal';
import {deviceLogs} from '../../../redux/selectors/device/logs';
import {DATE_FORMAT} from '../../../services/formatters';
import tooltip from '../../common/tooltip';


const DeviceLog = ({clearParams, entries, params, setParams, setModalData, count, levelOptions}) => {
    const getSorting = (field) => setParams({ordering: field});

    return (<div className="details-content">
        <div className="row table-header">
            <div className="col-md-4 col-xs-5">
                <h3>{translate('title', {scope: 'logging'})}</h3>
            </div>
            <div className="col-md-8 col-xs-7 text-right">
                <div className="table-panel">
                    <OverlayTrigger placement="bottom" overlay={tooltip(translate('clear_filter', {scope: 'tooltips.device_list'}))}>
                        <span className="btn btn-info" onClick={() => clearParams({})}>{translate('clear_filters', {scope: 'devices'})}</span>
                    </OverlayTrigger>
                    <div className="datepicker-wrapper">
                        <DatePicker className="form-control"
                            placeholderText={translate('from', {scope: 'placeholders'})}
                            dateFormat={DATE_FORMAT}
                            maxDate={params.end_date || moment()}
                            isClearable={true}
                            showMonthDropdown
                            selected={params.start_date}
                            selectsStart
                            startDate={params.start_date}
                            endDate={params.end_date}
                            onChange={(d) => setParams({start_date: d})}/>
                    </div>
                    <label>-</label>
                    <div className="datepicker-wrapper right-datepicker">
                        <DatePicker className="form-control"
                            data-placement="right"
                            placeholderText={translate('to', {scope: 'placeholders'})}
                            dateFormat={DATE_FORMAT}
                            minDate={params.start_date}
                            maxDate={moment()}
                            isClearable={true}
                            showMonthDropdown
                            selected={params.end_date}
                            selectsEnd
                            startDate={params.start_date}
                            endDate={params.end_date}
                            onChange={(d) => setParams({end_date: d})}/>
                    </div>
                </div>
            </div>
            <div className="col-xs-12">
                <Pagination changePage={setParams} limit={params.limit} page={params.page} count={count}/>
            </div>
        </div>
        <div className="table-wrapper content">
            <table className="table main-list table-hover">
                <thead>
                    <tr>
                        <NewHeaderCell className="cell-md nofilter sortable" field="timestamp" label={translate('timestamp', {scope: 'logging.label'})}
                            currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('timestamp', {scope: 'tooltips.log_list'})}/>
                        <NewHeaderCell className="cell-sm sortable" field="level" label={translate('level', {scope: 'logging.label'})}
                            currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('level', {scope: 'tooltips.log_list'})}>
                            <Select name="logEntry-level" value={params.level} clearable={false} searchable={false}
                                options={levelOptions} onChange={(e) => setParams({level: e.value})}
                            />
                        </NewHeaderCell>
                        <NewHeaderCell className="cell-sm sortable" field="error_group" label={translate('error_group', {scope: 'logging.label'})}
                            currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('error_group', {scope: 'tooltips.log_list'})}>
                            <Select name="logEntry-group" value={params.error_group || 0} searchable={false}
                                options={(mapErrorGroups())} clearable={false}
                                onChange={(e) => setParams({error_group: e.value || '', error_code: ''})}
                            />
                        </NewHeaderCell>
                        <NewHeaderCell className="cell-lg sortable" field="error_code" label={translate('error_code', {scope: 'logging.label'})}
                            currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('error_code', {scope: 'tooltips.log_list'})}>
                            <Select name="logEntry-code" value={params.error_code || 0} searchable={false}
                                options={(mapErrorCodes(params.error_group))}
                                onChange={(e) => setParams({error_code: e.value || ''})} clearable={false}
                            />
                        </NewHeaderCell>
                        <th>
                            <span className="th-label-log-message">{translate('message', {scope: 'logging.label'})}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {_.isEmpty(entries) ?
                        <tr>
                            <td colSpan="5" className="text-center">
                                {translate('no_match', {scope: 'devices.device_details'})}
                            </td>
                        </tr>
                        :
                        entries.map((entry, i) => (
                            <tr key={i}>
                                <td>{entry.ts}</td>
                                <td>{entry.level}</td>
                                <td>{entry.group}</td>
                                <td>{entry.code}</td>
                                <td className="overflowing iconed">
                                    {entry.message.map((item, key) => <span key={key}>{item}<br/></span>)}
                                    <a className="icon-wrapper" data-toggle="modal" data-target="#logDetailsModal"
                                        onClick={() => setModalData(entry)}>
                                        <i className="fa fa-eye"/>
                                    </a>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <LogDetailsModal/>
        </div>
        <div className="row table-footer">
            <div className="col-xs-4"/>
            <div className="col-xs-4 text-center">
                <Pagination changePage={setParams} limit={params.limit} page={params.page} count={count}/>
            </div>
            <div className="col-xs-4 text-right table-panel">
                <label className="no-input select-wrapper pagination-select">
                    <span className="label-title">{translate('per_page', {scope: 'devices'})}</span>
                    <Select value={params.limit} options={PAGE_SIZE_OPTIONS} clearable={false} searchable={false}
                        onChange={(e) => {
                            if (e.value !== params.limit) {
                                const new_page = Math.floor((params.page - 1) * params.limit / e.value) + 1;
                                setParams({limit: e.value, page: new_page});
                            }
                        }}
                    />
                </label>
            </div>
        </div>
    </div>);
};

DeviceLog.propTypes = {
    clearParams: PropTypes.func.isRequired,
    setParams: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,
    entries: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    syncing: PropTypes.bool.isRequired,
    levelOptions: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
    clearParams: makeAction(LOGENTRIES_CLEAR_PARAMS),
    setParams: makeAction(LOGENTRIES_SET_PARAMS),
    setModalData: makeAction(SET_MODAL_DATA),
};

export default connect(deviceLogs, mapDispatchToProps)(withRouter(DeviceLog));
