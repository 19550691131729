import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import translate from 'counterpart';

import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import Switch from '../../../../conf_template/form/rows/valueTypes/Switch';
import SectionInput from '../../../../conf_template/form/rows/valueTypes/SectionInput';
import {deviceNetwork} from '../../../../../redux/selectors/device/network';
import DeviceNetworkConfig from './DeviceNetworkConfig';
import DeviceNetworkExtra from './DeviceNetworkExtra';
import tooltip from '../../../../common/tooltip';

const DeviceNetwork = ({config, editMode, global_section, ethernet_section, proxy, setConfiguration, validation, editable}) => (
    <div className="col-md-4 col-xs-4">
        <h3>
            {translate('network_connection', {scope: 'devices.device_network'})}
            <OverlayTrigger overlay={tooltip(translate('explanation', {scope: 'tooltips.device_details.network'}))}>
                <i className="tooltip-trigger inline-block fa fa-info-circle"/>
            </OverlayTrigger>
        </h3>
        {editMode && editable ?
            <dl className="dl-casual dl-horizontal">
                <dt>
                    {translate('server_thread_pool', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('server_thread_pool', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>
                    <span className="mode-edit">
                        <SectionInput
                            setInput={setConfiguration}
                            section={config.global_section}
                            sectionName="global_section"
                            fieldName="server_thread_pool"
                            error={!validation.server_thread_pool_valid || !validation.server_thread_pool_not_empty}
                        />
                        {!validation.server_thread_pool_valid &&
                            <OverlayTrigger overlay={tooltip(translate('number_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                        {!validation.server_thread_pool_not_empty &&
                            <OverlayTrigger overlay={tooltip(translate('field_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                    </span>
                </dd>
                <dt>
                    {translate('server_socket_host', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('server_socket_host', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>
                    <span className="mode-edit">
                        <SectionInput
                            setInput={setConfiguration}
                            section={config.global_section}
                            sectionName="global_section"
                            fieldName="server_socket_host"
                            error={!validation.server_socket_host_valid || !validation.server_socket_host_not_empty}
                        />
                        {!validation.server_socket_host_valid &&
                            <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                        {!validation.server_socket_host_not_empty &&
                            <OverlayTrigger overlay={tooltip(translate('field_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                    </span>
                </dd>
                <dt>
                    {translate('server_socket_port', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('server_socket_port', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>
                    <span className="mode-edit">
                        <SectionInput
                            setInput={setConfiguration}
                            section={config.global_section}
                            sectionName="global_section"
                            fieldName="server_socket_port"
                            error={!validation.server_socket_port_valid || !validation.server_socket_port_not_empty}
                        />
                        {!validation.server_socket_port_valid &&
                            <OverlayTrigger overlay={tooltip(translate('number_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                        {!validation.server_socket_port_not_empty &&
                            <OverlayTrigger overlay={tooltip(translate('field_required', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                    </span>
                </dd>
                <dt>
                    {translate('ssl_support', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('ssl_support', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd id="editSsl">
                    <Switch
                        setInput={setConfiguration}
                        section={config.global_section}
                        sectionName="global_section"
                        fieldName="ssl_enabled"
                        innerClass=""
                    />
                </dd>
                <dt>
                    {translate('proxy', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('proxy', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd>
                    <span className="mode-edit">
                        <SectionInput
                            setInput={setConfiguration}
                            section={config}
                            fieldName="proxy"
                            error={!validation.proxy_valid}
                        />
                        {!validation.proxy_valid &&
                            <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                <i className="error-icon fa fa-exclamation-circle"/>
                            </OverlayTrigger>
                        }
                    </span>
                </dd>
            </dl>
            :
            <dl className="dl-casual dl-horizontal">
                <dt key="1">
                    {translate('server_thread_pool', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('server_thread_pool', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd key="2">{global_section.server_thread_pool}</dd>
                <dt key="3">
                    {translate('server_socket_host', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('server_socket_host', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd key="4">{global_section.server_socket_host}</dd>
                <dt key="5">
                    {translate('server_socket_port', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('server_socket_port', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd key="6">{global_section.server_socket_port}</dd>
                <dt key="7">
                    {translate('ssl_support', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('ssl_support', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd key="8" id="ssl">{global_section.ssl_enabled}</dd>
                <dt key="11">
                    {translate('proxy', {scope: 'devices.device_network'})}:
                    <OverlayTrigger overlay={tooltip(translate('proxy', {scope: 'tooltips.device_details.network'}))}>
                        <i className="inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </dt>
                <dd key="21">{proxy}</dd>
            </dl>
        }
        <ul className="list-unstyled panel-group network-settings" id="accordion" role="tablist"
            aria-multiselectable="true">
            <DeviceNetworkConfig tooltip={tooltip} key="ethernet_section"/>
            {ethernet_section &&
            <DeviceNetworkExtra tooltip={tooltip} key="usb_to_ethernet"/>
            }
        </ul>
    </div>
);

DeviceNetwork.propTypes = {
    config: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    global_section: PropTypes.shape({
        server_thread_pool: PropTypes.string.isRequired,
        server_socket_host: PropTypes.string.isRequired,
        server_socket_port: PropTypes.string.isRequired,
        ssl_enabled: PropTypes.string.isRequired,
    }).isRequired,
    ethernet_section: PropTypes.object.isRequired,
    proxy: PropTypes.string,
    setConfiguration: PropTypes.func.isRequired,
    validation: PropTypes.object,
};

const mapDispatchToProps = {
    setConfiguration: makeAction(actionTypes.SET_CONFIGURATION),
};

export default connect(deviceNetwork, mapDispatchToProps)(DeviceNetwork);
