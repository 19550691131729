import _ from 'lodash';
import * as actionTypes from '../actionTypes';
import {get, save} from '../../services/persist';

const cleanFilters = {
    id: '',
    name: '',
    status: '',
    operator_id: '',
    relevant: 'True',
    business_id: '',
    soft_version: '',
    company_id: '',
    mac: '',
};

const columnDefs = [
    {
        name: 'id',
        label: 'ID',
        thClass: 'cell-sm sortable',
        link: true,
    },
    {
        name: 'name',
        label: 'Name',
        thClass: 'sortable',
        link: true,
    },
    {
        name: 'business_id',
        label: 'Business ID',
        thClass: 'cell-md sortable',
    },
    {
        name: 'operator_id',
        label: 'Operator ID',
        thClass: 'cell-md sortable',
    },
    {
        name: 'relevant',
        label: 'Relevant',
        thClass: 'cell-md sortable',
        selectable: true,
        filterName: 'relevant',
    },
    {
        name: 'company',
        label: 'Company',
        thClass: 'cell-md sortable',
        selectable: true,
        filterName: 'company_id',
    },
    {
        name: 'mac',
        label: 'MAC address',
        thClass: 'cell-md',
        filterName: 'mac',
    },
    {
        name: 'plugins',
        label: 'Plugins',
        thClass: 'cell-md',
        selectable: true,
        filterName: 'plugins',
    },
];

const defaultColumns = ['id', 'name', 'business_id'];

const getDefaultState = () => {
    const devices_column_names = get('devices_column_names');
    let columns;
    if (devices_column_names) {
        columns = JSON.parse(devices_column_names).map((name) => columnDefs.find((el) => el.name === name));
    } else {
        columns = save('devices_column_names', JSON.stringify(defaultColumns));
    }
    return {
        multi_soft_version: {},
        multi_action_version: {},
        selected_pre_config: {},
        selected_action: {},
        selected_company: {label: 'Unassign', value: null},
        list: [],
        message: null,
        checked: false,
        inProgress: false,
        count: parseInt(get('devices_count'), 10) || save('devices_count', 10),
        ordering: get('devices_sort') || save('devices_sort', ''),
        limit: parseInt(get('devices_limit'), 10) || save('devices_limit', 25),
        filters: JSON.parse(get('devices_filters')) || save('devices_filters', JSON.stringify(cleanFilters)),
        page: parseInt(get('devices_page'), 10) || save('devices_page', 1),
        columns: columns,
    };
};

const reducer = (state = getDefaultState(), action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return getDefaultState();
        }
        case actionTypes.FETCH_DEVICES: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.DEVICES_FETCHED: {
            save('devices_count', action.payload.count);
            return {
                ...state,
                list: action.payload.results,
                count: action.payload.count,
                inProgress: false,
            };
        }
        case actionTypes.SELECT_MULTI_SOFT: {
            return {
                ...state,
                multi_soft_version: action.payload,
            };
        }
        case actionTypes.SELECT_MULTI_COMPANY: {
            return {
                ...state,
                selected_company: action.payload,
            };
        }
        case actionTypes.SELECT_MULTI_ACTION: {
            return {
                ...state,
                selected_action: action.payload,
            };
        }
        case actionTypes.SOFT_INSTALL_IN_PROGRESS: {
            const {id} = action;

            return {
                ...state,
                list: state.list.map((device) => (device.id === id ?
                    {
                        ...device,
                        in_progress: true,
                    }
                    :
                    device
                )),
                inProgress: false,
            };
        }
        case actionTypes.UPDATE_IN_PROGRESS: {
            const {id} = action;

            return {
                ...state,
                list: state.list.map((device) => (device.id === id ?
                    {
                        ...device,
                        in_progress: true,
                    }
                    :
                    device
                )),
                inProgress: false,
            };
        }
        case actionTypes.CHECK_DEVICE: {
            const {id} = action.payload;

            return {
                ...state,
                list: state.list.map((device) => (device.id === id ?
                    {
                        ...device,
                        checked: action.payload.state,
                    }
                    :
                    device
                )),
            };
        }
        case actionTypes.CHECK_DEVICES: {
            return {
                ...state,
                list: state.list.map((device) => (
                    {
                        ...device,
                        checked: action.payload.state,
                    }
                )),
                checked: action.payload.state,
            };
        }
        case actionTypes.FETCH_IN_PROGRESS: {
            return {
                ...state,
                list: [],
                inProgress: true,
            };
        }
        case actionTypes.SEND_CONFIG_ID: {
            return {
                ...state,
                selected_pre_config: action.payload,
            };
        }
        case actionTypes.CHANGE_IN_PROGRESS: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.CHANGE_SUCCESS: {
            const data = action.payload;

            return {
                ...state,
                list: state.list.map((obj) => (obj.id === data.id ? data : obj)),
                inProgress: false,
            };
        }
        case actionTypes.INSTALL_IN_PROGRESS: {
            return {
                ...state,
                list: state.list.map((obj) => {
                    if (obj.id === action.id) {
                        obj.in_progress = true;
                    }
                    return obj;
                }),
                inProgress: false,
            };
        }
        case actionTypes.CHANGE_FAILED: {
            return {
                ...state,
                inProgress: false,
            };
        }
        case actionTypes.DEVICES_LIST_CHANGE_SORT: {
            save('devices_sort', action.payload.ordering);
            return {
                ...state,
                ordering: action.payload.ordering,
                inProgress: true,
            };
        }
        case actionTypes.DEVICES_LIST_CHANGE_FILTERS: {
            const filters = {...state.filters, ...action.payload};
            save('devices_filters', JSON.stringify(filters));
            return {
                ...state,
                filters: filters,
                // inProgress: true,
            };
        }
        case actionTypes.DEVICES_LIST_CLEAR_FILTERS: {
            save('devices_filters', JSON.stringify(cleanFilters));
            return {
                ...state,
                filters: cleanFilters,
                inProgress: true,
            };
        }
        case actionTypes.DEVICES_LIST_CHANGE_LIMIT: {
            save('devices_limit', action.payload.limit);
            return {
                ...state,
                limit: action.payload.limit,
                inProgress: true,
            };
        }
        case actionTypes.DEVICES_LIST_PAGINATION: {
            save('devices_page', action.payload.page);
            return {
                ...state,
                page: action.payload.page,
            };
        }
        case actionTypes.DEVICES_LIST_ADD_COLUMN: {
            const columnsList = [
                ...state.columns,
                action.payload,
            ];
            save('devices_column_names', JSON.stringify(columnsList.map((el) => el.name)));

            return {
                ...state,
                columns: columnsList,
            };
        }
        case actionTypes.DEVICES_LIST_REMOVE_COLUMN: {
            const columnsList = _.filter(state.columns, (obj) => obj.name !== action.payload.name);
            save('devices_column_names', JSON.stringify(columnsList.map((el) => el.name)));

            return {
                ...state,
                columns: columnsList,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
