import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';

import {TEMPLATE_PAGE} from '../../../configuration/paths';
import {CONFIG_LIST_CLEAR_PARAMS} from '../../../redux/actionTypes';
import makeAction from '../../../redux/actions/common/makeAction';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';
import tooltip from '../../common/tooltip';

const ConfigListHeader = ({clearParams, count, params}) => {
    const from = (params.page - 1) * params.limit + 1;
    const to = Math.min(params.page * params.limit, count);

    return (<div className="table-header row">
        <div className="col-xs-6">
            <h2>
                {translate('configs_table_header', {scope: 'devices'})}
                <span className="extra-info">{translate('table_count', {scope: 'devices', from, to, count})}</span>
            </h2>
        </div>
        <div className="col-xs-6 text-right options">
            <div className="table-panel">
                <button className="btn btn-info" onClick={() => clearParams({})}>Clear filters</button>
                <PermissionCheck permission={permissions.ADD_CONFIGS}>
                    <label>
                        <Link to={TEMPLATE_PAGE} className="btn btn-primary">
                            <OverlayTrigger overlay={tooltip(translate('pre_configuration', {scope: 'devices.buttons'}))}>
                                <span><i className="fa fa-plus"/></span>
                            </OverlayTrigger>
                        </Link>
                    </label>
                </PermissionCheck>
            </div>
        </div>
    </div>);
};

ConfigListHeader.propTypes = {
    clearParams: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    count: state.deviceConfigurations.count,
    params: state.deviceConfigurations.params,
});

const mapDispatchToProps = {
    clearParams: makeAction(CONFIG_LIST_CLEAR_PARAMS),
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigListHeader);
