import {LIST_DEVICE, MANAGE_COMPANY, LIST_PLUGIN} from './permissions';

export const CONFIGURATION_TYPE = {
    SIMPLE_MAPPING: 'simple_mapping',
    MAPPING: 'mapping',
    SHIFT_CLOSE_MAPPING: 'shift_close_mapping',
    ETHERNET: 'ethernet',
    EXTRA_ETHERNET: 'extra_ethernet',
    PROXY: 'proxy',
    SINGLE_PLUGIN: 'plugin',
    PLUGINS_SECTION: 'plugins',
};

export const DEVICE_STATUS = {
    NEW: 'new',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    STEP1: 'step1',
    STEP2: 'step2',
    PROVISIONED: 'provisioned',
    PROVISIONING_FAILED: 'provisioning_failed',
    UPGRADING: 'upgrading',
    REBOOTING: 'rebooting',
    CONNECTED: 'connected',
    NOT_CONNECTED: 'not_connected',
};

export const IMAGE_TYPES_OPTIONS = [
    {value: 0, label: 'All'},
    {value: 1, label: 'RPi'},
    {value: 2, label: 'VM'},
    {value: 3, label: 'Docker'},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 25, label: '25'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
];

export const CONSTRAINT_OPTIONS = [
    {value: 'all', label: 'Send all items'},
    {value: 'non_zero', label: 'Send all non-zero items'},
    {value: 'items', label: 'Send sub-items only'},
    {value: 'main', label: 'Send aggregated main item'},
];

export const AGGREGATED_ITEMS_OPTIONS = [
    {value: 'all', label: 'Send all items'},
    {value: 'items', label: 'Send sub-items only'},
    {value: 'main', label: 'Send aggregated main item'},
];

export const MAPPING_TYPES = {
    NORMAL: 'normal',
    SHIFT_CLOSE: 'shift_close',
    NUMERIC_KEY: 'numeric',
    GROUP_VAT_MAPPING: 'group_vat',
};

export const DEVICES_LIST_ADDABLE_COLUMNS = [
    {
        name: 'operator_id',
        label: 'Operator ID',
        thClass: 'cell-sm sortable',
        permission: LIST_DEVICE,
    },
    {
        name: 'relevant',
        label: 'Relevant',
        thClass: 'cell-sm',
        selectable: true,
        permission: LIST_DEVICE,
    },
    {
        name: 'company',
        label: 'Company',
        thClass: 'cell-md sortable',
        selectable: true,
        permission: MANAGE_COMPANY,
        filterName: 'company_id',
    },
    {
        name: 'mac',
        label: 'MAC address',
        thClass: 'cell-md',
        permission: LIST_DEVICE,
    },
    {
        name: 'plugins',
        label: 'Plugins',
        thClass: 'cell-md',
        selectable: true,
        permission: LIST_PLUGIN,
    },
];

export const INVOICES_LIST_ADDABLE_COLUMNS = [
    {
        name: 'discount',
        label: 'Discount',
    },
];


export const CUMULATED_INVOICE_TO_ROOM = {
    OFF: 'off',
    ARTICLE: 'article',
    ARTICLE_GROUP: 'article_group',
    SUPERGROUP: 'supergroup',
};

export const CUMULATED_INVOICE_TO_ROOM_OPTIONS = [
    {value: CUMULATED_INVOICE_TO_ROOM.OFF, label: 'Off'},
    {value: CUMULATED_INVOICE_TO_ROOM.ARTICLE, label: 'Aggregate by article'},
    {value: CUMULATED_INVOICE_TO_ROOM.ARTICLE_GROUP, label: 'Aggregate by article group'},
    {value: CUMULATED_INVOICE_TO_ROOM.SUPERGROUP, label: 'Aggregate by supergroup'},
];

export const CUMULATED_SHIFT_CLOSE = {
    OFF: 'off',
    ARTICLE: 'article',
    ARTICLE_GROUP: 'article_group',
    SUPERGROUP: 'supergroup',
    ARTICLE_SERVICEPERIOD: 'article_serviceperiod',
    ARTICLE_GROUP_SERVICEPERIOD: 'article_group_serviceperiod',
    SUPERGROUP_SERVICEPERIOD: 'supergroup_serviceperiod',
};

export const CUMULATED_SHIFT_CLOSE_OPTIONS = [
    {value: CUMULATED_SHIFT_CLOSE.OFF, label: 'Off'},
    {value: CUMULATED_SHIFT_CLOSE.ARTICLE, label: 'Aggregate by article'},
    {value: CUMULATED_SHIFT_CLOSE.ARTICLE_GROUP, label: 'Aggregate by article group'},
    {value: CUMULATED_SHIFT_CLOSE.SUPERGROUP, label: 'Aggregate by supergroup'},
];

export const CUMULATED_SHIFT_CLOSE_SERVICEPERIOD_OPTIONS = [
    {value: CUMULATED_SHIFT_CLOSE.ARTICLE_SERVICEPERIOD, label: 'Aggregate by article and service period'},
    {value: CUMULATED_SHIFT_CLOSE.ARTICLE_GROUP_SERVICEPERIOD, label: 'Aggregate by article group and service period'},
    {value: CUMULATED_SHIFT_CLOSE.SUPERGROUP_SERVICEPERIOD, label: 'Aggregate by supergroup and service period'},
];

export const CUMULATED_SERVICEPERIOD_MAP = {
    [CUMULATED_SHIFT_CLOSE.ARTICLE_SERVICEPERIOD]: CUMULATED_SHIFT_CLOSE.ARTICLE,
    [CUMULATED_SHIFT_CLOSE.ARTICLE_GROUP_SERVICEPERIOD]: CUMULATED_SHIFT_CLOSE.ARTICLE_GROUP,
    [CUMULATED_SHIFT_CLOSE.SUPERGROUP_SERVICEPERIOD]: CUMULATED_SHIFT_CLOSE.SUPERGROUP,
};
