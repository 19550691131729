import React from 'react';
import translate from 'counterpart';

const LogsModal = () => (
    <div className="modal fade" id="loadConfigModal" tabIndex={-1} role="dialog" aria-labelledby="loadConfigModal"
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">{translate('load_config_device', {scope: 'device_details'})}</h3>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <dl className="dl-horizontal dl-casual">
                        <dt>
                            {translate('url', {scope: 'devices.plugins.sihot'})}:
                            <i className="fa fa-question-circle" data-toggle="tooltip" data-placement="right"
                                title="Field description"/>
                        </dt>
                        <dd className="with-icon">
                            <input className="form-control"/>
                            <a target="_blank" href="config_details.html"><i className="fa fa-eye"/></a>
                        </dd>
                        <dt>
                            {translate('overwrite', {scope: 'modals'})}:
                            <i className="fa fa-question-circle" data-toggle="tooltip" data-placement="right"
                                title="Field description"/>
                        </dt>
                        <dd>
                            <ul className="list-unstyled list-checkboxes">
                                <li className="checkbox checkbox-wrapper">
                                    <input type="checkbox" id="check_all"/>
                                    <label htmlFor="check_all"><span className="fake-checkbox"/>{translate('all', {scope: 'devices.invoices'})}</label>
                                </li>
                                <li className="checkbox checkbox-wrapper">
                                    <input type="checkbox" id="check_1"/>
                                    <label htmlFor="check_1"><span className="fake-checkbox"/>{translate('device_info', {scope: 'devices.device_menu'})}</label>
                                </li>
                                <li className="checkbox checkbox-wrapper">
                                    <input type="checkbox" id="check_2"/>
                                    <label htmlFor="check_2"><span className="fake-checkbox"/>{translate('hotel_systems', {scope: 'devices.device_menu'})}</label>
                                </li>
                                <li className="checkbox checkbox-wrapper">
                                    <input type="checkbox" id="check_3"/>
                                    <label htmlFor="check_3"><span className="fake-checkbox"/>{translate('printers', {scope: 'devices.device_menu'})}</label>
                                </li>
                                <li className="checkbox checkbox-wrapper">
                                    <input type="checkbox" id="check_3"/>
                                    <label htmlFor="check_3"><span className="fake-checkbox"/>{translate('printers', {scope: 'devices.device_menu'})}</label>
                                </li>
                                <li className="checkbox checkbox-wrapper">
                                    <input type="checkbox" id="check_3"/>
                                    <label htmlFor="check_3"><span className="fake-checkbox"/>{translate('printers', {scope: 'devices.device_menu'})}</label>
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div className="modal-footer row">
                    <div className="col-xs-6">
                        <button type="button" className="btn btn-default" data-dismiss="modal">{translate('close', {scope: 'modals'})}</button>
                    </div>
                    <div className="col-xs-6 text-right">
                        <button type="button" className="btn btn-primary">{translate('load', {scope: 'modals'})}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default LogsModal;
