import {createSelector} from 'reselect';
import {deviceConfiguration} from '../deviceConfiguration/stateSelector';
import {deviceDetails, deviceDetailsConfiguration} from './stateSelector';
import translate from 'counterpart';
import {formatDuration} from '../../../services/formatters';
import {validationSelector} from './validation';

export const deviceGeneral = createSelector(
    deviceDetails,
    deviceConfiguration,
    validationSelector,
    deviceDetailsConfiguration,
    (details, config, validation, detailsConfig) => ({
        name: details.name || '---',
        id: details.id,
        company: details.company || '---',
        active: details.active ? 'Active' : 'Inactive',
        status: translate(details.status, {scope: 'devices.table_options'}),
        pos_network_ip: details.pos_network_ip || '---',
        external_ip: details.external_ip || '---',
        business_id: detailsConfig.business_id || '---',
        operator_id: detailsConfig.operator_id || '---',
        soft_version: details.soft_version || '---',
        mac: details.mac || '---',
        serial: details.serial || '---',
        domain: details.domain || '---',
        external_domain: details.external_domain || '---',
        client_key: details.client_key || '---',
        system_key: details.system_key || '---',
        machine_info: details.machine_info || '---',
        system_info: details.system_info || '---',
        cpu_info: details.cpu_info || '---',
        relevant: details.relevant ? 'Relevant' : 'Irrelevant',
        uptime: details.uptime === '' ? '---' : formatDuration(details.uptime),
        config,
        validation,
        details,
    })
);
