import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import ConfigurationPage from '../components/conf_template';
import * as actionTypes from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';

// TODO: change path, merge with edit?
class NewConfigurationPage extends React.Component {
    componentDidMount() {
        this.props.fetchDefaultConfiguration();
        this.props.setDeviceConfiguration({
            ...this.props.defaultDeviceConfiguration,
            id: null,
            plugins: [],
        });
    }

    componentDidUpdate() {
        this.props.setDeviceConfiguration({
            ...this.props.defaultDeviceConfiguration,
            id: null,
            plugins: [],
        });
    }

    render() {
        return (
            <ConfigurationPage {...this.props}/>
        );
    }
}

NewConfigurationPage.propTypes = {
    fetchDefaultConfiguration: PropTypes.func.isRequired,
    setDeviceConfiguration: PropTypes.func.isRequired,
    defaultDeviceConfiguration: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    defaultDeviceConfiguration: state.defaultDeviceConfiguration,
});

const mapDispatchToProps = {
    fetchDefaultConfiguration: makeAction(actionTypes.FETCH_DEFAULT_CONFIGURATION),
    setDeviceConfiguration: makeAction(actionTypes.SET_CONFIGURATION),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewConfigurationPage);
