import PropTypes from 'prop-types';
import React from 'react';
import OraclePlugin from './details/OraclePlugin';
import ProtelPlugin from './details/ProtelPlugin';
import SihotPlugin from './details/SihotPlugin';

const PluginDetails = ({plugin, endpoint_url}) => {
    switch (plugin.type) {
        case 'OraclePlugin': {
            return <OraclePlugin plugin={plugin} endpoint_url={endpoint_url}/>;
        }
        case 'ProtelPlugin': {
            return <ProtelPlugin plugin={plugin} endpoint_url={endpoint_url}/>;
        }
        case 'SihotPlugin': {
            return <SihotPlugin plugin={plugin} endpoint_url={endpoint_url}/>;
        }
        default: {
            return <div/>;
        }
    }
};

PluginDetails.propTypes = {
    plugin: PropTypes.any.isRequired,
    endpoint_url: PropTypes.string.isRequired,
};

export default PluginDetails;
