import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import makeAction from '../../../redux/actions/common/makeAction';
import {DEVICES_LIST_CHANGE_FILTERS, FETCH_DEVICES} from '../../../redux/actionTypes';

const SelectFilter = ({name, value, options, changeFilters, fetchDevices}) => (
    <Select
        name={name}
        value={value}
        options={options}
        searchable={false}
        clearable={false}
        onChange={(obj) => {
            changeFilters({[name]: obj.value});
            fetchDevices();
        }}
    />
);

SelectFilter.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    changeFilters: PropTypes.func.isRequired,
    fetchDevices: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    changeFilters: makeAction(DEVICES_LIST_CHANGE_FILTERS),
    fetchDevices: makeAction(FETCH_DEVICES),
};

export default connect(null, mapDispatchToProps)(SelectFilter);
