import _ from 'lodash';
// override uniqueId from lodash where it doesn't apply eg. added item doesn't get new id after save on backend
export const getId = (elementList, prefix) => {
    const idList = elementList.filter((element) => element.id.indexOf(prefix) > -1).map((element) => element.id.replace(prefix, ''));
    return `${prefix}${idList.length ? Math.max(...idList) + 1 : 1}`;
};

export const getListOptions = (listOfObjects) => listOfObjects.map((obj) => ({
    label: _.values(obj)[0],
    value: 'plugin',
}));
