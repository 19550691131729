import {createSelector} from 'reselect';
import {deviceDetails, deviceDetailsConfiguration, devicePlugins} from './stateSelector';
import {
    editMode, selectedID, selectedName, selectedPlugin,
    updateInProgress,
} from '../navigation/stateSelector';
import * as _ from 'lodash';
import {
    defaultDeviceConfiguration, defaultPlugins, deviceConfiguration, pluginsEdit,
    pluginsToDelete,
} from '../deviceConfiguration/stateSelector';
import * as idx from 'idx';
import {editableStatus} from './validation';


export const pluginDetails = createSelector(
    devicePlugins,
    selectedPlugin,
    pluginsEdit,
    editMode,
    (plugins, selected, pluginsEditable, edit) => (
        _.find((edit || selected === 'new' ? pluginsEditable : plugins), {id: selected}) || 'add'
    )
);

const pluginsValidationSelector = createSelector(
    pluginDetails,
    editMode,
    (plugin, edit) => {
        if (edit && plugin.type === 'SihotPlugin') {
            return plugin.url !== '';
        }
        return true;
    }
);

export const pluginsNotValid = createSelector(
    pluginsValidationSelector,
    (validation) => _.includes(validation, false)
);

export const mappingsName = createSelector(
    pluginDetails,
    (plugin) => _.groupBy(plugin.mappings, 'name')
);

export const pluginMappings = createSelector(
    pluginDetails,
    (plugin) => _.groupBy(plugin.mappings, 'business_id')
);

const businessIdOptions = createSelector(
    pluginMappings,
    (mappings) => Object.keys(mappings).map((id) => (
        {value: id, label: id}
    ))
);

const nameOptions = createSelector(
    mappingsName,
    (mappings) => Object.keys(mappings).map((id) => (
        {value: id, label: id}
    ))
);

export const mappingsForID = createSelector(
    pluginMappings,
    selectedID,
    (mappings, id) => mappings[id]
);

export const selectedMappingSelector = createSelector(
    mappingsForID,
    selectedName,
    (mappings, name) => (name ? _.find(mappings, {name: name}) : {})
);

export const itemsSelector = createSelector(
    mappingsForID,
    selectedName,
    (mappings, name) => ((name && _.find(mappings, {name: name})) ? _.find(mappings, {name: name}).items : [])
);

export const mappingDefaults = createSelector(
    pluginDetails,
    defaultDeviceConfiguration,
    (plugin, config) => idx(config.plugins.filter((defaultPlugin) => defaultPlugin.type === plugin.type), (p) => p[0].mappings)
);

export const maxMappingGroup = createSelector(
    pluginDetails,
    (plugin) => (plugin.mappings && plugin.mappings.length > 0 ? _.maxBy(plugin.mappings, 'mapping_group').mapping_group : 0)
);

export const hotelSystems = createSelector(
    devicePlugins,
    pluginDetails,
    selectedID,
    selectedName,
    pluginsToDelete,
    deviceConfiguration,
    deviceDetails,
    deviceDetailsConfiguration,
    (plugins, plugin, id, name, toDelete, config, device, configDetails) => ({
        firstPlugin: _.isEmpty(plugins) ? 'new' : plugins[0].id,
        pluginsLength: plugins.length,
        lastPlugin: _.isEmpty(plugins) ? 'new' : _.last(plugins).id,
        plugin,
        name,
        id,
        toDelete: !_.isEmpty(toDelete),
        config,
        device,
        configDetails,
    })
);

export const listPlugins = createSelector(
    devicePlugins,
    selectedPlugin,
    editMode,
    deviceDetailsConfiguration,
    deviceConfiguration,
    deviceDetails,
    (plugins, selected, edit, configDetails, config, device) => ({
        plugins,
        edit,
        configDetails,
        selectedConfig: config.plugins,
        device,
    })
);

export const pluginHeader = createSelector(
    pluginDetails,
    editMode,
    deviceDetailsConfiguration,
    deviceConfiguration,
    deviceDetails,
    defaultPlugins,
    updateInProgress,
    editableStatus,
    pluginsValidationSelector,
    (plugin, edit, config, configEdit, device, pluginsToAdd, inProgress, status, isValid) => ({
        plugin,
        edit,
        config,
        configEdit,
        device,
        options: pluginsToAdd.map((addPlugin) => ({value: addPlugin.id, label: addPlugin.name})),
        pluginsToAdd: pluginsToAdd.reduce((sum, addPlugin) => ({...sum, [addPlugin.id]: addPlugin}), {}),
        toAdd: idx(plugin, (a) => a.name),
        inProgress,
        disabled: !status,
        notValid: !isValid,
    })
);

export const pluginOptions = createSelector(
    pluginDetails,
    deviceDetails,
    editMode,
    pluginsValidationSelector,
    (plugin, device, edit, validation) => ({
        plugin: plugin.type === 'ProtelPlugin' ? {
            ...plugin,
            samba_server_workgroup_switch: _.isString(plugin.samba_server_workgroup),
        } : plugin,
        device,
        edit,
        validation,
    })
);

export const mappingsSelector = createSelector(
    pluginMappings,
    editMode,
    selectedID,
    businessIdOptions,
    nameOptions,
    selectedName,
    itemsSelector,
    selectedMappingSelector,
    pluginDetails,
    selectedPlugin,
    mappingsForID,
    (mappings, edit, id, options, names, name, items, mapping, plugin, selected) => ({
        edit,
        mappings,
        id,
        options,
        names,
        name,
        items,
        mapping,
        plugin,
        selected,
    })
);

export const mappingsAdd = createSelector(
    maxMappingGroup,
    mappingDefaults,
    pluginDetails,
    selectedID,
    (group, defaults, plugin, id) => ({
        group,
        defaults,
        plugin,
        id,
    })
);
