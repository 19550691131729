import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {CONFIG_DETAILS_PAGE} from '../../../configuration/paths';
import PermissionCheck from '../../authorization/PermissionCheck';
import * as permissions from '../../../configuration/permissions';
import NewHeaderCell from '../../common/table/newHeaderCell';
import {FETCH_CONFIGURATIONS, CONFIG_LIST_SET_PARAMS} from '../../../redux/actionTypes';
import makeAction from '../../../redux/actions/common/makeAction';
import Loader from '../../common/loaderBox';
import debouncedFunc from '../../../services/debounce';
import WarningModal from '../../common/modals/WarningModal';
import translate from 'counterpart';

const debounce = debouncedFunc();

const configsListTable = ({configsList, fetchConfigs, inProgress, params, setParams}) => {
    const getSorting = (field) => {
        const arg = {...params, ordering: field};
        setParams(arg);
        fetchConfigs(arg);
    };
    return (<div className="table-wrapper content">
        <table className="table table-condensed table-hover" id="devicessList">
            <thead>
                <tr>
                    <NewHeaderCell className="cell-sm nofilter sortable" field="id" label="ID"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('id', {scope: 'tooltips.config_list'})} />
                    <NewHeaderCell className="nofilter sortable" field="name" label="Name"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('name', {scope: 'tooltips.config_list'})}>
                        <div className="input-group">
                            <input type="search" className="form-control" value={params.name}
                                onChange={(e) => {
                                    const arg = {...params, name: e.target.value};
                                    setParams(arg);
                                    debounce(fetchConfigs, arg);
                                }}/>
                            <span className="input-group-addon" onClick={() => setParams({...params, name: ''}) }>
                                { params.name ?
                                    <i className="fa fa-remove" /> :
                                    null
                                }
                            </span>
                        </div>
                    </NewHeaderCell>
                    <NewHeaderCell className="cell-md sortable" field="template_level" label="Type"
                        currentOrder={params.ordering} onSortChange={getSorting} tooltipContent={translate('template_level', {scope: 'tooltips.config_list'})}>
                        <Select clearable={false} searchable={false} value={params.template_level || 0} options={[
                            {value: 0, label: 'All'},
                            {value: 1, label: 'Template'},
                            {value: 2, label: 'Preconfig'},
                        ]} onChange={(e) => {
                            const arg = {...params, template_level: e.value === 0 ? '' : e.value};
                            setParams(arg);
                            fetchConfigs(arg);
                        }}/>
                    </NewHeaderCell>
                    <th className="nofilter">
                        <span className="th-label">Plugins</span>
                    </th>
                    <th className="cell-md nofilter">
                        <span className="th-label">Actions</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {inProgress ?
                    <tr>
                        <td colSpan="5"><Loader/></td>
                    </tr>
                    :
                    (configsList || []).map((config) => (
                        <tr key={config.id}>
                            <td>
                                <span>
                                    <PermissionCheck permission={permissions.CHANGE_CONFIGS} fallbackComponent={<span>{config.id}</span>}>
                                        <Link to={CONFIG_DETAILS_PAGE(config.id)}>{config.id}</Link>
                                    </PermissionCheck>
                                </span>
                            </td>
                            <td><span>{config.name}</span></td>
                            <td><span>{config.template_level === 1 ? 'Template' : 'Preconfig'}</span></td>
                            <td><span>{config.plugins.map((p) => p.name).join(', ')}</span></td>
                            <PermissionCheck permission={permissions.DELETE_CONFIGS} fallbackComponent={<td><span >No actions</span></td>}>
                                <td><span >Delete this config</span></td>
                            </PermissionCheck>
                        </tr>
                    ))
                }
            </tbody>

        </table>
        <WarningModal id={'removeModal'} text={'Are you sure you want to remove config?'}/>

    </div>);
};

configsListTable.propTypes = {
    configsList: PropTypes.arrayOf(
        PropTypes.object
    ),
    fetchConfigs: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    inProgress: state.deviceConfigurations.inProgress,
    configsList: state.deviceConfigurations.results,
    params: state.deviceConfigurations.params,
});

const mapDispatchToProps = {
    fetchConfigs: makeAction(FETCH_CONFIGURATIONS),
    setParams: makeAction(CONFIG_LIST_SET_PARAMS),
};

export default connect(mapStateToProps, mapDispatchToProps)(configsListTable);
