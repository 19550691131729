import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import error from './common/errorReducer';
import auth from './authReducer';
import device from './deviceReducer';
import devices from './devicesReducer';
import navigation from './common/navigationReducer';
import notifications from './common/notificationReducer';
import sysImageReducer from './sysImageReducer';
import soft from './softReducer';
import page from './common/pageReducer';
import loader from './common/loaderReducer';
import deviceConfigurations from './deviceConfigurationsReducer';
import deviceConfiguration from './deviceConfigurationReducer';
import defaultDeviceConfiguration from './defaultDeviceConfigurationReducer';
import deviceConfigurationsToProvisione from './deviceConfigurationsToProvisioneReducer';
import deviceFile from './deviceFileReducer';
import companies from './companiesReducer';

const root = combineReducers({
    auth,
    companies,
    error,
    device,
    devices,
    notifications,
    navigation,
    sysImageReducer,
    soft,
    page,
    loader,
    routing: routerReducer,
    deviceConfigurations,
    deviceConfiguration,
    defaultDeviceConfiguration,
    deviceConfigurationsToProvisione,
    deviceFile,
});

export default root;
