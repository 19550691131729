import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as actionTypes from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';
import InstallPrinter from '../components/devices/details/printers/index';

class InstallPrinterPage extends React.Component {
    componentDidMount() {
        this.props.pullPrinter({
            device_id: this.props.match.params.deviceId,
            printer_hash: this.props.match.params.printerHash,
        });
    }

    render() {
        return (
            <InstallPrinter edit={false} device_id={this.props.match.params.deviceId}/>
        );
    }
}

InstallPrinterPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            deviceId: PropTypes.string.isRequired,
            printerHash: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    pullPrinter: PropTypes.func.isRequired,
    installed_printer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    installed_printer: state.device.installed_printer,
});

const mapDispatchToProps = {
    pullPrinter: makeAction(actionTypes.PULL_PRINTER),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(InstallPrinterPage));
