import {createSelector} from 'reselect';

export const getAuthBranch = (state) => state.auth;

export const permissionsSelector = createSelector(
    getAuthBranch,
    (auth) => auth.user.permissions
);

export const nameSelector = createSelector(
    getAuthBranch,
    (auth) => auth.user.fullname
);
