import {TYPE as DISABLE_WRAPPER} from '../../actions/common/navigation/disableWrapper';
import {
    ADD_MAPPING,
    CHANGE_EDIT_MODE, CHANGE_LANG, DEVICE_UPDATE_FAILED, DEVICE_UPDATE_SUCCESS, DISABLE_NAVIGATION, SELECT_ID,
    SELECT_NAME, SELECT_PLUGIN,
    SET_MODAL_DATA, UPDATE_DEVICE,
} from '../../actionTypes';
import {get, save} from '../../../services/persist';

const DEFAULT_STATE = {
    disabled: false,
    editMode: false,
    navigationDisabled: false,
    lang: get('lang') || (window.navigator.language.substr(0, 2) === 'de' ? 'de' : 'en'),
    modalData: {},
    selectedPlugin: false,
    selectedID: false,
    selectedName: false,
    updateInProgress: false,
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case DISABLE_WRAPPER: {
            return {
                ...state,
                disabled: true,
            };
        }
        case DISABLE_NAVIGATION: {
            return {
                ...state,
                navigationDisabled: true,
            };
        }
        case SELECT_PLUGIN: {
            return {
                ...state,
                selectedPlugin: action.payload,
                selectedID: false,
                selectedName: false,
            };
        }
        case SELECT_ID: {
            return {
                ...state,
                selectedID: action.payload,
            };
        }
        case ADD_MAPPING: {
            return {
                ...state,
                selectedID: action.payload.business_id,
            };
        }
        case SELECT_NAME: {
            return {
                ...state,
                selectedName: action.payload,
            };
        }
        case UPDATE_DEVICE: {
            return {
                ...state,
                updateInProgress: true,
            };
        }
        case DEVICE_UPDATE_SUCCESS: {
            return {
                ...state,
                updateInProgress: false,
            };
        }
        case DEVICE_UPDATE_FAILED: {
            return {
                ...state,
                updateInProgress: false,
            };
        }
        case '@@router/LOCATION_CHANGE': {
            return {
                ...state,
                disabled: false,
                editMode: false,
                navigationDisabled: false,
                selectedPlugin: false,
                selectedID: false,
                selectedName: false,
                updateInProgress: false,
            };
        }
        case CHANGE_LANG: {
            save('lang', action.payload);
            return {
                ...state,
                lang: action.payload,
            };
        }
        case CHANGE_EDIT_MODE: {
            return {
                ...state,
                editMode: action.payload,
            };
        }
        case SET_MODAL_DATA: {
            return {
                ...state,
                modalData: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};


export default reducer;
