export const getEndpointURL = (plugin, device) => {
    if (!plugin || !device) {
        throw new Error('Missing parameters: plugin, device');
    }
    if (!plugin.type || !device.pos_network_ip) {
        return '';
    }

    const plugin_path = plugin.type.toLowerCase().replace('plugin', '');
    let endpoint_url = `http${device.ssl_enabled ? 's' : ''}://${device.pos_network_ip}/hapisrv/${plugin_path}/`;
    if (plugin.order_no > 1) {
        endpoint_url += `${plugin.order_no}/`;
    }
    return endpoint_url;
};
