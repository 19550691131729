import PropTypes from 'prop-types';
import React from 'react';

const ConfigurationHeader = ({title}) => (
    <div className="details-header">
        <div className="row">
            <div className="col-xs-6 box-title">
                <h2>{title}</h2>
            </div>
        </div>
    </div>
);

ConfigurationHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default ConfigurationHeader;
