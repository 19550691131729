import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import ConfigList from '../components/conf_template/list/configListComponent';
import {FETCH_CONFIGURATIONS} from '../redux/actionTypes';
import makeAction from '../redux/actions/common/makeAction';
import {get_default_state} from '../redux/reducers/deviceConfigurationsReducer';

class ConfigListPage extends React.Component {
    componentDidMount() {
        this.props.fetchConfigs({...get_default_state().params});
    }

    render() {
        return (
            <ConfigList/>
        );
    }
}

ConfigListPage.propTypes = {
    fetchConfigs: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    fetchConfigs: makeAction(FETCH_CONFIGURATIONS),
};

export default connect(null, mapDispatchToProps)(ConfigListPage);
