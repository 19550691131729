import {createSelector} from 'reselect';
import {deviceConfiguration} from '../deviceConfiguration/stateSelector';
import {deviceDetails, deviceDetailsConfiguration, selectedCompany} from './stateSelector';
import {editMode} from '../navigation/stateSelector';
import {companiesOptions, multiSelected, softList} from '../devices/stateSelector';
import {editableStatus, notValid} from './validation';
import {hasUserPermission} from '../../../services/auth';
import {permissionsSelector} from '../auth/stateSelector';
import * as permissions from '../../../configuration/permissions';
import _ from 'lodash';
import translate from 'counterpart';

const permsOptions = [
    {value: 'upgrade', label: translate('upgrade', {scope: 'devices'}), perm: permissions.UPGRADE_DEVICE},
    {value: 'timed_upgrade', label: translate('timed_upgrade', {scope: 'devices'}), perm: permissions.UPGRADE_DEVICE},
    {value: 'reboot', label: translate('reboot', {scope: 'devices'}), perm: permissions.REBOOT_DEVICE},
    {value: 'remove', label: translate('remove', {scope: 'devices'}), perm: permissions.DELETE_DEVICE},
    {value: 'manage_company', label: translate('manage_company', {scope: 'devices'}), perm: permissions.MANAGE_COMPANY},
];

export const optionsSelector = createSelector(
    permissionsSelector,
    (perms) => _.filter(permsOptions, (x) => hasUserPermission(perms, x.perm)).map((x) => ({
        value: x.value,
        label: x.label,
    }))
);

export const multiValue = createSelector(
    multiSelected,
    (multi) => ({
        isUpgrade: multi.value === 'upgrade',
        isTimedUpgrade: multi.value === 'timed_upgrade',
        isReboot: multi.value === 'reboot',
        isRemove: multi.value === 'remove',
        isManageCompany: multi.value === 'manage_company',
    })
);

export const actionsBar = createSelector(
    deviceConfiguration,
    deviceDetails,
    editMode,
    softList,
    multiSelected,
    editableStatus,
    multiValue,
    notValid,
    deviceDetailsConfiguration,
    optionsSelector,
    companiesOptions,
    selectedCompany,
    (config, details, edit, soft, multi, editable, {isUpgrade, isTimedUpgrade, isReboot, isRemove, isManageCompany}, error, detailsConfig, options, companies, company) => ({
        config,
        device: details,
        editMode: edit,
        listSoft: soft,
        selectedAction: multi.value,
        options,
        companies: [{label: 'Unassign', value: null}, ...companies],
        company,
        apply: {
            type: multi.value ? 'button' : null,
            dataToggle: multi.value ? 'modal' : null,
            dataTarget: isUpgrade ? '#installModal' : isTimedUpgrade ? '#timedUpgradeModal' :
                isReboot ? '#rebootModal' : isRemove ? '#removeModal' :
                    isManageCompany ? '#companyModal' : null,
            dataBackdrop: multi.value ? 'static' : null,
            dataKeyboard: multi.value ? 'false' : null,
            disabled:
                isUpgrade || isTimedUpgrade ? (!details.soft_update || !editable) :
                    isReboot ? !editable :
                        isRemove ? !editable : !isManageCompany,
        },
        error,
        editable: editable,
        softValue: multi.value === 'upgrade' || multi.value === 'timed_upgrade' ? details.soft_update :
            'Select version',
        configFromDevice: detailsConfig,
    })
);
