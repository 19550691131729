import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {MAPPING_TYPES} from '../../../../configuration/constants';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import MappingItemRow from './mappingItemRow';
import translate from 'counterpart';

const mappingRowNew = ({mapping, addMappingPair}) => {
    const collapseId = `collapseMapping${mapping.id}`;
    return (
        <div className="form-group row">
            <label className="control-label col-xs-2" htmlFor="value">{mapping.name}</label>
            <div className="col-xs-10">
                <div>
                    <button className="btn btn-default" type="button" data-toggle="collapse" data-target={`#${collapseId}`} aria-expanded="false" aria-controls="collapseExample">
                        Show mapping
                    </button>
                    <div className="collapse" id={collapseId} key={collapseId}>
                        <div>
                            <ul className="list-unstyled">
                                <li className="row">
                                    <div className={mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING ? 'col-xs-2' : 'col-xs-4'}>Gastrofix data</div>
                                    {mapping.type === MAPPING_TYPES.GROUP_VAT_MAPPING &&
                                        <div className="col-xs-2">{translate('vat_rate', {scope: 'devices.plugins'})}</div>
                                    }
                                    <div className="col-xs-4">{translate('hotel_system', {scope: 'devices.plugins'})}</div>
                                </li>
                                {mapping.items.map((pair, innerIdx) => (
                                    <MappingItemRow pair={pair} key={`mappingItem${innerIdx}`} mapping={mapping}/>
                                ))}
                                {mapping.type !== MAPPING_TYPES.SHIFT_CLOSE &&
                                <li>
                                    <div onClick={() => addMappingPair({
                                        pluginId: mapping.plugin,
                                        mappingId: mapping.id,
                                        mappingType: mapping.type,
                                    })}
                                    className="btn btn-md"><i className="fa fa-plus" />{translate('add_pair', {scope: 'devices.plugins'})}</div>
                                </li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

mappingRowNew.propTypes = {
    mapping: PropTypes.object.isRequired,
    addMappingPair: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    addMappingPair: makeAction(actionTypes.ADD_MAPPING_PAIR),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(mappingRowNew);
