import PropTypes from 'prop-types';
import React from 'react';

const SelectableHeaderCell = ({checked, onChange}) => (
    <th className="cell-xs">
        <div className="checkbox checkbox-wrapper">
            <input type="checkbox" id="checkAll" checked={checked} onChange={() => onChange()}/>
            <label htmlFor="checkAll"><span className="fake-checkbox" /></label>
        </div>
    </th>
);

SelectableHeaderCell.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SelectableHeaderCell;
