import React from 'react';

import ConfigsListTable from './configsListTable';
import ConfigListHeader from './ConfigListHeader';
import ConfigListPagination from './configListPagination';

const List = () => (
    <div className="content-wrapper ">
        <div className="container">
            <ConfigListHeader/>
            <ConfigsListTable/>
            <ConfigListPagination/>
        </div>
    </div>
);

List.propTypes = {};

export default List;
