import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';

const AcceptButton = ({device, changeModal, fetchConfiguration}) => (
    device.status === 'new' &&
        <button className="soft_install_button btn btn-default" onClick={() => {
            fetchConfiguration();
            changeModal({selectConfigModal: true});
        }}
        >
            Accept
        </button>
);

AcceptButton.propTypes = {
    device: PropTypes.object.isRequired,
    changeModal: PropTypes.func.isRequired,
    fetchConfiguration: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    changeModal: makeAction(actionTypes.CHANGE_MODAL),
    fetchConfiguration: makeAction(actionTypes.FETCH_DEVICE_CONFIGURATIONS_TO_PROVISIONE),
};

export default connect(null, mapDispatchToProps)(AcceptButton);
