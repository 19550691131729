import React from 'react';
import Mappings from './Mappings';
import Options from './Options';
import PluginHeader from './PluginHeader';
import ListPlugins from './ListPlugins';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import {hotelSystems} from '../../../../redux/selectors/device/hotelSystems';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class HotelSystems extends React.Component {
    componentDidMount() {
        this.props.select(this.props.firstPlugin);
    }

    componentDidUpdate(prevProps) {
        if (this.props.plugin.id !== prevProps.plugin.id) {
            this.props.selectID(false);
            this.props.selectName(false);
        }
        if (prevProps.pluginsLength > this.props.pluginsLength) {
            this.props.select(this.props.firstPlugin);
            this.props.fetchConfiguration({config: this.props.configDetails});
        }
        if (prevProps.pluginsLength < this.props.pluginsLength) {
            this.props.select(this.props.lastPlugin);
            this.props.fetchConfiguration({config: this.props.configDetails});
        }
        if (prevProps.toDelete !== this.props.toDelete && this.props.toDelete) {
            this.props.save({
                data: this.props.config,
                config: true,
                callback: false,
                device: {
                    ...this.props.device,
                    network_change_time: this.props.config.network_change_time,
                },
            });
        }
    }

    render() {
        return (
            <div className="details-content read-mode">
                <div className="row">
                    <ListPlugins/>
                    <ul className="col-xs-9 plugins-list">
                        <li className="list-group-item">
                            <PluginHeader/>
                            <div className="row">
                                <Options/>
                            </div>
                            <div className="row">
                                <Mappings/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

HotelSystems.propTypes = {
    fetchConfiguration: PropTypes.func.isRequired,
    firstPlugin: PropTypes.any.isRequired,
    plugin: PropTypes.any,
    name: PropTypes.any,
    id: PropTypes.any,
    select: PropTypes.func.isRequired,
    selectID: PropTypes.func.isRequired,
    selectName: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    toDelete: PropTypes.bool.isRequired,
    config: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    configDetails: PropTypes.object.isRequired,
    pluginsLength: PropTypes.number.isRequired,
    lastPlugin: PropTypes.any,
};

const mapDispatchToProps = {
    select: makeAction(actionTypes.SELECT_PLUGIN),
    selectID: makeAction(actionTypes.SELECT_ID),
    selectName: makeAction(actionTypes.SELECT_NAME),
    save: makeAction(actionTypes.UPDATE_DEVICE),
    fetchConfiguration: makeAction(actionTypes.FETCH_DEVICE_CONFIGURATION),
};

export default connect(hotelSystems, mapDispatchToProps)(HotelSystems);
