import React from 'react';
import translate from 'counterpart';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

const Styles = {
    label: {
        fontWeight: 'bold',
    },
    desc: {
        marginBottom: 12,
    },
};

const ModalStatus = () => (
    <div id="statusModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">×</button>
                    <h3 className="modal-title">{translate('statuses_explanation', {scope: 'devices.table_options'})}</h3>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('new', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('new', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('accepted', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('accepted', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('rejected', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('rejected', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('step1', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('bootstrapping', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('step2', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('installing_bridge_software', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('provisioned', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('provisioned', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('provisioning_failed', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('provisioning_failed', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('upgrading', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('upgrading', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('rebooting', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('rebooting', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('connected', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('connected', {scope: 'tooltips.status'})}</Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={Styles.label} className="text-right">{translate('not_connected', {scope: 'devices.table_options'})}</Col>
                        <Col xs={8} style={Styles.desc} className="overflowing">{translate('not_connected', {scope: 'tooltips.status'})}</Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">{translate('close', {scope: 'modals'})}</button>
                </div>
            </div>
        </div>
    </div>
);

export default ModalStatus;
