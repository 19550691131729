import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import DevicesList from '../components/devices/list';
import makeAction from '../redux/actions/common/makeAction';
import * as actionTypes from '../redux/actionTypes';
import {withRouter} from 'react-router';

class DevicesListPage extends React.Component {
    componentDidMount() {
        this.props.fetchDevices();
        this.props.fetchSoft();
        this.props.fetchCompanies();
    }

    componentDidUpdate() {
        this.props.fetchDevices();
        this.props.fetchSoft();
        this.props.fetchCompanies();
    }

    render() {
        return (
            <DevicesList/>
        );
    }
}

DevicesListPage.propTypes = {
    fetchDevices: PropTypes.func.isRequired,
    fetchSoft: PropTypes.func.isRequired,
    fetchCompanies: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    fetchDevices: makeAction(actionTypes.FETCH_DEVICES),
    fetchSoft: makeAction(actionTypes.FETCH_SOFT),
    fetchCompanies: makeAction(actionTypes.FETCH_COMPANIES),
};

export default withRouter(connect(null, mapDispatchToProps)(DevicesListPage));
