import PropTypes from 'prop-types';
import React from 'react';

const TabView = ({names, onChange, visible}) => (
    <ul className="border-top modal-nav list-inline list-unstyled row">
        {names.map((name, index) => (name &&
            <li key={index} className={index === visible ? 'active' : ''}
                onClick={() => onChange(index)}>{name}</li>
        ))}
    </ul>
);

TabView.propTypes = {
    names: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    visible: PropTypes.number.isRequired,
};

export default TabView;
