import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import translate from 'counterpart';

import makeAction from '../../../../redux/actions/common/makeAction';
import * as actionTypes from '../../../../redux/actionTypes';
import {INSTALL_PRINTER_PAGE} from '../../../../configuration/paths';
import tooltip from '../../../common/tooltip';

const PrinterActionButtons = ({printer, device_id}) => (
    <OverlayTrigger overlay={tooltip(translate('install_printer', {scope: 'devices.printers'}))}>
        <Link className="btn btn-info" to={INSTALL_PRINTER_PAGE(device_id, printer.hash)}>
            <i className={'fa fa-plus actions_dropdown_i'}/>{translate('install', {scope: 'devices.printers'})}
        </Link>
    </OverlayTrigger>
);

PrinterActionButtons.propTypes = {
    printer: PropTypes.object.isRequired,
    device_id: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
    removePrinter: makeAction(actionTypes.REMOVE_PRINTER),
};

export default connect(null, mapDispatchToProps)(PrinterActionButtons);
