import PropTypes from 'prop-types';
import React from 'react';
import SectionInput from '../../../../conf_template/form/rows/valueTypes/SectionInput';
import {connect} from 'react-redux';

import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import Select from 'react-select';
import NetworkHeader from './NetworkHeader';
import NetworkFields from './NetworkFields';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {deviceNetworkExtra} from '../../../../../redux/selectors/device/network';
import translate from 'counterpart';


const DeviceNetworkExtra = ({editMode, editable, extraEdit, name, header, extra, enabled, extraEnabled, options, setEthernet, tooltip, validation}) => (
    <li>
        <NetworkHeader name={name} header={header} extraEnabled={extraEnabled} status={extra.status} editMode={editMode} editable={editable}/>
        <div id={name} className="collapse" role="tabpanel" aria-labelledby="headingOne">
            {editMode && editable ?
                <div className="panel-body">
                    {extraEdit &&
                    <dl className="dl-casual dl-horizontal">
                        {enabled &&
                        <div>
                            <dt>
                                {translate('type', {scope: 'devices.device_network'})}:
                            </dt>
                            <dd className="overflowing">
                                <Select
                                    value={extraEdit.type}
                                    options={options}
                                    onChange={(option) => setEthernet({...extraEdit, type: option.value})}
                                    searchable={false}
                                    clearable={false}
                                />
                            </dd>
                            {extraEdit.type === 'STATIC' &&
                            <div>
                                <dt>
                                    {translate('ip_address', {scope: 'devices.device_network'})}:
                                    <OverlayTrigger overlay={tooltip(translate('ip_address', {scope: 'tooltips.device_details.network'}))}>
                                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                    </OverlayTrigger>
                                </dt>
                                <dd>
                                    <span className="mode-edit">
                                        <SectionInput
                                            setInput={setEthernet}
                                            section={extraEdit}
                                            fieldName="address"
                                            error={!validation.extra_ip_valid}
                                        />
                                        {!validation.extra_ip_valid &&
                                            <OverlayTrigger overlay={tooltip(translate('invallid_ip', {scope: 'validation'}))}>
                                                <i className="error-icon fa fa-exclamation-circle"/>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </dd>
                                <dt>
                                    {translate('subnet_mask', {scope: 'devices.device_network'})}:
                                    <OverlayTrigger overlay={tooltip(translate('subnet_mask', {scope: 'tooltips.device_details.network'}))}>
                                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                    </OverlayTrigger>
                                </dt>
                                <dd>
                                    <span className="mode-edit">
                                        <SectionInput
                                            setInput={setEthernet}
                                            section={extraEdit}
                                            fieldName="netmask"
                                            error={!validation.extra_mask_valid}
                                        />
                                        {!validation.extra_mask_valid &&
                                            <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                                <i className="error-icon fa fa-exclamation-circle"/>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </dd>
                                <dt>
                                    {translate('gateway', {scope: 'devices.device_network'})}:
                                    <OverlayTrigger overlay={tooltip(translate('gateway', {scope: 'tooltips.device_details.network'}))}>
                                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                    </OverlayTrigger>
                                </dt>
                                <dd>
                                    <span className="mode-edit">
                                        <SectionInput
                                            setInput={setEthernet}
                                            section={extraEdit}
                                            fieldName="gateway"
                                            error={!validation.extra_gateway_valid}
                                        />
                                        {!validation.extra_gateway_valid &&
                                            <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                                <i className="error-icon fa fa-exclamation-circle"/>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </dd>
                                <dt>
                                    {translate('dns_server', {scope: 'devices.device_network'})}:
                                    <OverlayTrigger overlay={tooltip(translate('dns_server', {scope: 'tooltips.device_details.network'}))}>
                                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                                    </OverlayTrigger>
                                </dt>
                                <dd>
                                    <span className="mode-edit">
                                        <SectionInput
                                            setInput={setEthernet}
                                            section={extraEdit}
                                            fieldName="dns_nameservers"
                                            error={!validation.extra_dns_valid}
                                        />
                                        {!validation.extra_dns_valid &&
                                            <OverlayTrigger overlay={tooltip(translate('invalid_ip', {scope: 'validation'}))}>
                                                <i className="error-icon fa fa-exclamation-circle"/>
                                            </OverlayTrigger>
                                        }
                                    </span>
                                </dd>
                            </div>
                            }
                        </div>
                        }
                    </dl>
                    }
                </div>
                :
                extraEnabled && <NetworkFields obj={extra}/>
            }
        </div>
    </li>
);

DeviceNetworkExtra.propTypes = {
    editMode: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    extraEdit: PropTypes.object,
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    extra: PropTypes.object,
    enabled: PropTypes.bool.isRequired,
    extraEnabled: PropTypes.bool.isRequired,
    setEthernet: PropTypes.func.isRequired,
    tooltip: PropTypes.func.isRequired,
    validation: PropTypes.object,
    options: PropTypes.array.isRequired,
};

const mapDispatchToProps = {
    setEthernet: makeAction(actionTypes.SET_ETHERNET),
};

export default connect(
    deviceNetworkExtra,
    mapDispatchToProps
)(DeviceNetworkExtra);
