import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {connect} from 'react-redux';
import Select from 'react-select';
import translate from 'counterpart';

import {pluginHeader} from '../../../../redux/selectors/device/hotelSystems';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import * as permissions from '../../../../configuration/permissions';
import PermissionCheck from '../../../authorization/PermissionCheck';
import tooltip from '../../../common/tooltip';

let selectedPluginTypeValue = null;

const PluginHeader = ({plugin, setEdit, edit, fetchConfiguration, config, configEdit, device,
    save, add, options, pluginsToAdd, inProgress, disabled, notValid}) => (
    <div className="plugin-header">
        {plugin === 'add' || plugin.id === 'new' ?
            <PermissionCheck permission={permissions.ADD_PLUGIN}>
                <div className="row">
                    <div className="col-xs-6">
                        <Select
                            value={selectedPluginTypeValue}
                            valueKey="value"
                            options={options}
                            disabled={disabled}
                            onChange={(option) => {
                                fetchConfiguration({config});
                                add(pluginsToAdd[option.value]);
                                selectedPluginTypeValue = option.value;
                            }}
                            searchable={false}
                            clearable={false}
                        />
                    </div>
                    <div className="col-xs-6 text-right">
                        <div className="table-panel">
                            <button
                                id="saveChanges"
                                className="btn btn-info shuffle-modes"
                                disabled={disabled || plugin === 'add' || inProgress}
                                onClick={() => {
                                    save({
                                        data: configEdit,
                                        config: true,
                                        callback: false,
                                        device: {
                                            ...device,
                                            network_change_time: configEdit.network_change_time,
                                        },
                                    });
                                }}
                            >
                                {translate('add_plugin', {scope: 'devices.plugins'})}
                            </button>
                        </div>
                    </div>
                </div>
            </PermissionCheck>
            :
            <div className="row ">
                <div className="col-xs-6">
                    <h3>
                        {plugin.name} ({plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'} {plugin.order_no})
                    </h3>
                </div>
                <div className="col-xs-6 text-right">
                    {edit ?
                        <div className="table-panel">
                            <button
                                id="revertChanges"
                                className="btn btn-default shuffle-modes"
                                onClick={() => setEdit(false)}
                            >
                                {translate('cancel', {scope: 'devices.device_details'})}
                            </button>
                            <button
                                id="saveChanges"
                                className="btn btn-info shuffle-modes"
                                disabled={notValid}
                                onClick={() => {
                                    save({
                                        data: configEdit,
                                        config: true,
                                        callback: false,
                                        device: {
                                            ...device,
                                            network_change_time: configEdit.network_change_time,
                                        },
                                    });
                                    setEdit(false);
                                }}
                            >
                                {translate('save_changes', {scope: 'devices.device_details'})}
                            </button>
                        </div>
                        :
                        <div className="table-panel">
                            <PermissionCheck permission={permissions.EDIT_PLUGIN}>
                                <OverlayTrigger placement="bottom" overlay={tooltip(translate('edit_tab', {scope: 'tooltips.device_details'}))}>
                                    <span
                                        id="edit"
                                        className="btn btn-info shuffle-modes"
                                        disabled={disabled || inProgress}
                                        onClick={() => {
                                            if (!(disabled || inProgress)) {
                                                fetchConfiguration({config});
                                                setEdit(true);
                                            }
                                        }}
                                    >
                                        {translate('edit_tab', {scope: 'devices.device_details'})}
                                    </span>
                                </OverlayTrigger>
                            </PermissionCheck>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
);


PluginHeader.propTypes = {
    plugin: PropTypes.any,
    config: PropTypes.object.isRequired,
    notValid: PropTypes.bool.isRequired,
    configEdit: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    setEdit: PropTypes.func.isRequired,
    fetchConfiguration: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    pluginsToAdd: PropTypes.object.isRequired,
    select: PropTypes.func.isRequired,
    toAdd: PropTypes.string,
    inProgress: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    setEdit: makeAction(actionTypes.CHANGE_EDIT_MODE),
    fetchConfiguration: makeAction(actionTypes.FETCH_DEVICE_CONFIGURATION),
    save: makeAction(actionTypes.UPDATE_DEVICE),
    add: makeAction(actionTypes.ADD_PLUGIN),
    select: makeAction(actionTypes.SELECT_PLUGIN),
};

export default connect(pluginHeader, mapDispatchToProps)(PluginHeader);
