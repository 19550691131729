import React from 'react';
import PluginDetails from './PluginDetails';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {pluginOptions} from '../../../../redux/selectors/device/hotelSystems';
import PluginEdit from './PluginEdit';
import translate from 'counterpart';
import ClipboardJS from 'clipboard';
import {getEndpointURL} from './helpers';

class Options extends React.Component {
    componentDidMount() {
        this.clipboard = new ClipboardJS('.clipboard');
    }

    componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    }

    render() {
        const {plugin, device, edit, validation} = this.props;
        const endpoint_url = getEndpointURL(plugin, device);
        return (
            <div className="col-xs-10 col-xs-offset-1">
                <h4>{translate('options', {scope: 'devices.plugins'})}</h4>
                {edit || plugin.id === 'new' ?
                    <PluginEdit plugin={plugin} endpoint_url={endpoint_url} validation={validation}/>
                    :
                    <PluginDetails plugin={plugin} endpoint_url={endpoint_url}/>
                }
            </div>
        );
    }
}

Options.propTypes = {
    plugin: PropTypes.any.isRequired,
    device: PropTypes.any.isRequired,
    edit: PropTypes.bool.isRequired,
    validation: PropTypes.bool.isRequired,
};

export default connect(pluginOptions)(Options);
