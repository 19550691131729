import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import _ from 'lodash';
import {DEVICE_DETAILS_PAGE} from '../../../../../configuration/paths';
import translate from 'counterpart';

const filledIn = ({installed_printer, selected_driver}) => (
    (installed_printer.name && installed_printer.description && installed_printer.location).length > 0 && !_.isEmpty(selected_driver)
);

const Stage2Footer = ({device_id, selected_driver, installed_printer, installPrinterAction, changePrinterStageAction, history}) => {
    const callback = () => history.push(DEVICE_DETAILS_PAGE(device_id));
    return (
        <div className="row">
            <div className="col-xs-12 text-right buttons-wrapper">
                <button type="button" className="soft_install_button btn btn-default pull-right"
                    onClick={() => changePrinterStageAction('one')}>
                    <span><i className="fa fa-angle-left"/><span> {translate('back', {scope: 'devices.printers'})}</span></span>
                </button>
                <button disabled={!filledIn({installed_printer, selected_driver})} type="button"
                    className="pull-right btn btn-md btn-primary"
                    onClick={() => installPrinterAction(
                        {
                            id: device_id,
                            printer: installed_printer,
                            driver: selected_driver.value,
                            callback: callback,
                        }
                    )}>
                    <span><i className="fa fa-check"/><span>{translate('install', {scope: 'devices.printers'})}</span></span>
                </button>
            </div>
        </div>
    );
};

Stage2Footer.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    device_id: PropTypes.string.isRequired,
    changePrinterStageAction: PropTypes.func.isRequired,
    installPrinterAction: PropTypes.func.isRequired,
    selected_driver: PropTypes.object.isRequired,
    installed_printer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    selected_driver: state.device.selected_driver,
    installed_printer: state.device.installed_printer,
});

const mapDispatchToProps = {
    changePrinterStageAction: makeAction(actionTypes.CHANGE_PRINTER_STAGE),
    installPrinterAction: makeAction(actionTypes.PRE_INSTALL_PRINTER),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Stage2Footer));
