import PropTypes from 'prop-types';
import React from 'react';
import Highlight from 'react-highlight/lib/optimized';
import ClipboardJS from 'clipboard';
import translate from 'counterpart';
import Modal from 'react-bootstrap/lib/Modal';
import {connect} from 'react-redux';

import InvoiceDetails from './InvoiceDetails';
import TabView from '../../../common/TabView';
import {withRouter} from 'react-router-dom';
import {FETCH_INVOICE} from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import {invoiceDetailsSelector} from '../../../../redux/selectors/device/invoice';


class InvoiceModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {visible: 0};
    }

    componentWillMount() {
        const qString = new URLSearchParams(this.props.location.search.substr(1));
        if (qString.has('tab')) {
            const topStr = qString.get('tab');
            try {
                let top = parseInt(topStr, 10);
                // we have only 4 tabs, if a wrong number was entered just fallback to 0
                if (top > 3 || top < 0) {
                    top = 0;
                }
                this.setState({visible: top});
            } catch (e) {
                console.error(e);
            }
        }
    }

    componentDidMount() {
        this.clipboard = new ClipboardJS('.clipboard');
        this.props.fetchInvoice({deviceId: this.props.match.params.deviceId, invoiceId: this.props.match.params.invoiceId});
    }

    componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    }

    close(e) {
        if (e) {
            e.preventDefault();
        }
        this.props.history.push(`/device/${this.props.match.params.deviceId}/invoices`);
    }

    tabClicked(index) {
        this.setState({visible: index});
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?tab=${index}`,
        });
    }

    render() {
        const TabContents = () => {
            switch (this.state.visible) {
                case 1:
                    return (
                        <Modal.Body>
                            <h4 className="inline-block">{translate('xml_pos_rpi', {scope: 'logging.label'})}</h4>
                            <button className="clipboard btn btn-default copy-button pull-right"
                                data-clipboard-target=".xml_pos">{translate('copy', {scope: 'common'})}</button>
                            <Highlight languages={['xml']} className="xml xml_pos">{this.props.invoice.xml_pos}</Highlight>
                        </Modal.Body>
                    );
                case 2:
                    return (
                        <Modal.Body>
                            <h4 className="inline-block">{translate('xml_rpi_pms', {scope: 'logging.label'})}</h4>
                            <button className="clipboard btn btn-default copy-button pull-right"
                                data-clipboard-target=".xml_bridge">{translate('copy', {scope: 'common'})}</button>
                            <Highlight languages={['xml']} className="xml xml_bridge">{this.props.invoice.xml_bridge}</Highlight>
                        </Modal.Body>
                    );
                case 3:
                    return (
                        <Modal.Body>
                            <h4 className="inline-block">{translate('xml_pms', {scope: 'logging.label'})}</h4>
                            <button className="clipboard btn btn-default copy-button pull-right"
                                data-clipboard-target=".xml_pms">{translate('copy', {scope: 'common'})}</button>
                            <Highlight languages={['xml']} className="xml xml_pms">{this.props.invoice.xml_pms}</Highlight>
                        </Modal.Body>
                    );
                default:
                    return <InvoiceDetails invoice={this.props.invoice}/>;
            }
        };
        return (
            <Modal id="invoiceModal" bsSize="xxl" show={true} aria-labelledby="invoiceDetailsModal" onHide={(e) => this.close(e)}>
                <Modal.Header>
                    <button id="closeModalBtn" type="button" className="close" onClick={(e) => this.close(e)}>&times;</button>
                    <h3 className="modal-title">{translate('invoice', {scope: 'devices.invoices'})} {this.props.invoice.invoicenumber}</h3>
                </Modal.Header>
                <TabView visible={this.state.visible} names={[
                    translate('info', {scope: 'common'}),
                    this.props.invoice.xml_pos && translate('xml_pos_rpi', {scope: 'logging.label'}),
                    this.props.invoice.xml_bridge && translate('xml_rpi_pms', {scope: 'logging.label'}),
                    this.props.invoice.xml_pms && translate('xml_pms', {scope: 'logging.label'}),
                ]} onChange={(i) => this.tabClicked(i)}
                />
                <TabContents/>
                <Modal.Footer>
                    <button type="button" className="btn btn-default" onClick={(e) => this.close(e)}>{translate('close', {scope: 'modals'})}</button>
                </Modal.Footer>
            </Modal>
        );
    }
}


InvoiceModal.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    fetchInvoice: PropTypes.func.isRequired,
    invoice: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    fetchInvoice: makeAction(FETCH_INVOICE),
};

export default connect(invoiceDetailsSelector, mapDispatchToProps)(withRouter(InvoiceModal));
