import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import translate from 'counterpart';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import tooltip from '../../../../common/tooltip';
import CopyButton from '../../CopyButton';
import CollapsingGroup from '../CollapsingGroup';

// TODO: create selector for strings

const SihotPlugin = ({plugin, endpoint_url}) => (
    <div key="sihot-main-block">
        <CollapsingGroup id="mainInfo" name="Main Plugin Information">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('name', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('name', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.name || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('type', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('type', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.type ? translate(plugin.type, {scope: 'devices.plugins'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('is_active', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('is_active', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>
                    {plugin.is_active ? translate('active', {'scope': 'common'}) : translate('inactive', {'scope': 'common'})}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('endpoint_url', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('endpoint_url', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                    <CopyButton target={`endpoint_url_${plugin.id}`} />
                </Col>
                <Col xs={6} id={`endpoint_url_${plugin.id}`}>{endpoint_url || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="pmsConnection" name="PMS Connection">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('url', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('url', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.url || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('api_key', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('api_key', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.api_key || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('hotel', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('hotel', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.hotel || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="timeouts" name="Timeouts, Intervals & Amounts">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_interval', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.shift_close_check_interval || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_retry_interval', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close_retry_interval', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.shift_close_retry_interval || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close_max_retry_attempts', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close_max_retry_attempts', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.shift_close_max_retry_attempts || '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="features" name="Features">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('negative_postings', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('negative_postings', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.allow_negative_postings.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('block_zero_payments', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('block_zero_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.block_zero_payments.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('duplicate_check', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('duplicate_check', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate((!plugin.disable_duplicate_check).toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('serviceperiod', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('serviceperiod', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.enable_serviceperiod.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('shift_close', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('shift_close', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate((!plugin.disable_shift_close).toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('internal', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('internal', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.forward_internal ? 'Forward' : 'Block'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('nonturnover', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('nonturnover', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.forward_nonturnover ? 'Forward' : 'Block'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('midnight_separation', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('midnight_separation', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.midnight_separation.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('substract_tip_payments', {scope: 'devices.plugins'})}:
                    <OverlayTrigger overlay={tooltip(translate('substract_tip_payments', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{translate(plugin.substract_tip_payments.toString(), {scope: 'common'})}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.constraints_behavior'})}:
                    <OverlayTrigger overlay={
                        tooltip(translate('constraints_behavior', {scope: 'tooltips.hotel_systems.plugin'}) +
                            '\n' + translate('all', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('non_zero', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_all_non_zero_items', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('items', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_sub_items_only', {scope: 'tooltips.hotel_systems.items_behaviour'}) +
                            '\n' + translate('main', {scope: 'devices.plugins.constraints_behavior'}) + ' - ' + translate('send_constraint_main_item', {scope: 'tooltips.hotel_systems.items_behaviour'})
                        )}
                    >
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.constraints_behavior ? translate(plugin.constraints_behavior, {scope: 'devices.plugins.constraints_behavior'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.aggregated_items_behavior'})}:
                    <OverlayTrigger overlay={tooltip(translate('aggregated_items_behavior', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.aggregated_items_behavior ? translate(plugin.aggregated_items_behavior, {scope: 'devices.plugins.aggregated_items_behavior'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.cumulated_shift_close'})}:
                    <OverlayTrigger overlay={tooltip(translate('cumulated_shift_close', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.cumulated_shift_close ? translate(plugin.cumulated_shift_close, {scope: 'devices.plugins.cumulated_shift_close'}) : '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('label', {scope: 'devices.plugins.cumulated_invoice_to_room'})}:
                    <OverlayTrigger overlay={tooltip(translate('cumulated_invoice_to_room', {scope: 'tooltips.hotel_systems.plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.cumulated_invoice_to_room ? translate(plugin.cumulated_invoice_to_room, {scope: 'devices.plugins.cumulated_invoice_to_room'}) : '---'}</Col>
            </Row>
        </CollapsingGroup>
        <CollapsingGroup id="others" name="Others">
            <Row>
                <Col xs={6} className="options-label">
                    {translate('gratuity_id', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('gratuity_id', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.gratuity_id || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('gratuity_group_id', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('gratuity_group_id', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.gratuity_group_id || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('gratuity_super_group_id', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('gratuity_super_group_id', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.gratuity_supergroup_id || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('gratuity_label', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('gratuity_label', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.gratuity_label || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('gratuity_payment_method', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('gratuity_payment_method', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.gratuity_payment_method || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('decimal_precision', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('decimal_precision', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.decimal_precision || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('decimal_separator', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('decimal_separator', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.decimal_separator || '---'}</Col>
            </Row>
            <Row>
                <Col xs={6} className="options-label">
                    {translate('gratuity_payment_method', {scope: 'devices.plugins.sihot'})}:
                    <OverlayTrigger overlay={tooltip(translate('gratuity_payment_method', {scope: 'tooltips.hotel_systems.sihot_plugin'}))}>
                        <i className="tooltip-trigger inline-block fa fa-info-circle"/>
                    </OverlayTrigger>
                </Col>
                <Col xs={6}>{plugin.gratuity_payment_method || '---'}</Col>
            </Row>
        </CollapsingGroup>
    </div>
);

SihotPlugin.propTypes = {
    plugin: PropTypes.object.isRequired,
    endpoint_url: PropTypes.string.isRequired,
};

export default SihotPlugin;
