import React from 'react';
import {Route, Switch} from 'react-router-dom';
import * as paths from './paths';
// pages
import LogIn from '../containers/LogInPage';
import NavigationWrapper from '../containers/NavigationWrapper';
import DevicesList from '../containers/DevicesListPage';
import ConfigDetails from '../containers/ConfigDetailsPage';
import Details from '../containers/DeviceDetailsPage';
import InstallPrinter from '../containers/InstallPrinterPage';
import PreInstallPrinter from '../containers/PreInstallPrinterPage';
import EditPrinter from '../containers/EditPrinterPage';
import Template from '../containers/TemplatesPage';
import ConfigsList from '../containers/ConfigListPage';
import ImageList from '../containers/ImageList';
import NotFoundPage from '../containers/NotFoundPage';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import PrintTemplateEditPage from '../containers/PrintTemplateEditPage';
import DeviceDetails from '../components/devices/details/info/DeviceDetails';
import HotelSystems from '../components/devices/details/plugins/HotelSystems';
import DevicePrinters from '../components/devices/details/DevicePrinters';
import DeviceInvoices from '../components/devices/details/DeviceInvoices';
import DeviceLog from '../components/devices/details/DeviceLog';
import DeviceTools from '../components/devices/details/DeviceTools';
import InvoiceModal from '../components/devices/details/invoices/InvoiceModal.jsx';
// guardians
import inLoggedIn from './guardians/isLoggedIn';
import IsNotLoggedIn from './guardians/isNotLoggedIn';

const routes = (
    <NavigationWrapper>
        <Switch>
            <Route exact path={paths.LOGIN_PAGE} render={() => IsNotLoggedIn(<LogIn/>)}/>
            <Route exact path={paths.DASHBOARD_PAGE} render={() => inLoggedIn(<DevicesList/>)}/>
            <Route path={paths.DEVICE_DETAILS_PAGE(':deviceId')} render={() => (
                <Details>
                    <Switch>
                        <Route exact path={paths.DEVICE_DETAILS_PAGE(':deviceId')} render={() => inLoggedIn(<DeviceDetails/>)}/>
                        <Route exact path={paths.DEVICE_CONFIG_PAGE(':deviceId')} render={() => inLoggedIn(<HotelSystems/>)}/>
                        <Route exact path={paths.DEVICE_PRINTERS_PAGE(':deviceId')} render={() => inLoggedIn(<DevicePrinters/>)}/>
                        <Route path={paths.DEVICE_INVOICES_PAGE(':deviceId')} render={() => inLoggedIn(<DeviceInvoices/>)}/>
                        <Route exact path={paths.DEVICE_LOG_PAGE(':deviceId')} render={() => inLoggedIn(<DeviceLog/>)}/>
                        <Route exact path={paths.DEVICE_TOOLS_PAGE(':deviceId')} render={() => inLoggedIn(<DeviceTools/>)}/>
                        <Route exact path={paths.EDIT_PRINT_TEMPLATE_PAGE(':deviceId', ':templateId')} render={() => inLoggedIn(<PrintTemplateEditPage/>)}/>
                        <Route exact path={paths.INSTALL_PRINTER_PAGE(':deviceId', ':printerHash')} render={() => inLoggedIn(<InstallPrinter/>)}/>
                        <Route exact path={paths.PRE_INSTALL_PRINTER_PAGE(':deviceId')} render={() => inLoggedIn(<PreInstallPrinter/>)}/>
                        <Route exact path={paths.EDIT_PRINTER_PAGE(':deviceId', ':printerHash')} render={() => inLoggedIn(<EditPrinter/>)}/>
                    </Switch>
                    <Route exact path={paths.DEVICE_INVOICE_DETAILS(':deviceId', ':invoiceId')} render={() => inLoggedIn(<InvoiceModal/>)}/>
                </Details>
            )}/>
            <Route exact path={paths.CONFIGS_LIST_PAGE} render={() => inLoggedIn(<ConfigsList/>)}/>
            <Route exact path={paths.TEMPLATE_PAGE} render={() => inLoggedIn(<Template/>)}/>
            <Route exact path={paths.CONFIG_DETAILS_PAGE(':configId')} render={() => inLoggedIn(<ConfigDetails/>)}/>
            <Route exact path={paths.IMAGES_LIST_PAGE} render={() => inLoggedIn(<ImageList/>)}/>
            <Route exact path={paths.UNAUTHORIZED_PAGE} render={() => <UnauthorizedPage/>}/>
            <Route path={paths.NOT_FOUND_PAGE} render={() => <NotFoundPage/>}/>
        </Switch>
    </NavigationWrapper>
);

export default routes;
