export const DASHBOARD_PAGE = '/';
export const NOT_FOUND_PAGE = DASHBOARD_PAGE + 'notfound';
export const UNAUTHORIZED_PAGE = DASHBOARD_PAGE + 'unauthorized';
export const LOGIN_PAGE = DASHBOARD_PAGE + 'login';
export const DEVICE_PAGE = DASHBOARD_PAGE + 'device';
export const TEMPLATE_PAGE = DASHBOARD_PAGE + 'template';
export const CONFIGS_LIST_PAGE = DASHBOARD_PAGE + 'configs';
export const IMAGES_LIST_PAGE = DASHBOARD_PAGE + 'images';

export const DEVICE_DETAILS_PAGE = (id) => `${DEVICE_PAGE}/${id}`;
export const DEVICE_CONFIG_PAGE = (id) => `${DEVICE_DETAILS_PAGE(id)}/config`;
export const DEVICE_PRINTERS_PAGE = (id) => `${DEVICE_DETAILS_PAGE(id)}/printers`;
export const DEVICE_INVOICES_PAGE = (id) => `${DEVICE_DETAILS_PAGE(id)}/invoices`;
export const DEVICE_INVOICE_DETAILS = (id, invoiceId) => `${DEVICE_DETAILS_PAGE(id)}/invoices/${invoiceId}`;
export const DEVICE_LOG_PAGE = (id) => `${DEVICE_DETAILS_PAGE(id)}/logs`;
export const DEVICE_TOOLS_PAGE = (id) => `${DEVICE_DETAILS_PAGE(id)}/tools`;
export const CONFIG_DETAILS_PAGE = (id) => `${CONFIGS_LIST_PAGE}/${id}`;
export const INSTALL_PRINTER_PAGE = (device_id, id) => `${DEVICE_DETAILS_PAGE(device_id)}/installPrinter/${id}`;
export const PRE_INSTALL_PRINTER_PAGE = (device_id) => `${DEVICE_DETAILS_PAGE(device_id)}/preInstallPrinter/`;
export const EDIT_PRINTER_PAGE = (device_id, id) => `${DEVICE_DETAILS_PAGE(device_id)}/editPrinter/${id}`;

export const EDIT_PRINT_TEMPLATE_PAGE = (device_id, id) => `${DEVICE_DETAILS_PAGE(device_id)}/editPrintTemplate/${id}`;
