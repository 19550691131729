import {all} from 'redux-saga/effects';
import auth from './authSaga';
import devices from './devices/devicesSaga';
import device from './devices/details/deviceSaga';
import rpiImages from './sysImageSaga';
import soft from './softSaga';
import deviceConfigurationsSaga from './deviceConfigurationsSaga';
import fetchCompanies from './companySaga';

export default function *rootSaga() {
    yield all([
        auth(),
        fetchCompanies(),
        devices(),
        device(),
        rpiImages(),
        soft(),
        deviceConfigurationsSaga(),
    ]);
}
