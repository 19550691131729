import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Header from './header';
import DetailsLinks from './DetailsLinks';

const DeviceDetailsPage = ({device, children, disabled}) => {
    if (!device.hasOwnProperty('name')) {
        return <div/>;
    }
    if (disabled) {
        return (
            <div>
                {children}
            </div>
        );
    }
    return (
        <div className="content-wrapper">
            <div className="container">
                <div className="content">
                    <Header device={device}/>
                    <DetailsLinks device={device}/>
                    {children}
                </div>
            </div>
        </div>
    );
};

DeviceDetailsPage.propTypes = {
    device: PropTypes.object,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    device: state.device.device,
});

export default connect(
    mapStateToProps,
    null
)(DeviceDetailsPage);
