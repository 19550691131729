import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILED,
} from '../actionTypes';

const DEFAULT_STATE = {
    list: [],
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPANIES: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case FETCH_COMPANIES_SUCCESS: {
            return {
                ...state,
                inProgress: false,
                list: action.payload,
            };
        }
        case FETCH_COMPANIES_FAILED: {
            return {
                ...state,
                inProgress: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
