import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../../../redux/actionTypes';
import makeAction from '../../../../redux/actions/common/makeAction';
import MappingRow from './mappingRow';
import translate from 'counterpart';

const PluginMappings = ({plugin, changeBusiness, removeMapping, pluginMappings, mappingGroup}) => {
    const [firstMapping] = pluginMappings;
    return (
        <div className="mapping-wrapper">
            <div className="form-group">
                <label className="control-label col-xs-2">{translate('business_id', {scope: 'devices.plugins'})}:</label>
                <div className="col-xs-10">
                    <div className="col-xs-9 no-padd">
                        <input value={(firstMapping.business_id || '')}
                            onChange={(event) => changeBusiness({
                                pluginId: plugin.id,
                                business_id: event.target.value,
                                mappingGroup,
                            })}
                            className="form-control"
                            placeholder={translate('type_business_id', {scope: 'placeholders'})}
                        />
                    </div>
                    <span onClick={() => removeMapping({pluginId: plugin.id, mappingGroup})}
                        className="btn btn-md pull-right">
                        <i className="fa fa-minus"/>
                        {translate('remove_this_mapping', {scope: 'devices.plugins'})}
                    </span>
                </div>
            </div>
            {pluginMappings.map((mapping, key) => {
                if ((plugin.type === 'OraclePlugin' || plugin.type === 'ProtelPlugin') && mapping.name === 'outlet_map') {
                    if (!plugin.business_id_outlet) {
                        return <MappingRow key={mapping.id} id={mapping.id} mapping={mapping}/>;
                    }
                    return <div key={key}/>;
                }
                return <MappingRow key={mapping.id} id={mapping.id} mapping={mapping}/>;
            })}
        </div>
    );
};

PluginMappings.propTypes = {
    changeBusiness: PropTypes.func.isRequired,
    removeMapping: PropTypes.func.isRequired,
    pluginMappings: PropTypes.array.isRequired,
    plugin: PropTypes.object.isRequired,
    mappingGroup: PropTypes.number.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    changeBusiness: makeAction(actionTypes.CHANGE_BUSINESS),
    removeMapping: makeAction(actionTypes.REMOVE_MAPPING),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PluginMappings);
