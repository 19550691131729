import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as actionTypes from '../../../../../redux/actionTypes';
import makeAction from '../../../../../redux/actions/common/makeAction';
import translate from 'counterpart';

const filledIn = ({installed_printer}) => (
    (installed_printer.uri && installed_printer.model && installed_printer.make).length > 0
);

const PrinterModelMake = ({device_id, installed_printer, changePrinterUriAction, changePrinterModelAction, changePrinterMakeAction, pullPrinterDriversActions}) => (
    <form className="form-horizontal">
        <div className="form-group">
            <label className="control-label col-xs-2" htmlFor="Name">{translate('url', {scope: 'devices.printers'})}:</label>
            <div className="col-xs-10">
                <input className="form-control" id="Name" defaultValue={installed_printer.uri}
                    onBlur={(event) => changePrinterUriAction(event.target.value)}/>
            </div>
        </div>
        <div className="form-group">
            <label className="control-label col-xs-2" htmlFor="Name">{translate('make', {scope: 'devices.printers'})}:</label>
            <div className="col-xs-10">
                <input className="form-control" id="Name" defaultValue={installed_printer.make}
                    onBlur={(event) => changePrinterMakeAction(event.target.value)}/>
            </div>
        </div>
        <div className="form-group">
            <label className="control-label col-xs-2" htmlFor="Name">{translate('model', {scope: 'devices.printers'})}:</label>
            <div className="col-xs-10">
                <input className="form-control" id="Name" defaultValue={installed_printer.model}
                    onBlur={(event) => changePrinterModelAction(event.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 text-right buttons-wrapper">
                {filledIn({installed_printer}) ?
                    <button type="button" className="pull-right btn btn-md btn-primary"
                        onClick={() => {
                            pullPrinterDriversActions({
                                id: device_id,
                                printer: {make: installed_printer.make, model: installed_printer.model},
                            });
                        }}>
                        <span><span>{translate('next', {scope: 'devices.printers'})}</span><i className="fa fa-arrow-right"/></span>
                    </button>
                    :
                    <button disabled type="button" className="pull-right btn btn-md btn-primary">
                        <span><span>{translate('not_detected', {scope: 'devices.printers'})}next</span><i className="fa fa-arrow-right"/></span>
                    </button>
                }
            </div>
        </div>
    </form>
);

PrinterModelMake.propTypes = {
    installed_printer: PropTypes.object.isRequired,
    changePrinterUriAction: PropTypes.func.isRequired,
    changePrinterModelAction: PropTypes.func.isRequired,
    changePrinterMakeAction: PropTypes.func.isRequired,
    pullPrinterDriversActions: PropTypes.func.isRequired,
    device_id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    installed_printer: state.device.installed_printer,
});

const mapDispatchToProps = {
    changePrinterUriAction: makeAction(actionTypes.CHANGE_PRINTER_URI),
    changePrinterModelAction: makeAction(actionTypes.CHANGE_PRINTER_MODEL),
    changePrinterMakeAction: makeAction(actionTypes.CHANGE_PRINTER_MAKE),
    pullPrinterDriversActions: makeAction(actionTypes.PULL_PRINTERS_DRIVERS),
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterModelMake);
