import {DEVICE_STATUS} from './constants';
import _ from 'lodash';

const apply_to_all = (arr, ...func_arr) => {
    for (const obj of arr) {
        for (const func of func_arr) {
            if (!func(obj)) {
                return false;
            }
        }
    }
    return true;
};

export const isNotEmpty = (...strings) => apply_to_all(strings, (str) => Boolean(str));

const editableStatuses = [DEVICE_STATUS.NEW, DEVICE_STATUS.PROVISIONED, DEVICE_STATUS.CONNECTED];
export const checkStatus = (obj) => _.includes(editableStatuses, obj);

export const nameRegex = /^.{0,50}$/; // eslint-disable-line require-unicode-regexp
export const isValidName = (...strings) => apply_to_all(strings, nameRegex.test.bind(nameRegex));

export const numberRegex = /(^$|^[0-9]*$)/; // eslint-disable-line require-unicode-regexp
export const isValidNumber = (...strings) => apply_to_all(strings, numberRegex.test.bind(numberRegex));

export const idsRegex = /^$|^[0-9 _,]*$/; // eslint-disable-line require-unicode-regexp
export const isValidID = (...strings) => apply_to_all(strings, idsRegex.test.bind(idsRegex));

export const ipRegex = /(^$|^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$)/; // eslint-disable-line require-unicode-regexp
export const isValidIP = (...strings) => apply_to_all(strings, (obj) => !obj || ipRegex.test(obj));

export function isValidIPs(field) {
    const addresses = field.split(/\s* \s*/u);
    for (let i = 0; i < addresses.length; i += 1) {
        if (!ipRegex.test(addresses[i])) {
            return false;
        }
    }
    return true;
}

// TODO: fix this
export const proxyRegex = /(^$|^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):[0-9]*$)/; // eslint-disable-line require-unicode-regexp
export const isValidProxy = (...strings) => apply_to_all(strings, (obj) => !obj || proxyRegex.test(obj));
