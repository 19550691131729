import * as actionTypes from '../actionTypes';
import {get, remove, save} from '../../services/persist';

export const get_default_state = () => ({
    count: 0,
    params: {
        kind: '',
        limit: parseInt(get('configs_limit'), 10) || save('configs_limit', 15),
        name: '',
        ordering: get('configs_sort') || 'id',
        page: parseInt(get('configs_page'), 10) || save('configs_page', 1),
        template_level: '',
    },
    inProgress: false,
    results: [],
});

const deviceConfigurationsReducer = (state = get_default_state(), action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return get_default_state();
        }
        case actionTypes.FETCH_CONFIGURATIONS: {
            return {
                ...state,
                inProgress: true,
            };
        }
        case actionTypes.FETCH_CONFIGURATIONS_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                inProgress: false,
            };
        }
        case actionTypes.CONFIG_LIST_CLEAR_PARAMS: {
            remove('configs_page');
            remove('configs_sort');
            return {
                ...state,
                params: get_default_state().params,
                inProgress: true,
            };
        }
        case actionTypes.CONFIG_LIST_SET_PARAMS: {
            save('configs_limit', action.payload.limit);
            save('configs_page', action.payload.page);
            save('configs_sort', action.payload.ordering);
            return {
                ...state,
                params: action.payload,
                inProgress: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default deviceConfigurationsReducer;
