import {createSelector} from 'reselect';
import translate from 'counterpart';
import _ from 'lodash';

import {filteredEntries, logEntries} from './stateSelector';
import {formatDate} from '../../../services/formatters';

export const lastErrors = createSelector(
    logEntries,
    filteredEntries,
    (log_entries, filtered_entries) => ({
        log_entries,
        filtered: filtered_entries.map(
            (entry) => ({
                ...entry,
                ts: formatDate(entry.timestamp),
                message: `${translate(String(entry.error_group), {scope: 'logging.group'})} - ${translate(String(entry.error_code), {scope: ['logging', 'code', String(entry.error_group)]})}`,
            })
        ),
        empty: _.isEmpty(filtered_entries),
    })
);
