import PropTypes from 'prop-types';
import React from 'react';
import TemplateButtons from './TemplateButtons';
import translate from 'counterpart';

const PrinterTemplates = ({template_list, device_id}) => (
    <div className="content">
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>{translate('name', {scope: 'devices.printers'})}</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {template_list.map((template, index) => (
                    <tr className="break-line" key={index}>
                        <td>{template.name}</td>
                        <td className="text-right">
                            <TemplateButtons template={template} device_id={device_id}/>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

PrinterTemplates.propTypes = {
    template_list: PropTypes.arrayOf(
        PropTypes.object
    ),
    device_id: PropTypes.number.isRequired,
};

export default PrinterTemplates;
