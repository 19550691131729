import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {LOG_IN} from '../../redux/actionTypes';
import makeAction from '../../redux/actions/common/makeAction';
import {getLogIn} from '../../redux/selectors/auth/logIn';
import {get} from '../../services/persist';
import {DASHBOARD_PAGE} from '../../configuration/paths';
import translate from 'counterpart';

const printError = (error) => {
    if (!error.response) {
        return translate('app_waiting', {scope: 'login'});
    }
    if (error.response.status === 400) {
        return translate('invalid_credentials', {scope: 'login'});
    }
    return translate('server_error', {scope: 'login'});
};

const Login = ({error, handleSubmit, history}) => {
    const callback = () => history.push(get('previous_location') || DASHBOARD_PAGE);
    return (
        <div className="main-wrapper login">
            <div className="form-wrapper">

                <form className="form-signin" onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit({
                        data: {
                            username: event.target[0].value,
                            password: event.target[1].value,
                        },
                        callback: callback,
                    });
                }}>
                    <div className="gastrofix-logo text-hidden">
                        Gastrofix
                    </div>
                    <label htmlFor="inputUser">{translate('login', {scope: 'login'})}:</label>
                    <div className={`form-group has-feedback ${error.message ? 'has-error' : ''}`}>
                        <input type="text" id="inputUser" className="form-control" placeholder="" required
                            autoFocus/>
                        <span className={`fa ${error.message ? 'fa-close' : 'fa-asterisk'} form-control-feedback`}/>
                    </div>
                    <label htmlFor="inputPassword">{translate('password', {scope: 'login'})}:</label>
                    <div className={`form-group has-feedback ${error.message ? 'has-error' : ''}`}>
                        <input type="password" id="inputPassword" className="form-control" placeholder="" required/>
                        <span className={`fa ${error.message ? 'fa-close' : 'fa-asterisk'} form-control-feedback`}/>
                    </div>
                    <div className={` error-wrap ${error.message ? 'is-error' : 'invisible'}`}>
                        <span className="valid valid-danger">{printError(error)}</span>
                    </div>
                    <button className="btn btn-primary btn-lg btn-block" type="submit">
                        {translate('log_in', {scope: 'login'})}
                        <i className="fa fa-angle-right"/>
                    </button>
                </form>
            </div>
        </div>
    );
};

Login.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    error: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    handleSubmit: makeAction(LOG_IN),
};

export default connect(getLogIn, mapDispatchToProps)(withRouter(Login));
