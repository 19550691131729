import {createSelector} from 'reselect';

export const getNavigationBranch = (state) => state.navigation;

export const editMode = createSelector(
    getNavigationBranch,
    (navigation) => navigation.editMode
);

export const selectedPlugin = createSelector(
    getNavigationBranch,
    (navigation) => navigation.selectedPlugin
);

export const selectedID = createSelector(
    getNavigationBranch,
    (navigation) => navigation.selectedID
);

export const selectedName = createSelector(
    getNavigationBranch,
    (navigation) => navigation.selectedName
);

export const updateInProgress = createSelector(
    getNavigationBranch,
    (navigation) => navigation.updateInProgress
);
