import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import EnabledExtra from './EnabledExtra';
import translate from 'counterpart';

const NetworkHeader = ({name, header, extraEnabled, objEdit, status, editMode, editable}) => (
    <div role="tab" id="headingOne">
        <h4 className={'panel-title' + (extraEnabled || name === 'LAN' || editMode ? '' : ' disabled') + (name !== 'LAN' && objEdit && editMode && editable ? ' with-switch' : '')}>
            <a role="button" data-toggle="collapse" data-parent="#accordion" href={'#' + name}
                aria-expanded="true" aria-controls="collapseOne">
                <i className="fa fa-angle-down left"/>
                {header || name}
                {
                    extraEnabled || name === 'LAN' ?
                        <span className={'status' + (status === 'connected' ? ' status-active' : ' status-inactive')}>
                            ({status ? translate(status, {scope: 'devices.device_network'}) : ''})
                        </span>
                        :
                        <span className="status">({translate('not_enabled', {scope: 'devices.device_network'})})</span>
                }

            </a>
            {name !== 'LAN' && objEdit && editMode && editable &&
            <EnabledExtra/>
            }
        </h4>
    </div>
);

NetworkHeader.propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    extraEnabled: PropTypes.bool,
    editMode: PropTypes.bool,
    editable: PropTypes.bool,
    objEdit: PropTypes.object,
    status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    objEdit: state.deviceConfiguration.ethernets[0],
});

export default connect(mapStateToProps)(NetworkHeader);
