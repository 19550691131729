import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import translate from 'counterpart';

const SectionSelect = ({label, setInput, section, sectionName, fieldName, options}) => (
    <React.Fragment>
        {label ?
            <div className="form-group">
                <span>
                    <label className="control-label col-xs-2">{label}</label>
                    <div className="col-xs-10">
                        <Select
                            value={section[fieldName]}
                            options={options}
                            onChange={(obj) => setInput(
                                sectionName ?
                                    {
                                        [sectionName]: {
                                            ...section,
                                            [fieldName]: obj.value,
                                        },
                                    }
                                    :
                                    {
                                        ...section,
                                        [fieldName]: obj.value,
                                    }
                            )}
                            searchable={false}
                            clearable={false}
                            noResultsText={translate('no_results_found', {scope: 'common'})}
                            placeholder={translate('select_value', {scope: 'common'})}
                        />
                    </div>
                </span>
            </div>
            :
            <Select
                value={section[fieldName]}
                options={options}
                onChange={(obj) => setInput(
                    sectionName ?
                        {
                            [sectionName]: {
                                ...section,
                                [fieldName]: obj.value,
                            },
                        }
                        :
                        {
                            ...section,
                            [fieldName]: obj.value,
                        }
                )}
                searchable={false}
                clearable={false}
                noResultsText={translate('no_results_found', {scope: 'common'})}
                placeholder={translate('select_value', {scope: 'common'})}
            />
        }
    </React.Fragment>
);

SectionSelect.propTypes = {
    fieldName: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    setInput: PropTypes.func,
    section: PropTypes.object,
    sectionName: PropTypes.string,
};

export default SectionSelect;
