import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translate from 'counterpart';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import makeAction from '../../../redux/actions/common/makeAction';
import tooltip from '../../common/tooltip';
import PermissionCheck from '../../authorization/PermissionCheck';
import {manageColumnsButton} from '../../../redux/selectors/devices/manageColumnsButton';
import {DEVICES_LIST_ADD_COLUMN, DEVICES_LIST_REMOVE_COLUMN} from '../../../redux/actionTypes';


const ManageColumnsButton = ({selectableColumns, addColumn, removeColumn}) => (
    <div className="dropdown">
        <OverlayTrigger placement="top" overlay={tooltip(translate('manage_columns', {scope: 'tooltips.device_list'}))}>
            <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                {translate('manage_columns', {scope: 'devices'})}
                <span className="caret" /></button>
        </OverlayTrigger>
        <ul className="dropdown-menu">
            {selectableColumns.map((obj) => (
                <PermissionCheck permission={obj.permission} key={`permissionCheck_${obj.name}`}>
                    <li key={`manageColumn_${obj.name}`}>
                        <span className="link" onClick={() => {
                            if (obj.selected) {
                                removeColumn(obj);
                            } else {
                                addColumn(obj);
                            }
                        }}>
                            {translate(obj.selected ? 'hide_column' : 'show_column', {scope: 'devices.device_details', column: obj.label})}
                        </span>
                    </li>
                </PermissionCheck>
            ))}
        </ul>
    </div>
);

ManageColumnsButton.propTypes = {
    selectableColumns: PropTypes.array.isRequired,
    addColumn: PropTypes.func.isRequired,
    removeColumn: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    addColumn: makeAction(DEVICES_LIST_ADD_COLUMN),
    removeColumn: makeAction(DEVICES_LIST_REMOVE_COLUMN),
};

export default connect(manageColumnsButton, mapDispatchToProps)(ManageColumnsButton);
