import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {permissionsSelector} from '../../redux/selectors/auth/stateSelector';
import {hasUserPermission} from '../../services/auth';

const PermissionCheck = ({children, permission, permissions, fallbackComponent = null}) => (
    hasUserPermission(permissions, permission) ? children : fallbackComponent
);

PermissionCheck.propTypes = {
    children: PropTypes.any,
    permissions: PropTypes.array,
    permission: PropTypes.any,
    fallbackComponent: PropTypes.any,
};

const mapStateToProps = (state) => ({
    permissions: permissionsSelector(state),
});

export default connect(
    mapStateToProps,
    null
)(PermissionCheck);
