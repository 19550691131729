import {all, call, put, take, takeLatest} from 'redux-saga/effects';
import * as actionTypes from '../../actionTypes';
import {get} from '../../../services/persist';
import request, {GET, PUT} from '../../../services/request';
import {API_URL} from '../../../configuration/config';
import {ERROR as get_error_notification, INFO as get_info_notification, SUCCESS as get_success_notification} from '../../../configuration/notifications';
import {DASHBOARD_PAGE} from '../../../configuration/paths';
import {handleError} from '../errors';

function *accept() {
    while (true) {
        try {
            const {payload, callback} = yield take(actionTypes.CHANGE_DEVICE_STATUS);
            yield put({type: actionTypes.CHANGE_IN_PROGRESS});
            const path = DASHBOARD_PAGE;
            const response = yield request(PUT, API_URL + `devices/${payload.id}/status/`, {
                status: payload.status,
                preconfig_id: payload.preconfig_id,
            });
            yield put({type: actionTypes.CHANGE_SUCCESS, payload: response.data});
            if (callback) {
                yield call(callback, path);
            }
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Status changed'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.CHANGE_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Status change failed'),
            });
        }
    }
}

function *retryProvisioning() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.RETRY_PROVISIONING);
            yield put({type: actionTypes.CHANGE_IN_PROGRESS});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Device ${payload.id} is retrying provisioning.`),
            });
            const response = yield request(PUT, API_URL + `devices/${payload.id}/status/`, {status: payload.status});
            yield put({type: actionTypes.CHANGE_SUCCESS, payload: response.data});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_success_notification('Status changed'),
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.CHANGE_FAILED, error: e});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_error_notification('Status change failed'),
            });
        }
    }
}

function *updateDeviceSoft() {
    while (true) {
        try {
            const {payload} = yield take(actionTypes.INSTALL_SOFT_VERSION);
            yield put({type: actionTypes.CHANGE_IN_PROGRESS});
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification('Soft update installation in progress. It may take few minutes to finish.'),
            });
            yield put({
                type: actionTypes.SOFT_INSTALL_IN_PROGRESS,
                id: payload.id,
            });
            const response = yield (request(PUT, API_URL + `devices/${payload.id}/packages/`, {soft_version: payload.soft_version}));
            yield put({
                type: actionTypes.SOFT_UPDATE_SUCCESS,
                payload: response.data,
            });
        } catch (e) {
            console.error(e);
            yield handleError(e);
            yield put({type: actionTypes.SOFT_UPDATE_FAILED, error: e});
        }
    }
}

function *sendMultipleActions(action) {
    try {
        yield put({type: actionTypes.MULTIPLE_ACTION_IN_PROGRESS});
        yield put({
            type: actionTypes.ADD_NOTIFICATION,
            notification: get_info_notification(`Devices ${action.payload.devices} in progress`),
        });
        const response = yield (request(PUT, `${API_URL}devices/multiple/`, action.payload));
        yield put({
            type: actionTypes.MULTIPLE_ACTION_SUCCESS,
            payload: response.data,
        });
        yield put({
            type: actionTypes.ADD_NOTIFICATION,
            notification: get_success_notification(`Action: ${action.payload.action} for devices: ${action.payload.devices} success`),
        });
        if (action.payload.eta !== null) {
            yield put({
                type: actionTypes.ADD_NOTIFICATION,
                notification: get_info_notification(`Devices will be upgraded on ${action.payload.eta}`),
            });
        }
        yield put({
            type: actionTypes.FETCH_DEVICES,
        });
    } catch (e) {
        console.error(e);
        yield handleError(e);
        yield put({type: actionTypes.MULTIPLE_ACTION_FAILED, error: e});
    }
}

function *fetch({payload = {}}) {
    const filters = JSON.parse(get('devices_filters')) || {};
    const parameters = {
        page: payload.page || get('devices_page') || '',
        limit: payload.limit || get('devices_limit') || '',
        ordering: payload.ordering || get('devices_sort') || '',
        relevant: payload.relevant || filters.relevant,
        status: payload.status || filters.status || '',
        operator_id: payload.operator_id || filters.operator_id,
        mac: payload.mac || filters.mac,
        soft_version: payload.soft_version || filters.soft_version,
        business_id: payload.business_id || filters.business_id,
        name: payload.name || filters.name,
        id: payload.id || filters.id,
        company_id: payload.company_id || filters.company_id,
        plugins: payload.plugins || filters.plugins,
    };
    if (parameters.company_id === 'null') {
        parameters.company_id = '';
        parameters.company__isnull = true;
    }
    const url = `${API_URL}devices/`;
    const urlSearchParams = new URLSearchParams();
    Object.keys(parameters).map((key) => {
        if (parameters[key]) {
            urlSearchParams.set(key, parameters[key]);
        }
        return true;
    });
    const devicesURL = `${url}?${urlSearchParams.toString()}`;

    try {
        const response = yield call(request, GET, devicesURL);
        yield put({type: actionTypes.DEVICES_FETCHED, payload: response.data});
    } catch (e) {
        console.error(e);
        yield handleError(e);
        yield put({type: actionTypes.DEVICES_FETCH_FAILED, error: e});
        if (payload.page > 1) {
            yield put({type: actionTypes.DEVICES_LIST_PAGINATION, payload: {...payload, page: 1}});
        }
    }
}

function *fetchDevices() {
    yield takeLatest([actionTypes.FETCH_DEVICES, actionTypes.DEVICES_LIST_CHANGE_LIMIT, actionTypes.DEVICES_LIST_PAGINATION,
        actionTypes.DEVICES_LIST_CHANGE_SORT, actionTypes.DEVICES_LIST_CLEAR_FILTERS], fetch);
}

function *multiActions() {
    yield takeLatest([actionTypes.MULTIPLE_REBOOT, actionTypes.MULTIPLE_REMOVE, actionTypes.MULTIPLE_INSTALL], sendMultipleActions);
}

export default function *devices() {
    yield all([
        fetchDevices(),
        accept(),
        updateDeviceSoft(),
        multiActions(),
        retryProvisioning(),
    ]);
}
