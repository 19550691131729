import * as actionTypes from '../../actionTypes';
import {TYPE as RESET_AUTHORIZATION_STATE} from '../../actions/authorization/resetState';

const DEFAULT_STATE = {
    login: {},
    list: {},
};

const reducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return DEFAULT_STATE;
        }
        case RESET_AUTHORIZATION_STATE: {
            return {
                ...state,
                login: {},
            };
        }
        case actionTypes.LOG_IN_FAILED: {
            return {
                ...state,
                login: action.error,
            };
        }
        case actionTypes.DEVICES_FETCH_FAILED: {
            return {
                ...state,
                list: action.error,
            };
        }
        default: {
            return state;
        }
    }
};

export default reducer;
